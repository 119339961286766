export interface CardinalOptions {
    // token: string, // OUR api token to fetch a cardinal JWT Token using cardinal JWT mutation
    sandbox?: boolean,
    orderAmount: number, // order amount using float
    orderNumber: string,
    cardNumber: string,
    cardExpMonth: number,
    cardExpYear: number,
    billingAddress?: {
        address1?: string,
        city?: string,
        state?: string,
        zipCode?: string
    },
    resolve?: (response: _.Omit<CardinalResponse, 'status' | 'loading' | 'message'> |
        PromiseLike<_.Omit<CardinalResponse, 'status' | 'loading' | 'message'>>) => void,
    reject?: (error?: any) => void,
    cardinalJWT?: string,
}

export interface CardinalResponse {
    status: "PROCESSING" | "SUCCESS" | "FAILED",
    loading?: boolean,
    data?: any,
    jwt?: string
    message?: string
}

export interface CardinalState {
    visible?: boolean,
    response?: CardinalResponse,
    options?: CardinalOptions,
}

export const initialState: CardinalState = {
    visible: false
}