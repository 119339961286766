import { registerReducer, Action } from "../../common_modules/providers/ApolloGraphQL/reducer/reducer";
import { State } from "../../common_modules/providers/ApolloGraphQL/reducer/state";
import { Dispatch } from "react";
import { appIdentifier } from "../../identifier";
import { getItem, removeItem, setItem } from "./storage";
import { localAuthenticationBiometric, localIsEnableBiometricAuth } from "./localAuthentication";

export interface CustomerAuthData {
    username: string;
    token: string; // set the token manually, does not call the api
    expireAt: string;
    refreshToken: string;
    biometricType?: number;
    enableBiometricAuth?: boolean;
}

export interface IMerchantAuthData {
    token: string
}

export const KEY_AUTH = appIdentifier !== 'CLIENT' ? 'bearerToken' : 'customer-auth';

const NAME = "setAuthMethod";

interface Data extends Action {
    authMethod: "BIOMETRIC" | "SECRET";
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    return {
        ...state,
        authMethod: data.authMethod
    };
}

export const getCustomerAuthData = async (): Promise<CustomerAuthData | IMerchantAuthData | null> => {
    const result = await getItem(KEY_AUTH, true);
    if (appIdentifier !== 'CLIENT') {
        return result ? JSON.parse(result) as IMerchantAuthData : null;
    }
    return result ? JSON.parse(result) as CustomerAuthData : null;
}

export const setCustomerAuthData = async (data: CustomerAuthData | string, rememberMe = false) => {
    if (!data) throw new Error("Data auth can`t empty");

    if (typeof data !== 'string' && appIdentifier === 'CLIENT') {
        const inStore = await getCustomerAuthData();
        const newAuthData: CustomerAuthData = {
            ...data,
            biometricType: inStore === null ? 0 : data.biometricType,
            enableBiometricAuth: inStore === null ? false : data.enableBiometricAuth
        }
        return await setItem(KEY_AUTH, JSON.stringify(newAuthData), true, rememberMe);
    }

    return await setItem(KEY_AUTH, JSON.stringify({ token: data }), true, rememberMe);
}

export const removeCustomerAuthData = async () => {
    return await removeItem(KEY_AUTH, true);
}

export const authenticationBiometric = async () =>
    await localAuthenticationBiometric();


export const isEnableBiometricAuth = async () =>
    await localIsEnableBiometricAuth();

export const setAuthenticationMethod = (dispatch: Dispatch<Action>, authMethod: "BIOMETRIC" | "SECRET") => {
    dispatch({ type: NAME, authMethod })
}