import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Title, useTheme, Text } from 'react-native-paper';

interface Props {
    title?: string
}

/**
 * @function Component HeaderTitle 
 * @param {string} title Title
 */
const HeaderTitle = (props: Props) => {
    const { colors } = useTheme();
    return (
        <View style={[styles.container, { backgroundColor: colors.primary }]}>
            <Title numberOfLines={1}
                style={{
                    color: colors.surface,
                    fontFamily: 'Montserrat_Bold',
                    textAlign: 'center',
                    width: '90%'
                }}>
                {props.title ? props.title.toUpperCase() : '...'}
            </Title>
        </View >
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

export default HeaderTitle;