import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity, Image } from "react-native";
import { BaseButton, ScrollView } from "react-native-gesture-handler";
import { ProgressBar, Text } from "react-native-paper";
import { getPrice } from "../../lib/Transactions/transactions";
import { getPlatform, isWebPlatform } from "../../lib/PlatformDevice";
import { CustomInput } from "../CustomInputs/CustomInputAdornment";
import { InputHelperError } from "../CustomInputs/InputHelperText";

interface Props {
  label?: string;
  array: any[];
  field: string;
  valueInitial?: string;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  onChangeText: (value: string) => void;
  inputLeftProps?: JSX.Element;
  inputRightProps?: JSX.Element;
  value: (data: any) => void;
  clearSelected: boolean;
  clearArray: () => void;
}

/**
 * @function Component Autocomplete
 * @param {string} array Array of records to filter
 * @param {string} label Label Input
 * @param {string} field field name show match
 * @param {string} valueInitial Value initial Input
 * @param {boolean} error Status error
 * @param {boolean} loading Status loading
 * @param {string} errorMessage Error Message
 * @param {boolean} clearSelected: Clean input
 * @param {Function} clearArray: Clean Array Data
 * @param {Function} onChangeText Callback handle change input value
 * @param {Function} value Callback handle set value
 * @param {JSX.Element} inputLeftProps Content left side of input
 * @param {JSX.Element} inputRightProps Content right side of input
 */
const Autocomplete = ({
  label,
  array,
  field,
  valueInitial,
  loading,
  error,
  errorMessage,
  onChangeText,
  clearArray,
  inputLeftProps,
  inputRightProps,
  clearSelected,
  value,
}: Props) => {
  const initial = !valueInitial ? "" : valueInitial;
  const [autoData, setAutoData] = useState({
    text: initial,
    selected: initial,
    error: true,
  });

  const [onBlur, setOnBlur] = useState(true);
  const navigation = useNavigation();

  useEffect(() => {
    setAutoData((prevData) => ({
      ...prevData,
      error: false,
      text: "",
      selected: "",
    }));
  }, [clearSelected]);

  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () => {
      clearArray();
      setAutoData((prevData) => ({
        ...prevData,
        error: false,
        text: "",
        selected: "",
      }));
    });
    return unsubscribe;
  }, [navigation]);

  const renderHeaderAndroid = useCallback(
    (data) => (
      <View key={data[0].carrier.id}>
        <BaseButton
          key={`head${data[0].carrier.id}`}
          style={{
            zIndex: 99999999,
            flexDirection: "row",
            alignItems: "center",
            padding: 10,
            borderColor: "#D1D2DE",
            backgroundColor: "#FFFFFF",
            borderBottomWidth: 2,
            elevation: 99999999,
          }}
          onPress={() => null}
        >
          <Image
            style={styles.tinyLogo}
            source={{ uri: data[0].carrier.logoUrl }}
          />
          <Text
            style={[styles.autocompleteText, { fontFamily: "Montserrat_Bold" }]}
          >
            {data[0].carrier.name.toUpperCase()}
          </Text>
        </BaseButton>
        {Object.keys(data).map((val, key) => renderItemAndroid(data, key))}
      </View>
    ),
    []
  );

  const renderItemAndroid = useCallback(
    (data, index) => (
      <BaseButton
        style={{
          zIndex: 99999999,
          elevation: 99999999,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        key={`opt${data[index].id}`}
        onPress={() => {
          setAutoData({
            selected: data[index][field],
            text: data[index][field],
            error: false,
          });
          value(data[index]);
        }}
      >
        <Text
          numberOfLines={1}
          style={[
            styles.autocompleteText,
            { fontFamily: "Montserrat_Light", width: "70%" },
          ]}
        >
          {data[index][field]}
        </Text>
        <Text
          style={[styles.autocompleteText, { fontFamily: "Montserrat_Bold" }]}
        >
          {getPrice(data[index].price)}
        </Text>
      </BaseButton>
    ),
    []
  );

  const renderHeader = useCallback(
    (data) => (
      <View key={data[0].carrier.id}>
        <TouchableOpacity
          accessible={false}
          accessibilityLabel="Group"
          disabled={true}
          style={{
            zIndex: 99999999,
            flexDirection: "row",
            alignItems: "center",
            padding: 10,
            borderStyle: getPlatform() === "ios" ? "solid" : "dashed",
            borderColor: "#D1D2DE",
            backgroundColor: "#ffffff",
            borderBottomWidth: 2,
          }}
          key={`head${data[0].carrier.id}`}
          onPress={() => null}
        >
          <Image
            style={styles.tinyLogo}
            key={`img${data[0].carrier.id}`}
            source={{ uri: data[0].carrier.logoUrl }}
          />
          <Text
            style={[styles.autocompleteText, { fontFamily: "Montserrat_Bold" }]}
          >
            {data[0].carrier.name.toUpperCase()}
          </Text>
        </TouchableOpacity>
        {Object.keys(data).map((val, key) => renderItem(data, key))}
      </View>
    ),
    []
  );

  const renderItem = useCallback(
    (data, index) => (
      <TouchableOpacity
        accessible={true}
        accessibilityLabel="Selecciona"
        style={{
          zIndex: 99999999,
          elevation: getPlatform() === "android" ? 99999999 : 0,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        key={`opt${data[index].id}`}
        onPress={() => {
          setAutoData({
            selected: data[index][field],
            text: data[index][field],
            error: false,
          });
          value(data[index]);
        }}
      >
        <Text
          numberOfLines={1}
          style={[
            styles.autocompleteText,
            { fontFamily: "Montserrat_Light", width: "70%" },
          ]}
        >
          {data[index][field]}
        </Text>
        <Text
          style={[styles.autocompleteText, { fontFamily: "Montserrat_Bold" }]}
        >
          {getPrice(data[index].price)}
        </Text>
      </TouchableOpacity>
    ),
    []
  );

  const _listOptions = () => {
    return (
      <View
        style={{
          position: "absolute",
          zIndex: 99999999,
          elevation: getPlatform() === "android" ? 99999999 : 0,
          width: "100%",
          top: 0,
          maxHeight: 250,
        }}
      >
        <ScrollView
          showsVerticalScrollIndicator={isWebPlatform() ? false : true}
          keyboardShouldPersistTaps="handled"
          style={styles.autocomplete}
        >
          {array.map((data, key) => renderHeaderAndroid(data))}
        </ScrollView>
      </View>
    );
  };
  return (
    <View>
      <View nativeID="disable-outline">
        <CustomInput
          style={{ textAlign: "auto" }}
          label={label}
          mode="flat"
          onBlur={() => {
            setTimeout(() => {
              setOnBlur(true);
            }, 150);
          }}
          onKeyPress={() => {}}
          onFocus={() => setOnBlur(false)}
          value={autoData.text}
          error={autoData.error}
          right={inputRightProps ? inputRightProps : undefined}
          onChangeText={(text) => {
            setAutoData((prevState) => ({ ...prevState, text }));
            onChangeText(text);
          }}
          numberOfLines={1}
        />
        <InputHelperError isError={error} message={errorMessage} />
      </View>
      <View>
        {loading ? <ProgressBar indeterminate color="#efefef" /> : null}
        {!onBlur ? _listOptions() : null}
      </View>
    </View>
  );
};

export default Autocomplete;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  autocomplete: {
    backgroundColor: "#efefef",
    zIndex: 999999999,
    elevation: getPlatform() === "android" ? 99999999 : 0,
    borderBottomStartRadius: 5,
    borderBottomEndRadius: 5,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  autocompleteText: {
    zIndex: 999999999,
    padding: 10,
    fontSize: 17,
  },
  tinyLogo: {
    width: 24,
    height: 24,
    resizeMode: "stretch",
  },
});
