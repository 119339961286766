import { ObservableQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { DataTable, Text, useTheme, Avatar, ProgressBar } from 'react-native-paper';
import SearchBarComponent from '../../common_modules/components/SeaarchBar/Searchbar';
import _ from 'lodash';
import { useMediaQuery } from "react-responsive";
import { CustomText } from '../../common_modules/components/Text/Text';
import { getDisplayWidth, getDisplayHeight, isIosPlatform, getPlatform, IS_DESKTOP_OR_LAPTOP, IS_SMARTPHONE } from '../../common_modules/lib/PlatformDevice';
import { getPrice } from '../../common_modules/lib/Transactions/transactions';
import { Product } from '../Market/reducer/state';
import { productFragment2Product } from '../Market/reducer/actions/loadProducts';
import { PRODUCT_LIST } from '../Market/MarketWizard/StepProductList.graphql';
import { useNavigation } from '@react-navigation/native';
import { useMarket } from '../Market/hook/useMarket';
import Footer from '../../common_modules/components/Footer/Footer';
import HeaderTitle from '../../navigation/Header/HeaderTitle';
import { useApolloGraphQL } from '../../common_modules/providers/ApolloGraphQL/hook/useGraphqlApolloClient';

let searchDebounceTimer = null;

const ProductsPage = () => {
    const { clerk } = useMarket();
    const { colors } = useTheme();
    const navigation = useNavigation();
    const { client } = useApolloGraphQL();
    const [refreshed, setRefreshed] = useState(false);
    const [productsQuery, setProductsQuery] = useState<ObservableQuery>(undefined);
    const [products, setProducts] = useState<Array<Product>>([]);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [search, setSearch] = useState('');
    const [endCursor, setEndCursor] = useState(undefined);
    const [hasMore, setHasMore] = useState(false);
    const [focused, setFocused] = useState(true);
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
    const isSmartPhone = useMediaQuery(IS_SMARTPHONE);

    // refresh on focus
    if (productsQuery && !refreshed && focused && !loadingProducts) {
        setRefreshed(true);
        productsQuery.refetch({ search });
    }

    if (productsQuery && !focused && refreshed) {
        setRefreshed(false);
    }

    useEffect(() => {
        const unsubscribe = navigation.addListener('blur', () => {
            setFocused(false)
        });

        return unsubscribe;
    }, [navigation]);

    useEffect(() => {
        const query = client.watchQuery({
            query: PRODUCT_LIST,
            fetchPolicy: 'no-cache'

        });

        if (focused && !productsQuery) {
            query.subscribe((result) => {
                setLoadingProducts(result.loading);
                if (result.data) {
                    setEndCursor(result.data.products.all.pageInfo.endCursor);
                    setHasMore(result.data.products.all.pageInfo.hasNextPage);
                    const products: Product[] = [];
                    _.forEach(result.data.products.all.edges, (edge) => {
                        products.push(productFragment2Product(edge.node));
                    });
                    setProducts(products);
                }
            });
            setProductsQuery(query);
        }
    }, [productsQuery, client, search]);

    const handleSearch = async (search) => {
        setSearch(search);

        clearTimeout(searchDebounceTimer);
        searchDebounceTimer = setTimeout(() => {
            productsQuery.refetch({ search });
        }, 500);
    };

    const loadMore = () => {
        if (focused && !loadingProducts && hasMore) {
            setLoadingProducts(true);
            productsQuery.fetchMore({
                variables: {
                    search,
                    endCursor,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                    return {
                        ...fetchMoreResult,
                        products: {
                            ...fetchMoreResult.products,
                            all: {
                                ...fetchMoreResult.products.all,
                                pageInfo: fetchMoreResult.products.all.pageInfo,
                                totalCount: fetchMoreResult.products.all.totalCount,
                                edges: [
                                    ...prevResult.products.all.edges,
                                    ...fetchMoreResult.products.all.edges,
                                ]
                            }
                        }
                    };
                }
            });
        }
    };

    const renderItem = useCallback(({ item, index }) =>
        <DataTable.Row
            key={item.id}
            style={{
                backgroundColor: colors.surface,
                borderStyle: !isIosPlatform() ? 'dashed' : undefined,
                borderTopEndRadius: index == 0 ? 10 : 0,
                borderTopStartRadius: index == 0 ? 10 : 0,
                borderBottomEndRadius: index + 1 == products.length && !hasMore && loadingProducts ? 10 : 0,
                borderBottomStartRadius: index + 1 == products.length && !hasMore && loadingProducts ? 10 : 0,
                width: isSmartPhone ? getDisplayWidth() * 3 : undefined
            }}>

            <DataTable.Cell style={{ alignItems: 'center' }}>
                {item.carrier &&
                    <Avatar.Image
                        size={40}
                        style={{ backgroundColor: 'transparent' }}
                        source={{ uri: item.carrier.logoUrl }} />
                }
            </DataTable.Cell>

            <DataTable.Cell >
                <Text>
                    {item.sku}
                </Text>
            </DataTable.Cell>

            <DataTable.Cell>
                <Text >{item.carrier ? item.carrier.name : ''} </Text>
            </DataTable.Cell>

            <DataTable.Cell >
                <Text>{item.name}</Text>
            </DataTable.Cell>

            <DataTable.Cell numeric>
                <Text>{!item.variablePrice ? getPrice(item.price) : getPrice(item.minPrice - item.maxPrice)}</Text>
            </DataTable.Cell>

            {clerk.settings.showProductDiscounts &&
                <>
                    <DataTable.Cell numeric>
                        {item.discountMode === 'FIXED' &&
                            <Text>{getPrice(item.discount)}</Text>
                        }
                        {item.discountMode === 'RATE' &&
                            <Text>{Math.abs(Number(item.discount)).toFixed(2)}%</Text>
                        }
                    </DataTable.Cell>

                    <DataTable.Cell numeric>
                        {!item.variablePrice && item.discountMode === 'FIXED' &&
                            <Text>{getPrice(item.discount)}</Text>
                        }
                        {!item.variablePrice && item.discountMode === 'RATE' &&
                            <Text>{getPrice(item.price - (item.discount * item.price / 100))}</Text>
                        }
                        {item.variablePrice && '-'}
                    </DataTable.Cell>
                </>
            }
        </DataTable.Row>, [products]
    );

    const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
        const paddingToBottom = 20;
        return layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom;
    };

    return (
        <>
            <HeaderTitle title="Products" />
            <SearchBarComponent search={search} onSearch={handleSearch} />
            <ScrollView horizontal={isDesktopOrLaptop ? false : true}>
                <DataTable style={{ paddingHorizontal: 20, paddingTop: 20 }}>
                    <DataTable.Header style={[styles.DtHeader, { width: isSmartPhone ? getDisplayWidth() * 3 : undefined }]}>

                        <DataTable.Title >
                            <CustomText type="sBold" color={colors.surface}>Logo</CustomText>
                        </DataTable.Title>

                        <DataTable.Title >
                            <CustomText type="sBold" color={colors.surface}>SKU</CustomText>
                        </DataTable.Title>

                        <DataTable.Title >
                            <CustomText type="sBold" color={colors.surface}>Carrier</CustomText>
                        </DataTable.Title>

                        <DataTable.Title >
                            <CustomText type="sBold" color={colors.surface}>Product</CustomText>
                        </DataTable.Title>

                        <DataTable.Title numeric>
                            <CustomText type="sBold" color={colors.surface}>Price</CustomText>
                        </DataTable.Title>

                        {clerk.settings.showProductDiscounts &&
                            <>
                                <DataTable.Title numeric>
                                    <CustomText type="sBold" color={colors.surface}>Discount</CustomText>
                                </DataTable.Title>
                                <DataTable.Title numeric>
                                    <CustomText type="sBold" color={colors.surface}>You Pay</CustomText>
                                </DataTable.Title>
                            </>
                        }
                    </DataTable.Header>

                    {!products.length && <ProgressBar indeterminate color={colors.primary} />}
                    <View
                        style={{
                            height: isSmartPhone ? getPlatform() === 'ios' ? getDisplayHeight() * 0.55 :
                                getDisplayHeight() * 0.65 : getDisplayHeight() * 0.60
                        }}>
                        <ScrollView onScroll={({ nativeEvent }) => {
                            if (isCloseToBottom(nativeEvent) && focused && hasMore && !loadingProducts) {
                                loadMore();
                            }
                        }}
                            scrollEventThrottle={400}>
                            {products.map((item, index) => (
                                renderItem({ item, index })
                            ))}
                            {hasMore || search && loadingProducts ? <ProgressBar indeterminate color={colors.primary} /> : null}
                        </ScrollView>
                    </View>
                </DataTable>
            </ScrollView>
            <Footer />
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    DtHeader: {
        backgroundColor: '#d23232',
        borderRadius: 10,
        marginBottom: 20,
    },
    footer: {
        padding: 10,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
    }
});



export default ProductsPage;