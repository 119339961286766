import _ from 'lodash';
import { QUERY_STORE_INFO } from '../../reducer/actions/loadMarketInfo.graphql';
import { loadMarketInfoSuccess } from '../../reducer/actions/loadMarketInfoSuccess';
import { ContextType } from './../context';
import { registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'loadMarketInfo';

registerReducer(NAME, reducer);

function reducer(state: State): State {

    return {
        ...state,
        loadingMarketInfo: true,
    };
}

export const loadMarketInfo = (context: ContextType) => {
    context.apolloGraphQL.client.query({
        query: QUERY_STORE_INFO,
        fetchPolicy: 'no-cache',
    }).then(({ data }) => {
        if (data) {

            // switch to allowed payment method if the saved preferred is not allowed
            if (data.storeInfo.supportedPaymentMethods.length && !_.includes(data.storeInfo.supportedPaymentMethods, data.uiSettings.preferredPaymentMethod)) {
                data.uiSettings.preferredPaymentMethod = data.storeInfo.supportedPaymentMethods[0];
            }

            //if restricted payment method && does not have any default saved, use the first allowed
            if (data.storeInfo.supportedPaymentMethods.length && !data.uiSettings.preferredPaymentMethod) {
                data.uiSettings.preferredPaymentMethod = data.storeInfo.supportedPaymentMethods[0];
            }

            loadMarketInfoSuccess(context, {
                uiSettings: data.uiSettings,
                clerk: {
                    name: data.viewer.name,
                    sandbox: data.viewer.sandbox,
                    settings: data.viewer.clerkSettings
                },
                storeInfo: {
                    number: data.storeInfo.number,
                    name: data.storeInfo.name,
                    consumerAuthentication: data.storeInfo.consumerAuthentication,
                    agent:{
                        ...data.storeInfo.agent,
                    },
                    address: data.storeInfo.address,
                    theme: {
                        primaryColor: data.storeInfo.primaryColor ? data.storeInfo.primaryColor : '#F44336',
                        secondaryColor: data.storeInfo.secondaryColor ? data.storeInfo.secondaryColor : '#F44336',
                    },
                    privateLabelGiftCardSettings: data.storeInfo.privateLabelGiftCardSettings
                }
            });
        }
    });

    context.dispatch({ type: NAME });
};
