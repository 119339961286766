import React from 'react';
import { CountUp } from 'use-count-up'
import { View } from 'react-native'
import { Avatar, Text } from 'react-native-paper'

interface Props {
    current: number | string,
    last: number | string
    money?: boolean
}


export const PeriodComparisonValue = ({ current, last, money }: Props) => {
    const decimalPlaces = 2;
    const countUpProps = {
        isCounting: true,
        start: 0,
        end: last ? parseFloat(last) : 0,
        duration: 2,
        easing: 'easeOutCubic', // easeOutCubic | easeInCubic | linear
        shouldUseToLocaleString: true,
        toLocaleStringParams: {
            locale: undefined, // set locale here
            // set options here
            options: {
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces
            }
        },
        prefix: money ? '$' : '',
        // suffix: " users",
        decimalPlaces: money ? 2 : 0,
        thousandsSeparator: ',',
        decimalSeparator: '.'
    }

    let icon, color;
    if (Number(current) > Number(last)) {
        icon = "show-chart";
        color = "green";
    } else if (Number(current) < Number(last)) {
        icon = "arrow-right-alt";
        color = "blue";
    }

    return (
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text>
                <CountUp {...countUpProps}>
                    {({ value, reset }) => value}
                </CountUp>
            </Text>
            <Avatar.Icon icon={icon} size={24} color={color} style={{ backgroundColor: 'transparent' }} />
        </View>
    );
};
