// import { logger } from '../../../../lib/logger';
import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { BearerToken, State } from '../state';
import { setCustomerAuthData } from '../../../../lib/auth';

const NAME = 'setAPIBearerToken';

interface Data extends Action {
    bearerToken: string,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    // logger && logger.debug('Bearer token loaded', { token: data.bearerToken });
    if (state.persistent) {
        getBearerToken(data.bearerToken);
    }

    return {
        ...state,
        bearerToken: new BearerToken(data.bearerToken)
    };
}

async function getBearerToken(bearerToken: string) {
    setCustomerAuthData(bearerToken);
}

export const setAPIBearerToken = (context: ContextType, data: Omit<Data, 'type'>) => {
    context.dispatch({ type: NAME, ...data });
};
