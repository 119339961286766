import gql from "graphql-tag";
import { TRANSACTION_FRAGMENT } from "../../../../../lib/transactions.graphql";

export const EGIFT_SUBSCRIBE_TRANSACTION_COMPLETE = gql`
    subscription subscribe ($correlationId: String!){
        transactions {
            onFinish(correlationId: $correlationId) {
                ... on OnFinishTransactionEvent {
                    transaction{
                        ... Transaction
                    }
                }
                ... on SubscriptionLink {
                    url
                }
            }
        }
    }
    ${TRANSACTION_FRAGMENT}
`;

