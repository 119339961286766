import React, { useState, useEffect, useRef } from "react";
import { Image, Animated, View } from "react-native";
import { Modal, Portal } from "react-native-paper";
import * as _ from "lodash";
import { getDisplayWidth } from "../../lib/PlatformDevice";

interface Props {
  isOpen: boolean;
  onClose: () => any;
}

/**
 * @function LoadingScanMobile
 * @param {boolean} isOpen Status mounted component
 * @param {Function} onClose Callback handle event unmounted component
 */
export default function LoadingScanMobile({ isOpen, onClose }: Props) {
  const [screenDimensions, setScreenDimensions] = useState({
    width: 0,
    height: 0,
  });
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const animation = () => {
    Animated.sequence([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 600,
        useNativeDriver: true,
      }),
      Animated.timing(fadeAnim, {
        toValue: 0.001,
        duration: 600,
        useNativeDriver: true,
      }),
    ]).start(({ finished }) => {
      if (finished && isOpen) {
        animation();
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      animation();
    } else {
      fadeAnim.setValue(0);
      fadeAnim.stopAnimation();
    }
  }, [isOpen]);

  const handleOnClose = () => {
    fadeAnim.setValue(0);
    fadeAnim.stopAnimation();
    onClose();
  };

  return (
    <Portal>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "flex-end",
        }}
        onLayout={(event) => {
          let { x, y, width, height } = event.nativeEvent.layout;
          setScreenDimensions({ width, height });
        }}
      >
        <Modal
          visible={isOpen}
          onDismiss={handleOnClose}
          contentContainerStyle={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#d23232",
            width: 200,
            height: 200,
            borderRadius: 100,
            left: getDisplayWidth() / 2 - 100,
          }}
          dismissable={false}
        >
          <Animated.Image
            style={{
              opacity: fadeAnim,
              justifyContent: "center",
              alignItems: "center",
              width: 220,
              height: 220,
            }}
            source={require("../../../../assets/img/icon_qr.png")}
          ></Animated.Image>
        </Modal>
      </View>
    </Portal>
  );
}
