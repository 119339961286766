import { ObservableQuery } from '@apollo-client';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { DataTable, Divider, ProgressBar, Text, useTheme } from 'react-native-paper';
import SearchBarComponent from '../../common_modules/components/SeaarchBar/Searchbar';
import _ from 'lodash';
import { useMediaQuery } from "react-responsive";
import { getDisplayWidth, getDisplayHeight, isIosPlatform, IS_DESKTOP_OR_LAPTOP, IS_SMARTPHONE } from '../../common_modules/lib/PlatformDevice';
import { TRANSACTION_LIST } from '../Market/MarketWizard/StepTransactions.graphql';
import { getPrice } from '../../common_modules/lib/Transactions/transactions';
import { useNavigation } from '@react-navigation/native';
import Footer from '../../common_modules/components/Footer/Footer';
import TimeAgo from 'react-native-timeago';
import HeaderTitle from '../../navigation/Header/HeaderTitle';
import { useApolloGraphQL } from '../../common_modules/providers/ApolloGraphQL/hook/useGraphqlApolloClient';

interface Transaction {
    id: string,
    number: string,
    date: Date
    status: string,
    accountNumber: string,
    productName: string,
    carrierName: string,
    merchantCost: number,
    grossTotal: number,
    adjustmentsTotal: number,
    total: number,
}

let searchDebounceTimer = null;

const Transactions = () => {
    const { colors } = useTheme();
    const { client } = useApolloGraphQL();
    const [refreshed, setRefreshed] = useState(false);
    const [transactionsQuery, setTransactionsQuery] = useState<ObservableQuery>(undefined);
    const [transactions, seTransactions] = useState<Array<Transaction>>([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [endCursor, setEndCursor] = useState(undefined);
    const [hasMore, setHasMore] = useState(false);
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
    const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
    const [focused, setFocused] = useState(true);
    const navigation = useNavigation();

    useEffect(() => {
        const unsubscribe = navigation.addListener('blur', () => {
            setFocused(false)
        });

        return unsubscribe;
    }, [navigation]);

    useEffect(() => {
        const query = client.watchQuery({
            query: TRANSACTION_LIST,
            fetchPolicy: "cache-and-network",
        });

        // refresh on focus
        if (transactionsQuery && !refreshed && focused && !loading) {
            setRefreshed(true);
            transactionsQuery.refetch({ search });
        }

        if (transactionsQuery && !focused && refreshed) {
            setRefreshed(false);
        }

        if (focused && !transactionsQuery) {
            query.subscribe((result) => {
                setLoading(result.loading);
                if (result.data) {
                    setEndCursor(result.data.transactions.all.pageInfo.endCursor);
                    setHasMore(result.data.transactions.all.pageInfo.hasNextPage);
                    const transactions: Array<Transaction> = [];
                    _.forEach(result.data.transactions.all.edges, (edge) => {
                        transactions.push({
                            id: edge.node.id,
                            number: edge.node.number,
                            date: edge.node.date,
                            status: edge.node.status,
                            productName: edge.node.product.name,
                            accountNumber: edge.node.inputs.accountNumber,
                            carrierName: edge.node.product.carrier ? edge.node.product.carrier.name : undefined,
                            merchantCost: edge.node.merchantCost,
                            grossTotal: edge.node.grossTotal,
                            adjustmentsTotal: edge.node.adjustmentsTotal,
                            total: edge.node.total,
                        });
                    });
                    seTransactions(transactions);
                }
            });
            setTransactionsQuery(query);
        }
    }, [client, loading, focused, refreshed, search, transactionsQuery]);

    const handleSearch = (search) => {
        setSearch(search);

        clearTimeout(searchDebounceTimer);
        searchDebounceTimer = setTimeout(() => {
            transactionsQuery.refetch({ search });
        }, 100);
    };

    const loadMore = () => {
        if (focused && !loading && hasMore) {
            setLoading(true);
            transactionsQuery.fetchMore({
                variables: {
                    search,
                    endCursor,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                    return {
                        ...fetchMoreResult,
                        transactions: {
                            ...fetchMoreResult.transactions,
                            all: {
                                ...fetchMoreResult.transactions.all,
                                pageInfo: fetchMoreResult.transactions.all.pageInfo,
                                totalCount: fetchMoreResult.transactions.all.totalCount,
                                edges: [
                                    ...prevResult.transactions.all.edges,
                                    ...fetchMoreResult.transactions.all.edges,
                                ]
                            }
                        }
                    };
                }
            });
        }
    };

    const renderItem = useCallback(({ item, index }) =>
        <DataTable.Row
            key={item.id}
            style={{
                backgroundColor: colors.surface,
                borderStyle: !isIosPlatform() ? 'dashed' : undefined,
                borderTopEndRadius: index == 0 ? 10 : 0,
                borderTopStartRadius: index == 0 ? 10 : 0,
                borderBottomEndRadius: index + 1 == transactions.length ? 10 : 0,
                borderBottomStartRadius: index + 1 == transactions.length ? 10 : 0,
                width: isSmartPhone ? getDisplayWidth() * 3 : undefined
            }}>
            <DataTable.Cell>
                <Text>{item.status}</Text>
            </DataTable.Cell>
            <DataTable.Cell >
                <View>
                    <Text style={{ fontFamily: 'Montserrat_Bold' }}>
                        {item.number}
                    </Text>
                    {/* <TimeAgo time={item.date} /> */}
                </View>
            </DataTable.Cell>
            <DataTable.Cell style={{ flex: isSmartPhone ? 3 : 2, paddingVertical: 10 }}>
                <View style={{ justifyContent: 'center' }}>
                    <Text style={{ fontFamily: 'Montserrat_Bold' }}>{item.productName}</Text>
                    <Text ><TimeAgo time={item.date} /></Text>
                </View>
            </DataTable.Cell>
            <DataTable.Cell style={{ flex: isSmartPhone ? 2 : 1, marginLeft: !isSmartPhone ? 20 : undefined }}>
                <View>
                    <Text style={{ fontFamily: 'Montserrat_Bold' }}>{item.accountNumber}</Text>
                </View>
            </DataTable.Cell>
            <DataTable.Cell style={{ flex: 1 }}>
                <Text>{getPrice(item.merchantCost)}</Text>
            </DataTable.Cell>
            <DataTable.Cell style={{ flex: 1 }}>
                <Text>{getPrice(item.grossTotal)}</Text>
            </DataTable.Cell>
            <DataTable.Cell style={{ flex: 1 }}>
                <Text>{getPrice(item.adjustmentsTotal, false)}</Text>
            </DataTable.Cell>
            <DataTable.Cell style={{ flex: 1 }}>
                <Text style={{ fontFamily: 'Montserrat_Bold' }}>
                    {getPrice(item.total)}
                </Text>
            </DataTable.Cell>
        </DataTable.Row>, [transactions]
    );

    const divider = () => {
        return <Divider
            style={{
                borderStyle: 'dashed',
                borderWidth: 0.5,
                borderColor: '#D1D2DE',
            }} />
    }

    const renderFooter = () =>
        // Footer View with Loader
        hasMore && <ProgressBar indeterminate color={colors.primary} />;

    const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
        const paddingToBottom = 20;
        return layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom;
    };

    return (
        <>
            <HeaderTitle title="Transactions" />
            <SearchBarComponent search={search} onSearch={handleSearch} />
            <ScrollView horizontal={isDesktopOrLaptop ? false : true}>
                <DataTable style={{ paddingHorizontal: 20, paddingTop: 20, flex: 1 }}>
                    <DataTable.Header
                        style={[styles.DtHeader, {
                            width: isSmartPhone ? getDisplayWidth() * 3 : undefined,
                            backgroundColor: colors.primary
                        }]}>
                        <DataTable.Title >
                            <Text style={{ fontFamily: 'Montserrat_Semi_Bold', color: colors.surface }}>
                                Status
                            </Text>
                        </DataTable.Title>
                        <DataTable.Title >
                            <Text style={{ fontFamily: 'Montserrat_Semi_Bold', color: colors.surface }}>
                                Number
                            </Text>
                        </DataTable.Title>
                        <DataTable.Title style={{ flex: isSmartPhone ? 3 : 2 }}>
                            <Text style={{ fontFamily: 'Montserrat_Semi_Bold', color: colors.surface }}>
                                Product
                            </Text>
                        </DataTable.Title>
                        <DataTable.Title style={{ flex: isSmartPhone ? 2 : 1 }}>
                            <Text style={{ fontFamily: 'Montserrat_Semi_Bold', color: colors.surface }}>
                                Account Number
                            </Text>
                        </DataTable.Title>
                        <DataTable.Title >
                            <Text style={{ fontFamily: 'Montserrat_Semi_Bold', color: colors.surface }}>
                                Cost
                            </Text>
                        </DataTable.Title>
                        <DataTable.Title >
                            <Text style={{ fontFamily: 'Montserrat_Semi_Bold', color: colors.surface }}>
                                Sales Price
                            </Text>
                        </DataTable.Title>
                        <DataTable.Title >
                            <Text style={{ fontFamily: 'Montserrat_Semi_Bold', color: colors.surface }}>
                                Adjustment
                            </Text>
                        </DataTable.Title>
                        <DataTable.Title >
                            <Text style={{ fontFamily: 'Montserrat_Semi_Bold', color: colors.surface }}>
                                Total
                            </Text>
                        </DataTable.Title>
                    </DataTable.Header>
                    {!transactions.length ? <ProgressBar indeterminate color={colors.primary} /> : null}
                    <View style={{ height: isSmartPhone ? getDisplayHeight() * 0.65 : getDisplayHeight() * 0.60 }}>
                        <ScrollView onScroll={({ nativeEvent }) => {
                            if (isCloseToBottom(nativeEvent) && focused && hasMore && !loading) {
                                loadMore();
                            }
                        }}
                            scrollEventThrottle={400}>
                            {transactions.map((item, index) => (
                                renderItem({ item, index })
                            ))}
                            {hasMore && <ProgressBar indeterminate color={colors.primary} />}
                        </ScrollView>
                    </View>
                </DataTable>
            </ScrollView>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    DtHeader: {
        borderRadius: 10,
        marginBottom: 20,
    },
    footer: {
        padding: 10,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
    }
});



export default Transactions;