import React from "react";
import { useTheme, Appbar, IconButton, Button, Text } from "react-native-paper";
import { Image, StyleSheet, View } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import Login from "../../screens/Login/Login";
import { useMarket } from "../../screens/Market/hook/useMarket";
import Home from "../../screens/Home/Home";
import { useMediaQuery } from "react-responsive";
import ProductsList from "../../screens/Products/ProductsList";
import ProductsPage from "../../screens/Products/ProductsPage";
import CarrierList from "../../screens/Carriers/CarriersList";
import ProductForm from "../../screens/Products/ProductForm";
import Settings from "../../screens/Settings/Settings";
import DashBoard from "../../screens/DashBoard/DashBoard";
import ScanCode from "../../screens/ScanCode/ScanCode";
import Finish from "../../screens/Finish/Finish";
import Transactions from "../../screens/Transactions/Transactions";
import CodeScannerWeb from "../../common_modules/components/BarCodeScanner/CodeScannerWeb";
import SignUp from "../../common_modules/screens/SignUp/Signup";
import { IS_SMARTPHONE } from "../../common_modules/lib/PlatformDevice";
import ResetPassword from "../../common_modules/screens/ResetPassword/ResetPassword";
import { usePOSLogin } from "../../screens/Login/hook/usePOSLogin";
import ContactListScreen from "../../common_modules/screens/ContactList/ContactList";

const Stack = createStackNavigator();

const Header = ({ scene, previous, navigation }) => {
  const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
  const { clerk, resetWizard, storeInfo, loadingMarketInfo } = useMarket();
  const theme = useTheme();
  const { colors } = useTheme();
  const { logout } = usePOSLogin();
  const { options } = scene.descriptor;
  const title =
    options.headerTitle !== undefined
      ? options.headerTitle
      : options.title !== undefined
      ? options.title
      : scene.route.name;
  const isHome = scene.route.name === "Home";
  const isFinish = scene.route.name === "Finish";
  const redirectToHome = () => {
    if (isFinish) {
      resetWizard();
    }
    navigation.navigate("Home");
  };

  let logo_web = require("../../../assets/img/logo.png")

  if (theme.agent && theme.agent.logo) {
    logo_web = theme.agent.logo;
  }

  return (
    <Appbar.Header
      theme={{
        colors: {
          primary: colors.surface,
        },
      }}
    >
      {!isFinish && (
        <>
          {previous ? (
            isSmartPhone ? (
              <View
                style={[
                  styles.containerBtnBack,
                  { backgroundColor: colors.primary },
                ]}
              >
                <Appbar.BackAction
                  onPress={navigation.goBack}
                  color={colors.surface}
                  style={{}}
                />
              </View>
            ) : (
              <Button mode="outlined" onPress={navigation.goBack}>
                <Text
                  style={{
                    fontFamily: "Montserrat_Bold",
                    color: colors.primary,
                  }}
                >
                  Back
                </Text>
              </Button>
            )
          ) : (
            <Appbar.Action
              icon="menu"
              onPress={() => navigation.openDrawer()}
            />
          )}
        </>
      )}
      {!isSmartPhone ? (
        isHome ? (
          <Image
            source={logo_web}
            style={styles.logo}
          />
        ) : null
      ) : null}
      <Appbar.Content
        title={
          !isSmartPhone ? (
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontFamily: "Montserrat_Bold" }}>
                {!loadingMarketInfo && storeInfo ? storeInfo.name : ""}
              </Text>

              {isHome && (
                <>
                  <Text style={{ fontFamily: "Montserrat_Bold" }}>
                    {"\u{A0}\u{A0}/\u{A0}\u{A0}"}
                  </Text>
                  <Text>
                    Welcome,{" "}
                    {!loadingMarketInfo && storeInfo ? clerk.name : "..."}
                  </Text>
                </>
              )}
            </View>
          ) : !loadingMarketInfo && storeInfo ? (
            storeInfo.name
          ) : (
            ""
          )
        }
        subtitle={
          isHome && isSmartPhone
            ? `Welcome, ${!loadingMarketInfo && clerk ? clerk.name : "..."}`
            : ""
        }
        titleStyle={{ textAlign: "center", fontFamily: "Montserrat_Bold" }}
        subtitleStyle={{
          textAlign: "center",
          fontFamily: "Montserrat_Regular",
        }}
      />
      {!isSmartPhone ? (
        <Button
          mode="outlined"
          onPress={() => (!isHome ? redirectToHome() : logout())}
        >
          <Text
            style={{ fontFamily: "Montserrat_Bold", color: colors.primary }}
          >
            {!isHome ? "Home" : "Logout"}
          </Text>
        </Button>
      ) : (
        <View
          style={[
            styles.containerBtnLogout,
            { backgroundColor: colors.primary },
          ]}
        >
          <IconButton
            icon={!isHome ? "home" : "logout"}
            color={colors.surface}
            size={20}
            onPress={() => (!isHome ? redirectToHome() : logout())}
          />
        </View>
      )}
    </Appbar.Header>
  );
};

const styles = StyleSheet.create({
  containerBtnBack: {
    marginLeft: 10,
    width: 36,
    height: 36,
    borderRadius: 18,
    alignItems: "center",
    justifyContent: "center",
  },
  containerBtnLogout: {
    marginRight: 10,
    width: 36,
    height: 36,
    borderRadius: 18,
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: 100,
    height: 50,
    resizeMode: "center",
  },
});

const StackNavigatorSetup = () => {
  const isSmartPhone = useMediaQuery({ maxDeviceWidth: 480 });
  const { isAuthenticated } = usePOSLogin();
  const { clerk, storeInfo } = useMarket();
  const MainTitle = storeInfo ? storeInfo.name : "";

  return (
    <Stack.Navigator
      headerMode="screen"
      screenOptions={{
        gestureEnabled: false,
        header: ({ scene, previous, navigation }) => {
          const data = { scene, previous, navigation };
          return <Header {...data} />;
        },
      }}
    >
      {isAuthenticated ? (
        <>
          <Stack.Screen
            name="Home"
            component={Home}
            options={{
              headerTitle: !isSmartPhone
                ? `${MainTitle} / Welcome ${clerk ? clerk.name : "..."}`
                : MainTitle,
            }}
          />

          <Stack.Screen
            name="Products"
            component={ProductsList}
            options={{ headerTitle: MainTitle }}
          />

          <Stack.Screen
            name="Products Page"
            component={ProductsPage}
            options={{ headerTitle: MainTitle }}
          />

          <Stack.Screen
            name="Product Form"
            component={ProductForm}
            options={{ headerTitle: MainTitle }}
          />

          <Stack.Screen
            name="Carriers"
            component={CarrierList}
            options={{ headerTitle: MainTitle }}
          />

          <Stack.Screen
            name="Contact List"
            component={ContactListScreen}
            options={{ headerTitle: "Contacts" }}
          />

          <Stack.Screen
            name="Settings"
            component={Settings}
            options={{ headerTitle: MainTitle }}
          />

          <Stack.Screen
            name="Dashboard"
            component={DashBoard}
            options={{ headerTitle: MainTitle }}
          />

          <Stack.Screen
            name="Scan"
            component={ScanCode}
            options={{ headerTitle: MainTitle }}
          />

          <Stack.Screen
            name="ScanWeb"
            component={CodeScannerWeb}
            options={{ headerTitle: MainTitle }}
          />

          <Stack.Screen
            name="Transactions"
            component={Transactions}
            options={{ headerTitle: MainTitle }}
          />

          <Stack.Screen
            name="Finish"
            component={Finish}
            options={{ headerTitle: MainTitle }}
          />
        </>
      ) : (
        <>
          <Stack.Screen
            name="Login"
            component={Login}
            options={{ headerShown: false, gestureEnabled: false }}
          />

          <Stack.Screen
            name="SignUp"
            component={SignUp}
            options={{ headerShown: false, gestureEnabled: false }}
          />

          <Stack.Screen
            name="ResetPassword"
            component={ResetPassword}
            options={{ headerShown: false, gestureEnabled: false }}
          />
        </>
      )}
    </Stack.Navigator>
  );
};

export default StackNavigatorSetup;
