import React, { useEffect, useState } from "react";
import { View, StyleSheet, SafeAreaView } from "react-native";
import { DrawerItem, DrawerContentScrollView } from "@react-navigation/drawer";
import { Drawer, Text, useTheme } from "react-native-paper";
import { MaterialIcons } from "@expo/vector-icons";
import Constants from "expo-constants";
import { ThemePreferencesContext } from "../../theme/ThemePreferenceContexts";
import moment from "moment";
import { usePOSLogin } from "../../../screens/Login/hook/usePOSLogin";
import { useCustomerAuth } from "../../providers/CustomerAuth/hook/useCustomerAuth";
import { appIdentifier } from "../../../identifier";

interface PropsBtnMenu {
  iconName: string;
  size: number;
}
interface PropsTitleMenu {
  title: string;
  subTitle: string;
}

const DrawerContent = (props) => {
  const { toggleTheme, isThemeDark } = React.useContext(
    ThemePreferencesContext
  );
  const app = useCustomerAuth();
  const pos = usePOSLogin();
  const isClient = appIdentifier === "CLIENT";

  return (
    <>
      <DrawerContentScrollView {...props}>
        <View style={styles.drawerContent}>
          <Drawer.Section style={styles.drawerSection}>
            <DrawerItem
              icon={({ color, size }) => (
                <ButtonMenu size={size} iconName="home" />
              )}
              label={(focused, color) => (
                <TitleMenu title="Home" subTitle="Take payments" />
              )}
              onPress={() => props.navigation.navigate("Home")}
            />
            {isClient && (
              <DrawerItem
                icon={({ color, size }) => (
                  <ButtonMenu size={size} iconName="account-circle" />
                )}
                label={(focused, color) => (
                  <TitleMenu title="Profile" subTitle="Account information" />
                )}
                onPress={() => props.navigation.navigate("Profile")}
              />
            )}
            {!isClient && (
              <DrawerItem
                icon={({ color, size }) => (
                  <ButtonMenu size={size} iconName="insert-chart" />
                )}
                label={(focused, color) => (
                  <TitleMenu
                    title="Dashboard"
                    subTitle="Sales & Transactions Reports"
                  />
                )}
                onPress={() => props.navigation.navigate("Dashboard")}
              />
            )}
            {/* <DrawerItem
            icon={({ color, size }) => (
              <ButtonMenu size={size} iconName="local-offer" />
            )}
            label={(focused, color) => (
              <TitleMenu
                title="Products"
                subTitle="View the entire list of products"
              />
            )}
            onPress={() => props.navigation.navigate("Products Page")}
          /> */}
            <DrawerItem
              icon={({ color, size }) => (
                <ButtonMenu size={size} iconName="list" />
              )}
              label={(focused, color) => (
                <TitleMenu
                  title="Transactions"
                  subTitle="List your transactions"
                />
              )}
              onPress={() => props.navigation.navigate("Transactions")}
            />
            {isClient && (
              <DrawerItem
                icon={({ color, size }) => (
                  <ButtonMenu size={size} iconName="card-giftcard" />
                )}
                label={(focused, color) => (
                  <TitleMenu title="Load pack" subTitle="All load pack" />
                )}
                onPress={() => props.navigation.navigate("Pins")}
              />
            )}
            {
              /* !isClient &&  */ <DrawerItem
                icon={({ color, size }) => (
                  <ButtonMenu size={size} iconName="tune" />
                )}
                label={(focused, color) => (
                  <TitleMenu title="Settings" subTitle="Configure your post" />
                )}
                onPress={() => props.navigation.navigate("Settings")}
              />
            }
            <DrawerItem
              icon={({ color, size }) => (
                <ButtonMenu size={size} iconName="logout" />
              )}
              label={(focused, color) => (
                <TitleMenu title="Logout" subTitle="Exit app" />
              )}
              onPress={() => {
                props.navigation.closeDrawer();
                isClient ? app.logout() : pos.logout();
              }}
            />
          </Drawer.Section>
          {/* <Drawer.Section title="Preferences">
          <TouchableRipple onPress={toggleTheme}>
            <View style={styles.preference}>
              <Text>Dark Theme</Text>
              <View pointerEvents="none">
                <Switch value={isThemeDark} />
              </View>
            </View>
          </TouchableRipple>
        </Drawer.Section> */}
        </View>
      </DrawerContentScrollView>
      <View
        style={{
          position: "absolute",
          bottom: 16,
          alignSelf: "center",
        }}
      >
        <Text style={{ textAlign: "center" }}>
          {`Version ${Constants.manifest?.version}`}
        </Text>
        <Text style={{ textAlign: "center" }}>&copy; {moment().year()}</Text>
      </View>
    </>
  );
};

export const ButtonMenu = (props: PropsBtnMenu) => {
  const { colors } = useTheme();
  return (
    <View
      style={{
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: colors.primary,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <MaterialIcons
        name={props.iconName}
        color={colors.surface}
        size={props.size}
      />
    </View>
  );
};

export const TitleMenu = (props: PropsTitleMenu) => {
  return (
    <View style={{ alignItems: "flex-start", justifyContent: "center" }}>
      <Text style={{ fontSize: 16, fontFamily: "Montserrat_Bold" }}>
        {props.title}
      </Text>
      <Text style={{ fontSize: 12, fontFamily: "Montserrat_Regular" }}>
        {props.subTitle}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  drawerContent: {
    flex: 1,
  },
  userInfoSection: {
    paddingLeft: 20,
  },
  title: {
    marginTop: 20,
    fontWeight: "bold",
  },
  caption: {
    fontSize: 14,
    lineHeight: 14,
  },
  row: {
    marginTop: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
  },
  paragraph: {
    fontWeight: "bold",
    marginRight: 3,
  },
  drawerSection: {
    marginTop: 15,
  },
  preference: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
});

export default DrawerContent;
