import React, { useEffect, useState } from 'react';
import { View, Dimensions } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import { Rect, Text as TextSVG, Svg } from 'react-native-svg';
import moment from 'moment';
import { Avatar } from 'react-native-paper';
import { ScrollView } from 'react-native-gesture-handler';
import LoadingComponent from '../Loading/Loading';
import { isWebPlatform } from '../../lib/PlatformDevice';

/**
 * @function Component PeriodComparisonChart
 * @param {Array} summaryData Summary Data 
 */
export const PeriodComparisonChart = ({ summaryData }) => {
    let [tooltipPos, setTooltipPos] = useState({
        x: 0,
        y: 0,
        visible: false,
        value: 0,
    });

    const [dataChart, setDataChart] = useState({
        transactions: [],
        time: []
    });

    useEffect(() => {
        let data = { transactions: [], time: [] };
        summaryData.map((s, index) => {
            if (index % 7 === 0 || s.count > 0) {
                data.transactions.push(s.count);
                const date = new Date(s.name);
                data.time.push(!s.name.includes(',') ? s.name : moment(date).format( 'DD MMM YY'))
            }
        });
        setDataChart(data);
    }, [summaryData]);

    return (
        < View >
            {dataChart.transactions.length && dataChart.time.length ?
                <ScrollView horizontal={true}>
                    <LineChart
                        data={{
                            labels: dataChart.time,
                            datasets: [
                                {
                                    data: dataChart.transactions
                                },
                            ],
                        }}
                        width={Dimensions.get('window').height}
                        height={250}
                        yAxisInterval={5}
                        chartConfig={{
                            backgroundColor: 'white',
                            backgroundGradientFrom: '#fbfbfb',
                            backgroundGradientTo: '#fbfbfb',
                            decimalPlaces: 0,
                            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                            labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                            style: {
                                borderRadius: 0,
                            },
                            propsForDots: {
                                r: '6',
                                strokeWidth: '0',
                                stroke: 'blue',
                            },
                        }}
                        bezier
                        style={{
                            marginVertical: 8,
                            borderRadius: 6,
                        }}
                        decorator={() => {
                            return tooltipPos.visible ? (
                                <View>
                                    <Svg>
                                        <Rect
                                            x={tooltipPos.x - 15}
                                            y={tooltipPos.y + 10}
                                            width="120"
                                            height="30"
                                            fill="black"
                                        />
                                        <Avatar.Icon
                                            icon="attach-money"
                                            size={32}
                                            color="rgb(67, 67, 67)"
                                            style={{ backgroundColor: "transparent" }}
                                        />
                                        <TextSVG
                                            x={tooltipPos.x + 5}
                                            y={tooltipPos.y + 30}
                                            fill="white"
                                            fontSize="12"
                                            fontWeight="bold"
                                            textAnchor="end">
                                            {tooltipPos.value}
                                        </TextSVG>
                                        <TextSVG
                                            x={tooltipPos.x + 10}
                                            y={tooltipPos.y + 30}
                                            fill="white"
                                            fontSize="12"
                                            fontWeight="bold"
                                            textAnchor="start">
                                            Transactions
                                        </TextSVG>
                                    </Svg>
                                </View>
                            ) : null;
                        }}
                        verticalLabelRotation={!isWebPlatform() ? -16 : undefined}
                        onDataPointClick={(data) => {
                            let isSamePoint = tooltipPos.x === data.x && tooltipPos.y === data.y;

                            isSamePoint
                                ? setTooltipPos((previousState) => {
                                    return {
                                        ...previousState,
                                        value: data.value,
                                        visible: !previousState.visible,
                                    };
                                })
                                : setTooltipPos({
                                    x: data.x,
                                    value: data.value,
                                    y: data.y,
                                    visible: true,
                                });
                        }}
                    />
                </ScrollView > : <LoadingComponent />}
        </View >
    );
};