import React, {useEffect, useState} from "react";
import { StyleSheet, View } from "react-native";
import CardProduct from "../../common_modules/components/Card/CardProduct";
import LoadingComponent from "../../common_modules/components/Loading/Loading";
import { useMarket } from "../Market/hook/useMarket";
import { useMediaQuery } from "react-responsive";
import { ScrollView } from "react-native-gesture-handler";
import Footer from "../../common_modules/components/Footer/Footer";
import HeaderTitle from "../../navigation/Header/HeaderTitle";
import { IS_DESKTOP_OR_LAPTOP, IS_SMARTPHONE, IS_TABLET_OR_MOBILE_DEVICE } from "../../common_modules/lib/PlatformDevice";
import CardVerify from "../../common_modules/components/Card/CardVerify";
import {useStoreInformation} from "../../common_modules/providers/StoreInfo/hook/useStoreInformation";
import {useNavigation} from "@react-navigation/native";

const ProductsList = ({ route }) => {
  const { productState, selectProduct, carrierState } = useMarket();
  const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
  const isTabletOrMobileDevice = useMediaQuery(IS_TABLET_OR_MOBILE_DEVICE);
  const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
  const [isGiftCard, setGiftCard] = useState(false);
  const navigation = useNavigation();

  const { store } = useStoreInformation();

  useEffect(() => {
    if (productState.loading) return;
    const loadProduct = productState.products.some((element, index, array) => {
      return element.sku === store.privateLabelGiftCardSettings?.productLoad?.sku
    });
    const redeemProduct = productState.products.some((element, index, array) => {
      return element.sku === store.privateLabelGiftCardSettings?.productRedeem?.sku
    });
    if (productState.products.length === 2) {
      setGiftCard(loadProduct && redeemProduct);
    }
    if (productState.products.length === 1) {
      selectProduct(productState.products[0]);
      navigation.navigate("Product Form");
    }
  }, [productState.loading]);

  return (
    <>
      <HeaderTitle title={carrierState.selected?.name} />
      {!productState.loading ? (
        <ScrollView>
          <View
            style={[
              styles.container,
              {
                marginLeft: !isTabletOrMobileDevice ? "5%" : 15,
                marginRight: !isTabletOrMobileDevice ? undefined : 15,
                marginTop: !isTabletOrMobileDevice ? "3%" : 15,
                marginBottom: !isSmartPhone ? "3%" : "10%",
                justifyContent: isDesktopOrLaptop
                  ? "flex-start"
                  : "space-between",
              },
            ]}
          >
            {productState.products.length
              ? productState.products.map((product, index) => (
                  <View
                    key={index}
                    style={{
                      flexDirection: "column",
                      marginBottom: 10,
                      marginRight: !isTabletOrMobileDevice ? 20 : 0,
                      width: !isDesktopOrLaptop
                        ? isSmartPhone
                          ? "48%"
                          : "28%"
                        : "18%",
                    }}
                  >
                    <CardProduct
                      hidePrice={isGiftCard}
                      key={product.id}
                      product={product}
                      onSelectProduct={selectProduct}
                    />
                  </View>
                ))
              : null}
            {isGiftCard &&
                <View
                    key={9999999}
                    style={{
                        flexDirection: "column",
                        marginBottom: 10,
                        marginRight: !isTabletOrMobileDevice ? 20 : 0,
                        width: !isDesktopOrLaptop
                            ? isSmartPhone
                                ? "68%"
                                : "48%"
                            : "38%",
                    }}
                >
                    <CardVerify/>
                </View>}
          </View>
        </ScrollView>
      ) : (
        <LoadingComponent />
      )}
      {/* <Footer /> */}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default ProductsList;
