import { SubmitTransactionPlugin } from './Plugin';
import { TransactionPaymentMethod } from '../../../../../model/TransactionPaymentMethod';

export const PrepareTransactionPlugin: SubmitTransactionPlugin = {
    onSubmit: (event) => new Promise((finishDone, finishError) => {
        let creditCard = undefined;
        if (event.data.creditCard && event.data.paymentMethod === TransactionPaymentMethod.CREDIT_CARD) {
            creditCard = {
                ...event.data.creditCard,
            };

            // normalized any formatted card number
            if (creditCard.number) {
                creditCard.number = creditCard.number.replace(/[^\d]/g, '');
            }

            if (event.context.customerAuth && event.context.customerAuth.token && creditCard.token) {
                creditCard = { token: creditCard.token, cvv: creditCard.cvv };
            }
        }

        let eGift = undefined;
        if (event.data.eGift && event.data.paymentMethod === TransactionPaymentMethod.EGIFT) {
            eGift = {
                ...event.data.eGift,
            };
        }

        let customerToken = event.data.customerToken;
        if (!customerToken && event.context.customerAuth) {
            customerToken = event.context.customerAuth.token;
        }

        finishDone({
            ...event.data,
            // ensure valid credit card
            creditCard,

            // ensure valid eGift
            eGift,

            // set client fingerprint
            clientFingerprint: event.context.clientFingerPrint ? event.context.clientFingerPrint.fingerPrint : undefined,


            // ensure always a valid correlation ID
            correlationId: event.data.correlationId ? event.data.correlationId : String(Date.now()),

            // inputs always
            inputs: event.data.inputs ? event.data.inputs : {},

            // set current customer token
            customerToken
        });
    })
};
