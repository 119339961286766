import { ContextType } from './../context';
import { registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'clearTransactionError';

registerReducer(NAME, reducer);

function reducer(state: State): State {

    return {
        ...state,
        transactionFailed: undefined,
    };
}

export const clearTransactionError = (context: ContextType) =>
    context.dispatch({ type: NAME });
