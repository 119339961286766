import gql from 'graphql-tag';

export const VALIDATE = gql`
    mutation validate($input: ValidateTransactionInput!) {
        transactions {
            validate(input: $input) {
                message
                grossTotal
                paymentBillingDescriptor
                paymentTermsAndConditionsUrl
                product {
                    id
                    name
                    carrier{
                        id
                        name
                    }
                    category {
                        id
                        name
                    }
                }
                adjustments {
                    name
                    mode
                    amount
                    total
                    isDiscount
                    rateMode
                }
                total
            }
        }
    }
`;

export const TRANSACTION_FRAGMENT = gql`
    fragment Transaction on Transaction{
        id
        number
        correlationId
        status
        date
        receiptEmail
        customerReceiptPdf
        product {
            id
            name
            isPIN
            carrier{
                id
                name
            }
            category {
                id
                name
            }
        }
        grossTotal
        adjustments {
            name
            mode
            amount
            total
            isDiscount
            rateMode
        }
        total
        response {
            errorCode
            redemptionUrl
            message
            ... on PinSaleResponse{
                code
                pin
                pinApplied
            }
        }
    }
`;

export const SUBMIT = gql`
    mutation submitTransaction($input: SubmitTransactionInput!){
        transactions{
            submit(input: $input){
                redirectUrl
                transaction{
                    ...Transaction
                }
            }
        }
    }
    ${TRANSACTION_FRAGMENT}
`;

export const FETCH_TRANSACTION = gql`
    query node ($id: ID!){
        node(id: $id){
            ... on Transaction{
                ...Transaction
            }
        }
    }
    ${TRANSACTION_FRAGMENT}
`;
