export const localStorageSetItem = (key: string, data: unknown, rememberMe = true) => {
    if (rememberMe) {
        return localStorage.setItem(key, JSON.stringify(data));
    }
    return sessionStorage.setItem(key, JSON.stringify(data));
}

export const localStorageGetItem = (key: string) =>
    localStorage.getItem(key) || sessionStorage.getItem(key);


export const localStorageRemoveItem = (key: string) => {
    if (localStorage.getItem(key)) {
        return localStorage.removeItem(key);
    }
    return sessionStorage.removeItem(key);
}
