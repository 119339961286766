import { MarketWizardStep, SubmitTransactionPayload } from '../../reducer/state';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'submitTransactionSuccess';

interface Data extends Action {
    payload: SubmitTransactionPayload,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        wizard: {
            ...state.wizard,
            currentStep: MarketWizardStep.FINISH,
        },
        transactionIsProcessing: false,
        transactionPayload: data.payload,
    };
}

export const submitTransactionSuccess = (context: ContextType, data: Omit<Data, 'type'>) => {
    if (data.payload.transaction.status === 'COMPLETED') {
        context.notify.success('Transaction has been completed successfully');
    }

    if (data.payload.transaction.status === 'ON_HOLD') {
        context.notify.warning('Transaction is not completed, require a customer payment.');
    }

    return context.dispatch({ type: NAME, ...data });
};
