import React, { Dispatch } from 'react';
import { Action } from './reducer';
import { State } from './state';
import { Notification } from '../../../common_modules/providers/Notification/hook/useNotification';
import { ApolloGraphQL } from '../../../common_modules/providers/ApolloGraphQL/hook/useGraphqlApolloClient';

export interface ContextType {
    readonly _type?: 'POS',
    dispatch: Dispatch<Action>,
    state: State,
    apolloGraphQL: ApolloGraphQL,
    notification: Notification,
}

export const POSContext = React.createContext<ContextType>(null);
export default POSContext;
