import {MaterialIcons} from "@expo/vector-icons";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import * as React from "react";
import merge from 'deepmerge';
import {
    DarkTheme as NavigationDarkTheme,
    DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';
import {
    configureFonts,
    DarkTheme as PaperDarkTheme,
    DefaultTheme as PaperDefaultTheme, Provider as PaperProvider
} from 'react-native-paper';
import {Fonts} from 'react-native-paper/lib/typescript/types';
import {useWhiteLabelAgent} from "./useWhiteLabelAgent";

let CombinedDefaultTheme = merge(PaperDefaultTheme, NavigationDefaultTheme);
const CombinedDarkTheme = merge(PaperDarkTheme, NavigationDarkTheme);

export default function useCachedResources() {
    const [isLoadingComplete, setLoadingComplete] = React.useState(false);

    const {isLoadingWhiteLabelAgentComplete, agentObj, dealerObj, siteSettings} = useWhiteLabelAgent();

    // Load any resources or data that we need prior to rendering the app
    React.useEffect(() => {
        async function loadResourcesAndDataAsync() {
            try {
                SplashScreen.preventAutoHideAsync();

                // Load fonts
                await Font.loadAsync({
                    ...MaterialIcons.font,
                    "Montserrat_Black": require('../../../../assets/fonts/Montserrat-Black.otf'),
                    "Montserrat_Bold": require('../../../../assets/fonts/Montserrat-Bold.otf'),
                    "Montserrat_Semi_Bold": require('../../../../assets/fonts/Montserrat-SemiBold.otf'),
                    "Montserrat_Extra_Bold": require('../../../../assets/fonts/Montserrat-ExtraBold.otf'),
                    "Montserrat_Regular": require('../../../../assets/fonts/Montserrat-Regular.otf'),
                    "Montserrat_Light": require('../../../../assets/fonts/Montserrat-Light.otf'),
                    "Montserrat_Ultra_Light": require('../../../../assets/fonts/Montserrat-UltraLight.otf'),
                    "Montserrat_Hairline": require('../../../../assets/fonts/Montserrat-Hairline.otf'),
                });

            } catch (e) {
                // We might want to provide this error information to an error reporting service
                console.warn(e);
            } finally {
                setLoadingComplete(true);
                SplashScreen.hideAsync();
            }
        }

        loadResourcesAndDataAsync();
    }, []);

    const fontConfig: Fonts = {
        regular: {
            fontFamily: 'Montserrat_Regular',
        },
        medium: {
            fontFamily: 'Montserrat_Regular',
        },
        light: {
            fontFamily: 'Montserrat_Light',
        },
        thin: {
            fontFamily: 'Montserrat_Regular',
        },
    };

    // Define Theme
    const Theme = {
        ...CombinedDefaultTheme,
        roundness: 6,
        title: agentObj ? (dealerObj ? dealerObj.name : agentObj.name) : 'Pay\'nUp',
        agent: agentObj,
        dealer: dealerObj,
        colors: {
            ...CombinedDefaultTheme.colors,
            primary: siteSettings?.primaryColor ? siteSettings?.primaryColor : '#d23232',
            notifyError: '#fac828',
            accent: '#f1c40f',
            surface: '#ffffff',
            success: '#2DBF0A',
            info: '#1C6BF3',
            background: '#efefef',
            onSurface: '#212121',
            dividers: '#757575'
        },
        fonts: configureFonts({default: fontConfig, web: fontConfig, android: fontConfig, ios: fontConfig,}),
    };

    return {isLoadingComplete, isLoadingWhiteLabelAgentComplete, Theme, CombinedDarkTheme};
}
