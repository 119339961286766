import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'clear';

interface Data extends Action {
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        transaction: undefined,
        total: undefined,
        submitting: false,
        error: undefined,
        response: undefined,
    };
}

export const clear = (context: ContextType) =>
    context.dispatch({ type: NAME });
