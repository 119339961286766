import gql from 'graphql-tag';

export const FAVORITE_CARRIERS = gql`
    query FAVORITE_CARRIERS($ids: [ID!]!){
        nodes(ids: $ids){
            ... on ProductCategory{
                id
                name
                sku
                bgColor
                logoUrl
                standalonePaymentLink
            }
            ... on ProductCarrier {
                country
            }
        }
    }
`;
