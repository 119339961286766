import { SubmitTransactionPlugin } from "./Plugin";

export const LogRocketPlugin: SubmitTransactionPlugin = {
    onSubmit: (event) => new Promise((finishDone, finishError) => {

        let logRocketSessionUrl = undefined;

        if (event.context.logRocket && event.context.logRocket.sessionUrl) {
            logRocketSessionUrl = event.context.logRocket.sessionUrl;
        }

        finishDone({
            ...event.data,
            // add log rocket session url
            logRocketSessionUrl,
        });
    })
};
