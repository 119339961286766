import { ApolloGraphQLController } from './ApolloGraphQLController';
import React, { useReducer } from 'react';
import Context from './reducer/context';
import { reducer } from './reducer/reducer';
import { initialState } from './reducer/state';
import { useNotification } from '../Notification/hook/useNotification';

interface Props {
    persistent: boolean,
    children?: any
}

export function ApolloGraphQLProvider(props: Props) {

    const [state, dispatch] = useReducer(reducer, {...initialState, persistent: props.persistent});
    const notification = useNotification();

    return (
        <Context.Provider value={{ state, dispatch, notification }}>
            <ApolloGraphQLController persistent={props.persistent}>
                {props.children}
            </ApolloGraphQLController>
        </Context.Provider>
    );
}
