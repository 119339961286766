import { SubmitTransactionPlugin } from './Plugin';
import { validateTransaction } from '../../../../../lib/Transactions/transactions';
import { APIError } from '../../../../../model/APIError';
import { TransactionPaymentMethod } from '../../../../../model/TransactionPaymentMethod';
import { secureWithCardinal } from '../../../../../lib/cardinal';
import { isWebPlatform } from '../../../../../lib/PlatformDevice';
import { captureException } from '../../../../../lib/sentry';
import { isDevEnvironment } from '../../../../../lib/env';

export const CardinalCommercePlugin: SubmitTransactionPlugin = {
    onSubmit: (event) => new Promise((finishDone, finishError) => {
        if (event.data.paymentMethod === TransactionPaymentMethod.CREDIT_CARD
            && event.context.storeInfo.store.consumerAuthentication === 'CARDINAL_COMMERCE'
            && event.data.creditCard
            && !event.data.creditCard.token
        ) {
            if (event.context.storeInfo.viewer.sandbox) {
                // set sandbox token
                finishDone({
                    ...event.data,
                    creditCard: {
                        ...event.data.creditCard,
                        consumerAuthToken: 'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiI1ODJlMG1NDgQ.m9xie49ddoGSAQ3JHIzKBs5UsDX',
                    }
                });
                // ignore sandbox
                // finishDone(event.data);
            } else {
                validateTransaction(event.context.apolloGraphQL.client, {
                    referrer: event.data.referrer,
                    standalonePaymentUrl: event.data.standalonePaymentUrl,
                    product: event.data.product,
                    paymentMethod: event.data.paymentMethod,
                    inputs: event.data.inputs,
                }).then((result) => {
                    if (event.context.userActivity) {
                        event.context.userActivity.info('Starting Cardinal 3D secure');
                    }

                    // Native Cardinal Secure Verification process
                    if (!isWebPlatform()) {
                        event.context.cardinalSecure?.secureWithCardinal({
                            sandbox: isDevEnvironment(),
                            orderAmount: result.total,
                            orderNumber: event.data.correlationId,
                            cardNumber: event.data.creditCard.number,
                            cardExpMonth: event.data.creditCard.expMonth,
                            cardExpYear: event.data.creditCard.expYear,
                            billingAddress: {
                                address1: event.data.creditCard.holder.address1,
                                zipCode: event.data.creditCard.holder.zipCode,
                            }
                        }).then((response) => {
                            finishDone({
                                ...event.data,
                                creditCard: {
                                    ...event.data.creditCard,
                                    consumerAuthToken: response.jwt,
                                }
                            });

                            if (event.context.userActivity) {
                                event.context.userActivity.info(`Cardinal 3D secure completed with code: ${response.data.ActionCode}`);
                            }
                        }).catch((error) => {
                            finishDone(event.data);
                            if (event.context.userActivity) {
                                event.context.userActivity.warning(`Cardinal 3D secure failed ${error.message}`);
                            }

                            captureException(error)
                        });
                    }
                    //  Cardinal Secure Verification process in Web
                    else {
                        secureWithCardinal(event.context.apolloGraphQL.client, {
                            sandbox: isDevEnvironment(),
                            orderAmount: result.total,
                            orderNumber: event.data.correlationId,
                            cardNumber: event.data.creditCard.number,
                            cardExpMonth: event.data.creditCard.expMonth,
                            cardExpYear: event.data.creditCard.expYear,
                            billingAddress: {
                                address1: event.data.creditCard.holder.address1,
                                zipCode: event.data.creditCard.holder.zipCode,
                            }
                        }).then((response) => {
                            finishDone({
                                ...event.data,
                                creditCard: {
                                    ...event.data.creditCard,
                                    consumerAuthToken: response.jwt,
                                }
                            });

                            if (event.context.userActivity) {
                                event.context.userActivity.info(`Cardinal 3D secure completed with code: ${response.data.ActionCode}`);
                            }
                        }).catch((error) => {
                            finishDone(event.data);
                            if (event.context.userActivity) {
                                event.context.userActivity.warning(`Cardinal 3D secure failed ${error.message}`);
                            }
                            captureException(error)
                        });
                    }
                }).catch((error) => {
                    finishError(new APIError(error.code, error.message));
                });
            }
        } else {
            finishDone(event.data);
        }
    })
};
