import { SAVE_UI_SETTINGS } from '../../reducer/actions/saveUISettings.graphql';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State, UISettings } from './../state';

const NAME = 'saveUISettings';

interface Data extends Action {
    settings: UISettings,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        uiSettings: data.settings
    };
}

export const saveUISettings = (context: ContextType, data: Omit<Data, 'type'>) => {

    context.apolloGraphQL.client.mutate({
        mutation: SAVE_UI_SETTINGS,
        variables: {
            settings: data.settings,
        }
    });

    context.dispatch({ type: NAME, ...data });
};
