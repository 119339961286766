import { ApolloClient, WatchQueryOptions } from 'apollo-client';
import { AppEventListener, onAppEvent } from '../../../lib/events';
import _ from 'lodash';
import moment from 'moment';
import { flattenSummary } from './summary.util';

export enum SummaryViewMode {
    HOURLY = 'HOURLY',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export interface SummaryOptions {
    type: any,
    view?: SummaryViewMode
    minDate: Date,
    maxDate: Date,
}

export class ObservableSummary<T extends SummaryOptions, E> {
    private callback: (summary: any) => void;
    private readonly subscription: ZenObservable.Subscription;
    // private readonly listeners: Array<AppEventListener<any>> = [];
    protected readonly options: T;

    constructor(client: ApolloClient<any>, options: T, queryOptions: WatchQueryOptions, resultPath: string,/*  updateOnEvents?: Array<E> */) {
        this.options = options;

        const query = client.watchQuery({
            fetchPolicy: 'cache-first',
            variables: this.parseVariables(),
            ...queryOptions
        });

        // if (updateOnEvents) {
        //     _.forEach(updateOnEvents, (event: E) => {
        //         this.listeners.push(onAppEvent(event, () => {
        //             query.refetch(this.parseVariables());
        //         }));
        //     });
        // }

        this.subscription = query.subscribe(({ data }) => {
            if (data && _.get(data, resultPath)) {
                this.callback(flattenSummary(_.get(data, resultPath)));
            }
        });
    }

    protected parseVariables(): any {
        let maxDate = this.options.maxDate;

        // if max date is today, set max time to now
        if (moment(this.options.maxDate).format('ll') === moment().format('ll')) {
            maxDate = moment(this.options.maxDate).set({
                hour: moment().get('hour') + 1,
                minute: 0,
                second: 0,
            }).toDate();
        }

        return {
            type: this.options.type,
            view: this.options.view,
            minDate: this.options.minDate,
            maxDate: maxDate,
        };
    }

    public subscribe(func: (summary: any) => void): ObservableSummary<T, E> {
        this.callback = func;
        return this;
    }

    public unsubscribe() {
        this.subscription.unsubscribe();
        // _.forEach(this.listeners, (listener) => {
        //     listener.off();
        // });
    }
}
