import React, { useEffect, useReducer, useState } from 'react'
import { useSummary } from '../../common_modules/providers/Summary/hook/useSummary';
import moment from 'moment';
import _ from 'lodash';
import { createMaxDate, createMinDate, isCurrentMonth, isCurrentYear, isToday } from '../../common_modules/lib/datepicker.utils';
import { TransactionSummaryType } from '../../common_modules/providers/Summary/lib/transactions';
import PeriodComparisonWidget from './PeriodCoparisonWidget';

export interface PeriodsToCompare {
    label: string,
    minDate: Date,
    maxDate: Date,
}

const PeriodComparison = (props) => {
    const summary = useSummary();
    const [periodsToCompare, setPeriodsToCompare] = useState<Array<PeriodsToCompare>>([]);
    const [currentPeriodSummary, setCurrentPeriodSummary] = useState([]);
    const [calculatedPeriods, updateCalculatedPeriods] = useReducer((state, calculated) => {
        return {
            ...state,
            [calculated.label]: calculated.summary
        };
    }, {});

    useEffect(() => {
        const periods: Array<PeriodsToCompare> = [];
        if (isToday(props.summaryOptions.minDate, props.summaryOptions.maxDate)) {
            periods.push({
                label: moment().subtract(1, 'days').format('[Yesterday until] h:mm a'),
                minDate: createMinDate().subtract(1, 'days').toDate(),
                maxDate: moment().subtract(1, 'days').toDate(),
            });

            const lastSameDay = createMinDate().subtract(7, 'days').toDate();
            periods.push({
                label: `${createMinDate(lastSameDay).format('ddd')}, ${moment().subtract(7, 'days').format('MMM D [until] h:mm a')} `,
                minDate: createMinDate(lastSameDay).toDate(),
                maxDate: moment().subtract(7, 'days').toDate(),
            });
        }

        if (isCurrentMonth(props.summaryOptions.minDate, props.summaryOptions.maxDate)) {
            const lastMonth = createMinDate().subtract(1, 'month').toDate();
            periods.push({
                label: createMinDate(lastMonth).format('MMMM 1 - D, YYYY'),
                minDate: createMinDate(lastMonth).startOf('month').toDate(),
                maxDate: moment().subtract(1, 'month').toDate(),
            });

            const lastPreviousMonth = createMinDate().subtract(2, 'month').toDate();
            periods.push({
                label: createMinDate(lastPreviousMonth).format('MMMM 1 - D, YYYY'),
                minDate: createMinDate(lastPreviousMonth).startOf('month').toDate(),
                maxDate: moment().subtract(2, 'month').toDate(),
            });
        }

        if (isCurrentYear(props.summaryOptions.minDate, props.summaryOptions.maxDate)) {
            const lastYear = createMinDate().subtract(1, 'year').toDate();
            periods.push({
                label: createMinDate(lastYear).format('[Jan] - MMMM, YYYY'),
                minDate: createMinDate(lastYear).startOf('year').toDate(),
                maxDate: createMaxDate(lastYear).toDate(),
            });

            const lastPreviousYear = createMinDate().subtract(2, 'year').toDate();
            periods.push({
                label: createMinDate(lastPreviousYear).format('[Jan] - MMMM, YYYY'),
                minDate: createMinDate(lastPreviousYear).startOf('year').toDate(),
                maxDate: createMaxDate(lastPreviousYear).toDate(),
            });
        }

        if (_.isEmpty(periods)) {
            const firstPeriodMaxDate = moment(props.summaryOptions.minDate).subtract(1, 'day').toDate();
            const firstPeriodMinDate = moment(firstPeriodMaxDate).subtract(moment(props.summaryOptions.maxDate).diff(props.summaryOptions.minDate)).toDate();

            periods.push({
                label: `${createMinDate(firstPeriodMinDate).format('ll')} - ${createMaxDate(firstPeriodMaxDate).format('ll')}`,
                minDate: firstPeriodMinDate,
                maxDate: createMaxDate(firstPeriodMaxDate).toDate(),
            });

            const secondPeriodMaxDate = moment(firstPeriodMinDate).subtract(1, 'day').toDate();
            const secondPeriodMinDate = moment(secondPeriodMaxDate).subtract(moment(firstPeriodMaxDate).diff(firstPeriodMinDate)).toDate();

            periods.push({
                label: `${createMinDate(secondPeriodMinDate).format('ll')} - ${createMaxDate(secondPeriodMaxDate).format('ll')}`,
                minDate: secondPeriodMinDate,
                maxDate: createMaxDate(secondPeriodMaxDate).toDate(),
            });
        }

        setPeriodsToCompare(periods);

        const subscription = summary.fetchTransactionSummary({
            type: TransactionSummaryType.COUNT_BY_DATE,
            commissions: props.grossCommission || props.netCommission,
            minDate: props.summaryOptions.minDate,
            maxDate: props.summaryOptions.maxDate,
            where: props.summaryOptions.where,
            view: props.summaryOptions.view
        }).subscribe((summary) => {
            setCurrentPeriodSummary(summary);
        });

        return () => subscription.unsubscribe();

    }, [props.summaryOptions.minDate]);

    useEffect(() => {
        const subscriptions = [];
        _.forEach(periodsToCompare, (period, index) => {
            subscriptions.push(summary.fetchTransactionSummary({
                type: TransactionSummaryType.COUNT_BY_DATE,
                commissions: props.grossCommission || props.netCommission,
                minDate: period.minDate,
                maxDate: period.maxDate,
                view: props.summaryOptions.view,
                where: props.summaryOptions.where
            }).subscribe((summary) => {
                updateCalculatedPeriods({ label: period.label, summary });
            }));
        });

        return () => _.forEach(subscriptions, (subscription) => subscription.unsubscribe());


    }, [periodsToCompare]);

    const currentPeriodSummaryMergedWithOldPeriods = _.map(currentPeriodSummary, (row, index) => {
        _.forEach(periodsToCompare, ({ label }) => {
            row[label] = calculatedPeriods[label] && calculatedPeriods[label][index] ? calculatedPeriods[label][index] : undefined;
        });

        return row;
    });

    const periodColors = ['#7B1FA2', '#1565C0', '#4bcd00'];

    const periodsTable = _.map(calculatedPeriods, (row, index) => {
        row.count = _.sumBy(row, 'count') || 0;
        row.sales = Number(_.sumBy(row, 'sales') || 0).toFixed(2);
        row.grossCommission = Number(_.sumBy(row, 'grossCommission') || 0).toFixed(2);
        row.netCommission = Number(_.sumBy(row, 'netCommission') || 0).toFixed(2);

        row.currentPeriodCount = _.sumBy(currentPeriodSummary, 'count');
        row.currentPeriodSales = Number(_.sumBy(currentPeriodSummary, 'sales')).toFixed(2);
        row.currentPeriodGrossCommission = Number(_.sumBy(currentPeriodSummary, 'grossCommission')).toFixed(2);
        row.currentPeriodNetCommission = Number(_.sumBy(currentPeriodSummary, 'netCommission')).toFixed(2);

        return {
            name: index,
            color: periodColors[_.findIndex(_.keys(calculatedPeriods), (v) => v === index)],
            count: _.sumBy(row, 'count') || 0,
            sales: Number(_.sumBy(row, 'sales') || 0).toFixed(2),
            grossCommission: Number(_.sumBy(row, 'grossCommission') || 0).toFixed(2),
            netCommission: Number(_.sumBy(row, 'netCommission') || 0).toFixed(2),
            currentPeriodCount: _.sumBy(currentPeriodSummary, 'count'),
            currentPeriodSales: Number(_.sumBy(currentPeriodSummary, 'sales')).toFixed(2),
            currentPeriodGrossCommission: Number(_.sumBy(currentPeriodSummary, 'grossCommission')).toFixed(2),
            currentPeriodNetCommission: Number(_.sumBy(currentPeriodSummary, 'netCommission')).toFixed(2),
        };
    });

    return (
        <PeriodComparisonWidget
            dataPeriods={periodsTable}
            currentPeriodSummary={currentPeriodSummaryMergedWithOldPeriods}
            periodsToCompare={periodsToCompare}
        />
    )
}

export default PeriodComparison;