// import { logger } from 'common/lib/logger';
import { SubmitTransactionArgs } from '../../../../common_modules/lib/Transactions/transactions';
import { APIError } from '../../../../common_modules/model/APIError';
import { TransactionPaymentMethod } from '../../../../common_modules/model/TransactionPaymentMethod';
import _ from 'lodash';
import moment from 'moment';
import { submitTransactionFailed } from '../../reducer/actions/submitTransactionFailed';
import { Action } from '../reducer';
import { SubmitTransactionData } from '../state';
import { ContextType } from './../context';
import { registerReducer } from './../reducer';
import { State } from './../state';
import { submitTransactionSuccess } from './submitTransactionSuccess';

const NAME = 'submitTransaction';

registerReducer(NAME, reducer);


interface Data extends Action {
    transaction: SubmitTransactionData,
}

function reducer(state: State, data: Data): State {

    return {
        ...state,
        transaction: data.transaction,
        transactionIsProcessing: true,
        transactionPayload: undefined,
    };
}

export const submitTransaction = (context: ContextType, data: Omit<Data, 'type'>) => {

    console.log(data)

    const inputs: { [name: string]: any } = {};
    _.forEach(data.transaction.product.inputs, (input) => {
        if (data.transaction.productInputs[input.name] !== undefined) {
            inputs[input.name] = data.transaction.productInputs[input.name];
        }
    });

    const transaction: Partial<SubmitTransactionArgs> = {
        product: context.state.product.selected.id,
        // TODO: use clerk number or something else
        correlationId: String((new Date()).getTime()),
        inputs,
        paymentAgreementToken: data.transaction.productInputs.paymentAgreementToken,
        receiptEmail: data.transaction.receiptEmail,
    }

    if (data.transaction.paymentMethod) {
        transaction.paymentMethod = data.transaction.paymentMethod;
    }

    if (transaction.paymentMethod === TransactionPaymentMethod.PRIVATE_LABEL_GIFT_CARD) {
        transaction.privateLabelGiftCard = {
            number: data.transaction.privateLabelGiftCard.number,
            magData: data.transaction.privateLabelGiftCard.magData
        }
    }

    if (transaction.paymentMethod === TransactionPaymentMethod.CREDIT_CARD) {
        transaction.securePayments = context.state.storeInfo.consumerAuthentication;
        transaction.creditCard = {
            number: data.transaction.creditCard.cardNumber,
            expMonth: Number(moment(data.transaction.creditCard.cardExp).format('MM')),
            expYear: Number(moment(data.transaction.creditCard.cardExp).format('YYYY')),
            cvv: data.transaction.creditCard.cvv,
            magData: data.transaction.creditCard.magData,
            holder: {
                name: data.transaction.creditCard.name,
                zipCode: data.transaction.creditCard.zipCode,
                address1: data.transaction.creditCard.address,
            }
        }
    }

    // logger.debug('Submitting transaction', transaction);
    context.submitTransaction.submit(transaction).then((result) => {
        submitTransactionSuccess(context, { payload: result });
    }).catch((error: APIError) => {
        submitTransactionFailed(context, { error });
    });

    context.dispatch({ type: NAME } as any);
};
