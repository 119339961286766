import React, { useState } from 'react';
import { View, TextInput, ImageBackground } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import * as _ from 'lodash';
import { useMediaQuery } from "react-responsive";
import { CustomText } from '../Text/Text';
import { SWIPE_CARD } from '../../providers/CreditCardPayment/CardSwiper.graphql';
import { getDisplayHeight, IS_DESKTOP_OR_LAPTOP } from '../../lib/PlatformDevice';
import { captureException } from '../../lib/sentry';
import { useApolloGraphQL } from '../../providers/ApolloGraphQL/hook/useGraphqlApolloClient';
export interface CardMagData {
    magData: string,
    sku?: string,
    cardNumber: string,
    cardHolder?: string,
    expYear?: number,
    expMonth?: number,
}

interface Props {
    isOpen: boolean,
    onClose: () => any,
    onSwipe: (cardData: CardMagData) => any,
}

let swipeListerTimeOut;

/**
 * @function CodeScannerWeb 
 * @param {boolean} isOpen Status mounted component
 * @param {Function} onClose Callback handle event unmounted component
 * @param {Function} onSwipe Callback handle event swipe card
 */
export default function CodeScannerWeb({ isOpen, onClose, onSwipe }: Props) {
    const [status, setStatus] = useState<'WAITING' | 'READING' | 'SUCCESS' | 'ERROR'>('WAITING');
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
    const { client } = useApolloGraphQL();

    const handleOnClose = () => {
        onClose();
        setTimeout(
            () => {
                setStatus('WAITING');
            },
            1000
        );
    };

    const handleSwipe = (event) => {
        clearTimeout(swipeListerTimeOut);
        setStatus('READING');

        // !!!Is very important to read well the magnetic data from swiper wait at least 1 seconds for the second track
        swipeListerTimeOut = setTimeout(
            () => {
                if (event.includes('?') !== -1) {
                    client.mutate({
                        mutation: SWIPE_CARD,
                        variables: { magData: event }
                    }).then(({ data }) => {
                        if (data.tools.swipeCard) {
                            onSwipe({
                                magData: event,
                                ...data.tools.swipeCard
                            });
                            handleOnClose();
                        } else {
                            setStatus('ERROR');
                            handleOnClose();
                        }
                    }).catch((e) => {
                        setStatus('ERROR');
                        handleOnClose();
                        captureException(e);
                    });
                }
            },
            1000
        );
    };

    const containerStyle = {
        backgroundColor: 'transparent',
        width: getDisplayHeight() * 0.2,
        height: getDisplayHeight() * 0.2,
        borderRadius: getDisplayHeight() * 0.1,
        marginHorizontal: !isDesktopOrLaptop ? '25%' : '40%',
    };

    return (
        <Portal>
            <Modal
                visible={isOpen}
                onDismiss={handleOnClose}
                contentContainerStyle={containerStyle}
                dismissable={true}>
                <ImageBackground style={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}
                    source={require('../../../../assets/img/icon_qr_scan.png')}>
                    <View style={{ bottom: 25 }}>
                        <CustomText color='white' type="sBold">{status}</CustomText>
                    </View>
                    <TextInput
                        focusable
                        style={{ width: 0, height: 0 }}
                        onBlur={handleOnClose}
                        onChangeText={(text) => handleSwipe(text)}
                        ref={input => input && input.focus()}
                    />
                </ImageBackground>
            </Modal>
        </Portal>
    );
}
