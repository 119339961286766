import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { MarketWizardStep, State } from './../state';

const NAME = 'goBackMarketWizard';

interface Data extends Action {

}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    switch (state.wizard.currentStep) {
        case MarketWizardStep.CARRIERS:
            return {
                ...state,
                wizard: {
                    ...state.wizard,
                    currentStep: MarketWizardStep.WELCOME,
                    subHeaderColor: state.storeInfo.theme.primaryColor,
                },
                category: {
                    ...state.category,
                    loading: false,
                    selected: undefined,
                }
            };
        case MarketWizardStep.PRODUCTS:
            let fromProductsToStep = MarketWizardStep.CARRIERS;
            if (state.carrier.carriers.length === 1 || !state.category.selected) {
                fromProductsToStep = MarketWizardStep.WELCOME;
            }
            return {
                ...state,
                wizard: {
                    ...state.wizard,
                    currentStep: fromProductsToStep,
                    subHeaderColor: fromProductsToStep === MarketWizardStep.CARRIERS && state.category.selected ? state.category.selected.color : state.storeInfo.theme.primaryColor,
                },
                carrier: {
                    ...state.carrier,
                    loading: false,
                    selected: undefined,
                }
            };
        case MarketWizardStep.PRODUCT_INPUTS:
            let fromInputsToStep = MarketWizardStep.PRODUCTS;
            if (state.product.products.length === 1) {
                fromInputsToStep = MarketWizardStep.CARRIERS;
                if (state.carrier.carriers.length === 1 || !state.category.selected) {
                    fromInputsToStep = MarketWizardStep.WELCOME;
                }
            }

            if (!state.product.products.length) {
                fromInputsToStep = MarketWizardStep.WELCOME;
            }

            return {
                ...state,
                wizard: {
                    ...state.wizard,
                    currentStep: fromInputsToStep,
                    subHeaderColor: fromInputsToStep === MarketWizardStep.WELCOME ? state.storeInfo.theme.primaryColor : state.wizard.subHeaderColor,
                },
                product: {
                    ...state.product,
                    loading: false,
                    selected: undefined,
                },
                transactionFailed: undefined
            };

        default:
            return state;
    }
}

export const goBackMarketWizard = (context: ContextType) =>
    context.dispatch({ type: NAME });
