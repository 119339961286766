import { ContextType } from 'react';
import Context from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'customerLoginSuccess';

interface Data extends Action {
    token: string,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        token: data.token,
        waitingTwoFactorAuth: false,
        error: undefined,
        authenticating: false,
    };
}

export const customerLoginSuccess = (context: ContextType<typeof Context>, data: Omit<Data, 'type'>) => {
    if (context.userActivity) {
        context.userActivity.info('Customer login success');
    }
    context.dispatch({ type: NAME, ...data });
};
