import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'waitingTwoFactorAuth';

interface Data extends Action {
    waiting: boolean,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        authenticating: data.waiting,
        waitingTwoFactorAuth: data.waiting,
    };
}

export const waitingTwoFactorAuth = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
