import { LOAD_STORE_INFO } from './loadStoreInfo.graphql';
import { DocumentNode } from 'graphql';
import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { ApiViewer, State, StoreInfo } from '../state';
import { captureException } from '../../../../lib/sentry';

const NAME = 'loadStoreInfo';

interface Data extends Action {
    store: StoreInfo
    viewer: ApiViewer
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    return {
        ...state,
        store: data.store,
        viewer: data.viewer,
    };
}

export const loadStoreInfo = (context: ContextType, query?: DocumentNode): Promise<StoreInfo> => new Promise((resolve, reject) => {
    context.apolloGraphQL.client.query({
        query: query ? query : LOAD_STORE_INFO,
        fetchPolicy: 'no-cache',
    }).then(({ data }) => {
        context.dispatch({ type: NAME, store: data.storeInfo, viewer: data.viewer });
        resolve(data);
    }).catch((error) => {
        reject(error);
        captureException(error);
    });
});

