import { SubmitTransactionArgs, SubmitTransactionComplete } from '../../../lib/Transactions/transactions';
import { TotalAndAdjustments } from '../../../model/Adjustment';
import { APIError } from '../../../model/APIError';

export interface State {
    transaction?: Partial<SubmitTransactionArgs>, //current transaction
    total?: TotalAndAdjustments | undefined, // calculated totals every time the transaction is updated
    paymentBillingDescriptor?: string
    paymentTermsAndConditionsUrl?: string
    updatingTotal: boolean,
    submitting: boolean,
    response?: SubmitTransactionComplete,
    error?: APIError,
}

export const initialState: State = {
    submitting: false,
    updatingTotal: false,
};
