import React, { useEffect, useState } from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { Text, Button } from "react-native-paper";
import { BarCodeScanner, BarCodeScannerResult } from "expo-barcode-scanner";
import BarcodeMask from "react-native-barcode-mask";
import { Camera } from "expo-camera";
import LoadingScanMobile from "./LoadingScanMobile";
import { getDisplayHeight, getDisplayWidth } from "../../../lib/PlatformDevice";

const finderWidth: number = 280;
const finderHeight: number = 230;
const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height;
const viewMinX = (width - finderWidth) / 2;
const viewMinY = (height - finderHeight) / 2;

interface Props {
  scanComplete: (data, type) => void;
  error: any;
  setGo: (val: boolean) => void;
  go: boolean;
  setError: (val: any) => void;
  setTextInfo: (val: string) => void;
  loading: boolean;
}

/**
 * @function CodeScanner
 * @param {boolean}  loading  Status loading
 * @param {boolean}  go      Status process
 * @param {Function} setError Callback set error object
 * @param {Function} setTextInfo Callback set Info message
 * @param {Function} scanComplete Callback handle scanning complete
 * @param {Function} setGo Callback handle processing complete
 */
export default function CodeScanner({
  scanComplete,
  setGo,
  setError,
  error,
  setTextInfo,
  go,
  loading,
}: Props) {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
//   const [type, setType] = useState<any>(BarCodeScanner.Constants.Type.back);
  const [type, setType] = useState<any>(Camera.Constants.Type.back);
  const [scanned, setScanned] = useState<boolean>(false);
  const [btnDistance, setBtnDistance] = useState(0);

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestPermissionsAsync();
      setHasPermission(status === "granted");
    })();
  }, []);

  const handleBarCodeScanned = (scanningResult: BarCodeScannerResult) => {
    setTextInfo("Scanning...");
    const { data, type } = scanningResult;
    if (!go && !error.error) {
      scanComplete(data, type);
      if (error.error) {
        setScanned(false);
      }
    }
  };

  if (hasPermission === null) {
    return <Text>Requesting for camera permission</Text>;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }
  return (
    <View style={styles.container}>
      <Camera
        onBarCodeScanned={scanned ? handleBarCodeScanned : undefined}
        onLayout={(event) => {
          let { x, y, width, height } = event.nativeEvent.layout;
          const maskHeight = 230;
          const btnHeight = 32;
          const spacing = height / 2 - maskHeight / 2;
          setBtnDistance(spacing - btnHeight - 20);
        }}
        type={type}
        style={[StyleSheet.absoluteFill, styles.container]}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: "transparent",
            flexDirection: "row",
          }}
        ></View>
        {!loading && (
          <BarcodeMask
            width={280}
            height={230}
            edgeColor={scanned && !error.error ? "#fac828" : "#d23232"}
            showAnimatedLine={scanned && !error.error}
          />
        )}

        {!scanned && !error.error ? (
          <Button
            mode="contained"
            onPress={() => setScanned(true)}
            style={[styles.btn, { marginBottom: btnDistance }]}
          >
            Scan
          </Button>
        ) : null}

        {error.error ? (
          <Button
            mode="contained"
            onPress={() => {
              setTextInfo("Please scan code");
              setError({ error: false, message: "" });
              setScanned(true);
              setGo(false);
            }}
            style={[styles.btn, { marginBottom: btnDistance }]}
          >
            Please try again
          </Button>
        ) : null}
      </Camera>
      {loading && <LoadingScanMobile isOpen={loading} onClose={() => null} />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  btn: {
    width: "70%",
  },
});
