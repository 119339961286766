import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { AuthorizedCreditCardAmount, State } from './../state';

const NAME = 'authorizationSuccess';

interface Data extends Action {
    authorization?: AuthorizedCreditCardAmount,
    onConfirm?: (amount: number) => Promise<any>,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        authorization: data.authorization,
        waitingAuthorizedAmount: true,
        onConfirm: data.onConfirm,
    };
}

export const authorizationSuccess = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
