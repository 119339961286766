import { removeCustomerAuthPersistedToken } from '../../../CustomerAuth/persistenceAuth';
import { ContextType } from '../context';
import { registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'customerLogout';

registerReducer(NAME, reducer);

function reducer(): State {

    return {};
}

export const customerLogout = async (context: ContextType) => {
    removeCustomerAuthPersistedToken(context);
    context.dispatch({ type: NAME });
};
