import React from 'react';
import { StyleProp, TextStyle } from 'react-native';
import { Text } from 'react-native-paper';
interface Props {
    children: any,
    size?: number | string
    color?: string,
    align?: 'auto' | 'left' | 'right' | 'center' | 'justify',
    type: 'regular' | 'black' | 'hLine' | 'bold' | 'sBold' | 'eBold' | 'light' | 'uLight';
    style?: StyleProp<TextStyle>
}

const fontFamily = {
    regular: 'Montserrat_Regular',
    black: "Montserrat_Black",
    hLine: "Montserrat_Hairline",
    bold: "Montserrat_Bold",
    sBold: "Montserrat_Semi_Bold",
    eBold: "Montserrat_Extra_Bold",
    light: "Montserrat_Light",
    uLight: "Montserrat_Ultra_Light",
};

export const CustomText = ({ children, size, color, align, type }: Props) => {
    return (
        <Text
            style={{
                fontFamily: fontFamily[type],
                textAlign: !align ? 'auto' : align,
                ...(size && { fontSize: size }),
                ...(color && { color: color }),
            }}>
            { children}
        </Text >
    );
}