import React, { useReducer } from 'react';
import { ClientFingerPrintController } from './ClientFingerPrintController';
import Context from './reducer/context';
import { reducer } from './reducer/reducer';
import { initialState } from './reducer/state';

interface Props {
    children?: any
}

export function ClientFingerPrintProvider(props: Props) {

    const [state, dispatch] = useReducer(reducer, initialState);


    return (
        <Context.Provider value={{ state, dispatch }}>
            <ClientFingerPrintController />
            {props.children}
        </Context.Provider>
    );
}
