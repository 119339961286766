import React, {useEffect} from "react";
import NavigationProvide from "./src/navigation/NavigationProvide";
import {Provider as PaperProvider} from "react-native-paper";
import {Platform} from 'react-native';
import {MaterialIcons} from "@expo/vector-icons";
import {LoginProvider} from "./src/screens/Login/LoginProvider";
import SnackBarAlert from "./src/common_modules/components/SnackBar/SnackBarAlert";
import {QUERY_STORE_INFO} from "./src/screens/Market/reducer/actions/loadMarketInfo.graphql";
import {MarketProvider} from "./src/screens/Market/MarketProvider";
import {MarketSessionTimeout} from "./src/screens/Market/MarketSessionTimeout";
import {Global, css} from '@emotion/react'
import * as Sentry from "sentry-expo";
import {ThemePreferencesContext} from "./src/common_modules/theme/ThemePreferenceContexts";
import {NotificationProvider} from "./src/common_modules/providers/Notification/NotificationProvider";
import {StoreInfoProvider} from "./src/common_modules/providers/StoreInfo/StoreInfoProvider";
import {CreditCardVerificationProvider} from "./src/common_modules/providers/CreditCardVerification/CreditCardVerificationProvider";
import {SubmitTransactionProvider} from "./src/common_modules/providers/SubmitTransaction/SubmitTransactionProvider";
import {ClientFingerPrintProvider} from "./src/common_modules/providers/ClientFingerPrint/ClientFingerPrintProvider";
import {SummaryProvider} from "./src/common_modules/providers/Summary/SummaryProvider";
import {isWebPlatform} from "./src/common_modules/lib/PlatformDevice";
import useCachedResources from "./src/common_modules/providers/hooks/useCachedResources";
import {CardinalProvider} from "./src/common_modules/components/Cardinal/CardinalProvider";
import {ApolloGraphQLProvider} from "./src/common_modules/providers/ApolloGraphQL/ApolloGraphQLProvider";

Sentry.init({
    dsn: "https://dcf9a2f1982549ae8439822ef6ad7ac9@sentry.devitall.com/12",
    enableInExpoDevelopment: false,
    debug: true,
});

export default function App() {
    const {isLoadingComplete, isLoadingWhiteLabelAgentComplete, Theme, CombinedDarkTheme} = useCachedResources();
    const [isThemeDark, setIsThemeDark] = React.useState(false); //Change Theme Dark Mode

    let theme = isThemeDark ? CombinedDarkTheme : Theme;

    const toggleTheme = React.useCallback(() => {
        return setIsThemeDark(!isThemeDark);
    }, [isThemeDark]);

    const preferences = React.useMemo(
        () => ({
            toggleTheme,
            isThemeDark,
        }),
        [toggleTheme, isThemeDark]
    );

    if (!isLoadingComplete || !isLoadingWhiteLabelAgentComplete) {
        return null;
    }

    return (
        <ThemePreferencesContext.Provider value={preferences}>
            <NotificationProvider>
                <ApolloGraphQLProvider persistent={true}>
                    <StoreInfoProvider query={QUERY_STORE_INFO}>
                        <CreditCardVerificationProvider>
                            <ClientFingerPrintProvider>
                                <CardinalProvider>
                                    <SubmitTransactionProvider>
                                        <PaperProvider
                                            theme={theme}
                                            settings={{
                                                icon: (props) => <MaterialIcons {...props} />,
                                            }}
                                        >
                                            <LoginProvider>
                                                <MarketProvider>
                                                    <MarketSessionTimeout>
                                                        <SummaryProvider>
                                                            {isWebPlatform() ? (
                                                                <Global
                                                                    styles={{
                                                                        "#disable-outline input": {
                                                                            outline: "none",
                                                                        },
                                                                        ".enable-outline input:focus": {
                                                                            outline: "auto",
                                                                        },
                                                                    }}
                                                                />
                                                            ) : null}
                                                            <NavigationProvide theme={theme}/>
                                                            <SnackBarAlert/>
                                                        </SummaryProvider>
                                                    </MarketSessionTimeout>
                                                </MarketProvider>
                                            </LoginProvider>
                                        </PaperProvider>
                                    </SubmitTransactionProvider>
                                </CardinalProvider>
                            </ClientFingerPrintProvider>
                        </CreditCardVerificationProvider>
                    </StoreInfoProvider>
                </ApolloGraphQLProvider>
            </NotificationProvider>
        </ThemePreferencesContext.Provider>
    );
}
