import React from "react";
import { Button, Modal, Portal, Text, Title } from "react-native-paper";
import { useMediaQuery } from "react-responsive";
import { IS_DESKTOP_OR_LAPTOP } from "../../lib/PlatformDevice";

interface Props {
    title?: string,
    message?: string,
    blocked?: boolean,
    visible: boolean
    onDismiss: () => void,
}

/**
 * @function Component ModalComponent
 * @param {string} Title Modal Title
 * @param {string} Message Message description 
 * @param {boolean} blocked Display blocked for user
 * @param {boolean} visible Show Modal
 * @param {Function} onDismiss Callback handle dismiss
 */
const ModalComponent = (props: Props) => {
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
    const containerStyle = {
        backgroundColor: 'white',
        padding: 20,
        marginHorizontal: !isDesktopOrLaptop ? '25%' : '40%',
        borderRadius: 10
    };

    return (
        <Portal>
            <Modal
                visible={props.visible}
                onDismiss={props.onDismiss}
                contentContainerStyle={containerStyle}
                dismissable={props.blocked ? false : props.blocked}>
                <Title style={{ textAlign: 'center', color: '#d23232' }}>{props.title}</Title>
                <Text style={{ textAlign: 'center' }}>{props.message}</Text>
                <Button style={{ marginTop: 10 }} mode="outlined" onPress={props.onDismiss}>Ok</Button>
            </Modal>
        </Portal>

    );
}

export default ModalComponent;