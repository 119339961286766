import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Card, Title, Text, useTheme } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';
import { useMediaQuery } from "react-responsive";
import { useNavigation } from '@react-navigation/native';
import { Category } from '../../../screens/Market/reducer/state';
import { IS_DESKTOP_OR_LAPTOP } from '../../lib/PlatformDevice';
interface Props {
    category: Category
    selectCategory: (category: Category) => void,
    iconColor: string
}

/**
* @function Component Category Card
* @param {Category} category Category Object  
* @param {Function} selectCategory Callback handle selection a category  
*/
const CardCategory = (props: Props) => {
    const navigation = useNavigation();
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP)
    const { colors } = useTheme();
    const getIcon = (iconName: string) => {
        if (!iconName) return "";
        return iconName.replace(/_/g, "-");
    }

    return (
        <Card style={styles.card} onPress={() => {
            props.selectCategory(props.category);
            navigation.navigate('Carriers');
        }}>
            <Card.Content>
                <View style={styles.cardContent}>
                    <View
                        style={{
                            width: isDesktopOrLaptop ? 70 : 50, height: isDesktopOrLaptop ? 70 : 50,
                            backgroundColor: props.iconColor, borderRadius: isDesktopOrLaptop ? 35 : 25,
                            alignItems: 'center', justifyContent: 'center'
                        }}>
                        <MaterialIcons
                            name={getIcon(props.category.icon)}
                            size={isDesktopOrLaptop ? 42 : 32} color={colors.surface} />
                    </View>
                    <View
                        style={{
                            flexDirection: 'column', justifyContent: 'center',
                            paddingLeft: isDesktopOrLaptop ? 10 : 20, flex: 1
                        }}>
                        <Title style={{
                            fontFamily: 'Montserrat_Bold',
                            lineHeight: !isDesktopOrLaptop ? 18 : undefined
                        }}>{props.category.name.toUpperCase()}</Title>
                        <Text
                            style={{
                                color: 'black',
                                fontFamily: 'Montserrat_Light',
                                lineHeight: !isDesktopOrLaptop ? 15 : undefined,
                                marginTop: !isDesktopOrLaptop ? 3 : undefined
                            }}>
                            {props.category.description}
                        </Text>
                    </View>
                </View>
            </Card.Content>
        </Card>
    );
};

const styles = StyleSheet.create({
    card: {
        flexGrow: 3
    },
    cardContent: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
        alignItems: 'center'
    },
    tinyLogo: {
        resizeMode: 'stretch',
        width: 80,
        height: 80,
    }
})

export default CardCategory;