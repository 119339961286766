import React, {useEffect, useState} from "react";
import {KeyboardAvoidingView, StyleSheet, View} from "react-native";
import {ScrollView} from "react-native-gesture-handler";
import {
    Divider,
    Title,
    Button,
    useTheme,
    Text,
    TextInput,
    Avatar,
} from "react-native-paper";
import {LoadingBlockComponent} from "../../common_modules/components/Loading/Loading";
import {useMediaQuery} from "react-responsive";
import {TransactionPaymentMethod} from "../../common_modules/model/TransactionPaymentMethod";
import {useMarket} from "../Market/hook/useMarket";
import _ from "lodash";
import ProductInputs from "./ProductInputs";
import CardPayment from "../../common_modules/components/Card/CardPayment";
import {getPrice, updatePaymentMethodData} from "../../common_modules/lib/Transactions/transactions";
import CreditCardPayment from "../../common_modules/components/Card/CreditCardPayment";
import {useForm, Controller} from "react-hook-form";
import {InputField, InputFieldType} from "../../common_modules/lib/form-yup.util";
import * as yup from "yup";
import moment from "moment";
import {ValidationError} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {CreditCardType} from "../../common_modules/model/CreditCardType";
import * as Contacts from "expo-contacts";
import {
    InputHelperError,
    InputHelperInfo,
} from "../../common_modules/components/CustomInputs/InputHelperText";
import creditCardType from "credit-card-type";
import InputBarCode from "../../common_modules/components/CustomInputs/InputBarCode";
import InputPrivateLabelGiftCard from "../../common_modules/components/CustomInputs/InputPrivateLabelGiftCard";
import {productInputsToSchema} from "../../common_modules/lib/form-yup-product.util";
import TransactionError from "../../common_modules/components/TransactionError/TransactionError";
import examples from "libphonenumber-js/examples.mobile.json";
import {
    getCountryCallingCode,
    parsePhoneNumberFromString,
    getExampleNumber,
    CountryCallingCode,
} from "libphonenumber-js";
import {useNavigation} from "@react-navigation/native";
import Footer from "../../common_modules/components/Footer/Footer";
import SwipeCardScanner from "../../common_modules/components/SwipeCardScanner/SwipeCardScanner";
import {
    getPlatform,
    isWebPlatform,
    IS_DESKTOP_OR_LAPTOP,
    IS_SMARTPHONE,
} from "../../common_modules/lib/PlatformDevice";
import DropDownComponent from "../../common_modules/components/DropDown/DropDown";
import HeaderTitle from "../../navigation/Header/HeaderTitle";
import DialogComponent from "../../common_modules/components/Dialog/DialogComponent";
import {
    DENY_PAYMENT_AGREEMENT,
    VERIFY_PAYMENT,
    REQUEST_APPROVAL_SUBSCRIPTION,
} from "../ScanCode/CustomerPayment.graphql";
import {captureException} from "../../common_modules/lib/sentry";
import NativeEventSource from "react-native-sse";
import {useStoreInformation} from "../../common_modules/providers/StoreInfo/hook/useStoreInformation";
import {useNotification} from "../../common_modules/providers/Notification/hook/useNotification";
import {useApolloGraphQL} from "../../common_modules/providers/ApolloGraphQL/hook/useGraphqlApolloClient";

export interface DataPhone {
    code: CountryCallingCode;
    exampleNumber: string;
}

let eventSource: NativeEventSource | EventSource;
let request_approval_subscription: ZenObservable.Subscription;

const ProductForm = ({route}, props) => {
    const {
        uiSettings,
        storeInfo,
        clerk,
        productState,
        transactionPayload,
        transactionIsProcessing,
        transactionSubmit,
        transactionFailed,
        clearTransactionError,
    } = useMarket();
    const {client} = useApolloGraphQL();
    const {success, error} = useNotification();
    const {colors} = useTheme();
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
    const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
    const [paymentChecked, setPaymentChecked] = useState(
        uiSettings.preferredPaymentMethod || "CASH"
    );
    const [isSwiping, setSwiping] = useState(false);
    const [isSwipingPrivateLabel, setSwipingPrivateLabel] = useState(false);
    const [isScanningPrivateLabel, setScanningPrivateLabel] = useState(false);
    const [isQr, setQr] = useState(false);
    const [isScanning, setScanning] = useState(false);
    const [inputsSubmittedValues, setInputsSubmittedValues] = useState({});
    const [dueCashAmount, setDueCashAmount] = useState(0);
    const [loadingAssignPayment, setLoadingAssignPayment] = useState(false);
    const [loadingCancelPayment, setLoadingCancelPayment] = useState(false);
    const [timeoutRequest, setTimeoutRequest] = useState(false);
    const [paymentApprovalID, setPaymentApprovalID] = useState("");
    const navigation = useNavigation();

    const {store} = useStoreInformation();

    const [supportedPaymentMethods, setSupportedPaymentMethods] = useState([]);

    useEffect(() => {
        setSupportedPaymentMethods(
            updatePaymentMethodData(store.supportedPaymentMethods)
        );
    }, []);

    useEffect(() => {
        if (productState.selected) {
            if (productState.selected.sku === store.privateLabelGiftCardSettings?.productRedeem?.sku) {
                setSupportedPaymentMethods(
                    updatePaymentMethodData([TransactionPaymentMethod.PRIVATE_LABEL_GIFT_CARD])
                )
                setPaymentChecked(TransactionPaymentMethod.PRIVATE_LABEL_GIFT_CARD);
            }
        }
    }, [productState.selected]);

    if (!productState.selected) {
        return <></>;
    }
    const product = productState.selected;
    const [amount, setAmount] = useState(product.price);
    const [productValue, setProductValue] = useState(0);
    const [requestAmount, setRequestAmount] = useState(product.price);

    const [privateLabelCardMagData, setPrivateLabelCardMagData] = useState("");
    const [privateLabelNumber, setPrivateLabelNumber] = useState("");

    let schemaFields: { [name: string]: yup.SchemaOf<any> } = {};
    schemaFields.paymentMethod = yup
        .string()
        .ensure()
        .default(TransactionPaymentMethod.CASH);
    schemaFields = {...schemaFields, ...productInputsToSchema(product)};
    schemaFields.receiptEmail = yup.string().ensure().email();

    if (paymentChecked === TransactionPaymentMethod.CREDIT_CARD) {
        schemaFields.name = yup
            .string()
            .required("Card Holder name is required")
            .ensure()
            .label("Card Holder");

        schemaFields.address = yup
            .string()
            .when("magData", {
                is: (value) => !value,
                then: yup.string().required("Billing address is required"),
                otherwise: yup.string().notRequired(),
            })
            .ensure()
            .label("Address");

        schemaFields.zipCode = yup
            .string()
            .when("magData", {
                is: (value) => !value,
                then: yup.string().required("ZipCode is required"),
                otherwise: yup.string().ensure().notRequired(),
            })
            .ensure();

        schemaFields.magData = yup.string().ensure().notRequired();

        schemaFields.cardNumber = yup
            .string()
            .required("Credit Card Number is required")
            .label("Card Number")
            .test("isValidCreditCard", "Please enter valid credit card", (value) => {
                const creditCard = creditCardType(value);

                return !!creditCard.length;
            })
            .test(
                "isSecureCreditCard",
                "Sorry, only VISA and MASTERCARD are accepted.",
                (value) => {
                    const creditCard = creditCardType(value);
                    if (props.onlySecurePayments) {
                        return (
                            creditCard.length &&
                            _.includes(["visa", "mastercard"], creditCard[0].type)
                        );
                    }

                    return true;
                }
            )
            .test({
                test: (value) => {
                    if (_.isEmpty(props.restrictedCreditCards)) {
                        return true;
                    }

                    const acceptedString = props.restrictedCreditCards.join(", ");
                    const creditCard = creditCardType(value);

                    if (creditCard.length && creditCard[0].type) {
                        let type = undefined;
                        switch (creditCard[0].type) {
                            case "american-express":
                                type = CreditCardType.AMEX;
                                break;
                            case "diners-club":
                                type = CreditCardType.DINERSCLUB;
                                break;
                            default:
                                type = _.upperCase(creditCard[0].type);
                        }

                        if (_.includes(props.restrictedCreditCards, type)) {
                            return true;
                        } else {
                            return new ValidationError(
                                `${type} credit card is not supported, please enter a valid card: ${acceptedString} `,
                                value,
                                "cardNumber"
                            );
                        }
                    }

                    return new ValidationError(
                        `Credit card is not supported, please enter a valid card: ${acceptedString} `,
                        value,
                        "cardNumber"
                    );
                },
            })
            .ensure();

        schemaFields.cardExp = yup
            .date()
            .default(null)
            .required("Expiration date is required")
            .typeError("Use the format MM/YY")
            .min(moment().subtract(1, "month").toDate(), "Invalid expiration date");

        schemaFields.cvv = yup
            .string()
            .when("magData", {
                is: (value) => !value,
                then: yup.string().required(),
                otherwise: yup.string().notRequired(),
            })
            .ensure()
            .label("CVV");

        schemaFields.token = yup.string().ensure();
        schemaFields.remember = yup.bool().default(false).oneOf([true, false]);

        if (!props.disableTermsAndConditions) {
            schemaFields.acceptTerms = yup
                .bool()
                .default(false)
                .required()
                .oneOf(
                    [true],
                    "Must accept the Terms & Conditions to proceed with the payment."
                );
        }
    }

    // const schema = yup.object(schemaFieldss).noUnknown();
    const schema = yup.object(schemaFields);
    const {
        control,
        handleSubmit,
        errors,
        setError,
        getValues,
        setValue,
        trigger,
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
    });

    const handleSubmitInputs = (values) => {
        setInputsSubmittedValues(values);
        transactionSubmit({
            product: product,
            receiptEmail: values.receiptEmail,
            paymentMethod: paymentChecked,
            productInputs: {
                ..._.omit(values, [
                    "receiptEmail",
                    "paymentMethod",
                    "verifyAccountNumber",
                ]),
                paymentAgreementToken:
                    values.paymentMethod === TransactionPaymentMethod.WALLET &&
                    paymentApprovalID
                        ? paymentApprovalID
                        : undefined,
            },
            privateLabelGiftCard: {
                number: values.privateLabelNumber,
                magData: values.privateLabelCardMagData
            },
            creditCard: {
                cardExp: values.cardExp,
                cardNumber: values.cardNumber,
                cvv: values.cvv,
                name: values.name,
                zipCode: values.zipCode,
                address: values.address,
                magData: "",
            },
        });
    };

    let youPayAmount = 0;
    if (product.discountMode === "FIXED") {
        youPayAmount = amount - product.discount;
    }

    if (product.discountMode === "RATE") {
        youPayAmount = amount - (product.discount * amount) / 100;
    }

    let paymentMethod = uiSettings.preferredPaymentMethod;
    if (product.type === "ATMProduct") {
        paymentMethod = TransactionPaymentMethod.CASH_BACK;
    }

    const allowBarCodeScan = !!_.find(product.inputs, {name: "accountNumber"});
    const countryCode = product.country || "US";
    const countryPhoneCode = getCountryCallingCode(countryCode);
    const parserPhone = (phone) => {
        const phoneParser = parsePhoneNumberFromString(phone, countryCode);
        if (phoneParser)
            return countryCode === "CU"
                ? phoneParser.formatNational().replace(/^0/, "")
                : phoneParser.formatNational();
        else return phone;
    };

    const dataPhoneNumber = (): DataPhone => {
        let data = getExampleNumber(countryCode, examples);
        return {
            code: countryPhoneCode,
            exampleNumber: data
                ? countryCode === "CU"
                    ? data.formatNational().replace(/^0/, "")
                    : data.formatNational()
                : "",
        };
    };

    const customerScan = async (dataScan: string) => {
        if (!dataScan) {
            throw new Error("Invalid customer payment identifier");
        }
        try {
            const {data} = await client.mutate({
                mutation: VERIFY_PAYMENT,
                fetchPolicy: "no-cache",
                variables: {
                    paymentAgreementToken: dataScan,
                },
            });
            success("Payment Verified");
            setPaymentApprovalID(dataScan);
        } catch (e) {
            error(e.message);
        }
    };

    const assignPaymentCustomer = async () => {
        try {
            setLoadingAssignPayment(true);
            request_approval_subscription = client
                .subscribe({
                    query: REQUEST_APPROVAL_SUBSCRIPTION,
                    fetchPolicy: "no-cache",
                    variables: {
                        paymentAgreementToken: paymentApprovalID,
                        amount: requestAmount,
                    },
                })
                .subscribe(({data}) => {
                    const url =
                        data.paymentAgreement && data.paymentAgreement.requestApproval
                            ? data.paymentAgreement.requestApproval.url
                            : null;

                    // Initialize EventSource
                    if (url) {
                        eventSource = isWebPlatform()
                            ? new EventSource(url)
                            : new NativeEventSource(url);

                        eventSource.addEventListener("error", (event) => {
                        });

                        // On event
                        eventSource.addEventListener("message", (event) => {
                            const {data, errors} = JSON.parse(event.data);
                            if (errors) {
                                error(errors[0].message);
                                resetPaymentCustomer();
                                request_approval_subscription.unsubscribe();
                                eventSource.removeEventListener("message", () => {
                                });
                                eventSource.close();
                            } else {
                                const paymentAgreementData =
                                    data.paymentAgreement.requestApproval.data;
                                success("Request approved");
                                setLoadingAssignPayment(false);
                                resetPaymentCustomer();
                                handleSubmit(handleSubmitInputs)();
                                request_approval_subscription.unsubscribe();
                                eventSource.removeEventListener("message", () => {
                                });
                                eventSource.close();
                            }
                        });
                    } else {
                        error("Payment could not be allocated correctly, please try again");
                        cancelPaymentCustomer();
                    }
                });
        } catch (e) {
            error(e.message);
            setLoadingAssignPayment(false);
            if (e.message.toLowerCase().includes("timeout")) {
                setTimeoutRequest(true);
            } else {
                setTimeoutRequest(false);
                cancelPaymentCustomer();
            }
            captureException(e);
        }
    };

    const cancelPaymentCustomer = async () => {
        try {
            setLoadingCancelPayment(true);
            const {data} = await client.mutate({
                mutation: DENY_PAYMENT_AGREEMENT,
                fetchPolicy: "no-cache",
                variables: {
                    paymentAgreementToken: paymentApprovalID,
                },
            });
            request_approval_subscription.unsubscribe();
            eventSource.removeEventListener("message", () => {
            });
            eventSource.close();
            success("Payment Agreement Canceled");
            setLoadingCancelPayment(false);
            if (data.paymentAgreement.deny.token) {
                resetPaymentCustomer();
            }
        } catch (e) {
            error(e.message);
            setLoadingCancelPayment(false);

            setTimeoutRequest(false);
            resetPaymentCustomer();

            captureException(e);
        }
    };

    const resetPaymentCustomer = () => {
        setPaymentApprovalID("");
    };

    useEffect(() => {
        const accountNumber = _.filter(
            product.inputs,
            (i: InputField) => i.name === "accountNumber"
        );
        const verifyAccount =
            accountNumber.length &&
            accountNumber[0].html?.type !== "hidden" &&
            product.type !== "ATMProduct";

        if (route && route.params) {
            if (route.params.accountNumber) {
                setValue("accountNumber", route.params.accountNumber);
            }
            if (route.params.customerScan && route.params.customerScan.verified) {
                setPaymentApprovalID(route.params.customerScan.dataScan);
            }
            if (route.params.contactList && accountNumber) {
                setTimeout(async () => {
                    setValue("accountNumber", route.params.contactList.accountNumber);
                    if (verifyAccount) {
                        setValue(
                            "verifyaccountNumber",
                            route.params.contactList.accountNumber
                        );
                    }
                    await trigger();
                }, 100);
            }
        }
    }, [route]);

    useEffect(() => {
        if (paymentApprovalID) {
            setLoadingAssignPayment(true);
            setTimeout(() => {
                assignPaymentCustomer();
            }, 4000);
        }
    }, [paymentApprovalID]);

    useEffect(() => {
        if (transactionFailed && !_.isEqual(inputsSubmittedValues, getValues())) {
            clearTransactionError();
        }
    }, [
        inputsSubmittedValues,
        getValues([
            "paymentMethod",
            "cardExp",
            "cardNumber",
            "cvv",
            "name",
            "zipCode",
            "address",
            "magData",
        ]),
    ]);

    useEffect(() => {
        if (transactionPayload && !transactionFailed) {
            navigation.navigate("Finish", {
                clerk,
                payload: transactionPayload,
                storeInfo,
            });
        }
    }, [transactionPayload]);

    const scan = async () => {
        const validation = await trigger();
        if (validation) {
            if (isWebPlatform() && !isSmartPhone) {
                setSwiping(true);
                setQr(true);
            } else {
                navigation.navigate("Scan", {
                    customerScan: {
                        requestAmount,
                    },
                });
            }
        }
    };

    const getContactFromContactBook = async () => {
        const {status} = await Contacts.requestPermissionsAsync();
        if (status === "granted") {
            const accountNumber = product.inputs?.filter(
                (field) => field.name === "accountNumber"
            );

            const contactFields =
                accountNumber &&
                (accountNumber[0].type === "phone" ||
                    accountNumber[0].type === "string")
                    ? Contacts.Fields.PhoneNumbers
                    : Contacts.Fields.Emails;

            navigation.navigate("Contact List", {contactFields, countryCode});
        } else {
            error("We need permissions to access your contact list");
        }
    };

    return (
        <>
            <HeaderTitle title={product.carrier?.name}/>
            <ScrollView keyboardShouldPersistTaps="handled">
                <View
                    style={{
                        paddingHorizontal: !isDesktopOrLaptop ? 20 : "10%",
                        paddingTop: 10,
                        paddingBottom: !isDesktopOrLaptop
                            ? getPlatform() === "ios"
                                ? "15%"
                                : "10%"
                            : "12%",
                    }}
                >
                    <View style={styles.container}>
                        <Title
                            numberOfLines={3}
                            style={{
                                fontFamily: "Montserrat_Bold",
                                width: "60%",
                                fontSize: 18,
                                lineHeight: isSmartPhone ? 18 : undefined,
                            }}
                        >
                            {product.name}
                        </Title>
                        {product.type !== "ATMProduct" && (
                            <View
                                style={{
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                }}
                            >
                                <Title style={{fontFamily: "Montserrat_Semi_Bold"}}>
                                    {product.inputs && product.inputs.filter(function (e) {
                                        return e.name === 'productValue';
                                    }).length > 0 ? getPrice(productValue) : getPrice(amount)}
                                </Title>
                                {clerk.settings.showProductDiscounts && (
                                    <Text style={{fontFamily: "Montserrat_Regular"}}>
                                        You pay {getPrice(youPayAmount)}
                                    </Text>
                                )}
                            </View>
                        )}
                    </View>

                    <Divider/>

                    <View
                        style={{
                            flexDirection: isDesktopOrLaptop ? "row" : "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <View
                            style={{
                                width: isDesktopOrLaptop ? "48%" : "100%",
                                paddingTop: 10,
                            }}
                        >
                            {/* Generate Payment Inputs */}
                            {product.inputs?.map((input, index) => (
                                <View
                                    key={index}
                                    nativeID="disable-outline"
                                    style={[{marginTop: 10}, input.type === InputFieldType.autocomplete ? {zIndex: 999999} : {}]}
                                >
                                    {input.type === "magdata" && allowBarCodeScan && (
                                        <>
                                            <InputBarCode
                                                editable={product.sku === store.privateLabelGiftCardSettings?.productLoad?.sku}
                                                isScan={() => setScanning(true)}
                                                isSwipe={() => setSwiping(true)}
                                                control={control}
                                                errors={errors}
                                                setScanningCode={(value) => {
                                                    setValue("cardMagData", "");
                                                    setValue("accountNumber", String(value).substr(-19));
                                                }}
                                            />

                                            <Controller
                                                control={control}
                                                render={({onChange, onBlur, value}) => (
                                                    <>
                                                        <TextInput
                                                            value={value}
                                                            onChangeText={(value) => onChange(value)}
                                                            onBlur={onBlur}
                                                            style={{display: "none"}}
                                                        />
                                                    </>
                                                )}
                                                name="cardMagData"
                                                defaultValue=""
                                            />
                                        </>
                                    )}

                                    {input.html.type !== "hidden" && (
                                        <Controller
                                            key={input.name}
                                            control={control}
                                            render={({onChange, onBlur, value}) => (
                                                <ProductInputs
                                                    key={input.name}
                                                    input={input}
                                                    onBlur={onBlur}
                                                    onFocus={() => {
                                                        if (input.name === "amount" && amount === 0) {
                                                            setValue("amount", "");
                                                        }
                                                        if (input.name === "productValue" && productValue === 0) {
                                                            setValue("productValue", "");
                                                        }
                                                    }}
                                                    onChangeValue={(value) => {
                                                        if (input.name === "amount") {
                                                            if (
                                                                value &&
                                                                value.match(/^[0-9]+([.][0-9]+)?$/)
                                                            ) {
                                                                setAmount(parseFloat(value));
                                                            } else {
                                                                setAmount(0);
                                                            }
                                                        }
                                                        if (input.name === "productValue") {
                                                            if (
                                                                value &&
                                                                value.match(/^[0-9]+([.][0-9]+)?$/)
                                                            ) {
                                                                setProductValue(parseFloat(value));
                                                            } else {
                                                                setProductValue(0);
                                                            }
                                                        }
                                                        onChange(value);
                                                    }}
                                                    value={
                                                        input.type === InputFieldType.phone
                                                            ? parserPhone(value)
                                                            : value.toString()
                                                    }
                                                    error={errors}
                                                    mode={isDesktopOrLaptop ? "outlined" : "flat"}
                                                    dataPhoneNumber={dataPhoneNumber()}
                                                    getContactFromContactBook={getContactFromContactBook}
                                                    product={product}
                                                />
                                            )}
                                            name={input.name}
                                            rules={{
                                                required: false,
                                                setValueAs: (value) =>
                                                    input.type === InputFieldType.phone && value
                                                        ? `+${countryPhoneCode}` + value
                                                        : value,
                                            }}
                                            defaultValue={
                                                input.type === InputFieldType.email ||
                                                input.type === InputFieldType.phone ||
                                                input.type === InputFieldType.string
                                                    ? ""
                                                    : "0"
                                            }
                                        />
                                    )}

                                    {input.name === "accountNumber" &&
                                        input.html.type !== "hidden" &&
                                        product.type !== "ATMProduct" && (
                                            <Controller
                                                key={`verify${input.name}`}
                                                control={control}
                                                render={({onChange, onBlur, value}) => (
                                                    <ProductInputs
                                                        key={`verify${input.name}`}
                                                        input={input}
                                                        onBlur={onBlur}
                                                        onChangeValue={(value) => onChange(value)}
                                                        value={
                                                            input.type === InputFieldType.phone
                                                                ? parserPhone(value)
                                                                : value
                                                        }
                                                        error={errors}
                                                        mode={isDesktopOrLaptop ? "outlined" : "flat"}
                                                        customLabel={`Verify ${input.label}`}
                                                        dataPhoneNumber={dataPhoneNumber()}
                                                        getContactFromContactBook={
                                                            getContactFromContactBook
                                                        }
                                                    />
                                                )}
                                                name={`verify${input.name}`}
                                                rules={{
                                                    required: input.required || false,
                                                    setValueAs: (value) =>
                                                        input.type === InputFieldType.phone && value
                                                            ? `+${countryPhoneCode}` + value
                                                            : value,
                                                }}
                                                defaultValue=""
                                            />
                                        )}
                                </View>
                            ))}

                            {!_.find(
                                product.inputs,
                                (i: InputField) => i.type === "email"
                            ) && (
                                <View nativeID="disable-outline">
                                    <Controller
                                        control={control}
                                        render={({onChange, onBlur, value}) => (
                                            <>
                                                <Text
                                                    style={{
                                                        paddingTop: 10,
                                                        paddingBottom: 8,
                                                        fontFamily: "Montserrat_Regular",
                                                    }}
                                                >
                                                    Customer Email (Optional)
                                                </Text>
                                                <TextInput
                                                    value={value}
                                                    onChangeText={(value) => onChange(value)}
                                                    onBlur={onBlur}
                                                    style={{backgroundColor: colors.surface}}
                                                    left={<TextInput.Icon name="mail-outline"/>}
                                                    error={!!errors.receiptEmail}
                                                    placeholder="Email"
                                                    mode={isDesktopOrLaptop ? "outlined" : "flat"}
                                                />
                                            </>
                                        )}
                                        name="receiptEmail"
                                        rules={{
                                            required: false,
                                        }}
                                        defaultValue=""
                                    />
                                    {!!errors.receiptEmail && (
                                        <InputHelperError
                                            isError={true}
                                            message={errors.receiptEmail?.message}
                                        />
                                    )}
                                    <InputHelperInfo
                                        isError={!!errors.receiptEmail}
                                        visible={true}
                                        message=" A copy of this receipt will be sent to this email"
                                    />
                                </View>
                            )}

                            {/* Transaction failed message */}
                            {transactionFailed && (
                                <View style={styles.transactionError}>
                                    <TransactionError
                                        error={transactionFailed}
                                        paymentMethod={paymentChecked as TransactionPaymentMethod}
                                    />
                                </View>
                            )}
                        </View>

                        <View
                            style={{
                                width: isDesktopOrLaptop ? "48%" : "100%",
                                paddingTop: 30,
                            }}
                        >
                            {/* Generate Payment Method Radio Button*/}
                            <View
                                style={{
                                    paddingTop: 0,
                                    display:
                                        product.sku === store.privateLabelGiftCardSettings?.productRedeem?.sku ? "none" : (!store.supportedPaymentMethods.length || store.supportedPaymentMethods.length > 1 ? "flex" : "none"),
                                }}
                            >
                                <Text
                                    style={{
                                        paddingBottom: 10,
                                        fontFamily: "Montserrat_Regular",
                                    }}
                                >
                                    Payment Method
                                </Text>
                                <DropDownComponent
                                    control={control}
                                    name="paymentMethod"
                                    data={supportedPaymentMethods}
                                    defaultValue={paymentChecked}
                                    onSelectionChange={(value) =>
                                        setPaymentChecked(value as TransactionPaymentMethod)
                                    }
                                    inputPropsRight={<TextInput.Icon name={"unfold-more"}/>}
                                />
                            </View>

                            {/* Data payment WALLET */}
                            {paymentChecked === TransactionPaymentMethod.WALLET && (
                                <KeyboardAvoidingView
                                    style={{paddingTop: 20}}
                                    behavior="padding"
                                >
                                    <CardPayment
                                        paymentMethod={paymentChecked as TransactionPaymentMethod}
                                        product={product}
                                        grossTotal={amount}
                                        onCashTenderedAmountChange={(total, amount) =>
                                            setRequestAmount(total)
                                        }
                                    />
                                    <Button
                                        style={{marginTop: 20}}
                                        mode="contained"
                                        onPress={scan}
                                        icon="qr-code-scanner"
                                    >
                                        {!transactionIsProcessing ? "Scan" : "Process"}
                                    </Button>
                                </KeyboardAvoidingView>
                            )}

                            {paymentChecked === TransactionPaymentMethod.CASH && (
                                <KeyboardAvoidingView
                                    style={{paddingTop: 20}}
                                    behavior="padding"
                                >
                                    <Controller
                                        control={control}
                                        render={({onChange, onBlur, value}) => (
                                            <CardPayment
                                                paymentMethod={
                                                    paymentChecked as TransactionPaymentMethod
                                                }
                                                product={product}
                                                setValue={setValue}
                                                grossTotal={amount}
                                                onBlur={onBlur}
                                                value={value}
                                                onChange={onChange}
                                                onCashTenderedAmountChange={(total, tenderedAmount) => {
                                                    if (total - tenderedAmount > 0) {
                                                        setDueCashAmount(total - tenderedAmount);
                                                    } else {
                                                        setDueCashAmount(0);
                                                    }
                                                }}
                                            />
                                        )}
                                        name="tenderedAmount"
                                        defaultValue="0"
                                    />
                                    <Button
                                        style={{marginTop: 20}}
                                        disabled={dueCashAmount > 0 || transactionIsProcessing}
                                        mode="contained"
                                        onPress={handleSubmit(handleSubmitInputs)}
                                    >
                                        PROCESS
                                    </Button>
                                </KeyboardAvoidingView>
                            )}

                            {/* Data payment EXTERNAL_CARD_TERMINAL || EGIFT_MESH */}
                            {_.includes(
                                [
                                    TransactionPaymentMethod.EXTERNAL_CARD_TERMINAL,
                                    TransactionPaymentMethod.EGIFT_MESH,
                                ],
                                paymentChecked
                            ) && (
                                <KeyboardAvoidingView
                                    style={{paddingTop: 20}}
                                    behavior="padding"
                                >
                                    <CardPayment
                                        paymentMethod={paymentChecked as TransactionPaymentMethod}
                                        product={product}
                                        grossTotal={amount}
                                    />
                                    <Button
                                        disabled={transactionIsProcessing}
                                        style={{marginTop: 10}}
                                        mode="contained"
                                        onPress={handleSubmit(handleSubmitInputs)}
                                    >
                                        PROCESS
                                    </Button>
                                </KeyboardAvoidingView>
                            )}

                            {/* Data payment CREDIT_CARD */}
                            {paymentChecked === TransactionPaymentMethod.CREDIT_CARD && (
                                <View
                                    nativeID="disable-outline"
                                    style={{marginTop: isSmartPhone ? 10 : 0}}
                                >
                                    <CreditCardPayment
                                        control={control}
                                        err={errors}
                                        smallContainer
                                        setValue={(value) => setValue("cardNumber", value)}
                                        termsAndConditions={
                                            <>
                                                <Text
                                                    style={{
                                                        textAlign: "justify",
                                                        fontFamily: "Montserrat_Regular",
                                                        width:
                                                            getPlatform() === "android" ? "90%" : undefined,
                                                    }}
                                                >
                                                    I accept the terms and conditions
                                                </Text>
                                            </>
                                        }
                                    />

                                    <View style={{marginTop: 10}}>
                                        <CardPayment
                                            paymentMethod={paymentChecked as TransactionPaymentMethod}
                                            product={product}
                                            grossTotal={amount}
                                        />
                                        <Button
                                            disabled={transactionIsProcessing}
                                            style={{marginTop: 10}}
                                            mode="contained"
                                            onPress={handleSubmit(handleSubmitInputs)}
                                        >
                                            PROCESS
                                        </Button>
                                    </View>
                                </View>
                            )}

                            {/* Data payment PRIVATE_LABEL_GIFT_CARD */}
                            {paymentChecked === TransactionPaymentMethod.PRIVATE_LABEL_GIFT_CARD && (
                                <View
                                    nativeID="disable-outline"
                                    style={{marginTop: isSmartPhone ? 20 : 10}}
                                >
                                    <>
                                        <InputPrivateLabelGiftCard
                                            isScan={() => setScanningPrivateLabel(true)}
                                            isSwipe={() => setSwipingPrivateLabel(true)}
                                            control={control}
                                            errors={errors}
                                            setScanningCode={(value) => {
                                                setPrivateLabelCardMagData("");
                                                setPrivateLabelNumber(String(value).substr(-19));
                                                setValue("privateLabelCardMagData", "");
                                                setValue("privateLabelNumber", String(value).substr(-19));
                                            }}
                                        />

                                        <Controller
                                            control={control}
                                            render={({onChange, onBlur, value}) => (
                                                <>
                                                    <TextInput
                                                        value={value}
                                                        onChangeText={(value) => {
                                                            onChange(value)
                                                        }}
                                                        onBlur={onBlur}
                                                        style={{display: "none"}}
                                                    />
                                                </>
                                            )}
                                            name="privateLabelCardMagData"
                                            defaultValue=""
                                        />
                                    </>

                                    <View style={{marginTop: 10, marginBottom: 150}}>
                                        <CardPayment
                                            paymentMethod={paymentChecked as TransactionPaymentMethod}
                                            product={product}
                                            grossTotal={amount}
                                            numberOrMagData={[privateLabelNumber, privateLabelCardMagData]}
                                        />
                                        <Button
                                            disabled={transactionIsProcessing}
                                            style={{marginTop: 10}}
                                            mode="contained"
                                            onPress={handleSubmit(handleSubmitInputs)}
                                        >
                                            PROCESS
                                        </Button>
                                    </View>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            </ScrollView>

            <SwipeCardScanner
                isOpen={isSwiping}
                isQr={isQr}
                onClose={() => {
                    setSwiping(false);
                    setQr(false);
                }}
                onScanQr={(data) => {
                    customerScan(data);
                }}
                onScanBarCode={(barcode) => {
                    setValue("cardMagData", "");
                    setValue(
                        "accountNumber",
                        barcode.length > 12 ? String(barcode).substr(-19) : ""
                    );
                }}
                onSwipe={(data) => {
                    setValue("cardMagData", data.magData);
                    setValue("accountNumber", data.cardNumber);
                }}
            />

            <SwipeCardScanner
                isOpen={isSwipingPrivateLabel}
                onClose={() => {
                    setSwipingPrivateLabel(false);
                }}
                onScanBarCode={(barcode) => {
                    setValue("privateLabelCardMagData", "");
                    setValue(
                        "privateLabelNumber",
                        barcode.length > 12 ? String(barcode).substr(-19) : ""
                    );
                }}
                onSwipe={(data) => {
                    setValue("privateLabelCardMagData", data.magData);
                    setValue("privateLabelNumber", data.cardNumber);
                }}
            />

            <DialogComponent
                dismissable={false}
                visible={!!paymentApprovalID}
                title={
                    <Text style={{textAlign: "center", color: colors.primary}}>
                        Payment agreement request
                    </Text>
                }
                content={
                    <View style={{justifyContent: "center", alignItems: "center"}}>
                        <Avatar.Icon
                            style={{backgroundColor: "transparent"}}
                            color="blue"
                            icon="error"
                            size={80}
                        />
                        <Text style={{textAlign: "center"}}>
                            {loadingAssignPayment
                                ? "Waiting for the client to approve the payment agreement request."
                                : timeoutRequest
                                    ? "The customer is taking time to authorize the request. Do you want to check the status of the payment agreement request again?"
                                    : "Send the following payment agreement request so that your client can approve it and continue with the operation."}
                        </Text>
                    </View>
                }
                actions={
                    <>
                        <Button
                            style={{flex: 1, marginRight: 5}}
                            mode="outlined"
                            loading={loadingCancelPayment}
                            disabled={loadingCancelPayment}
                            onPress={cancelPaymentCustomer}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{flex: 1, marginLeft: 5}}
                            mode="outlined"
                            loading={loadingAssignPayment}
                            disabled={loadingAssignPayment}
                            onPress={assignPaymentCustomer}
                            icon={timeoutRequest ? "refresh" : undefined}
                        >
                            {timeoutRequest ? "Retry" : "Request"}
                        </Button>
                    </>
                }
                onDismiss={cancelPaymentCustomer}
            />

            <LoadingBlockComponent
                message="Processing transaction"
                visible={transactionIsProcessing}
            />
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
    },
    transactionError: {
        flexDirection: "row",
        justifyContent: "center",
        marginTop: 20,
        backgroundColor: "#d23232",
        padding: 10,
        borderRadius: 5,
        alignItems: "center",
    },
});

export default ProductForm;
