import React from "react";
import { Card, Divider, Title, useTheme, Text } from "react-native-paper";
import { Product } from "../../../screens/Market/reducer/state";
import { StyleSheet, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import { useNavigation } from "@react-navigation/native";
import { IS_DESKTOP_OR_LAPTOP, IS_SMALL_SCREEN, IS_SMARTPHONE } from "../../lib/PlatformDevice";
import { appIdentifier } from "../../../identifier";

interface Props {
  product: Product;
  onSelectProduct: (product: Product) => void;
  url?: string;
  hidePrice?: boolean
}

/**
 * @function Component Card Product
 * @param {Product} product Product Object
 * @param {Function} onSelectProduct Callback handle selection a product
 */
const CardProduct = (props: Props) => {
  const smallScreen = useMediaQuery(IS_SMALL_SCREEN);
  const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
  const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
  const { colors } = useTheme();
  const navigation = useNavigation();
  const isClient = appIdentifier === "CLIENT";
  let discount;
  if (isClient) {
    const discountMode = props.product.discountMode;
    const discountQty = props.product.discount ? props.product.discount : 0;

    discount = discountQty
      ? discountMode === "FIXED"
        ? `$${discountQty}`
        : `${discountQty}%`
      : "";
  }

  const getPricePart = (number: number, part: number) =>
    number.toString().split(".")[part] || "00";
  return (
    <Card
      style={styles.card}
      onPress={() => {
        props.onSelectProduct(props.product);
        navigation.navigate("Product Form", { scanProductToken: props.url });
      }}
    >
      <Card.Content style={{ flexGrow: 3 }}>
        <View style={styles.cardContent}>
          <View style={{ justifyContent: "flex-start" }}>
            <Title
              numberOfLines={3}
              style={{
                fontFamily: "Montserrat_Bold",
                fontSize: 18,
                lineHeight: isSmartPhone ? 18 : undefined,
                marginVertical: 0,
                bottom: 1,
              }}
            >
              {props.product.name}
            </Title>
          </View>
        </View>
      </Card.Content>
      <Card.Content>
        <Text>{props.product.isPIN ? "PIN" : ""}</Text>
      </Card.Content>
      <View style={{ paddingTop: !isDesktopOrLaptop ? "20%" : "30%" }}>
        {isClient && (
          <Card.Content style={{ marginBottom: 12, marginTop: -12 }}>
            <Text style={{ color: colors.primary }}>
              {discount ? `${discount} off` : ""}
            </Text>
          </Card.Content>
        )}
        <Divider style={{ backgroundColor: colors.primary }} />
      </View>
      <Card.Actions
        style={[{
          justifyContent: "center",
          marginTop: !isDesktopOrLaptop ? "5%" : "2%",
        }, props.hidePrice ? {display: "none"} : {} ]}
      >
        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <Text
            style={{
              fontFamily: "Montserrat_Extra_Bold",
              fontSize: !isDesktopOrLaptop ? (smallScreen ? 20 : 25) : 45,
              alignSelf: "flex-start",
            }}
          >
            ${" "}
          </Text>
          <Text
            style={{
              fontFamily: "Montserrat_Extra_Bold",
              fontSize: !isDesktopOrLaptop ? (smallScreen ? 30 : 40) : 60,
            }}
          >
            {getPricePart(props.product.price, 0)}.
          </Text>
          <Text
            style={{
              fontFamily: "Montserrat_Extra_Bold",
              fontSize: !isDesktopOrLaptop ? (smallScreen ? 20 : 25) : 45,
            }}
          >
            {getPricePart(props.product.price, 1)}
          </Text>
        </View>
      </Card.Actions>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    flexGrow: 1,
    elevation: 1,
  },
  cardContent: {
    flexDirection: "column",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
});

export default CardProduct;
