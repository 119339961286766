import { updateTotal } from './updateTotal';
import { SubmitTransactionArgs, ValidationTransactionSuccess } from '../../../../lib/Transactions/transactions';
import _ from 'lodash';
import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';
import { captureException } from '../../../../lib/sentry';

const NAME = 'update';

interface Data extends Action {
    transaction: Partial<SubmitTransactionArgs>
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        transaction: _.merge({ ...state.transaction }, { ...data.transaction }),
    };
}

export const update = (context: ContextType, data: Omit<Data, 'type'>): Promise<ValidationTransactionSuccess> =>
    new Promise<ValidationTransactionSuccess>((success) => {

        context.dispatch({ type: NAME, ...data });

        // update total is optional, only log errors
        updateTotal(context, data.transaction)
            .then(success)
            .catch((e) => {
                success();
                captureException(e);
            });
    });
