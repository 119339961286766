import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { MarketWizardStep, Product, State } from './../state';

const NAME = 'selectProduct';

interface Data extends Action {
    product: Product,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        wizard: {
            ...state.wizard,
            currentStep: MarketWizardStep.PRODUCT_INPUTS,
        },
        product: {
            ...state.product,
            selected: data.product,
        },
    };
}

export const selectProduct = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
