import { useStoreInformation } from "./hook/useStoreInformation";
import { DocumentNode } from "graphql";
import React, { useEffect, useState } from "react";
import { captureException } from "../../lib/sentry";
import { useApolloGraphQL } from "../ApolloGraphQL/hook/useGraphqlApolloClient";

interface Props {
  query?: DocumentNode;
}

export const StoreInfoController = (props: Props) => {
  const [loadingStoreInfo, setLoadingStoreInfo] = useState(false);
  const { clientWithToken } = useApolloGraphQL();
  const { store, loadStoreInfo } = useStoreInformation();

  useEffect(() => {
    (async () => {
      if (!loadingStoreInfo && !!clientWithToken && !store) {
        try {
          setLoadingStoreInfo(true);
          const data = await loadStoreInfo();
          setLoadingStoreInfo(false);
        } catch (e) {
          console.log(e);
          captureException(e);
          setLoadingStoreInfo(false);
        }
      }
    })();
  }, [store, clientWithToken]);

  return <></>;
};
