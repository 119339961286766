import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { ApiViewer, State, StoreInfo } from '../state';

const NAME = 'cleanStoreInfo';

interface Data extends Action {
    store: StoreInfo
    viewer: ApiViewer
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    return {
        ...state,
        store: undefined,
        viewer: undefined,
    };
}

export const cleanStoreInfo = (context: ContextType) => {
    context.dispatch({ type: NAME });
}

