import { initializeClient as initializeClientRN } from "./reducer/actions/initializeClientRN";
import { initializeClient as initializeClientR } from "./reducer/actions/initializeClientR";
import { setAPIBearerToken } from "./reducer/actions/setAPIBearerToken";
import ApolloGraphQLContext from "./reducer/context";
import { useContext, useEffect } from "react";
import { removeAPIBearerToken } from "./reducer/actions/removeAPIBearerToken";
import { getCustomerAuthData } from "../../lib/auth";
import { isValidJsonWebToken } from "../../lib/jwt";
import { appIdentifier } from "../../../identifier";
interface Props {
  children?: any;
  persistent?: boolean;
}

export function ApolloGraphQLController(props: Props) {
  const apolloGraphQLContext = useContext(ApolloGraphQLContext);

  useEffect(() => {
    if (apolloGraphQLContext) {
      const { client, clientWithToken, bearerToken } =
        apolloGraphQLContext.state;
      const clientIsReady =
        client &&
        ((clientWithToken && bearerToken.getToken()) ||
          (!clientWithToken && !bearerToken.getToken()));

      //load from storage
      const loadFromStorage = async () => {
        const authData = await getCustomerAuthData();

        if (props.persistent && !bearerToken.getToken() && authData) {
          if (
            typeof authData === "object" &&
            "token" in authData &&
            isValidJsonWebToken(authData.token)
          ) {
            setAPIBearerToken(apolloGraphQLContext, {
              bearerToken: authData.token,
            });
          } else {
            removeAPIBearerToken(apolloGraphQLContext);
          }
          return;
        }

        if (!clientIsReady) {
          if (appIdentifier === "MESH") {
            initializeClientR(apolloGraphQLContext);
            return;
          }
          await initializeClientRN(apolloGraphQLContext);
          return;
        }
      };
      loadFromStorage();
    }
  });

  return props.children;
}
