import { CardinalOptions, CardinalResponse } from "../state";
import { ContextType } from "../context";
import gql from 'graphql-tag';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { md5 } from "../../../../lib/string.util";
import { roundNumber } from "../../../../lib/number.util";

const JWT_TOKEN_MUTATION = gql`
    mutation cardinalJWT($orderNumber: String!, $amount: Int!) {
        consumerAuthentication {
            cardinalJWT(orderNumber: $orderNumber, amount: $amount)
        }
    }
`;

export const secureWithCardinal = (context: ContextType, opt: CardinalOptions): Promise<CardinalResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            // Cache key transaction
            const cacheKey = md5(
                JSON.stringify({
                    orderNumber: opt?.orderNumber,
                    amount: roundNumber(Number(opt?.orderAmount) * 100, 0),
                })
            );
            const cache = await AsyncStorage.getItem('cacheKey');
            const cacheData = cache ? JSON.parse(cache) : null;

            if (cacheData && cacheData[cacheKey]) {
                resolve(cacheData[cacheKey]);
            } else {
                const { data } = await context.apolloGraphQL.client.mutate({
                    mutation: JWT_TOKEN_MUTATION,
                    variables: {
                        orderNumber: opt.orderNumber,
                        amount: roundNumber(Number(opt.orderAmount) * 100, 0),
                    },
                    fetchPolicy: 'no-cache'
                });
                const cardinalJWT = data.consumerAuthentication.cardinalJWT;

                const options = { ...opt, resolve, reject, cardinalJWT };
                context.dispatch({ type: 'SECURE_CARDINAL', options })
            }
        } catch (error) {
            reject(error);
        }

    })
}

export const closeSecureCardinal = (context: ContextType, cache: any) => {
    context.dispatch({ type: 'CLOSE_SECURE_CARDINAL' })
}