// import { logger } from 'common/lib/logger';
import jwtDecode from 'jwt-decode';
import CryptoJS from 'crypto-js';
import Base64 from './Base64';
import { KJUR } from 'jsrsasign';
// import { API_ID, API_KEY } from '@env';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const decodeJsonWebToken = (token: string): any => {
    return jwtDecode(token);
};

export const isValidJsonWebToken = (token: string): boolean => {
    // return true;
    try {
        if (token) {
            const tokenPayload = decodeJsonWebToken(token);
            if (tokenPayload) {
                return getTokenTimeToExpire(token) > 0;
            }
        }
    } catch (e) {
        // logger.debug('Invalid token', { error: e });
        // do nothing, invalid token
    }

    return false;
};

/**
 * time remaining to expire the token in ms
 */
export const getTokenTimeToExpire = (token): number => {
    if (token) {
        const match = token.match(/\.([^.]+)/);
        if (match && match[1]) {
            try {
                const authResponse = JSON.parse(Base64.atob(match[1]));
                let expireAt = new Date((new Date()).getTime() + (2 * 3600 * 1000)); // expire in 2 hours by default
                if (authResponse.exp) {
                    expireAt = new Date(authResponse.exp * 1000);
                }

                const expiresIn = expireAt.getTime() - new Date().getTime();
                if (expiresIn > 0) {
                    return expiresIn;
                }
            } catch (e) {
                console.error(e);

                return 0;
            }
        }
    }

    return 0;
};

export const encodeJWT = (data, secret) => {

    const header = {
        alg: 'HS256',
        typ: 'JWT'
    };

    const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    const encodedHeader = base64url(stringifiedHeader);

    const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    const encodedData = base64url(stringifiedData);

    let signature = encodedHeader + "." + encodedData;
    signature = CryptoJS.HmacSHA256(signature, secret);
    signature = base64url(signature);

    return `${encodedHeader}.${encodedData}.${signature}`;
}

function base64url(source) {
    // Encode in classical base64
    let encodedSource = CryptoJS.enc.Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
}

export const getSignJWT = async () => {
    const header = {
        alg: 'HS256',
        typ: 'JWT'
    };
    let tNow = KJUR.jws.IntDate.get('now');
    let tEnd = KJUR.jws.IntDate.get('now + 1day');

    const API_ID = "C29351684C663DF59882";
    const API_KEY = "E5E4314C-0EAC-421D-914E-F59636AEDCF7";

    const transactionId = Math.random();
    const payload = {
        jti: transactionId,
        iss: API_ID, //ApiIdentifier
        iat: tNow, //now
        exp: tEnd //now + 1day
    };
    const sHeader = JSON.stringify(header);
    const sPayload = JSON.stringify(payload);

    const sJWT = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, API_KEY);
    const t = { token: sJWT, expire: tEnd };
    await AsyncStorage.setItem('ApiToken', JSON.stringify(t));
    return sJWT;
}
