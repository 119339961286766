import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO-8601 encoded UTC date string. Example: `1985-06-18T18:05:00-05:00` */
  DateTime: any;
  /**
   * The `DynamicObject` represent a object with unknown and dynamic properties.
   * For this type of objects you can't select the properties to get.
   *
   * A common usage of these type of objects is when the object represent a set of **key:value** pairs.
   *
   * Some tools like GraphiQL display a syntax warning when try to send this type of object, but works fine.
   * In any case to remove the warning can set the object inside a string.
   */
  DynamicObject: any;
  /** An ISO-8601 encoded date string. Example: `1985-06-18` */
  Date: any;
};

export type Query = {
  __typename?: 'Query';
  achPayments?: Maybe<AchPaymentQuery>;
  apiKeys?: Maybe<ApiKeyQuery>;
  consumerAuthenticationProviders?: Maybe<ConsumerAuthenticationProviderQuery>;
  cronJobs?: Maybe<CronJobQuery>;
  customerCredits?: Maybe<CustomerCreditQuery>;
  customerCreditSettlementBatches?: Maybe<CustomerCreditSettlementBatchQuery>;
  customers?: Maybe<CustomersQuery>;
  /** Read current API user UI settings from cloud */
  uiSettings?: Maybe<Scalars['DynamicObject']>;
  /** Verify given token signature and return token payload. Return null if token is not valid. */
  verifyToken?: Maybe<Scalars['DynamicObject']>;
  e911UserFees?: Maybe<E911UserFeeQuery>;
  externalServiceRequests?: Maybe<ExternalServiceRequestQuery>;
  /** Check API connectivity & status doing a simple PING request. The PING return current server timestamp. */
  ping?: Maybe<Scalars['Int']>;
  merchantPayments?: Maybe<MerchantPaymentQuery>;
  merchants?: Maybe<MerchantQuery>;
  /** Find specific node using common property instead of ID. Helpful to create page navigation using slug, numbers and common properties instead of encoded ID. */
  findOneBy?: Maybe<Node>;
  node?: Maybe<Node>;
  nodes?: Maybe<Array<Maybe<Node>>>;
  paymentGateways?: Maybe<PaymentGatewayQuery>;
  pins?: Maybe<PinQuery>;
  plugins?: Maybe<PluginQuery>;
  priceLists?: Maybe<PriceListQuery>;
  productCategories?: Maybe<ProductCategoryQuery>;
  products?: Maybe<ProductQuery>;
  serviceProviders?: Maybe<ServiceProviderQuery>;
  /** Get store details */
  storeInfo?: Maybe<Store>;
  /** Create a token to login as any user */
  impersonateToken?: Maybe<Scalars['String']>;
  /** Refresh access token to get a new token */
  refreshToken?: Maybe<Scalars['String']>;
  /** Fetch a access token for given credentials */
  token?: Maybe<Scalars['String']>;
  tools?: Maybe<ToolsQuery>;
  transactions?: Maybe<TransactionQuery>;
  tvs?: Maybe<TvsBundleQuery>;
  userActivities?: Maybe<UserActivityQuery>;
  users?: Maybe<UserQuery>;
  userSessions?: Maybe<UserSessionQuery>;
  viewer?: Maybe<User>;
  verifoneTerminals?: Maybe<VerifoneTerminalQuery>;
};


export type QueryVerifyTokenArgs = {
  token: Scalars['String'];
};


export type QueryFindOneByArgs = {
  type: FindOneByNode;
  field: FindOneByField;
  value?: Maybe<Scalars['String']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryStoreInfoArgs = {
  number: Scalars['Int'];
};


export type QueryImpersonateTokenArgs = {
  merchant?: Maybe<Scalars['String']>;
  terminal?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};


export type QueryTokenArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type AchPaymentQuery = {
  __typename?: 'AchPaymentQuery';
  all?: Maybe<AchPaymentConnection>;
};


export type AchPaymentQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<AchPaymentOrderBy>>>;
  where?: Maybe<AchPaymentCondition>;
};

export type AchPaymentOrderBy = {
  field: AchPaymentOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum AchPaymentOrderByField {
  CreatedAt = 'createdAt',
  Description = 'description',
  ReferenceNumber = 'referenceNumber',
  Status = 'status',
  StatusChangedAt = 'statusChangedAt',
  TotalDebit = 'totalDebit',
  TotalCredit = 'totalCredit',
  Balance = 'balance'
}

export enum OrderDirection {
  /** Ascending Order */
  Asc = 'ASC',
  /** Descending Order */
  Desc = 'DESC'
}

export type AchPaymentCondition = {
  balance?: Maybe<FloatComparisonExpression>;
  totalCredit?: Maybe<FloatComparisonExpression>;
  totalDebit?: Maybe<FloatComparisonExpression>;
  statusChangedAt?: Maybe<DateTimeComparisonExpression>;
  status?: Maybe<StringComparisonExpression>;
  referenceNumber?: Maybe<StringComparisonExpression>;
  description?: Maybe<StringComparisonExpression>;
  createdAt?: Maybe<DateTimeComparisonExpression>;
  merchant?: Maybe<NodeComparisonExpression>;
};

/** Create float comparison expression to compare values. */
export type FloatComparisonExpression = {
  /** Comparison operator */
  op: NumberComparisonOperator;
  /** Number value to compare */
  value: Scalars['Float'];
  /** Max value to compare when use `BETWEEN` */
  maxValue?: Maybe<Scalars['Float']>;
};

export enum NumberComparisonOperator {
  /** Equals */
  Eq = 'EQ',
  /** Not Equals */
  Neq = 'NEQ',
  /** Lower Than */
  Lt = 'LT',
  /** Lower Than or Equals */
  Lte = 'LTE',
  /** Greater Than */
  Gt = 'GT',
  /** Greater Than or Equals */
  Gte = 'GTE',
  /** Between values *(Given values are included)* */
  Between = 'BETWEEN'
}

/** Create date comparison expression to filter values by date. */
export type DateTimeComparisonExpression = {
  /** Comparison operator */
  op: DateComparisonOperator;
  /** If strict mode is enabled the value given in not included. */
  strict?: Maybe<Scalars['Boolean']>;
  /** Base date to compare */
  date: Scalars['DateTime'];
  /** Max value when use **BETWEEN** operator */
  maxDate?: Maybe<Scalars['DateTime']>;
};

export enum DateComparisonOperator {
  Before = 'BEFORE',
  After = 'AFTER',
  Between = 'BETWEEN'
}


/** Create string comparison expression to filter values by contents */
export type StringComparisonExpression = {
  /** Comparison operator, default value: `CONTAINS` */
  op?: Maybe<StringComparisonOperator>;
  /** String value to search */
  value?: Maybe<Scalars['String']>;
  /** Multiple strings to search (using OR), retrieve records matching ONE OF this values. */
  values?: Maybe<Array<Scalars['String']>>;
};

export enum StringComparisonOperator {
  Contains = 'CONTAINS',
  Equal = 'EQUAL',
  StartsWith = 'STARTS_WITH',
  EndsWith = 'ENDS_WITH'
}

/** Create Node comparison expression to filter values by related nodes. */
export type NodeComparisonExpression = {
  /** Comparison operator, default `IN` */
  op?: Maybe<NodeComparisonOperator>;
  /** Array of nodes to search */
  nodes: Array<Scalars['ID']>;
};

export enum NodeComparisonOperator {
  /** Node In */
  In = 'IN',
  /** Node Not In */
  Nin = 'NIN'
}

export type AchPaymentConnection = {
  __typename?: 'AchPaymentConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<AchPaymentEdge>>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  page?: Maybe<Scalars['Int']>;
};

export type AchPaymentEdge = {
  __typename?: 'AchPaymentEdge';
  node: AchPayment;
  cursor: Scalars['String'];
};

export type AchPayment = Node & MerchantAware & {
  __typename?: 'AchPayment';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  info?: Maybe<AchInfo>;
  totalDebit: Scalars['Float'];
  totalCredit: Scalars['Float'];
  balance: Scalars['Float'];
  lines?: Maybe<Array<Maybe<AchPaymentLine>>>;
  nachaFilename: Scalars['String'];
  nachaFileUploadable: Scalars['Boolean'];
  nachaFile: Scalars['String'];
  number: Scalars['Int'];
};

/** An object with an ID. */
export type Node = {
  id: Scalars['ID'];
};

export type MerchantAware = {
  merchant?: Maybe<Merchant>;
};

export type Merchant = {
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  serviceProviders?: Maybe<Array<Maybe<ServiceProviderConfig>>>;
  paymentGateways?: Maybe<Array<Maybe<PaymentGatewayConfig>>>;
  apiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  billingSettings?: Maybe<MerchantBillingSettings>;
  securitySettings?: Maybe<MerchantSecuritySettings>;
  wholesalePriceList?: Maybe<WholeSalePriceList>;
  /** Merchant time-zone */
  timeZone?: Maybe<Scalars['String']>;
  paymentSettings?: Maybe<MerchantPaymentSettings>;
  serviceProvider?: Maybe<ServiceProviderConfig>;
  capabilities: Array<Maybe<MerchantCapability>>;
  number?: Maybe<Scalars['Int']>;
  merchant?: Maybe<Merchant>;
  id: Scalars['ID'];
  allowedProviders?: Maybe<Array<Maybe<ServiceProvider>>>;
  creditBalance: Scalars['Float'];
};


export type MerchantServiceProviderArgs = {
  provider: Scalars['ID'];
};

/** Merchant configuration for specific service. */
export type ServiceProviderConfig = Node & {
  __typename?: 'ServiceProviderConfig';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  service?: Maybe<ServiceProvider>;
  enabledInNewMerchants: Scalars['Boolean'];
  /** Default merchant credentials. This credentials are used for common merchant operations with this service. */
  credentials?: Maybe<ServiceProviderCredential>;
};

export type ServiceProvider = Node & {
  __typename?: 'ServiceProvider';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  credentialsInputs?: Maybe<Array<Maybe<InputField>>>;
  requireCredentials?: Maybe<Scalars['Boolean']>;
};

export type InputField = {
  __typename?: 'InputField';
  name: Scalars['String'];
  type: InputFieldType;
  /** Helpful information to display as help for final users. */
  help?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  /** Choices when type is a select */
  choices?: Maybe<Scalars['DynamicObject']>;
  /** Allow mutiple options selected, options must be sumitted using separated comma values. */
  allowMultiple: Scalars['Boolean'];
  html?: Maybe<InputFieldHtmlAttr>;
};

/** Define allowed types of data for specific inputs. */
export enum InputFieldType {
  /**
   * The `string` scalar type represents textual data,
   * represented as UTF-8 character sequences.
   */
  String = 'string',
  /** The `boolean` scalar type represents true or false. */
  Boolean = 'boolean',
  /**
   * The `float` scalar type represents signed double-precision
   * fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point).
   */
  Float = 'float',
  /**
   * The `integer` scalar type represents non-fractional signed whole numeric
   * values. Int can represent values between -(2^31) and 2^31 - 1.
   */
  Integer = 'integer',
  /**
   * An [ISO-8601](https://es.wikipedia.org/wiki/ISO_8601) encoded UTC date string.
   *
   * **Example:**
   *
   * 1985-06-18T18:05:00-05:00
   */
  Datetime = 'datetime',
  /**
   * An [ISO-8601](https://es.wikipedia.org/wiki/ISO_8601) encoded UTC date string.
   *
   * **Example:**
   *
   * 1985-06-18
   */
  Date = 'date',
  /**
   * An [ISO-8601](https://es.wikipedia.org/wiki/ISO_8601) encoded UTC time string.
   *
   * **Example:**
   *
   * 13:18:05
   */
  Time = 'time',
  /**
   * Represent a select of multiple options, some inputs may allow
   * select multiple options at once and in that case must submit each value separated by comma.
   */
  Select = 'select',
  /**
   * Telephone number using [E.123](https://en.wikipedia.org/wiki/E.123)
   * international or Microsoft format. Spaces or others symbols to separate groups of numbers are optional.
   *
   * **Examples:**
   *
   *     +1 305 1231234
   *     +1 (305)-123-1234
   *     +13051231234
   */
  Phone = 'phone',
  /** The `email` scalar type represent a valid email address. */
  Email = 'email',
  /**
   * Magnetic card information when users swipe a magnetic card.
   * Commonly these fields are related to hidden inputs and request to user to swipe the card.
   */
  Magdata = 'magdata'
}


export type InputFieldHtmlAttr = {
  __typename?: 'InputFieldHtmlAttr';
  type: Scalars['String'];
  maxlength?: Maybe<Scalars['Int']>;
  minlength?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  pattern?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Credentials related to a service provider. */
export type ServiceProviderCredential = Node & {
  __typename?: 'ServiceProviderCredential';
  id: Scalars['ID'];
  label: Scalars['String'];
  /** Price list related to this credentials to know provider prices and discounts. */
  priceList?: Maybe<PriceList>;
  /** Service related to this credentials */
  service?: Maybe<ServiceProvider>;
  /**
   * Get entered service credentials.
   * The return is a _key:value_ pair based on **ServiceProvider.credentialsInputs**
   */
  credentials?: Maybe<Scalars['DynamicObject']>;
};

export type PriceList = {
  children?: Maybe<Array<Maybe<PriceList>>>;
  name: Scalars['String'];
  items?: Maybe<PriceListItemConnection>;
  editMode: Scalars['Boolean'];
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  invalidItems?: Maybe<Scalars['Int']>;
  customProducts?: Maybe<Scalars['Int']>;
  allocated?: Maybe<Scalars['Int']>;
};


export type PriceListItemsArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<PriceListItemOrderBy>>>;
  where?: Maybe<PriceListItemCondition>;
};

export type PriceListItemOrderBy = {
  field: PriceListItemOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum PriceListItemOrderByField {
  Carrier = 'carrier',
  ProductSku = 'productSku'
}

export type PriceListItemCondition = {
  feeMode?: Maybe<StringComparisonExpression>;
  fee?: Maybe<FloatComparisonExpression>;
  discountMode?: Maybe<StringComparisonExpression>;
  discount?: Maybe<FloatComparisonExpression>;
  price?: Maybe<FloatComparisonExpression>;
  product?: Maybe<NodeComparisonExpression>;
  enabled?: Maybe<Scalars['Boolean']>;
  list?: Maybe<NodeComparisonExpression>;
  category?: Maybe<NodeComparisonExpression>;
  invalid?: Maybe<Scalars['Boolean']>;
  serviceProvider?: Maybe<NodeComparisonExpression>;
  /** This filter only works in WholeSalePriceList items */
  autoSync?: Maybe<Scalars['Boolean']>;
  carrier?: Maybe<NodeComparisonExpression>;
  customSaleDiscount?: Maybe<Scalars['Boolean']>;
};

export type PriceListItemConnection = {
  __typename?: 'PriceListItemConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<PriceListItemEdge>>>;
};

export type PriceListItemEdge = {
  __typename?: 'PriceListItemEdge';
  node: PriceListItem;
  cursor: Scalars['String'];
};

export type PriceListItem = {
  list?: Maybe<PriceList>;
  enabled?: Maybe<Scalars['Boolean']>;
  product?: Maybe<Product>;
  price: Scalars['Float'];
  discount: Scalars['Float'];
  discountMode: Scalars['String'];
  fee: Scalars['Float'];
  feeMode: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  agentCommission?: Maybe<Scalars['Float']>;
  saleCommission?: Maybe<Scalars['Float']>;
  invalidSalePrice?: Maybe<Scalars['Boolean']>;
  invalidSaleDiscount?: Maybe<Scalars['Boolean']>;
  invalidSaleFee?: Maybe<Scalars['Boolean']>;
  shouldBeComputed?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type Product = {
  name: Scalars['String'];
  category?: Maybe<ProductCategory>;
  price: Scalars['Float'];
  /** @deprecated [Since 02/2019] Use fees instead to get the list of product fees */
  fee: Scalars['Float'];
  /** @deprecated [Since 02/2019]  Use fees instead to get the list of product fees */
  feeMode?: Maybe<FeeMode>;
  variablePrice: Scalars['Boolean'];
  minPrice: Scalars['Float'];
  maxPrice: Scalars['Float'];
  details?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  canonicalName: Scalars['String'];
  carrier?: Maybe<ProductCarrier>;
  prepaidProduct: Scalars['Boolean'];
  prepaidType?: Maybe<PrepaidProductType>;
  id: Scalars['ID'];
  /**
   * All required inputs to purchase a product.
   * Commonly these fields are used to build the html form after the user select desired product.
   */
  inputs?: Maybe<Array<Maybe<InputField>>>;
  discountMode: AmountMode;
  enabled?: Maybe<Scalars['Boolean']>;
  fees?: Maybe<Array<Maybe<ProductFee>>>;
  discount: Scalars['Float'];
};

export type ProductCategory = {
  parent?: Maybe<ProductCategory>;
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  sku?: Maybe<Scalars['String']>;
  canonicalName: Scalars['String'];
  bgColor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  private?: Maybe<Scalars['Boolean']>;
};

export enum FeeMode {
  /** The amount represent a fixed value. */
  Fixed = 'FIXED',
  /** The amount represent a % of the gross total *(does not include other fees or discounts)*. */
  Rate = 'RATE',
  /** The amount represent a % of previously calculated subTotal *(include all previously calculated fees or discounts)*. */
  RateOver = 'RATE_OVER'
}

/** Represent the category directly related to products, commonly the company offering some service. */
export type ProductCarrier = {
  name: Scalars['String'];
  sku: Scalars['String'];
  country: CountryCode;
  id: Scalars['ID'];
};

/** Represent a country code using ISO 3166-1 alpha-2 */
export enum CountryCode {
  /** Afghanistan */
  Af = 'AF',
  /** Aland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua And Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bosnia And Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei Darussalam */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cape Verde */
  Cv = 'CV',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo, Democratic Republic */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Cote D'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island & Mcdonald Islands */
  Hm = 'HM',
  /** Holy See (Vatican City State) */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran, Islamic Republic Of */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle Of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Lao People's Democratic Republic */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libyan Arab Jamahiriya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Macedonia */
  Mk = 'MK',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia, Federated States Of */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** Netherlands Antilles */
  An = 'AN',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestinian Territory, Occupied */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Reunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russian Federation */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthelemy */
  Bl = 'BL',
  /** Saint Helena */
  Sh = 'SH',
  /** Saint Kitts And Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin */
  Mf = 'MF',
  /** Saint Pierre And Miquelon */
  Pm = 'PM',
  /** Saint Vincent And Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome And Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia And Sandwich Isl. */
  Gs = 'GS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard And Jan Mayen */
  Sj = 'SJ',
  /** Swaziland */
  Sz = 'SZ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syrian Arab Republic */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad And Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks And Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States */
  Us = 'US',
  /** United States Outlying Islands */
  Um = 'UM',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Viet Nam */
  Vn = 'VN',
  /** Virgin Islands, British */
  Vg = 'VG',
  /** Virgin Islands, U.S. */
  Vi = 'VI',
  /** Wallis And Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
  /** Curacao */
  Cw = 'CW'
}

export enum PrepaidProductType {
  /** PIN based product */
  Pin = 'PIN',
  /** Real-Time-Replenishment */
  Rtr = 'RTR'
}

export enum AmountMode {
  /** Amount is represented as fixed value. */
  Fixed = 'FIXED',
  /** Amount is represent as rate (%) */
  Rate = 'RATE'
}

export type ProductFee = Node & {
  __typename?: 'ProductFee';
  id: Scalars['ID'];
  name: Scalars['String'];
  amount: Scalars['Float'];
  mode: Scalars['String'];
};

export type PaymentGatewayConfig = MerchantAware & {
  __typename?: 'PaymentGatewayConfig';
  merchant?: Maybe<Merchant>;
  gateway?: Maybe<PaymentGateway>;
  enabled: Scalars['Boolean'];
  useInDealers: Scalars['Boolean'];
  consumerAuthentication?: Maybe<ConsumerAuthenticationProvider>;
  /**
   * Get entered gateway credentials.
   * The return is a _key:value_ pair based on **Gateway.credentialsInputs**
   */
  credentials?: Maybe<Scalars['DynamicObject']>;
};

export type PaymentGateway = Node & {
  __typename?: 'PaymentGateway';
  id: Scalars['ID'];
  name: Scalars['String'];
  credentialsInputs?: Maybe<Array<Maybe<InputField>>>;
};

export type ConsumerAuthenticationProvider = Node & {
  __typename?: 'ConsumerAuthenticationProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ApiKey = Node & MerchantAware & {
  __typename?: 'ApiKey';
  id: Scalars['ID'];
  scope: ApiKeyScope;
  enabled: Scalars['Boolean'];
  sandbox: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  identifier: Scalars['String'];
  merchant?: Maybe<Merchant>;
  description: Scalars['String'];
  lastUse?: Maybe<Scalars['DateTime']>;
  restrictedIps: Array<Maybe<Scalars['String']>>;
};

export enum ApiKeyScope {
  /**
   * Interact with the Merchant's store from the point of view of customers.
   *                         Can make transactions but need a valid payment method like Credit Card.
   *                         Merchant sensitive data like balance or product cost are not available.
   */
  Customer = 'CUSTOMER',
  /**
   * Interact with the Merchant's store like a employee or owner.
   *                         Can make transactions without require a valid payment.
   *                         Can access to merchant balance, product cost and other information.
   */
  Pos = 'POS'
}

export type MerchantBillingSettings = {
  __typename?: 'MerchantBillingSettings';
  achInfo?: Maybe<MerchantBillingAchInfo>;
  /** Day hour from 0-23 to indicate the time to use as cut-off time. */
  cutOffTime: Scalars['Int'];
};

export type MerchantBillingAchInfo = {
  __typename?: 'MerchantBillingAchInfo';
  companyName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankRoutingNumber?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountType?: Maybe<AchBankAccountType>;
  tid?: Maybe<Scalars['String']>;
  ftpServer?: Maybe<Scalars['String']>;
  ftpPort?: Maybe<Scalars['Int']>;
  ftpUsername?: Maybe<Scalars['String']>;
  ftpPassword?: Maybe<Scalars['String']>;
};

export enum AchBankAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS'
}

export type MerchantSecuritySettings = {
  __typename?: 'MerchantSecuritySettings';
  maxSalesAmountEnabled: Scalars['Boolean'];
  maxSalesAmount: Scalars['Int'];
  maxSalesAmountInHours: Scalars['Int'];
  maxSalesAmountMessage: Scalars['String'];
  maxSalesTransactionsEnabled: Scalars['Boolean'];
  maxSalesTransactions: Scalars['Int'];
  maxSalesTransactionsInHours: Scalars['Int'];
  maxSalesTransactionsMessage: Scalars['String'];
};

export type WholeSalePriceList = Node & Timestampable & WithNumericIdentifier & MerchantAware & PriceList & {
  __typename?: 'WholeSalePriceList';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  children?: Maybe<Array<Maybe<PriceList>>>;
  name: Scalars['String'];
  items?: Maybe<PriceListItemConnection>;
  editMode: Scalars['Boolean'];
  invalidItems?: Maybe<Scalars['Int']>;
  customProducts?: Maybe<Scalars['Int']>;
  allocated?: Maybe<Scalars['Int']>;
};


export type WholeSalePriceListItemsArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<PriceListItemOrderBy>>>;
  where?: Maybe<PriceListItemCondition>;
};

export type Timestampable = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WithNumericIdentifier = {
  number?: Maybe<Scalars['String']>;
};

export type MerchantPaymentSettings = {
  __typename?: 'MerchantPaymentSettings';
  paypal?: Maybe<MerchantPaypalSettings>;
  secure3ds?: Maybe<Merchant3DSecurePaymentSettings>;
};

export type MerchantPaypalSettings = {
  __typename?: 'MerchantPaypalSettings';
  /** PayPal payment are allowed in this merchant */
  allowed?: Maybe<Scalars['Boolean']>;
  /** If the merchant has PayPal allowed can enabled/disable this type of payments */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Paypal email account */
  email?: Maybe<Scalars['String']>;
};

export type Merchant3DSecurePaymentSettings = {
  __typename?: 'Merchant3DSecurePaymentSettings';
  /** Enable 3D secure payments for credit card payments */
  enabled?: Maybe<Scalars['Boolean']>;
  /**
   * Force 3D secure payments for credit card payments.
   * Credit cards not enrolled in 3D secure payment will be denied
   */
  forced?: Maybe<Scalars['Boolean']>;
};

export enum MerchantCapability {
  /** Have the Trust Verification System enabled and can view transaction scores. */
  Tvs = 'TVS'
}

export type AchInfo = {
  __typename?: 'AchInfo';
  companyName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankRoutingNumber?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountType?: Maybe<AchBankAccountType>;
  tid?: Maybe<Scalars['String']>;
};

export type AchPaymentLine = Node & MerchantAware & {
  __typename?: 'AchPaymentLine';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  referenceNumber?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  info?: Maybe<AchInfo>;
  amount: Scalars['Float'];
};

export type ApiKeyQuery = {
  __typename?: 'ApiKeyQuery';
  all?: Maybe<ApiKeyConnection>;
};


export type ApiKeyQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<ApiKeyOrderBy>>>;
  where?: Maybe<ApiKeyCondition>;
};

export type ApiKeyOrderBy = {
  field: ApiKeyOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum ApiKeyOrderByField {
  Scope = 'scope',
  Enabled = 'enabled',
  Sandbox = 'sandbox',
  CreatedAt = 'createdAt',
  Identifier = 'identifier',
  Description = 'description',
  LastUse = 'lastUse',
  ParentName = 'parentName'
}

export type ApiKeyCondition = {
  restrictedIps?: Maybe<ArrayComparisonExpression>;
  lastUse?: Maybe<DateTimeComparisonExpression>;
  description?: Maybe<StringComparisonExpression>;
  merchant?: Maybe<NodeComparisonExpression>;
  identifier?: Maybe<StringComparisonExpression>;
  createdAt?: Maybe<DateTimeComparisonExpression>;
  sandbox?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  scope?: Maybe<ApiKeyScopeComparisonExpression>;
};

/** Create array comparison expression to filter records by values in array. */
export type ArrayComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<Scalars['String']>>;
};

export enum CollectionComparisonOperator {
  /** Elements In */
  In = 'IN',
  /** Elements Not In */
  Nin = 'NIN'
}

/** Create enum comparison expression to filter records by current values. */
export type ApiKeyScopeComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<ApiKeyScope>>;
};

export type ApiKeyConnection = {
  __typename?: 'ApiKeyConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ApiKeyEdge>>>;
};

export type ApiKeyEdge = {
  __typename?: 'ApiKeyEdge';
  node: ApiKey;
  cursor: Scalars['String'];
};

export type ConsumerAuthenticationProviderQuery = {
  __typename?: 'ConsumerAuthenticationProviderQuery';
  all?: Maybe<ConsumerAuthenticationProviderConnection>;
};


export type ConsumerAuthenticationProviderQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<ConsumerAuthenticationProviderOrderBy>>>;
  where?: Maybe<ConsumerAuthenticationProviderCondition>;
};

export type ConsumerAuthenticationProviderOrderBy = {
  field: ConsumerAuthenticationProviderOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum ConsumerAuthenticationProviderOrderByField {
  Name = 'name'
}

export type ConsumerAuthenticationProviderCondition = {
  name?: Maybe<StringComparisonExpression>;
};

export type ConsumerAuthenticationProviderConnection = {
  __typename?: 'ConsumerAuthenticationProviderConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ConsumerAuthenticationProviderEdge>>>;
};

export type ConsumerAuthenticationProviderEdge = {
  __typename?: 'ConsumerAuthenticationProviderEdge';
  node: ConsumerAuthenticationProvider;
  cursor: Scalars['String'];
};

export type CronJobQuery = {
  __typename?: 'CronJobQuery';
  all?: Maybe<CronJobConnection>;
};


export type CronJobQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<CronJobOrderBy>>>;
  where?: Maybe<CronJobCondition>;
};

export type CronJobOrderBy = {
  field: CronJobOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum CronJobOrderByField {
  Status = 'status',
  LastExecution = 'lastExecution',
  ExecutionTime = 'executionTime',
  NextScheduledExecution = 'nextScheduledExecution',
  Error = 'error'
}

export type CronJobCondition = {
  error?: Maybe<StringComparisonExpression>;
  nextScheduledExecution?: Maybe<DateTimeComparisonExpression>;
  executionTime?: Maybe<IntegerComparisonExpression>;
  lastExecution?: Maybe<DateTimeComparisonExpression>;
  status?: Maybe<CronJobStatusComparisonExpression>;
};

/** Create integer comparison expression to compare values. */
export type IntegerComparisonExpression = {
  /** Comparison operator */
  op: NumberComparisonOperator;
  /** Number value to compare */
  value: Scalars['Int'];
  /** Max value to compare when use `BETWEEN` */
  maxValue?: Maybe<Scalars['Int']>;
};

/** Create enum comparison expression to filter records by current values. */
export type CronJobStatusComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<CronJobStatus>>;
};

export enum CronJobStatus {
  Waiting = 'WAITING',
  Running = 'RUNNING'
}

export type CronJobConnection = {
  __typename?: 'CronJobConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<CronJobEdge>>>;
};

export type CronJobEdge = {
  __typename?: 'CronJobEdge';
  node: CronJob;
  cursor: Scalars['String'];
};

export type CronJob = Node & {
  __typename?: 'CronJob';
  id: Scalars['ID'];
  status: CronJobStatus;
  lastExecution?: Maybe<Scalars['DateTime']>;
  executionTime?: Maybe<Scalars['Int']>;
  nextScheduledExecution: Scalars['DateTime'];
  error?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
};

export type CustomerCreditQuery = {
  __typename?: 'CustomerCreditQuery';
  all?: Maybe<CustomerCreditConnection>;
};


export type CustomerCreditQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<CustomerCreditOrderBy>>>;
  where?: Maybe<CustomerCreditCondition>;
};

export type CustomerCreditOrderBy = {
  field: CustomerCreditOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum CustomerCreditOrderByField {
  Sandbox = 'sandbox',
  Date = 'date',
  Status = 'status',
  Account = 'account',
  Settled = 'settled',
  SettlementStatus = 'settlementStatus',
  SettledAt = 'settledAt',
  SettledTransactionId = 'settledTransactionId',
  SettlementError = 'settlementError',
  ReferenceNumber = 'referenceNumber',
  Description = 'description',
  Type = 'type',
  Amount = 'amount',
  Number = 'number',
  Customer = 'customer',
  Batch = 'batch'
}

export type CustomerCreditCondition = {
  customer?: Maybe<NodeComparisonExpression>;
  amount?: Maybe<FloatComparisonExpression>;
  type?: Maybe<CustomerCreditTypeComparisonExpression>;
  description?: Maybe<StringComparisonExpression>;
  referenceNumber?: Maybe<StringComparisonExpression>;
  settlementBatch?: Maybe<NodeComparisonExpression>;
  settlementError?: Maybe<StringComparisonExpression>;
  settledTransactionId?: Maybe<StringComparisonExpression>;
  settledAt?: Maybe<DateTimeComparisonExpression>;
  settlementStatus?: Maybe<CustomerCreditSettlementStatusComparisonExpression>;
  settled?: Maybe<Scalars['Boolean']>;
  account?: Maybe<CustomerCreditAccountComparisonExpression>;
  status?: Maybe<StringComparisonExpression>;
  date?: Maybe<DateTimeComparisonExpression>;
  sandbox?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<NodeComparisonExpression>;
};

/** Create enum comparison expression to filter records by current values. */
export type CustomerCreditTypeComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<CustomerCreditType>>;
};

export enum CustomerCreditType {
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL'
}

/** Create enum comparison expression to filter records by current values. */
export type CustomerCreditSettlementStatusComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<CustomerCreditSettlementStatus>>;
};

export enum CustomerCreditSettlementStatus {
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

/** Create enum comparison expression to filter records by current values. */
export type CustomerCreditAccountComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<CustomerCreditAccount>>;
};

export enum CustomerCreditAccount {
  Mesh = 'MESH'
}

export type CustomerCreditConnection = {
  __typename?: 'CustomerCreditConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<CustomerCreditEdge>>>;
};

export type CustomerCreditEdge = {
  __typename?: 'CustomerCreditEdge';
  node: CustomerCredit;
  cursor: Scalars['String'];
};

export type CustomerCredit = Node & MerchantAware & {
  __typename?: 'CustomerCredit';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  sandbox: Scalars['Boolean'];
  date: Scalars['DateTime'];
  status: Scalars['String'];
  account: CustomerCreditAccount;
  settled: Scalars['Boolean'];
  settlementStatus: CustomerCreditSettlementStatus;
  settledAt?: Maybe<Scalars['DateTime']>;
  settledTransactionId?: Maybe<Scalars['String']>;
  settlementError?: Maybe<Scalars['String']>;
  settlementBatch?: Maybe<CustomerCreditSettlementBatch>;
  referenceNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<CustomerCreditType>;
  amount: Scalars['Float'];
  customer?: Maybe<Customer>;
  settlementRetryable: Scalars['Boolean'];
  retry: Scalars['Boolean'];
  number: Scalars['Int'];
  settlementRequired: Scalars['Boolean'];
};

export type CustomerCreditSettlementBatch = Node & MerchantAware & {
  __typename?: 'CustomerCreditSettlementBatch';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  createdAt: Scalars['DateTime'];
  account: Scalars['String'];
  status: CustomerCreditSettlementBatchStatus;
  error?: Maybe<Scalars['String']>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  referenceNumber?: Maybe<Scalars['String']>;
  creditsCount: Scalars['Int'];
  totalDeposit: Scalars['Float'];
  totalWithdrawal: Scalars['Float'];
  retryable: Scalars['Boolean'];
  number: Scalars['Int'];
  operationBalance: Scalars['Float'];
};

export enum CustomerCreditSettlementBatchStatus {
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Failed = 'FAILED',
  Settled = 'SETTLED',
  PartiallySettled = 'PARTIALLY_SETTLED'
}

export type Customer = Node & MerchantAware & User & {
  __typename?: 'Customer';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  email: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  number: Scalars['Int'];
  profile: CustomerProfile;
  login: Scalars['String'];
  name: Scalars['String'];
  /** If current credentials are using sandbox mode or not. */
  sandbox?: Maybe<Scalars['Boolean']>;
  eCommerceStatics: UserECommerceStatistics;
};

export type User = {
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  number: Scalars['Int'];
  login: Scalars['String'];
  name: Scalars['String'];
  /** If current credentials are using sandbox mode or not. */
  sandbox?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
};

export type CustomerProfile = {
  __typename?: 'CustomerProfile';
  fullName: Scalars['String'];
  /** @deprecated Use email field from user instead */
  email: Scalars['String'];
  phone: Scalars['String'];
  billingAddress1?: Maybe<Scalars['String']>;
  billingAddress2?: Maybe<Scalars['String']>;
  billingCity?: Maybe<Scalars['String']>;
  billingState?: Maybe<Scalars['String']>;
  billingZipCode?: Maybe<Scalars['String']>;
  billingCountryCode: CountryCode;
  verifiedPhone: Scalars['Boolean'];
  verifiedEmail: Scalars['Boolean'];
  virtualCheckingAccount?: Maybe<VirtualCheckingAccount>;
};

export type VirtualCheckingAccount = {
  __typename?: 'VirtualCheckingAccount';
  balance?: Maybe<Scalars['Float']>;
  accountNumber: Scalars['String'];
};

export type UserECommerceStatistics = {
  __typename?: 'UserECommerceStatistics';
  processedTransactions: Scalars['Int'];
  processedTransactionsAmount: Scalars['Float'];
  completedTransactions: Scalars['Int'];
  completedTransactionsAmount: Scalars['Float'];
  failedTransactions: Scalars['Int'];
  failedTransactionsAmount: Scalars['Float'];
};

export type CustomerCreditSettlementBatchQuery = {
  __typename?: 'CustomerCreditSettlementBatchQuery';
  all?: Maybe<CustomerCreditSettlementBatchConnection>;
};


export type CustomerCreditSettlementBatchQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<CustomerCreditSettlementBatchOrderBy>>>;
  where?: Maybe<CustomerCreditSettlementBatchCondition>;
};

export type CustomerCreditSettlementBatchOrderBy = {
  field: CustomerCreditSettlementBatchOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum CustomerCreditSettlementBatchOrderByField {
  CreatedAt = 'createdAt',
  Account = 'account',
  Status = 'status',
  Error = 'error',
  StatusChangedAt = 'statusChangedAt',
  ReferenceNumber = 'referenceNumber',
  CreditsCount = 'creditsCount',
  TotalDeposit = 'totalDeposit',
  TotalWithdrawal = 'totalWithdrawal',
  Number = 'number'
}

export type CustomerCreditSettlementBatchCondition = {
  totalWithdrawal?: Maybe<FloatComparisonExpression>;
  totalDeposit?: Maybe<FloatComparisonExpression>;
  creditsCount?: Maybe<IntegerComparisonExpression>;
  referenceNumber?: Maybe<StringComparisonExpression>;
  statusChangedAt?: Maybe<DateTimeComparisonExpression>;
  error?: Maybe<StringComparisonExpression>;
  status?: Maybe<CustomerCreditSettlementBatchStatusComparisonExpression>;
  account?: Maybe<StringComparisonExpression>;
  createdAt?: Maybe<DateTimeComparisonExpression>;
  merchant?: Maybe<NodeComparisonExpression>;
};

/** Create enum comparison expression to filter records by current values. */
export type CustomerCreditSettlementBatchStatusComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<CustomerCreditSettlementBatchStatus>>;
};

export type CustomerCreditSettlementBatchConnection = {
  __typename?: 'CustomerCreditSettlementBatchConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<CustomerCreditSettlementBatchEdge>>>;
};

export type CustomerCreditSettlementBatchEdge = {
  __typename?: 'CustomerCreditSettlementBatchEdge';
  node: CustomerCreditSettlementBatch;
  cursor: Scalars['String'];
};

export type CustomersQuery = {
  __typename?: 'CustomersQuery';
  /** Verify if given email or phone is already registered in our system. */
  exists?: Maybe<Scalars['Boolean']>;
  /** Fetch customer email and phone to user decide where receive the reset password PIN */
  resetPasswordTargets?: Maybe<ResetPasswordTargets>;
  /** Get customer profile details. */
  profile?: Maybe<CustomerProfile>;
};


export type CustomersQueryExistsArgs = {
  emailOrPhone: Scalars['String'];
  context?: Maybe<CustomerContext>;
};


export type CustomersQueryResetPasswordTargetsArgs = {
  username: Scalars['String'];
  context?: Maybe<CustomerContext>;
};


export type CustomersQueryProfileArgs = {
  customerToken: Scalars['String'];
};

export enum CustomerContext {
  Ecommerce = 'ECOMMERCE',
  VirtualCheckingAccount = 'VIRTUAL_CHECKING_ACCOUNT'
}

export type ResetPasswordTargets = {
  __typename?: 'ResetPasswordTargets';
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type E911UserFeeQuery = {
  __typename?: 'E911UserFeeQuery';
  all?: Maybe<E911UserFeeConnection>;
};


export type E911UserFeeQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<E911UserFeeOrderBy>>>;
  where?: Maybe<E911UserFeeCondition>;
};

export type E911UserFeeOrderBy = {
  field: E911UserFeeOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum E911UserFeeOrderByField {
  State = 'state',
  Amount = 'amount',
  Mode = 'mode'
}

export type E911UserFeeCondition = {
  mode?: Maybe<FeeModeComparisonExpression>;
  amount?: Maybe<FloatComparisonExpression>;
  state?: Maybe<EnumComparisonExpression>;
};

/** Create enum comparison expression to filter records by current values. */
export type FeeModeComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<FeeMode>>;
};

/** Create enum comparison expression to filter records by current values. */
export type EnumComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<Scalars['String']>>;
};

export type E911UserFeeConnection = {
  __typename?: 'E911UserFeeConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<E911UserFeeEdge>>>;
};

export type E911UserFeeEdge = {
  __typename?: 'E911UserFeeEdge';
  node: E911UserFee;
  cursor: Scalars['String'];
};

export type E911UserFee = Node & {
  __typename?: 'E911UserFee';
  id: Scalars['ID'];
  state: Scalars['String'];
  amount: Scalars['Float'];
  mode?: Maybe<FeeMode>;
};

export type ExternalServiceRequestQuery = {
  __typename?: 'ExternalServiceRequestQuery';
  all?: Maybe<ExternalServiceRequestConnection>;
};


export type ExternalServiceRequestQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<ExternalServiceRequestOrderBy>>>;
  where?: Maybe<ExternalServiceRequestCondition>;
};

export type ExternalServiceRequestOrderBy = {
  field: ExternalServiceRequestOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum ExternalServiceRequestOrderByField {
  CreatedAt = 'createdAt',
  Service = 'service',
  Action = 'action',
  Uri = 'uri',
  Method = 'method',
  Body = 'body',
  Headers = 'headers',
  StatusCode = 'statusCode',
  ResponseHeaders = 'responseHeaders',
  ResponseBody = 'responseBody',
  ErrorCode = 'errorCode',
  Error = 'error'
}

export type ExternalServiceRequestCondition = {
  error?: Maybe<StringComparisonExpression>;
  errorCode?: Maybe<StringComparisonExpression>;
  responseBody?: Maybe<StringComparisonExpression>;
  statusCode?: Maybe<IntegerComparisonExpression>;
  body?: Maybe<StringComparisonExpression>;
  method?: Maybe<StringComparisonExpression>;
  uri?: Maybe<StringComparisonExpression>;
  action?: Maybe<StringComparisonExpression>;
  service?: Maybe<ExternalServiceRequestServiceComparisonExpression>;
  createdAt?: Maybe<DateTimeComparisonExpression>;
  hasError?: Maybe<Scalars['Boolean']>;
};

/** Create enum comparison expression to filter records by current values. */
export type ExternalServiceRequestServiceComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<ExternalServiceRequestService>>;
};

export enum ExternalServiceRequestService {
  WoCash = 'WoCash',
  PrepayNation = 'PrepayNation',
  PrepayNationInComm = 'PrepayNationInComm',
  Orpheus = 'Orpheus',
  InComm = 'InComm'
}

export type ExternalServiceRequestConnection = {
  __typename?: 'ExternalServiceRequestConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ExternalServiceRequestEdge>>>;
};

export type ExternalServiceRequestEdge = {
  __typename?: 'ExternalServiceRequestEdge';
  node: ExternalServiceRequest;
  cursor: Scalars['String'];
};

export type ExternalServiceRequest = Node & {
  __typename?: 'ExternalServiceRequest';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  service?: Maybe<ExternalServiceRequestService>;
  action: Scalars['String'];
  uri: Scalars['String'];
  method: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  headers?: Maybe<Scalars['DynamicObject']>;
  statusCode: Scalars['Int'];
  responseHeaders?: Maybe<Scalars['DynamicObject']>;
  responseBody?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
};

export type MerchantPaymentQuery = {
  __typename?: 'MerchantPaymentQuery';
  all?: Maybe<MerchantPaymentConnection>;
};


export type MerchantPaymentQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<MerchantPaymentOrderBy>>>;
  where?: Maybe<MerchantPaymentCondition>;
};

export type MerchantPaymentOrderBy = {
  field: MerchantPaymentOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum MerchantPaymentOrderByField {
  AccountType = 'accountType',
  CreatedAt = 'createdAt',
  Sandbox = 'sandbox',
  Status = 'status'
}

export type MerchantPaymentCondition = {
  merchant?: Maybe<NodeComparisonExpression>;
  total?: Maybe<FloatComparisonExpression>;
  grossTotal?: Maybe<FloatComparisonExpression>;
  adjustmentsTotal?: Maybe<FloatComparisonExpression>;
  paymentTransactionResponse?: Maybe<StringComparisonExpression>;
  paymentTransactionID?: Maybe<StringComparisonExpression>;
  description?: Maybe<StringComparisonExpression>;
  invoiceNumber?: Maybe<StringComparisonExpression>;
  status?: Maybe<PaymentStatusComparisonExpression>;
  sandbox?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeComparisonExpression>;
  accountType?: Maybe<MerchantPaymentAccountTypeComparisonExpression>;
};

/** Create enum comparison expression to filter records by current values. */
export type PaymentStatusComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<PaymentStatus>>;
};

export enum PaymentStatus {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  OnHold = 'ON_HOLD',
  Authorized = 'AUTHORIZED',
  Refused = 'REFUSED',
  Completed = 'COMPLETED',
  Refunded = 'REFUNDED',
  ChargedBack = 'CHARGED_BACK',
  Failed = 'FAILED'
}

/** Create enum comparison expression to filter records by current values. */
export type MerchantPaymentAccountTypeComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<MerchantPaymentAccountType>>;
};

export enum MerchantPaymentAccountType {
  Prepaid = 'PREPAID',
  Postpaid = 'POSTPAID'
}

export type MerchantPaymentConnection = {
  __typename?: 'MerchantPaymentConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<MerchantPaymentEdge>>>;
};

export type MerchantPaymentEdge = {
  __typename?: 'MerchantPaymentEdge';
  node: MerchantPayment;
  cursor: Scalars['String'];
};

export type MerchantPayment = {
  accountType?: Maybe<MerchantPaymentAccountType>;
  createdAt: Scalars['DateTime'];
  sandbox: Scalars['Boolean'];
  status: PaymentStatus;
  instrument?: Maybe<PaymentInstrument>;
  invoiceNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  paymentTransactionID?: Maybe<Scalars['String']>;
  paymentTransactionResponse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  adjustmentsTotal?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  merchant?: Maybe<Merchant>;
};

export type PaymentInstrument = {
  name: Scalars['String'];
};

export type MerchantQuery = {
  __typename?: 'MerchantQuery';
  all?: Maybe<MerchantConnection>;
};


export type MerchantQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<MerchantOrderBy>>>;
  where?: Maybe<MerchantCondition>;
};

export type MerchantOrderBy = {
  field: MerchantOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum MerchantOrderByField {
  Name = 'name',
  Phone = 'phone',
  ParentName = 'parentName',
  Number = 'number',
  RetailPriceList = 'retailPriceList'
}

export type MerchantCondition = {
  merchant?: Maybe<NodeComparisonExpression>;
  phone?: Maybe<StringComparisonExpression>;
  name?: Maybe<StringComparisonExpression>;
  type?: Maybe<MerchantTypeComparisonExpression>;
  retailPriceList?: Maybe<NodeComparisonExpression>;
};

/** Create enum comparison expression to filter records by current values. */
export type MerchantTypeComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<MerchantType>>;
};

export enum MerchantType {
  Master = 'Master',
  Agent = 'Agent',
  Dealer = 'Dealer'
}

export type MerchantConnection = {
  __typename?: 'MerchantConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<MerchantEdge>>>;
};

export type MerchantEdge = {
  __typename?: 'MerchantEdge';
  node: Merchant;
  cursor: Scalars['String'];
};

export enum FindOneByNode {
  Transaction = 'Transaction',
  Product = 'Product',
  VerifoneTerminal = 'VerifoneTerminal',
  ApiKey = 'ApiKey',
  Customer = 'Customer',
  PriceList = 'PriceList',
  Merchant = 'Merchant'
}

export enum FindOneByField {
  Number = 'number',
  Slug = 'slug'
}

export type PaymentGatewayQuery = {
  __typename?: 'PaymentGatewayQuery';
  all?: Maybe<PaymentGatewayConnection>;
};


export type PaymentGatewayQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<PaymentGatewayOrderBy>>>;
  where?: Maybe<PaymentGatewayCondition>;
};

export type PaymentGatewayOrderBy = {
  field: PaymentGatewayOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum PaymentGatewayOrderByField {
  Name = 'name'
}

export type PaymentGatewayCondition = {
  name?: Maybe<StringComparisonExpression>;
};

export type PaymentGatewayConnection = {
  __typename?: 'PaymentGatewayConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<PaymentGatewayEdge>>>;
};

export type PaymentGatewayEdge = {
  __typename?: 'PaymentGatewayEdge';
  node: PaymentGateway;
  cursor: Scalars['String'];
};

export type PinQuery = {
  __typename?: 'PinQuery';
  all?: Maybe<PinConnection>;
};


export type PinQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<PinOrderBy>>>;
  where?: Maybe<PinCondition>;
};

export type PinOrderBy = {
  field: PinOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum PinOrderByField {
  CreatedAt = 'createdAt',
  SoldAt = 'soldAt',
  UsedAt = 'usedAt',
  Status = 'status',
  StatusNote = 'statusNote'
}

export type PinCondition = {
  saleTransaction?: Maybe<NodeComparisonExpression>;
  product?: Maybe<NodeComparisonExpression>;
  statusNote?: Maybe<StringComparisonExpression>;
  status?: Maybe<PinStatusComparisonExpression>;
  usedAt?: Maybe<DateTimeComparisonExpression>;
  soldAt?: Maybe<DateTimeComparisonExpression>;
  createdAt?: Maybe<DateTimeComparisonExpression>;
  merchant?: Maybe<NodeComparisonExpression>;
};

/** Create enum comparison expression to filter records by current values. */
export type PinStatusComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<PinStatus>>;
};

export enum PinStatus {
  /** Ready to sale and use */
  Available = 'AVAILABLE',
  /** Sold but it's not known if it was used or not */
  Sold = 'SOLD',
  /** Sold and already marked as used */
  Used = 'USED',
  /** Marked has invalid or returned */
  Invalid = 'INVALID'
}

export type PinConnection = {
  __typename?: 'PinConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<PinEdge>>>;
};

export type PinEdge = {
  __typename?: 'PinEdge';
  node: Pin;
  cursor: Scalars['String'];
};

export type Pin = Node & MerchantAware & {
  __typename?: 'Pin';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  createdAt: Scalars['DateTime'];
  soldAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PinStatus>;
  statusNote?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  saleTransaction?: Maybe<Transaction>;
  maskedPin?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type Transaction = {
  date: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sandbox: Scalars['Boolean'];
  agent?: Maybe<Merchant>;
  user?: Maybe<User>;
  merchant?: Maybe<Merchant>;
  status?: Maybe<TransactionStatus>;
  service?: Maybe<ServiceProcess>;
  product?: Maybe<Product>;
  logs?: Maybe<Array<Maybe<TransactionLog>>>;
  adjustments?: Maybe<Array<Maybe<TransactionAdjustment>>>;
  commissionBreakdown?: Maybe<CommissionBreakdown>;
  customer?: Maybe<Customer>;
  customerPayment?: Maybe<CustomerPayment>;
  number?: Maybe<Scalars['String']>;
  clientIp?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  /** Transaction date using merchant timezone instead of the server one */
  localDate?: Maybe<Scalars['DateTime']>;
  /** Transaction merchant cost */
  merchantCost?: Maybe<Scalars['Float']>;
  correlationId?: Maybe<Scalars['String']>;
  receiptEmail?: Maybe<Scalars['String']>;
  inputs?: Maybe<TransactionInputsMeta>;
  response?: Maybe<TransactionResponse>;
  userSession?: Maybe<UserSession>;
  id: Scalars['ID'];
  adjustmentsTotal?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export enum TransactionStatus {
  /** A transaction was created/initiated, but not yet completed. */
  Open = 'OPEN',
  /** Currently working on this transaction. Wait for status change. */
  Processing = 'PROCESSING',
  /** Awaiting payment or other user intervention like 3D secure. */
  OnHold = 'ON_HOLD',
  /** The transaction is pending to be executed. */
  Pending = 'PENDING',
  /** The transaction has been completed successfully. */
  Completed = 'COMPLETED',
  /** The transaction failed due to an abnormal error such as system failure. */
  Failed = 'FAILED',
  /** The transaction has been cancelled and not require any other action. */
  Cancelled = 'CANCELLED',
  /** The transaction has been totally/partially refunded. */
  Refunded = 'REFUNDED'
}

export type ServiceProcess = Node & {
  __typename?: 'ServiceProcess';
  id: Scalars['ID'];
  date: Scalars['DateTime'];
  status?: Maybe<ServiceProcessStatus>;
  provider?: Maybe<ServiceProvider>;
  grossTotal: Scalars['Float'];
  discount: Scalars['Float'];
  feesTotal: Scalars['Float'];
  total: Scalars['Float'];
  payment?: Maybe<MerchantPaymentPurchase>;
};

export enum ServiceProcessStatus {
  /** A service was created/initiated, but not yet completed. */
  Open = 'OPEN',
  /** Currently working on this service. Wait for status change. */
  Processing = 'PROCESSING',
  /** The service is pending to be executed. */
  Pending = 'PENDING',
  /** The service has been completed successfully. */
  Completed = 'COMPLETED',
  /** The service failed due to an abnormal error such as system failure. */
  Failed = 'FAILED',
  /** The service has been cancelled and not require any other action. */
  Cancelled = 'CANCELLED',
  /** The service has been totally/partially refunded. */
  Refunded = 'REFUNDED'
}

export type MerchantPaymentPurchase = Payment & Adjustable & Node & MerchantAware & MerchantPayment & {
  __typename?: 'MerchantPaymentPurchase';
  invoiceNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  paymentTransactionID?: Maybe<Scalars['String']>;
  paymentTransactionResponse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  adjustmentsTotal?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  merchant?: Maybe<Merchant>;
  accountType?: Maybe<MerchantPaymentAccountType>;
  postPaymentDue?: Maybe<InvoiceItem>;
  createdAt: Scalars['DateTime'];
  sandbox: Scalars['Boolean'];
  status: PaymentStatus;
  instrument?: Maybe<PaymentInstrument>;
};

export type Payment = {
  sandbox: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  status: PaymentStatus;
  invoiceNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  paymentTransactionID?: Maybe<Scalars['String']>;
  paymentTransactionResponse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  adjustmentsTotal?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type Adjustable = {
  adjustmentsTotal?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type InvoiceItem = Node & {
  __typename?: 'InvoiceItem';
  id: Scalars['ID'];
};

export type TransactionLog = Node & {
  __typename?: 'TransactionLog';
  id: Scalars['ID'];
  date: Scalars['DateTime'];
  level?: Maybe<LoggingLevel>;
  details: Scalars['String'];
};

export enum LoggingLevel {
  Debug = 'DEBUG',
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR'
}

export type TransactionAdjustment = Node & Adjustment & {
  __typename?: 'TransactionAdjustment';
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  isDiscount?: Maybe<Scalars['Boolean']>;
  rateMode?: Maybe<Scalars['Boolean']>;
  rateOverMode?: Maybe<Scalars['Boolean']>;
  fixedMode?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  concept?: Maybe<TransactionAdjustmentConcept>;
  mode?: Maybe<AdjustmentMode>;
};

export type Adjustment = {
  name?: Maybe<Scalars['String']>;
  mode?: Maybe<AdjustmentMode>;
  amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  isDiscount?: Maybe<Scalars['Boolean']>;
  rateMode?: Maybe<Scalars['Boolean']>;
  rateOverMode?: Maybe<Scalars['Boolean']>;
  fixedMode?: Maybe<Scalars['Boolean']>;
};

export enum AdjustmentMode {
  /** Rate based on gross total. */
  Rate = 'RATE',
  /** Rate based on total including all previously calculated adjustments. */
  RateOver = 'RATE_OVER',
  /** Fixed amount to use as adjustment */
  Fixed = 'FIXED'
}

export enum TransactionAdjustmentConcept {
  /** Product Fee. */
  ProductFee = 'PRODUCT_FEE',
  /**
   * Fee assessed by a payee when a consumer
   * pays with an electronic payment card rather than by cash, check or Automated Clearing House (ACH) transfer.
   */
  ConvenienceFee = 'CONVENIENCE_FEE',
  /**
   * Charge imposed on the consumer,
   * to be collected by sellers, when a retail transaction takes place which includes prepaid wireless telecommunications service.
   * See more details about
   * [Prepaid Wireless E911 Surcharge](http://www.revenue.state.il.us/Businesses/TaxInformation/Sales/E911.htm)
   */
  E911Surcharge = 'E911_SURCHARGE',
  /** Any other type of tax or discount. */
  Other = 'OTHER'
}

export type CommissionBreakdown = {
  __typename?: 'CommissionBreakdown';
  date: Scalars['DateTime'];
  details: Scalars['String'];
  merchant?: Maybe<Merchant>;
  salesRepresentative?: Maybe<SalesRepresentative>;
  parent?: Maybe<CommissionBreakdown>;
  breakdown?: Maybe<Array<Maybe<CommissionBreakdown>>>;
  amount: Scalars['Float'];
  rate: Scalars['Float'];
  grossCommission: Scalars['Float'];
  netCommission: Scalars['Float'];
  recipientName: Scalars['String'];
};

export type SalesRepresentative = Node & MerchantAware & {
  __typename?: 'SalesRepresentative';
  id: Scalars['ID'];
  user?: Maybe<User>;
  merchant?: Maybe<Merchant>;
  type: Scalars['String'];
  parentRepresentative?: Maybe<SalesRepresentative>;
  representatives?: Maybe<Array<Maybe<SalesRepresentative>>>;
  profitRate: Scalars['Float'];
};

export type CustomerPayment = Payment & Adjustable & Node & MerchantAware & {
  __typename?: 'CustomerPayment';
  invoiceNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  paymentTransactionID?: Maybe<Scalars['String']>;
  paymentTransactionResponse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  adjustmentsTotal?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  merchant?: Maybe<Merchant>;
  adjustments?: Maybe<Array<Maybe<CustomerPaymentAdjustment>>>;
  createdAt: Scalars['DateTime'];
  sandbox: Scalars['Boolean'];
  status: PaymentStatus;
  instrument?: Maybe<PaymentInstrument>;
};

export type CustomerPaymentAdjustment = Adjustment & {
  __typename?: 'CustomerPaymentAdjustment';
  isDiscount?: Maybe<Scalars['Boolean']>;
  rateMode?: Maybe<Scalars['Boolean']>;
  rateOverMode?: Maybe<Scalars['Boolean']>;
  fixedMode?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  adjustable?: Maybe<CustomerPayment>;
  name?: Maybe<Scalars['String']>;
  mode?: Maybe<AdjustmentMode>;
  amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type TransactionInputsMeta = {
  __typename?: 'TransactionInputsMeta';
  /** Amount entered by the customer in case the product allow variable price. */
  amount?: Maybe<Scalars['Float']>;
  /**
   * Represent the client account number and the type can vary based on the product.
   *     Some products may require a `phone` number and others like *Nauta* require a `email` address.
   */
  accountNumber?: Maybe<Scalars['String']>;
  /**
   * Full magnetic data when a card is swiped.
   *     Used in products like gift cards.
   */
  cardMagData?: Maybe<Scalars['String']>;
};

export type TransactionResponse = {
  /** API error code thrown during transaction process. */
  errorCode?: Maybe<Scalars['Int']>;
  /** Any response message that the client must know, can be a error or success response. */
  message?: Maybe<Scalars['String']>;
  /** Third party service transaction number for tracking purposes */
  providerTrackingNumber?: Maybe<Scalars['String']>;
};

export type UserSession = Node & {
  __typename?: 'UserSession';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  token?: Maybe<Scalars['String']>;
  endpoint?: Maybe<ApiEndpoint>;
  sandbox: Scalars['Boolean'];
  debug: Scalars['Boolean'];
  user?: Maybe<User>;
  sessionId: Scalars['String'];
  clientId: Scalars['String'];
  ip?: Maybe<Scalars['String']>;
  clientIp?: Maybe<Scalars['String']>;
  agent?: Maybe<Scalars['String']>;
  mobile: Scalars['Boolean'];
  origin?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  variables?: Maybe<Scalars['DynamicObject']>;
  response?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<UserSessionError>>>;
  dumps?: Maybe<Array<Maybe<UserSessionDump>>>;
  internalErrors?: Maybe<Array<Maybe<UserSessionError>>>;
  hasInternalErrors: Scalars['Boolean'];
  clientErrors?: Maybe<Array<Maybe<UserSessionError>>>;
  hasClientErrors: Scalars['Boolean'];
  hasErrors: Scalars['Boolean'];
  hasDumps: Scalars['Boolean'];
};

export enum ApiEndpoint {
  Root = 'ROOT',
  Master = 'MASTER',
  Agent = 'AGENT',
  Dealer = 'DEALER',
  Guest = 'GUEST',
  Frontend = 'FRONTEND',
  Pos = 'POS',
  VerifoneTerminal = 'VERIFONE_TERMINAL'
}

export type UserSessionError = {
  __typename?: 'UserSessionError';
  userSession?: Maybe<UserSession>;
  code: Scalars['Int'];
  trackingId: Scalars['String'];
  message: Scalars['String'];
  clientSafe: Scalars['Boolean'];
  file?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['Int']>;
  trace?: Maybe<Scalars['String']>;
};

export type UserSessionDump = Node & {
  __typename?: 'UserSessionDump';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  file: Scalars['String'];
  line: Scalars['String'];
  content: Scalars['String'];
};

export type PluginQuery = {
  __typename?: 'PluginQuery';
  all?: Maybe<PluginConnection>;
};


export type PluginQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<PluginOrderBy>>>;
  where?: Maybe<PluginCondition>;
};

export type PluginOrderBy = {
  field: PluginOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum PluginOrderByField {
  Name = 'name',
  Description = 'description'
}

export type PluginCondition = {
  description?: Maybe<StringComparisonExpression>;
  name?: Maybe<StringComparisonExpression>;
};

export type PluginConnection = {
  __typename?: 'PluginConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<PluginEdge>>>;
};

export type PluginEdge = {
  __typename?: 'PluginEdge';
  node: Plugin;
  cursor: Scalars['String'];
};

export type Plugin = Node & {
  __typename?: 'Plugin';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  /** Unique and fixed name of the plugin */
  codeName: PluginCodeName;
  configFields?: Maybe<Array<Maybe<InputField>>>;
  config?: Maybe<PluginConfig>;
};


export type PluginConfigArgs = {
  merchant?: Maybe<Scalars['ID']>;
};

export enum PluginCodeName {
  Tvs = 'TVS',
  IpQualityScore = 'IP_QUALITY_SCORE',
  CardinalCommerce = 'CARDINAL_COMMERCE',
  Bitly = 'BITLY'
}

export type PluginConfig = {
  __typename?: 'PluginConfig';
  shared: Scalars['Boolean'];
  fieldsValues?: Maybe<Scalars['DynamicObject']>;
  inherited: Scalars['Boolean'];
};

export type PriceListQuery = {
  __typename?: 'PriceListQuery';
  all?: Maybe<PriceListConnection>;
};


export type PriceListQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<PriceListOrderBy>>>;
  where?: Maybe<PriceListCondition>;
};

export type PriceListOrderBy = {
  field: PriceListOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum PriceListOrderByField {
  Name = 'name',
  EditMode = 'editMode',
  Number = 'number',
  Tree = 'tree'
}

export type PriceListCondition = {
  merchant?: Maybe<NodeComparisonExpression>;
  updatedAt?: Maybe<DateTimeComparisonExpression>;
  createdAt?: Maybe<DateTimeComparisonExpression>;
  editMode?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringComparisonExpression>;
};

export type PriceListConnection = {
  __typename?: 'PriceListConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<PriceListEdge>>>;
};

export type PriceListEdge = {
  __typename?: 'PriceListEdge';
  node: PriceList;
  cursor: Scalars['String'];
};

export type ProductCategoryQuery = {
  __typename?: 'ProductCategoryQuery';
  all?: Maybe<ProductCategoryConnection>;
};


export type ProductCategoryQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<ProductCategoryOrderBy>>>;
  where?: Maybe<ProductCategoryCondition>;
};

export type ProductCategoryOrderBy = {
  field: ProductCategoryOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum ProductCategoryOrderByField {
  Name = 'name',
  Enabled = 'enabled'
}

export type ProductCategoryCondition = {
  enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<StringComparisonExpression>;
  parent?: Maybe<NodeComparisonExpression>;
  country?: Maybe<ArrayComparisonExpression>;
  type?: Maybe<ProductCategoryTypeComparisonExpression>;
};

/** Create enum comparison expression to filter records by current values. */
export type ProductCategoryTypeComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<ProductCategoryType>>;
};

export enum ProductCategoryType {
  GenericCategory = 'GenericCategory',
  WirelessCategory = 'WirelessCategory',
  WirelessCarrier = 'WirelessCarrier',
  GiftCardIssuer = 'GiftCardIssuer'
}

export type ProductCategoryConnection = {
  __typename?: 'ProductCategoryConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ProductCategoryEdge>>>;
};

export type ProductCategoryEdge = {
  __typename?: 'ProductCategoryEdge';
  node: ProductCategory;
  cursor: Scalars['String'];
};

export type ProductQuery = {
  __typename?: 'ProductQuery';
  /** Find product by given sku */
  findBySku?: Maybe<Product>;
  all?: Maybe<ProductConnection>;
};


export type ProductQueryFindBySkuArgs = {
  sku: Scalars['String'];
};


export type ProductQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<ProductOrderBy>>>;
  where?: Maybe<ProductCondition>;
};

export type ProductOrderBy = {
  field: ProductOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum ProductOrderByField {
  Name = 'name',
  Price = 'price',
  Fee = 'fee',
  FeeMode = 'feeMode',
  VariablePrice = 'variablePrice',
  MinPrice = 'minPrice',
  MaxPrice = 'maxPrice',
  Details = 'details',
  Carrier = 'carrier',
  Sku = 'sku',
  Category = 'category'
}

export type ProductCondition = {
  enabled?: Maybe<Scalars['Boolean']>;
  details?: Maybe<StringComparisonExpression>;
  maxPrice?: Maybe<FloatComparisonExpression>;
  minPrice?: Maybe<FloatComparisonExpression>;
  variablePrice?: Maybe<Scalars['Boolean']>;
  feeMode?: Maybe<FeeModeComparisonExpression>;
  fee?: Maybe<FloatComparisonExpression>;
  price?: Maybe<FloatComparisonExpression>;
  category?: Maybe<NodeComparisonExpression>;
  name?: Maybe<StringComparisonExpression>;
  type?: Maybe<ProductTypeComparisonExpression>;
  carrier?: Maybe<NodeComparisonExpression>;
};

/** Create enum comparison expression to filter records by current values. */
export type ProductTypeComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<ProductType>>;
};

export enum ProductType {
  PrepaidPlan = 'PrepaidPlan',
  GiftCard = 'GiftCard',
  VirtualProduct = 'VirtualProduct'
}

export type ProductConnection = {
  __typename?: 'ProductConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  node: Product;
  cursor: Scalars['String'];
};

export type ServiceProviderQuery = {
  __typename?: 'ServiceProviderQuery';
  all?: Maybe<ServiceProviderConnection>;
};


export type ServiceProviderQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<ServiceProviderOrderBy>>>;
  where?: Maybe<ServiceProviderCondition>;
};

export type ServiceProviderOrderBy = {
  field: ServiceProviderOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum ServiceProviderOrderByField {
  Enabled = 'enabled',
  Name = 'name'
}

export type ServiceProviderCondition = {
  name?: Maybe<StringComparisonExpression>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type ServiceProviderConnection = {
  __typename?: 'ServiceProviderConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ServiceProviderEdge>>>;
};

export type ServiceProviderEdge = {
  __typename?: 'ServiceProviderEdge';
  node: ServiceProvider;
  cursor: Scalars['String'];
};

export type Store = {
  __typename?: 'Store';
  agent: StoreAgent;
  address?: Maybe<StoreAddress>;
  /** Store contact phone */
  phone?: Maybe<Scalars['String']>;
  /** @deprecated Use `billingDescriptor` instead */
  bankAccountTitle?: Maybe<Scalars['String']>;
  /** Is the way a company's name appears on a credit card statement and is set up when the merchant account is established. It is used by the credit card customer to identify who a payment was made to on a particular transaction. */
  billingDescriptor: Scalars['String'];
  /** Store time-zone */
  timeZone?: Maybe<DateTimeZone>;
  number: Scalars['Int'];
  name: Scalars['String'];
  /** Check if the store use consumer authentication(3D-Secure Payments) for credit card payments. */
  consumerAuthentication?: Maybe<ConsumerAuthenticationType>;
  virtualCheckingAccountSettings?: Maybe<VirtualCheckingAccountSettings>;
  creditBalance?: Maybe<Scalars['Float']>;
  e911Fee?: Maybe<MerchantE911UserFee>;
  eGiftCertificateSettings?: Maybe<EGiftCertificateSettings>;
};

export type StoreAgent = {
  __typename?: 'StoreAgent';
  /** Agent contact phone */
  phone?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  number: Scalars['Int'];
  address: StoreAddress;
};

export type StoreAddress = {
  __typename?: 'StoreAddress';
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
};

export type DateTimeZone = {
  __typename?: 'DateTimeZone';
  /** Name of the timezone */
  name?: Maybe<Scalars['String']>;
  /** Country code */
  countryCode?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['String']>;
  /** Longitude */
  longitude?: Maybe<Scalars['String']>;
};

export enum ConsumerAuthenticationType {
  /** Does not require consumer authentication during credit card payments. */
  None = 'NONE',
  /** Use 3D Secure consumer authentication with bank site redirection. */
  ConsumerAuthRedirect = 'CONSUMER_AUTH_REDIRECT',
  /** Use Cardinal Commerce consumer authentication method. */
  CardinalCommerce = 'CARDINAL_COMMERCE'
}

export type VirtualCheckingAccountSettings = {
  __typename?: 'VirtualCheckingAccountSettings';
  product: VirtualProduct;
};

export type VirtualProduct = Node & ProductWithCarrier & ProductWithServiceProvider & Product & {
  __typename?: 'VirtualProduct';
  id: Scalars['ID'];
  serviceProvider?: Maybe<ServiceProvider>;
  serviceSku?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  category?: Maybe<ProductCategory>;
  price: Scalars['Float'];
  /** @deprecated [Since 02/2019] Use fees instead to get the list of product fees */
  fee: Scalars['Float'];
  /** @deprecated [Since 02/2019]  Use fees instead to get the list of product fees */
  feeMode?: Maybe<FeeMode>;
  variablePrice: Scalars['Boolean'];
  minPrice: Scalars['Float'];
  maxPrice: Scalars['Float'];
  details?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  canonicalName: Scalars['String'];
  carrier?: Maybe<ProductCarrier>;
  prepaidProduct: Scalars['Boolean'];
  prepaidType?: Maybe<PrepaidProductType>;
  /**
   * All required inputs to purchase a product.
   * Commonly these fields are used to build the html form after the user select desired product.
   */
  inputs?: Maybe<Array<Maybe<InputField>>>;
  discountMode: AmountMode;
  enabled?: Maybe<Scalars['Boolean']>;
  fees?: Maybe<Array<Maybe<ProductFee>>>;
  discount: Scalars['Float'];
};

export type ProductWithCarrier = {
  carrier?: Maybe<ProductCarrier>;
};

export type ProductWithServiceProvider = {
  serviceProvider?: Maybe<ServiceProvider>;
  serviceSku?: Maybe<Scalars['String']>;
};

export type MerchantE911UserFee = {
  __typename?: 'MerchantE911UserFee';
  mode?: Maybe<FeeMode>;
  amount?: Maybe<Scalars['Float']>;
};

export type EGiftCertificateSettings = {
  __typename?: 'EGiftCertificateSettings';
  product: VirtualProduct;
  paymentMethod: CustomerPaymentMethod;
};

export enum CustomerPaymentMethod {
  /** Pay with cash. (only for reference) */
  Cash = 'CASH',
  /** Dispense cash to your clients using solutions like Spare(gotspare.com) and only available for ATMProduct types */
  CashBack = 'CASH_BACK',
  /** Pay with external card machines, eg: EFTPOS terminal or mobile phone with card swiper (only for reference) */
  ExternalCardTerminal = 'EXTERNAL_CARD_TERMINAL',
  /** Pay using Credit or Debit Card payment. */
  CreditCard = 'CREDIT_CARD',
  /** Pay using customer funds. Must login the customer using the context VIRTUAL_CHECKING_ACCOUNT. */
  VirtualCheckingAccount = 'VIRTUAL_CHECKING_ACCOUNT'
}

export type ToolsQuery = {
  __typename?: 'ToolsQuery';
  /** Fetch information about given IP address */
  ipAddressInfo?: Maybe<IpAddress>;
};


export type ToolsQueryIpAddressInfoArgs = {
  ip: Scalars['String'];
};

export type IpAddress = {
  __typename?: 'IpAddress';
  version: Scalars['Int'];
  isp?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  proxy: Scalars['Boolean'];
  tor: Scalars['Boolean'];
  vpn: Scalars['Boolean'];
  fraudScore?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  blackListed: Scalars['Boolean'];
  whiteListed: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
};

export type TransactionQuery = {
  __typename?: 'TransactionQuery';
  all?: Maybe<TransactionConnection>;
};


export type TransactionQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<TransactionOrderBy>>>;
  where?: Maybe<TransactionCondition>;
};

export type TransactionOrderBy = {
  field: TransactionOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum TransactionOrderByField {
  Date = 'date',
  UpdatedAt = 'updatedAt',
  Sandbox = 'sandbox',
  Status = 'status',
  Correlation = 'correlation',
  Merchant = 'merchant',
  Product = 'product',
  Total = 'total'
}

export type TransactionCondition = {
  total?: Maybe<FloatComparisonExpression>;
  grossTotal?: Maybe<FloatComparisonExpression>;
  adjustmentsTotal?: Maybe<FloatComparisonExpression>;
  userSession?: Maybe<NodeComparisonExpression>;
  customer?: Maybe<NodeComparisonExpression>;
  product?: Maybe<NodeComparisonExpression>;
  service?: Maybe<NodeComparisonExpression>;
  status?: Maybe<TransactionStatusComparisonExpression>;
  merchant?: Maybe<NodeComparisonExpression>;
  user?: Maybe<NodeComparisonExpression>;
  agent?: Maybe<NodeComparisonExpression>;
  sandbox?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<DateTimeComparisonExpression>;
  date?: Maybe<DateTimeComparisonExpression>;
  category?: Maybe<NodeComparisonExpression>;
  number?: Maybe<StringComparisonExpression>;
  isClerk?: Maybe<Scalars['Boolean']>;
  endpoint?: Maybe<ApiEndpointComparisonExpression>;
  serviceProvider?: Maybe<NodeComparisonExpression>;
  carrier?: Maybe<NodeComparisonExpression>;
};

/** Create enum comparison expression to filter records by current values. */
export type TransactionStatusComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<TransactionStatus>>;
};

/** Create enum comparison expression to filter records by current values. */
export type ApiEndpointComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<ApiEndpoint>>;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<TransactionEdge>>>;
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  node: Transaction;
  cursor: Scalars['String'];
};

export type TvsBundleQuery = {
  __typename?: 'TvsBundleQuery';
  /** Get summary of data to create a chart or anything else */
  paymentScoreSummary?: Maybe<Array<Maybe<SummaryRow>>>;
  trustRuleProcessors?: Maybe<TrustRuleProcessorQuery>;
  trustRules?: Maybe<TrustRuleQuery>;
};


export type TvsBundleQueryPaymentScoreSummaryArgs = {
  type: PaymentScoreSummaryType;
  minDate: Scalars['DateTime'];
  maxDate: Scalars['DateTime'];
  actionTaken?: Maybe<Array<Maybe<TvsRuleActionTaken>>>;
  groupBy?: Maybe<PaymentScoreSummaryGroupBy>;
};

export enum PaymentScoreSummaryType {
  /** Amount of processed transactions by date including processed money amount. */
  CountByDate = 'COUNT_BY_DATE'
}

export enum TvsRuleActionTaken {
  None = 'NONE',
  Abstained = 'ABSTAINED',
  Approved = 'APPROVED',
  Denied = 'DENIED'
}

export enum PaymentScoreSummaryGroupBy {
  /** Create a group for each row based on the action taken */
  ActionTaken = 'ACTION_TAKEN'
}

export type SummaryRow = {
  __typename?: 'SummaryRow';
  /** Summary row name, commonly the X-Axis label on charts */
  name: Scalars['String'];
  /** Matrix of values, commonly used to build charts using the Y-Axis */
  values: Array<Maybe<SummaryRowValue>>;
};

export type SummaryRowValue = {
  __typename?: 'SummaryRowValue';
  /** Value name or label */
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type TrustRuleProcessorQuery = {
  __typename?: 'TrustRuleProcessorQuery';
  all?: Maybe<TrustRuleProcessorConnection>;
};


export type TrustRuleProcessorQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<TrustRuleProcessorOrderBy>>>;
  where?: Maybe<TrustRuleProcessorCondition>;
};

export type TrustRuleProcessorOrderBy = {
  field: TrustRuleProcessorOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum TrustRuleProcessorOrderByField {
  Id = 'id',
  Enabled = 'enabled',
  Name = 'name',
  Description = 'description'
}

export type TrustRuleProcessorCondition = {
  serviceName?: Maybe<TvsRuleProcessorServiceComparisonExpression>;
  description?: Maybe<StringComparisonExpression>;
  name?: Maybe<StringComparisonExpression>;
  enabled?: Maybe<Scalars['Boolean']>;
};

/** Create enum comparison expression to filter records by current values. */
export type TvsRuleProcessorServiceComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<TvsRuleProcessorService>>;
};

export enum TvsRuleProcessorService {
  CountTransaction = 'COUNT_TRANSACTION',
  LastTransaction = 'LAST_TRANSACTION',
  AmountSaleTransaction = 'AMOUNT_SALE_TRANSACTION',
  IpAddressGuard = 'IP_ADDRESS_GUARD'
}

export type TrustRuleProcessorConnection = {
  __typename?: 'TrustRuleProcessorConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<TrustRuleProcessorEdge>>>;
};

export type TrustRuleProcessorEdge = {
  __typename?: 'TrustRuleProcessorEdge';
  node: TrustRuleProcessor;
  cursor: Scalars['String'];
};

export type TrustRuleProcessor = Node & {
  __typename?: 'TrustRuleProcessor';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Internal service used to process rules */
  serviceName: TvsRuleProcessorService;
  if: TrustRuleProcessorIf;
  messageTemplatePlaceholders: Array<Maybe<Scalars['String']>>;
  where?: Maybe<TrustRuleProcessorWhere>;
};

export type TrustRuleProcessorIf = {
  __typename?: 'TrustRuleProcessorIf';
  fields: Array<Maybe<TrustRuleProcessorIfField>>;
};

export type TrustRuleProcessorIfField = {
  __typename?: 'TrustRuleProcessorIfField';
  field: Scalars['String'];
  label: Scalars['String'];
  inputs?: Maybe<Array<Maybe<InputField>>>;
};

export type TrustRuleProcessorWhere = {
  __typename?: 'TrustRuleProcessorWhere';
  fields: Array<Maybe<TrustRuleProcessorWhereField>>;
};

export type TrustRuleProcessorWhereField = {
  __typename?: 'TrustRuleProcessorWhereField';
  field: Scalars['String'];
  label: Scalars['String'];
  inputs?: Maybe<Array<Maybe<InputField>>>;
};

export type TrustRuleQuery = {
  __typename?: 'TrustRuleQuery';
  all?: Maybe<TrustRuleConnection>;
};


export type TrustRuleQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<TrustRuleOrderBy>>>;
  where?: Maybe<TrustRuleCondition>;
};

export type TrustRuleOrderBy = {
  field: TrustRuleOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum TrustRuleOrderByField {
  Id = 'id',
  Enabled = 'enabled',
  Points = 'points',
  Description = 'description',
  Message = 'message'
}

export type TrustRuleCondition = {
  merchant?: Maybe<NodeComparisonExpression>;
  message?: Maybe<StringComparisonExpression>;
  description?: Maybe<StringComparisonExpression>;
  points?: Maybe<IntegerComparisonExpression>;
  enabled?: Maybe<Scalars['Boolean']>;
  processor?: Maybe<NodeComparisonExpression>;
};

export type TrustRuleConnection = {
  __typename?: 'TrustRuleConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<TrustRuleEdge>>>;
};

export type TrustRuleEdge = {
  __typename?: 'TrustRuleEdge';
  node: TrustRule;
  cursor: Scalars['String'];
};

export type TrustRule = MerchantAware & Node & {
  __typename?: 'TrustRule';
  id: Scalars['ID'];
  processor?: Maybe<TrustRuleProcessor>;
  enabled: Scalars['Boolean'];
  points: Scalars['Int'];
  description: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  where?: Maybe<Array<Maybe<TrustRuleWhere>>>;
  if?: Maybe<Array<Maybe<TrustRuleIf>>>;
  merchant?: Maybe<Merchant>;
  /** Amount of transactions where this rule has been applied. */
  transactions: TrustRuleAffectedTransactions;
};

export type TrustRuleWhere = {
  id: Scalars['ID'];
};

export type TrustRuleIf = Node & {
  __typename?: 'TrustRuleIf';
  id: Scalars['ID'];
  field: Scalars['String'];
  comparison: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type TrustRuleAffectedTransactions = {
  __typename?: 'TrustRuleAffectedTransactions';
  safe: Scalars['Int'];
  suspicious: Scalars['Int'];
  danger: Scalars['Int'];
  total: Scalars['Int'];
};

export type UserActivityQuery = {
  __typename?: 'UserActivityQuery';
  /** Get summary of data to create a chart or anything else */
  summary?: Maybe<Array<Maybe<SummaryRow>>>;
  all?: Maybe<UserActivityConnection>;
};


export type UserActivityQuerySummaryArgs = {
  type: UserActivitySummaryType;
  minDate: Scalars['DateTime'];
  maxDate: Scalars['DateTime'];
  groupBy?: Maybe<UserActivitySummaryGroupBy>;
};


export type UserActivityQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<UserActivityOrderBy>>>;
  where?: Maybe<UserActivityCondition>;
};

export enum UserActivitySummaryType {
  /** Amount of activities by date with automatic time switch */
  CountByDate = 'COUNT_BY_DATE',
  /** Top breakpoints */
  TopBreakpoints = 'TOP_BREAKPOINTS'
}

export enum UserActivitySummaryGroupBy {
  /** Separate by finished and non finished transactions */
  Finished = 'FINISHED'
}

export type UserActivityOrderBy = {
  field: UserActivityOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum UserActivityOrderByField {
  SessionId = 'sessionId',
  Endpoint = 'endpoint',
  FingerPrint = 'fingerPrint',
  Ip = 'ip',
  UserAgent = 'userAgent',
  Origin = 'origin',
  InitialActivity = 'initialActivity',
  LastActivity = 'lastActivity',
  LastActivityName = 'lastActivityName',
  Finished = 'finished',
  Username = 'username',
  Number = 'number'
}

export type UserActivityCondition = {
  finished?: Maybe<Scalars['Boolean']>;
  lastActivityName?: Maybe<StringComparisonExpression>;
  lastActivity?: Maybe<DateTimeComparisonExpression>;
  initialActivity?: Maybe<DateTimeComparisonExpression>;
  origin?: Maybe<StringComparisonExpression>;
  userAgent?: Maybe<StringComparisonExpression>;
  ip?: Maybe<StringComparisonExpression>;
  fingerPrint?: Maybe<StringComparisonExpression>;
  endpoint?: Maybe<ApiEndpointComparisonExpression>;
  user?: Maybe<NodeComparisonExpression>;
  sessionId?: Maybe<StringComparisonExpression>;
};

export type UserActivityConnection = {
  __typename?: 'UserActivityConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<UserActivityEdge>>>;
};

export type UserActivityEdge = {
  __typename?: 'UserActivityEdge';
  node: UserActivity;
  cursor: Scalars['String'];
};

export type UserActivity = Node & {
  __typename?: 'UserActivity';
  id: Scalars['ID'];
  sessionId: Scalars['String'];
  user?: Maybe<User>;
  endpoint?: Maybe<ApiEndpoint>;
  fingerPrint: Scalars['String'];
  ip: Scalars['String'];
  userAgent?: Maybe<Scalars['String']>;
  origin: Scalars['String'];
  initialActivity: Scalars['DateTime'];
  lastActivity: Scalars['DateTime'];
  lastActivityName: Scalars['String'];
  finished: Scalars['Boolean'];
  logs?: Maybe<Array<Maybe<UserActivityLog>>>;
  number: Scalars['Int'];
};

export type UserActivityLog = {
  __typename?: 'UserActivityLog';
  type?: Maybe<UserActivityLogType>;
  createdAt: Scalars['DateTime'];
  details: Scalars['String'];
  afterFinished: Scalars['Boolean'];
};

export enum UserActivityLogType {
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR'
}

export type UserQuery = {
  __typename?: 'UserQuery';
  all?: Maybe<UserConnection>;
};


export type UserQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<UserOrderBy>>>;
  where?: Maybe<UserCondition>;
};

export type UserOrderBy = {
  field: UserOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum UserOrderByField {
  CreatedAt = 'createdAt',
  Number = 'number',
  Email = 'email',
  FullName = 'fullName',
  ProcessedTransactions = 'processedTransactions',
  ProcessedTransactionsAmount = 'processedTransactionsAmount',
  Phone = 'phone',
  VirtualCheckingAccountBalance = 'virtualCheckingAccountBalance'
}

export type UserCondition = {
  merchant?: Maybe<NodeComparisonExpression>;
  email?: Maybe<StringComparisonExpression>;
  createdAt?: Maybe<DateTimeComparisonExpression>;
  roles?: Maybe<ArrayComparisonExpression>;
  type?: Maybe<UserTypeComparisonExpression>;
};

/** Create enum comparison expression to filter records by current values. */
export type UserTypeComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<UserType>>;
};

export enum UserType {
  Manager = 'Manager',
  Clerk = 'Clerk',
  Representative = 'Representative',
  Customer = 'Customer'
}

export type UserConnection = {
  __typename?: 'UserConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<UserEdge>>>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  node: User;
  cursor: Scalars['String'];
};

export type UserSessionQuery = {
  __typename?: 'UserSessionQuery';
  /** Get summary of data to create a chart or anything else */
  summary?: Maybe<Array<Maybe<SummaryRow>>>;
  all?: Maybe<UserSessionConnection>;
};


export type UserSessionQuerySummaryArgs = {
  type: UserSessionSummaryType;
  minDate: Scalars['DateTime'];
  maxDate: Scalars['DateTime'];
  endpoints?: Maybe<Array<Maybe<ApiEndpoint>>>;
  hasErrors?: Maybe<Scalars['Boolean']>;
  sandbox?: Maybe<Scalars['Boolean']>;
  groupBy?: Maybe<UserSessionSummaryGroupBy>;
};


export type UserSessionQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<UserSessionOrderBy>>>;
  where?: Maybe<UserSessionCondition>;
};

export enum UserSessionSummaryType {
  /** Amount of traffic by day */
  CountByDay = 'COUNT_BY_DAY',
  /** Amount of traffic by date with automatic time switch */
  CountByDate = 'COUNT_BY_DATE'
}

export enum UserSessionSummaryGroupBy {
  /** Create a group for each endpoint */
  Endpoint = 'ENDPOINT',
  /** Create group for requests containing errors and not */
  WithErrors = 'WITH_ERRORS'
}

export type UserSessionOrderBy = {
  field: UserSessionOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum UserSessionOrderByField {
  CreatedAt = 'createdAt',
  Token = 'token',
  Endpoint = 'endpoint',
  Sandbox = 'sandbox',
  Debug = 'debug',
  SessionId = 'sessionId',
  ClientId = 'clientId',
  Ip = 'ip',
  ClientIp = 'clientIp',
  Agent = 'agent',
  Mobile = 'mobile',
  Origin = 'origin',
  Query = 'query',
  Variables = 'variables',
  Response = 'response'
}

export type UserSessionCondition = {
  response?: Maybe<StringComparisonExpression>;
  query?: Maybe<StringComparisonExpression>;
  origin?: Maybe<StringComparisonExpression>;
  mobile?: Maybe<Scalars['Boolean']>;
  agent?: Maybe<StringComparisonExpression>;
  clientIp?: Maybe<StringComparisonExpression>;
  ip?: Maybe<StringComparisonExpression>;
  clientId?: Maybe<StringComparisonExpression>;
  sessionId?: Maybe<StringComparisonExpression>;
  user?: Maybe<NodeComparisonExpression>;
  debug?: Maybe<Scalars['Boolean']>;
  sandbox?: Maybe<Scalars['Boolean']>;
  endpoint?: Maybe<ApiEndpointComparisonExpression>;
  token?: Maybe<StringComparisonExpression>;
  createdAt?: Maybe<DateTimeComparisonExpression>;
  apiKey?: Maybe<NodeComparisonExpression>;
  withErrors?: Maybe<UserSessionErrorComparisonExpression>;
  hideGuestTraffic?: Maybe<Scalars['Boolean']>;
  verifoneTerminal?: Maybe<NodeComparisonExpression>;
  merchant?: Maybe<NodeComparisonExpression>;
  errorTrackingId?: Maybe<StringComparisonExpression>;
  hideAdminTraffic?: Maybe<Scalars['Boolean']>;
};

export type UserSessionErrorComparisonExpression = {
  /** Comparison operator, default value: `IN` */
  op?: Maybe<CollectionComparisonOperator>;
  /** values to search */
  values: Array<Maybe<UserSessionErrorFilterLevel>>;
};

export enum UserSessionErrorFilterLevel {
  None = 'NONE',
  ClientError = 'CLIENT_ERROR',
  InternalError = 'INTERNAL_ERROR'
}

export type UserSessionConnection = {
  __typename?: 'UserSessionConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<UserSessionEdge>>>;
};

export type UserSessionEdge = {
  __typename?: 'UserSessionEdge';
  node: UserSession;
  cursor: Scalars['String'];
};

export type VerifoneTerminalQuery = {
  __typename?: 'VerifoneTerminalQuery';
  socketAPIOnline?: Maybe<Scalars['Boolean']>;
  all?: Maybe<VerifoneTerminalConnection>;
  productServers?: Maybe<ProductServersQuery>;
};


export type VerifoneTerminalQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<VerifoneTerminalOrderBy>>>;
  where?: Maybe<VerifoneTerminalCondition>;
};

export type VerifoneTerminalOrderBy = {
  field: VerifoneTerminalOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum VerifoneTerminalOrderByField {
  Id = 'id',
  Enabled = 'enabled',
  CreatedAt = 'createdAt',
  ActivatedAt = 'activatedAt',
  ActivatedTerminalType = 'activatedTerminalType',
  Number = 'number',
  ReceiptHeader = 'receiptHeader',
  ActivationCode = 'activationCode',
  RestrictClerks = 'restrictClerks',
  RestrictAnis = 'restrictAnis',
  RestrictDevices = 'restrictDevices',
  NyStateFlag = 'nyStateFlag',
  ProductsHashUploaded = 'productsHashUploaded',
  ProductsHashDateUploaded = 'productsHashDateUploaded',
  ProductsHashUploadFailedReason = 'productsHashUploadFailedReason'
}

export type VerifoneTerminalCondition = {
  productsHashUploadFailedReason?: Maybe<StringComparisonExpression>;
  productsHashDateUploaded?: Maybe<DateTimeComparisonExpression>;
  productsHashUploaded?: Maybe<StringComparisonExpression>;
  nyStateFlag?: Maybe<Scalars['Boolean']>;
  restrictDevices?: Maybe<Scalars['Boolean']>;
  restrictAnis?: Maybe<Scalars['Boolean']>;
  restrictClerks?: Maybe<Scalars['Boolean']>;
  activationCode?: Maybe<StringComparisonExpression>;
  receiptHeader?: Maybe<StringComparisonExpression>;
  number?: Maybe<StringComparisonExpression>;
  activatedTerminalType?: Maybe<StringComparisonExpression>;
  activatedAt?: Maybe<DateTimeComparisonExpression>;
  createdAt?: Maybe<DateTimeComparisonExpression>;
  merchant?: Maybe<NodeComparisonExpression>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type VerifoneTerminalConnection = {
  __typename?: 'VerifoneTerminalConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<VerifoneTerminalEdge>>>;
};

export type VerifoneTerminalEdge = {
  __typename?: 'VerifoneTerminalEdge';
  node: VerifoneTerminal;
  cursor: Scalars['String'];
};

export type VerifoneTerminal = MerchantAware & Node & {
  __typename?: 'VerifoneTerminal';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  apiKey?: Maybe<ApiKey>;
  merchant?: Maybe<Merchant>;
  createdAt: Scalars['DateTime'];
  activatedAt?: Maybe<Scalars['DateTime']>;
  activatedTerminalType?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  receiptHeader?: Maybe<Scalars['String']>;
  activationCode: Scalars['String'];
  restrictClerks: Scalars['Boolean'];
  restrictAnis: Scalars['Boolean'];
  restrictDevices: Scalars['Boolean'];
  devices?: Maybe<Array<Maybe<VerifoneTerminalDevice>>>;
  anis?: Maybe<Array<Maybe<VerifoneTerminalAni>>>;
  nyStateFlag: Scalars['Boolean'];
  productsHash?: Maybe<Scalars['String']>;
  productsHashDate?: Maybe<Scalars['DateTime']>;
  productsHashUploaded?: Maybe<Scalars['String']>;
  productsHashDateUploaded?: Maybe<Scalars['DateTime']>;
  productsHashUploadFailedReason?: Maybe<Scalars['String']>;
  sandbox?: Maybe<Scalars['Boolean']>;
};

export type VerifoneTerminalDevice = Node & {
  __typename?: 'VerifoneTerminalDevice';
  id: Scalars['ID'];
  serialNumber: Scalars['String'];
  addedAt: Scalars['DateTime'];
  activatedAt?: Maybe<Scalars['DateTime']>;
};

export type VerifoneTerminalAni = Node & {
  __typename?: 'VerifoneTerminalAni';
  id: Scalars['ID'];
  ani: Scalars['String'];
  addedAt: Scalars['DateTime'];
};

export type ProductServersQuery = {
  __typename?: 'ProductServersQuery';
  all?: Maybe<VerifoneTerminalProductServerConnection>;
};


export type ProductServersQueryAllArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<VerifoneTerminalProductServerOrderBy>>>;
  where?: Maybe<VerifoneTerminalProductServerCondition>;
};

export type VerifoneTerminalProductServerOrderBy = {
  field: VerifoneTerminalProductServerOrderByField;
  direction?: Maybe<OrderDirection>;
};

export enum VerifoneTerminalProductServerOrderByField {
  Enabled = 'enabled'
}

export type VerifoneTerminalProductServerCondition = {
  enabled?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<NodeComparisonExpression>;
};

export type VerifoneTerminalProductServerConnection = {
  __typename?: 'VerifoneTerminalProductServerConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<VerifoneTerminalProductServerEdge>>>;
};

export type VerifoneTerminalProductServerEdge = {
  __typename?: 'VerifoneTerminalProductServerEdge';
  node: VerifoneTerminalProductServer;
  cursor: Scalars['String'];
};

export type VerifoneTerminalProductServer = Node & MerchantAware & {
  __typename?: 'VerifoneTerminalProductServer';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  enabled: Scalars['Boolean'];
  ftpSettings?: Maybe<VerifoneTerminalProductServerFtpSettings>;
};

export type VerifoneTerminalProductServerFtpSettings = {
  __typename?: 'VerifoneTerminalProductServerFTPSettings';
  host?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  achPayments?: Maybe<AchPaymentMutation>;
  apiKeys?: Maybe<ApiKeyMutation>;
  consumerAuthentication?: Maybe<ConsumerAuthenticationMutation>;
  cronJobs?: Maybe<CronJobMutation>;
  customerCredits?: Maybe<CustomerCreditMutation>;
  customerCreditSettlementBatches?: Maybe<CustomerCreditSettlementBatchMutation>;
  customers?: Maybe<CustomerMutation>;
  /** Save UI settings in the cloud for current API user */
  saveUISettings?: Maybe<Scalars['DynamicObject']>;
  e911UserFees?: Maybe<E911UserFeeMutation>;
  merchants?: Maybe<MerchantMutation>;
  pins?: Maybe<PinMutation>;
  priceListItems?: Maybe<PriceListItemMutation>;
  priceLists?: Maybe<PriceListMutation>;
  tools?: Maybe<ToolsMutation>;
  transactions?: Maybe<TransactionMutation>;
  tvs?: Maybe<TvsBundleMutation>;
  userActivities?: Maybe<UserActivityMutation>;
  users?: Maybe<UserMutation>;
  verifoneTerminals?: Maybe<VerifoneTerminalMutation>;
};


export type MutationSaveUiSettingsArgs = {
  settings?: Maybe<Scalars['DynamicObject']>;
  merge?: Maybe<Scalars['Boolean']>;
};

export type AchPaymentMutation = {
  __typename?: 'AchPaymentMutation';
  uploadNachaFile?: Maybe<Scalars['Boolean']>;
};


export type AchPaymentMutationUploadNachaFileArgs = {
  ach: Scalars['ID'];
};

export type ApiKeyMutation = {
  __typename?: 'ApiKeyMutation';
  reset?: Maybe<ResetApiKeyPayload>;
  add?: Maybe<AddApiKeyPayload>;
  update?: Maybe<UpdateApiKeyPayload>;
  delete?: Maybe<DeleteNodePayload>;
};


export type ApiKeyMutationResetArgs = {
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
};


export type ApiKeyMutationAddArgs = {
  input: AddApiKeyInput;
};


export type ApiKeyMutationUpdateArgs = {
  input: UpdateApiKeyInput;
};


export type ApiKeyMutationDeleteArgs = {
  input: DeleteApiKeyInput;
};

export type ResetApiKeyPayload = {
  __typename?: 'ResetApiKeyPayload';
  /** **APIKey**: The user must save this value because will be encrypted and never returned back again. */
  key?: Maybe<Scalars['String']>;
  /** API Identifier */
  identifier?: Maybe<Scalars['String']>;
  /** Updated node instance */
  node?: Maybe<ApiKey>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddApiKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  merchant: Scalars['ID'];
  enabled?: Maybe<Scalars['Boolean']>;
  scope?: Maybe<ApiKeyScope>;
  restrictedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
  sandbox?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
};

export type AddApiKeyPayload = {
  __typename?: 'AddApiKeyPayload';
  /** Updated node instance */
  node?: Maybe<ApiKey>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
  /** **APIKey**: The user must save this value because will be encrypted and never returned back again. */
  key?: Maybe<Scalars['String']>;
  /** API Identifier */
  identifier?: Maybe<Scalars['String']>;
};

export type UpdateApiKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  enabled?: Maybe<Scalars['Boolean']>;
  scope?: Maybe<ApiKeyScope>;
  restrictedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
  sandbox?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateApiKeyPayload = {
  __typename?: 'UpdateApiKeyPayload';
  /** Updated node instance */
  node?: Maybe<ApiKey>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteApiKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteNodePayload = {
  __typename?: 'DeleteNodePayload';
  /** ID of the node deleted on success */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ConsumerAuthenticationMutation = {
  __typename?: 'ConsumerAuthenticationMutation';
  cardinalJWT?: Maybe<Scalars['String']>;
};


export type ConsumerAuthenticationMutationCardinalJwtArgs = {
  orderNumber: Scalars['String'];
  amount: Scalars['Int'];
};

export type CronJobMutation = {
  __typename?: 'CronJobMutation';
  run?: Maybe<CronJob>;
};


export type CronJobMutationRunArgs = {
  cronJob: Scalars['ID'];
};

export type CustomerCreditMutation = {
  __typename?: 'CustomerCreditMutation';
  retrySettlement?: Maybe<CustomerCredit>;
  batchPendingSettlement?: Maybe<Array<Maybe<CustomerCreditSettlementBatch>>>;
};


export type CustomerCreditMutationRetrySettlementArgs = {
  credit: Scalars['ID'];
};

export type CustomerCreditSettlementBatchMutation = {
  __typename?: 'CustomerCreditSettlementBatchMutation';
  /** Allow retry a rejected or failed batch only if is retryable */
  retry?: Maybe<CustomerCreditSettlementBatch>;
  delete?: Maybe<DeleteNodePayload>;
};


export type CustomerCreditSettlementBatchMutationRetryArgs = {
  batch: Scalars['ID'];
};


export type CustomerCreditSettlementBatchMutationDeleteArgs = {
  input: DeleteCustomerCreditSettlementBatchInput;
};

export type DeleteCustomerCreditSettlementBatchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type CustomerMutation = {
  __typename?: 'CustomerMutation';
  add?: Maybe<AddNodePayload>;
  /** Return the customer token used to get customer details, update profile and/or place transactions */
  register?: Maybe<Scalars['String']>;
  /** Send a PIN for given target or user using the given protocol and return the token for 2FA */
  twoFactorAuth?: Maybe<TwoFactorAuthCustomerPayload>;
  /** Return a reset token, this token must be used with the PIN received in the user email or phone to send the new password */
  resetPasswordRequest?: Maybe<ResetPasswordRequestPayload>;
  update?: Maybe<UpdateNodePayload>;
};


export type CustomerMutationAddArgs = {
  input: AddCustomerInput;
};


export type CustomerMutationRegisterArgs = {
  input: RegisterCustomerInput;
};


export type CustomerMutationTwoFactorAuthArgs = {
  protocol: TwoFactorAuthenticationProtocol;
  target?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  customerToken?: Maybe<Scalars['String']>;
  context?: Maybe<CustomerContext>;
};


export type CustomerMutationResetPasswordRequestArgs = {
  username: Scalars['String'];
  context?: Maybe<CustomerContext>;
  protocol: TwoFactorAuthenticationProtocol;
};


export type CustomerMutationUpdateArgs = {
  input: UpdateCustomerInput;
};

export type AddCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Merchant where the customer has been registered */
  merchant: Scalars['ID'];
  /** Customer email used as username for login purposes later, must be verified. */
  email: Scalars['String'];
  password: Scalars['String'];
  profile: AddCustomerProfileInput;
};

export type AddCustomerProfileInput = {
  fullName: Scalars['String'];
  /**
   * Telephone number using [E.123](https://en.wikipedia.org/wiki/E.123)
   * international or Microsoft format. Spaces or others symbols to separate groups of numbers are optional.
   *
   * **Examples:**
   *
   *     +1 305 1231234
   *     +1 (305)-123-1234
   *     +13051231234
   */
  phone: Scalars['String'];
  billingAddress1?: Maybe<Scalars['String']>;
  billingAddress2?: Maybe<Scalars['String']>;
  billingZipCode?: Maybe<Scalars['String']>;
  billingCity?: Maybe<Scalars['String']>;
  billingState?: Maybe<Scalars['String']>;
  billingCountryCode?: Maybe<CountryCode>;
  virtualCheckingAccount: AddCustomerProfileVirtualCheckingAccountInput;
};

export type AddCustomerProfileVirtualCheckingAccountInput = {
  accountNumber: Scalars['String'];
};

export type AddNodePayload = {
  __typename?: 'AddNodePayload';
  /** Added node instance */
  node?: Maybe<Node>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Context to register the user, some fields are only required in some contexts. Default: `ECOMMERCE` */
  context?: Maybe<CustomerContext>;
  /** The 2-Factor Token when two factor authentication is required for registration */
  twoFactorAuthToken?: Maybe<Scalars['String']>;
  /** The 2-Factor PIN when two factor authentication is required for registration */
  twoFactorAuthPIN?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  /** Customer email used as username for login purposes later, must be verified. */
  email: Scalars['String'];
  password: Scalars['String'];
  /**
   * Telephone number using [E.123](https://en.wikipedia.org/wiki/E.123)
   * international or Microsoft format. Spaces or others symbols to separate groups of numbers are optional.
   *
   * **Examples:**
   *
   *     +1 305 1231234
   *     +1 (305)-123-1234
   *     +13051231234
   */
  phone: Scalars['String'];
  /** Social Security number only required in VIRTUAL_CHECKING_ACCOUNT context */
  ssn?: Maybe<Scalars['String']>;
  /** Date of birth only required in VIRTUAL_CHECKING_ACCOUNT context */
  dateOfBirth?: Maybe<Scalars['Date']>;
  billingAddress1?: Maybe<Scalars['String']>;
  billingAddress2?: Maybe<Scalars['String']>;
  billingZipCode?: Maybe<Scalars['String']>;
  billingCity?: Maybe<Scalars['String']>;
  billingState?: Maybe<Scalars['String']>;
  billingCountryCode?: Maybe<CountryCode>;
  /** If you get error `2301` during registration, you need take the GUID value from error message and append it to the original create customer call under a field called "GUID" */
  bankJetGUID?: Maybe<Scalars['String']>;
};


export enum TwoFactorAuthenticationProtocol {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type TwoFactorAuthCustomerPayload = {
  __typename?: 'TwoFactorAuthCustomerPayload';
  /** 2-Factor Auth token helful to do a login or ther operations requesting a 2-Factor Auth token and PIN */
  token: Scalars['String'];
  /** Protocol used to send the PIN to final customer */
  protocol: TwoFactorAuthenticationProtocol;
  /** Phone/Email of the customer used to send the PIN */
  target: Scalars['String'];
};

export type ResetPasswordRequestPayload = {
  __typename?: 'ResetPasswordRequestPayload';
  /** 2-Factor Auth token helful to do a login or ther operations requesting a 2-Factor Auth token and PIN */
  token: Scalars['String'];
  /** Protocol used to send the PIN to final customer */
  protocol: TwoFactorAuthenticationProtocol;
  /** Phone/Email of the customer used to send the PIN */
  target: Scalars['String'];
};

export type UpdateCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Customer email used as username for login purposes later, must be verified. */
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  profile: UpdateCustomerProfileInput;
};

export type UpdateCustomerProfileInput = {
  fullName?: Maybe<Scalars['String']>;
  /**
   * Telephone number using [E.123](https://en.wikipedia.org/wiki/E.123)
   * international or Microsoft format. Spaces or others symbols to separate groups of numbers are optional.
   *
   * **Examples:**
   *
   *     +1 305 1231234
   *     +1 (305)-123-1234
   *     +13051231234
   */
  phone?: Maybe<Scalars['String']>;
  billingAddress1?: Maybe<Scalars['String']>;
  billingAddress2?: Maybe<Scalars['String']>;
  billingZipCode?: Maybe<Scalars['String']>;
  billingCity?: Maybe<Scalars['String']>;
  billingState?: Maybe<Scalars['String']>;
  billingCountryCode?: Maybe<CountryCode>;
  virtualCheckingAccount: UpdateCustomerProfileVirtualCheckingAccountInput;
};

export type UpdateCustomerProfileVirtualCheckingAccountInput = {
  accountNumber?: Maybe<Scalars['String']>;
};

export type UpdateNodePayload = {
  __typename?: 'UpdateNodePayload';
  /** Updated node instance */
  node?: Maybe<Node>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type E911UserFeeMutation = {
  __typename?: 'E911UserFeeMutation';
  add?: Maybe<AddE911UserFeePayload>;
  update?: Maybe<UpdateE911UserFeePayload>;
  delete?: Maybe<DeleteNodePayload>;
};


export type E911UserFeeMutationAddArgs = {
  input: AddE911UserFeeInput;
};


export type E911UserFeeMutationUpdateArgs = {
  input: UpdateE911UserFeeInput;
};


export type E911UserFeeMutationDeleteArgs = {
  input: DeleteE911UserFeeInput;
};

export type AddE911UserFeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** US country state using USPS 2-letter codes, Example: FL or NY */
  state: Scalars['String'];
  amount: Scalars['Float'];
  mode: FeeMode;
};

export type AddE911UserFeePayload = {
  __typename?: 'AddE911UserFeePayload';
  /** Added node instance */
  node?: Maybe<E911UserFee>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateE911UserFeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** US country state using USPS 2-letter codes, Example: FL or NY */
  state: Scalars['String'];
  amount: Scalars['Float'];
  mode: FeeMode;
};

export type UpdateE911UserFeePayload = {
  __typename?: 'UpdateE911UserFeePayload';
  /** Updated node instance */
  node?: Maybe<E911UserFee>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteE911UserFeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MerchantMutation = {
  __typename?: 'MerchantMutation';
  update?: Maybe<UpdateMerchantPayload>;
};


export type MerchantMutationUpdateArgs = {
  input: UpdateMerchantInput;
};

export type UpdateMerchantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  retailPriceList?: Maybe<Scalars['ID']>;
  billingSettings: UpdateMerchantBillingSettingsInput;
  serviceProviders?: Maybe<Array<Maybe<UpdateMerchantServiceProvidersInput>>>;
};

export type UpdateMerchantBillingSettingsInput = {
  achInfo: UpdateMerchantBillingSettingsAchInfoInput;
  cutOffTime: Scalars['Float'];
};

export type UpdateMerchantBillingSettingsAchInfoInput = {
  bankName?: Maybe<Scalars['String']>;
  bankAccountType?: Maybe<AchBankAccountType>;
  bankRoutingNumber?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  tid?: Maybe<Scalars['String']>;
  ftpServer?: Maybe<Scalars['String']>;
  ftpPort: Scalars['Float'];
  ftpUsername?: Maybe<Scalars['String']>;
  ftpPassword?: Maybe<Scalars['String']>;
};

export type UpdateMerchantServiceProvidersInput = {
  id?: Maybe<Scalars['ID']>;
  enabled: Scalars['Boolean'];
  enabledInNewMerchants: Scalars['Boolean'];
  service: Scalars['ID'];
  credentials: UpdateMerchantServiceProvidersCredentialsInput;
};

export type UpdateMerchantServiceProvidersCredentialsInput = {
  id?: Maybe<Scalars['ID']>;
  credentials: Scalars['DynamicObject'];
};

export type UpdateMerchantPayload = {
  __typename?: 'UpdateMerchantPayload';
  /** Updated node instance */
  node?: Maybe<Merchant>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PinMutation = {
  __typename?: 'PinMutation';
  /** Redeem a purchased PIN. Return true on success. */
  redeem?: Maybe<Scalars['Boolean']>;
};


export type PinMutationRedeemArgs = {
  input: RedeemInput;
};

export type RedeemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** PIN to submit */
  pin: Scalars['String'];
  /** Product to use, should match with the original purchased product PIN. Leave empty to resolve the product automatically based on transactions matching given PIN. */
  product?: Maybe<Scalars['ID']>;
  /** Account number to apply the given PIN. */
  accountNumber?: Maybe<Scalars['String']>;
};

export type PriceListItemMutation = {
  __typename?: 'PriceListItemMutation';
  update?: Maybe<UpdatePriceListItemPayload>;
};


export type PriceListItemMutationUpdateArgs = {
  input: UpdatePriceListItemInput;
};

export type UpdatePriceListItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product: Scalars['ID'];
  list: Scalars['ID'];
};

export type UpdatePriceListItemPayload = {
  __typename?: 'UpdatePriceListItemPayload';
  /** Updated node instance */
  node?: Maybe<PriceListItem>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PriceListMutation = {
  __typename?: 'PriceListMutation';
  /** Synchronize wholesale price lists with prices and adjustments from providers */
  providerSync?: Maybe<Scalars['Boolean']>;
  /** Revert changes on price list, only if the price list in edit mode, automatically disable the edit mode too */
  revertChanges?: Maybe<PriceList>;
  add?: Maybe<AddPriceListPayload>;
  update?: Maybe<UpdatePriceListPayload>;
  delete?: Maybe<DeleteNodePayload>;
};


export type PriceListMutationRevertChangesArgs = {
  priceList: Scalars['ID'];
};


export type PriceListMutationAddArgs = {
  input: AddPriceListInput;
};


export type PriceListMutationUpdateArgs = {
  input: UpdatePriceListInput;
};


export type PriceListMutationDeleteArgs = {
  input: DeletePriceListInput;
};

export type AddPriceListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  editMode?: Maybe<Scalars['Boolean']>;
};

export type AddPriceListPayload = {
  __typename?: 'AddPriceListPayload';
  /** Added node instance */
  node?: Maybe<PriceList>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePriceListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  editMode?: Maybe<Scalars['Boolean']>;
};

export type UpdatePriceListPayload = {
  __typename?: 'UpdatePriceListPayload';
  /** Updated node instance */
  node?: Maybe<PriceList>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePriceListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ToolsMutation = {
  __typename?: 'ToolsMutation';
  /** Create a short link for given link */
  shortLink?: Maybe<Scalars['String']>;
  /** Create barcode outpout for given code */
  barcode?: Maybe<Scalars['String']>;
  /** Verify given ftp server status */
  verifyFTPService?: Maybe<ServiceStatus>;
};


export type ToolsMutationShortLinkArgs = {
  url: Scalars['String'];
};


export type ToolsMutationBarcodeArgs = {
  type: BarcodeType;
  code: Scalars['String'];
  output: BarcodeOutputType;
  widthFactor?: Maybe<Scalars['Int']>;
  totalHeight?: Maybe<Scalars['Int']>;
};


export type ToolsMutationVerifyFtpServiceArgs = {
  host: Scalars['String'];
  port?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
  password: Scalars['String'];
};

export enum BarcodeType {
  Code_39 = 'CODE_39',
  Code_128 = 'CODE_128'
}

export enum BarcodeOutputType {
  Base64Png = 'BASE64_PNG',
  Base64Jpg = 'BASE64_JPG',
  Base64Svg = 'BASE64_SVG',
  UrlPng = 'URL_PNG',
  UrlJpg = 'URL_JPG',
  UrlSvg = 'URL_SVG'
}

export type ServiceStatus = {
  __typename?: 'ServiceStatus';
  /** Executed tests and details */
  tests?: Maybe<Array<Maybe<ServiceStatusTest>>>;
  /** The service return a success response for all tests */
  success?: Maybe<Scalars['Boolean']>;
};

export type ServiceStatusTest = {
  __typename?: 'ServiceStatusTest';
  /** The service return a success for this test */
  success: Scalars['Boolean'];
  /**
   * This test can fail and does not affect the result of the entire verification.
   *     In any case should be displayed as warning for users.
   */
  optional: Scalars['Boolean'];
  /** Describe the test */
  description: Scalars['String'];
  /** Error in case of failed test */
  failReason?: Maybe<Scalars['String']>;
};

export type TransactionMutation = {
  __typename?: 'TransactionMutation';
  /** Re-send transaction receipt email for given email. */
  sendReceiptEmail?: Maybe<Scalars['Boolean']>;
  submit?: Maybe<SubmitTransactionPayload>;
  validate?: Maybe<ValidateTransactionPayload>;
};


export type TransactionMutationSendReceiptEmailArgs = {
  transaction: Scalars['ID'];
  email: Scalars['String'];
};


export type TransactionMutationSubmitArgs = {
  input: SubmitTransactionInput;
};


export type TransactionMutationValidateArgs = {
  input: ValidateTransactionInput;
};

export type SubmitTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of the product you want to process. */
  product: Scalars['ID'];
  /** Link this transaction to specific customer, must register or login with customer credentials. */
  customerToken?: Maybe<Scalars['String']>;
  /**
   * key:value pair with all inputs required to purchase given product.
   * To view all required inputs in any product see `Product.inputs`
   */
  inputs: Scalars['DynamicObject'];
  /** Payment method used by customer */
  paymentMethod?: Maybe<CustomerPaymentMethod>;
  /** Fill with customer credit card information when use payment method **CREDIT_CARD** */
  creditCard?: Maybe<SubmitTransactionCreditCardInput>;
  /**
   * Sent a transaction receipt to given customer’s email address.
   * Receipts are only sent when a successful payment has been made—no receipt is sent if the request fails or is declined.
   */
  receiptEmail?: Maybe<Scalars['String']>;
  /**
   * The client system can use this parameter to
   * save information related to the transaction. Most common use it to pass transaction number from the
   * client system. The correlationId should be unique in the client system.
   */
  correlationId?: Maybe<Scalars['String']>;
  /**
   * In cases where `redirectUrl` is returned, because the transaction needs a user intervention to proceed,
   * the client should be redirected to given `redirectUrl` site and when the interaction with that site is done, the client is redirected to this URL.
   */
  returnUrl?: Maybe<Scalars['String']>;
  /**
   * This is useful for identifying users or devices without cookies or sessions.
   * It's not a full proof technique, but it has been shown to be statistically significant at accurately identifying devices.
   * see [https://github.com/jackspirou/clientjs](https://github.com/jackspirou/clientjs)
   */
  clientFingerprint?: Maybe<Scalars['String']>;
  /** The 2-Factor Token when two factor authentication is required to make transactions */
  twoFactorAuthToken?: Maybe<Scalars['String']>;
  /** The 2-Factor PIN when two factor authentication is required to make transactions */
  twoFactorAuthPIN?: Maybe<Scalars['String']>;
};

/** Fill with customer credit card information when use payment method **CREDIT_CARD** */
export type SubmitTransactionCreditCardInput = {
  /** Credit card number. */
  number?: Maybe<Scalars['String']>;
  /** Credit Card exp. month */
  expMonth?: Maybe<Scalars['Float']>;
  /** Credit Card exp. year */
  expYear?: Maybe<Scalars['Float']>;
  /** Credit Card security code */
  cvv?: Maybe<Scalars['String']>;
  /**
   * Card magnetic data, including track1 and track2, using "\n" character to separate lines.
   *                 This field is only required to make card present transactions.
   */
  magData?: Maybe<Scalars['String']>;
  /**
   * Token generated using 3D secure provider to use secure payments.
   *                 If the merchant require secure payments all credit card payments require this token, otherwise return a error **2502**
   */
  consumerAuthToken?: Maybe<Scalars['String']>;
  /** Credit Card holder information */
  holder?: Maybe<SubmitTransactionCreditCardHolderInput>;
};

/** Credit Card holder information */
export type SubmitTransactionCreditCardHolderInput = {
  /** Cardholder's name */
  name?: Maybe<Scalars['String']>;
  /** Cardholder's address line 1 */
  address1?: Maybe<Scalars['String']>;
  /** Cardholder's address line 2 */
  address2?: Maybe<Scalars['String']>;
  /** Cardholder's city */
  city?: Maybe<Scalars['String']>;
  /** Cardholder's state */
  state?: Maybe<Scalars['String']>;
  /** Cardholder's zip code */
  zipCode?: Maybe<Scalars['String']>;
  /** Cardholder's country code using ISO 3166-1 alpha-2 */
  countryCode?: Maybe<Scalars['String']>;
};

export type SubmitTransactionPayload = {
  __typename?: 'SubmitTransactionPayload';
  /** Updated node instance */
  transaction?: Maybe<Transaction>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Redirect url when the transaction process need a user confirmation or manual action to proceed */
  redirectUrl?: Maybe<Scalars['String']>;
  /** Merchant balance after success transaction */
  balance?: Maybe<Scalars['Float']>;
  /** Get current products version to know if necessary sync products in the terminal */
  terminalProductsVersion?: Maybe<VerifoneTerminalProductsVersion>;
};

export type VerifoneTerminalProductsVersion = {
  __typename?: 'VerifoneTerminalProductsVersion';
  hash: Scalars['String'];
  date?: Maybe<Scalars['DateTime']>;
};

export type ValidateTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of the product you want to process. */
  product: Scalars['ID'];
  /** Link this transaction to specific customer, must register or login with customer credentials. */
  customerToken?: Maybe<Scalars['String']>;
  /**
   * key:value pair with all inputs required to purchase given product.
   * To view all required inputs in any product see `Product.inputs`
   */
  inputs?: Maybe<Scalars['DynamicObject']>;
  /** Payment method used by customer */
  paymentMethod?: Maybe<CustomerPaymentMethod>;
  /** Fill with customer credit card information when use payment method **CREDIT_CARD** */
  creditCard?: Maybe<ValidateTransactionCreditCardInput>;
};

/** Fill with customer credit card information when use payment method **CREDIT_CARD** */
export type ValidateTransactionCreditCardInput = {
  /** Credit card number. */
  number?: Maybe<Scalars['String']>;
  /** Credit Card exp. month */
  expMonth?: Maybe<Scalars['Float']>;
  /** Credit Card exp. year */
  expYear?: Maybe<Scalars['Float']>;
  /** Credit Card security code */
  cvv?: Maybe<Scalars['String']>;
  /**
   * Card magnetic data, including track1 and track2, using "\n" character to separate lines.
   *                 This field is only required to make card present transactions.
   */
  magData?: Maybe<Scalars['String']>;
  /**
   * Token generated using 3D secure provider to use secure payments.
   *                 If the merchant require secure payments all credit card payments require this token, otherwise return a error **2502**
   */
  consumerAuthToken?: Maybe<Scalars['String']>;
  /** Credit Card holder information */
  holder?: Maybe<ValidateTransactionCreditCardHolderInput>;
};

/** Credit Card holder information */
export type ValidateTransactionCreditCardHolderInput = {
  /** Cardholder's name */
  name?: Maybe<Scalars['String']>;
  /** Cardholder's address line 1 */
  address1?: Maybe<Scalars['String']>;
  /** Cardholder's address line 2 */
  address2?: Maybe<Scalars['String']>;
  /** Cardholder's city */
  city?: Maybe<Scalars['String']>;
  /** Cardholder's state */
  state?: Maybe<Scalars['String']>;
  /** Cardholder's zip code */
  zipCode?: Maybe<Scalars['String']>;
  /** Cardholder's country code using ISO 3166-1 alpha-2 */
  countryCode?: Maybe<Scalars['String']>;
};

export type ValidateTransactionPayload = {
  __typename?: 'ValidateTransactionPayload';
  sandbox: Scalars['Boolean'];
  product: Product;
  grossTotal: Scalars['Float'];
  adjustments?: Maybe<Array<Maybe<TransactionAdjustment>>>;
  total: Scalars['Float'];
};

export type TvsBundleMutation = {
  __typename?: 'TvsBundleMutation';
  trustRules?: Maybe<TrustRuleMutation>;
};

export type TrustRuleMutation = {
  __typename?: 'TrustRuleMutation';
  add?: Maybe<AddTrustRulePayload>;
  update?: Maybe<UpdateTrustRulePayload>;
  delete?: Maybe<DeleteNodePayload>;
};


export type TrustRuleMutationAddArgs = {
  input: AddTrustRuleInput;
};


export type TrustRuleMutationUpdateArgs = {
  input: UpdateTrustRuleInput;
};


export type TrustRuleMutationDeleteArgs = {
  input: DeleteTrustRuleInput;
};

export type AddTrustRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  points: Scalars['Int'];
  processor: Scalars['ID'];
  where?: Maybe<Array<Maybe<AddTrustRuleWhereInput>>>;
  if?: Maybe<Array<Maybe<AddTrustRuleIfInput>>>;
};

export type AddTrustRuleWhereInput = {
  field?: Maybe<Scalars['String']>;
  comparison?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  subConditions?: Maybe<Array<Maybe<AddTrustRuleWhereSubConditionsInput>>>;
};

export type AddTrustRuleWhereSubConditionsInput = {
  field?: Maybe<Scalars['String']>;
  comparison?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type AddTrustRuleIfInput = {
  field: Scalars['String'];
  comparison: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type AddTrustRulePayload = {
  __typename?: 'AddTrustRulePayload';
  /** Added node instance */
  node?: Maybe<TrustRule>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTrustRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  enabled?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  points: Scalars['Int'];
  where?: Maybe<Array<Maybe<UpdateTrustRuleWhereInput>>>;
  if?: Maybe<Array<Maybe<UpdateTrustRuleIfInput>>>;
};

export type UpdateTrustRuleWhereInput = {
  id?: Maybe<Scalars['ID']>;
  field?: Maybe<Scalars['String']>;
  comparison?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  subConditions?: Maybe<Array<Maybe<UpdateTrustRuleWhereSubConditionsInput>>>;
};

export type UpdateTrustRuleWhereSubConditionsInput = {
  id?: Maybe<Scalars['ID']>;
  field?: Maybe<Scalars['String']>;
  comparison?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UpdateTrustRuleIfInput = {
  id?: Maybe<Scalars['ID']>;
  field: Scalars['String'];
  comparison: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type UpdateTrustRulePayload = {
  __typename?: 'UpdateTrustRulePayload';
  /** Updated node instance */
  node?: Maybe<TrustRule>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteTrustRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UserActivityMutation = {
  __typename?: 'UserActivityMutation';
  /** Add activity logs and return the session id */
  registerActivities?: Maybe<Scalars['String']>;
  /**
   * Add activity log and return the session id
   * @deprecated Use register activities to register activities in a batch for perfomance
   */
  registerActivity?: Maybe<Scalars['String']>;
};


export type UserActivityMutationRegisterActivitiesArgs = {
  clientFingerPrint: Scalars['String'];
  activities: Array<UserActivityLogInput>;
  sessionId?: Maybe<Scalars['String']>;
};


export type UserActivityMutationRegisterActivityArgs = {
  clientFingerPrint: Scalars['String'];
  details: Scalars['String'];
  type?: Maybe<UserActivityLogType>;
  sessionId?: Maybe<Scalars['String']>;
  finish?: Maybe<Scalars['Boolean']>;
};

export type UserActivityLogInput = {
  details: Scalars['String'];
  type?: Maybe<UserActivityLogType>;
  finish?: Maybe<Scalars['Boolean']>;
};

export type UserMutation = {
  __typename?: 'UserMutation';
  delete?: Maybe<DeleteNodePayload>;
};


export type UserMutationDeleteArgs = {
  input: DeleteUserInput;
};

export type DeleteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type VerifoneTerminalMutation = {
  __typename?: 'VerifoneTerminalMutation';
  /** Activate Verifone device and get credentials to use for subsequent requests */
  activate?: Maybe<VerifoneActivationPayload>;
  /** @deprecated Use `VerifoneTerminal.devices` instead */
  addDevice?: Maybe<AddVerifoneTerminalDevicePayload>;
  /** @deprecated Use `VerifoneTerminal.anis` instead */
  addAni?: Maybe<AddVerifoneTerminalAniPayload>;
  add?: Maybe<AddVerifoneTerminalPayload>;
  update?: Maybe<UpdateVerifoneTerminalPayload>;
  productServers?: Maybe<ProductServersMutation>;
  /** @deprecated Use `VerifoneTerminal.devices` instead */
  deleteDevice?: Maybe<DeleteNodePayload>;
  /** @deprecated Use `VerifoneTerminal.anis` instead */
  deleteAni?: Maybe<DeleteNodePayload>;
  delete?: Maybe<DeleteNodePayload>;
  deleteBatch?: Maybe<DeleteBatchNodePayload>;
};


export type VerifoneTerminalMutationActivateArgs = {
  input: ActivateVerifoneTerminalInput;
};


export type VerifoneTerminalMutationAddDeviceArgs = {
  input: AddVerifoneTerminalDeviceInput;
};


export type VerifoneTerminalMutationAddAniArgs = {
  input: AddVerifoneTerminalAniInput;
};


export type VerifoneTerminalMutationAddArgs = {
  input: AddVerifoneTerminalInput;
};


export type VerifoneTerminalMutationUpdateArgs = {
  input: UpdateVerifoneTerminalInput;
};


export type VerifoneTerminalMutationDeleteDeviceArgs = {
  input: DeleteVerifoneTerminalDeviceInput;
};


export type VerifoneTerminalMutationDeleteAniArgs = {
  input: DeleteVerifoneTerminalAniInput;
};


export type VerifoneTerminalMutationDeleteArgs = {
  input: DeleteVerifoneTerminalInput;
};


export type VerifoneTerminalMutationDeleteBatchArgs = {
  input: DeleteBatchVerifoneTerminalInput;
};

export type ActivateVerifoneTerminalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Terminal Number */
  terminal: Scalars['String'];
  /** Terminal Type */
  terminalType: Scalars['String'];
  /** Terminal Activation Code */
  activationCode: Scalars['String'];
  /** Verifone device SerialNumber, this serial number should be in the list of allowed serials for given terminal */
  serialNumber: Scalars['String'];
  /** Phone number used to connect the terminal when use DialUp connection. */
  ani?: Maybe<Scalars['String']>;
};

export type VerifoneActivationPayload = {
  __typename?: 'VerifoneActivationPayload';
  /** API Identifier */
  identifier: Scalars['String'];
  /** API Key */
  key: Scalars['String'];
  /** Credentials are in sandbox mode or not */
  sandbox?: Maybe<Scalars['Boolean']>;
};

export type AddVerifoneTerminalDeviceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  terminal: Scalars['ID'];
  serialNumber: Scalars['String'];
};

export type AddVerifoneTerminalDevicePayload = {
  __typename?: 'AddVerifoneTerminalDevicePayload';
  /** Added node instance */
  node?: Maybe<VerifoneTerminalDevice>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddVerifoneTerminalAniInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  terminal: Scalars['ID'];
  ani: Scalars['String'];
};

export type AddVerifoneTerminalAniPayload = {
  __typename?: 'AddVerifoneTerminalAniPayload';
  /** Added node instance */
  node?: Maybe<VerifoneTerminalAni>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddVerifoneTerminalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  merchant?: Maybe<Scalars['ID']>;
};

export type AddVerifoneTerminalPayload = {
  __typename?: 'AddVerifoneTerminalPayload';
  /** Added node instance */
  node?: Maybe<VerifoneTerminal>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateVerifoneTerminalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  enabled?: Maybe<Scalars['Boolean']>;
  sandbox?: Maybe<Scalars['Boolean']>;
  nyStateFlag?: Maybe<Scalars['Boolean']>;
  receiptHeader?: Maybe<Scalars['String']>;
  restrictAnis?: Maybe<Scalars['Boolean']>;
  restrictClerks?: Maybe<Scalars['Boolean']>;
  restrictDevices?: Maybe<Scalars['Boolean']>;
  devices?: Maybe<Array<Maybe<UpdateVerifoneTerminalDevicesInput>>>;
  anis?: Maybe<Array<Maybe<UpdateVerifoneTerminalAnisInput>>>;
};

export type UpdateVerifoneTerminalDevicesInput = {
  id?: Maybe<Scalars['ID']>;
  serialNumber: Scalars['String'];
};

export type UpdateVerifoneTerminalAnisInput = {
  id?: Maybe<Scalars['ID']>;
  ani: Scalars['String'];
};

export type UpdateVerifoneTerminalPayload = {
  __typename?: 'UpdateVerifoneTerminalPayload';
  /** Updated node instance */
  node?: Maybe<VerifoneTerminal>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ProductServersMutation = {
  __typename?: 'ProductServersMutation';
  add?: Maybe<AddProductServersPayload>;
  update?: Maybe<UpdateProductServersPayload>;
};


export type ProductServersMutationAddArgs = {
  input: AddProductServersInput;
};


export type ProductServersMutationUpdateArgs = {
  input: UpdateProductServersInput;
};

export type AddProductServersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  ftpSettings?: Maybe<AddProductServersFtpSettingsInput>;
};

export type AddProductServersFtpSettingsInput = {
  host?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type AddProductServersPayload = {
  __typename?: 'AddProductServersPayload';
  /** Added node instance */
  node?: Maybe<VerifoneTerminalProductServer>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductServersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  enabled?: Maybe<Scalars['Boolean']>;
  ftpSettings?: Maybe<UpdateProductServersFtpSettingsInput>;
};

export type UpdateProductServersFtpSettingsInput = {
  host?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type UpdateProductServersPayload = {
  __typename?: 'UpdateProductServersPayload';
  /** Updated node instance */
  node?: Maybe<VerifoneTerminalProductServer>;
  /** Unique client mutation identifier */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteVerifoneTerminalDeviceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteVerifoneTerminalAniInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteVerifoneTerminalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteBatchVerifoneTerminalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Maybe<Scalars['ID']>>;
};

export type DeleteBatchNodePayload = {
  __typename?: 'DeleteBatchNodePayload';
  /** IDs of the node deleted on success */
  ids: Array<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  transactions?: Maybe<TransactionSubscriptions>;
};

export type TransactionSubscriptions = {
  __typename?: 'TransactionSubscriptions';
  /**
   * Subscribe to a event when a transaction has been finished.
   * this event is triggered every time a transaction has been processed regardless if it's completed or failed
   */
  onFinish?: Maybe<OnFinishTransactionSubscription>;
};


export type TransactionSubscriptionsOnFinishArgs = {
  correlationId: Scalars['String'];
};

export type OnFinishTransactionSubscription = OnFinishTransactionEvent | SubscriptionLink;

export type OnFinishTransactionEvent = {
  __typename?: 'OnFinishTransactionEvent';
  /** Finished transaction */
  transaction?: Maybe<Transaction>;
};

/**
 * The subscription link have all required information to subscribe to server events.
 *
 * Can susbribe to a service using something like:
 *
 *     new EventSource(subscription.url)
 *
 * **NOTE:** A subscription have a tll (Time to live), you must call a periodic request (heartbeat) to
 * the `heartbeatUrl` in order to keep-alive the subscription. Otherwise the subscription will be not available
 * after the specified ttl.
 */
export type SubscriptionLink = {
  __typename?: 'SubscriptionLink';
  /**
   * corresponding subscription url containing a unique subscription ID. The client can
   * subscribe to the event stream corresponding to this subscription by creating a `new EventSource`.
   */
  url: Scalars['String'];
  /** Url to send periodicals requests (heartbeats) to keep-alive the subscription. */
  heartbeatUrl: Scalars['String'];
  /** Time to live(in seconds) for a subscription, must call a heartbeat to keep the subscription alive */
  ttl: Scalars['Int'];
};

export type NodeConnection = Connection & {
  __typename?: 'NodeConnection';
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  edges?: Maybe<Array<Maybe<Edge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Connection = {
  totalCount: Scalars['Int'];
  pages: Scalars['Int'];
  edges?: Maybe<Array<Maybe<Edge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Edge = {
  node: Node;
  cursor: Scalars['String'];
};

export type NodeConnectionEdge = Edge & {
  __typename?: 'NodeConnectionEdge';
  node: Node;
  cursor: Scalars['String'];
};

export type PinSaleResponse = TransactionResponse & {
  __typename?: 'PinSaleResponse';
  /** PIN retrieved */
  pin?: Maybe<Scalars['String']>;
  /**
   * Indicates if the PIN is already applied or not,
   * some products automatically apply the PIN when a `accountNumber` is provided.
   */
  pinApplied?: Maybe<Scalars['Boolean']>;
  /** API error code thrown during transaction process. */
  errorCode?: Maybe<Scalars['Int']>;
  /** Any response message that the client must know, can be a error or success response. */
  message?: Maybe<Scalars['String']>;
  /** Third party service transaction number for tracking purposes */
  providerTrackingNumber?: Maybe<Scalars['String']>;
};

export type TopUpResponse = TransactionResponse & {
  __typename?: 'TopUpResponse';
  /** API error code thrown during transaction process. */
  errorCode?: Maybe<Scalars['Int']>;
  /** Any response message that the client must know, can be a error or success response. */
  message?: Maybe<Scalars['String']>;
  /** Third party service transaction number for tracking purposes */
  providerTrackingNumber?: Maybe<Scalars['String']>;
};

export type CreditCardInstrument = PaymentInstrument & {
  __typename?: 'CreditCardInstrument';
  consumerAuthentication?: Maybe<ConsumerAuthentication>;
  name: Scalars['String'];
  number: Scalars['String'];
  type: CreditCardType;
  holder: CreditCardHolder;
};

export type ConsumerAuthentication = {
  __typename?: 'ConsumerAuthentication';
  createdAt: Scalars['DateTime'];
  status: Scalars['String'];
};

export enum CreditCardType {
  Unknown = 'UNKNOWN',
  Maestro = 'MAESTRO',
  Visa = 'VISA',
  Mastercard = 'MASTERCARD',
  Amex = 'AMEX',
  Dinersclub = 'DINERSCLUB',
  Discover = 'DISCOVER',
  Unionpay = 'UNIONPAY',
  Jcb = 'JCB'
}

export type CreditCardHolder = {
  __typename?: 'CreditCardHolder';
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type VirtualCheckingAccountInstrument = PaymentInstrument & {
  __typename?: 'VirtualCheckingAccountInstrument';
  name: Scalars['String'];
};

export type Master = MerchantAware & Node & Merchant & {
  __typename?: 'Master';
  merchant?: Maybe<Merchant>;
  id: Scalars['ID'];
  number?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  serviceProviders?: Maybe<Array<Maybe<ServiceProviderConfig>>>;
  paymentGateways?: Maybe<Array<Maybe<PaymentGatewayConfig>>>;
  apiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  billingSettings?: Maybe<MerchantBillingSettings>;
  securitySettings?: Maybe<MerchantSecuritySettings>;
  wholesalePriceList?: Maybe<WholeSalePriceList>;
  /** Merchant time-zone */
  timeZone?: Maybe<Scalars['String']>;
  paymentSettings?: Maybe<MerchantPaymentSettings>;
  serviceProvider?: Maybe<ServiceProviderConfig>;
  capabilities: Array<Maybe<MerchantCapability>>;
  allowedProviders?: Maybe<Array<Maybe<ServiceProvider>>>;
  creditBalance: Scalars['Float'];
};


export type MasterServiceProviderArgs = {
  provider: Scalars['ID'];
};

export type Agent = MerchantAware & Node & Merchant & {
  __typename?: 'Agent';
  merchant?: Maybe<Merchant>;
  id: Scalars['ID'];
  number?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  serviceProviders?: Maybe<Array<Maybe<ServiceProviderConfig>>>;
  paymentGateways?: Maybe<Array<Maybe<PaymentGatewayConfig>>>;
  apiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  /** The merchant only can view specified product categories & products inside these categories */
  restrictCategories: Scalars['Boolean'];
  /** List of restricted categories */
  restrictedCategories?: Maybe<Array<Maybe<ProductCategory>>>;
  billingSettings?: Maybe<MerchantBillingSettings>;
  securitySettings?: Maybe<MerchantSecuritySettings>;
  wholesalePriceList?: Maybe<WholeSalePriceList>;
  /** Merchant time-zone */
  timeZone?: Maybe<Scalars['String']>;
  paymentSettings?: Maybe<MerchantPaymentSettings>;
  serviceProvider?: Maybe<ServiceProviderConfig>;
  capabilities: Array<Maybe<MerchantCapability>>;
  allowedProviders?: Maybe<Array<Maybe<ServiceProvider>>>;
  creditBalance: Scalars['Float'];
};


export type AgentServiceProviderArgs = {
  provider: Scalars['ID'];
};

export type Dealer = MerchantAware & Node & Merchant & {
  __typename?: 'Dealer';
  merchant?: Maybe<Merchant>;
  id: Scalars['ID'];
  number?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  serviceProviders?: Maybe<Array<Maybe<ServiceProviderConfig>>>;
  paymentGateways?: Maybe<Array<Maybe<PaymentGatewayConfig>>>;
  apiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  /** The merchant only can view specified product categories & products inside these categories */
  restrictCategories: Scalars['Boolean'];
  /** List of restricted categories */
  restrictedCategories?: Maybe<Array<Maybe<ProductCategory>>>;
  billingSettings?: Maybe<MerchantBillingSettings>;
  securitySettings?: Maybe<MerchantSecuritySettings>;
  retailPriceList?: Maybe<RetailerPriceList>;
  wholesalePriceList?: Maybe<WholeSalePriceList>;
  /** Merchant time-zone */
  timeZone?: Maybe<Scalars['String']>;
  paymentSettings?: Maybe<MerchantPaymentSettings>;
  serviceProvider?: Maybe<ServiceProviderConfig>;
  accountType?: Maybe<MerchantAccountType>;
  /** The credit limit is only applicable for POSTPAID merchants. */
  creditLimit: Scalars['Float'];
  capabilities: Array<Maybe<MerchantCapability>>;
  allowedProviders?: Maybe<Array<Maybe<ServiceProvider>>>;
  creditBalance: Scalars['Float'];
};


export type DealerServiceProviderArgs = {
  provider: Scalars['ID'];
};

export type RetailerPriceList = Node & Timestampable & WithNumericIdentifier & MerchantAware & PriceList & {
  __typename?: 'RetailerPriceList';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  parent?: Maybe<PriceList>;
  children?: Maybe<Array<Maybe<PriceList>>>;
  name: Scalars['String'];
  items?: Maybe<PriceListItemConnection>;
  editMode: Scalars['Boolean'];
  treeLevel: Scalars['Int'];
  invalidItems?: Maybe<Scalars['Int']>;
  customProducts?: Maybe<Scalars['Int']>;
  allocated?: Maybe<Scalars['Int']>;
};


export type RetailerPriceListItemsArgs = {
  search?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<PriceListItemOrderBy>>>;
  where?: Maybe<PriceListItemCondition>;
};

export enum MerchantAccountType {
  /** This merchant use his own services and don`t need any credit from his agent. */
  Standalone = 'STANDALONE',
  /** The merchant need recharge their account in order to accept transactions. */
  Prepaid = 'PREPAID',
  /** Works like a agreement, the merchant has a credit limit and receive a bill at the end of each period based on system usage. */
  Postpaid = 'POSTPAID'
}

export type MerchantPaymentRefund = Payment & Adjustable & Node & MerchantAware & MerchantPayment & {
  __typename?: 'MerchantPaymentRefund';
  invoiceNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  paymentTransactionID?: Maybe<Scalars['String']>;
  paymentTransactionResponse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  adjustmentsTotal?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  merchant?: Maybe<Merchant>;
  accountType?: Maybe<MerchantPaymentAccountType>;
  createdAt: Scalars['DateTime'];
  sandbox: Scalars['Boolean'];
  status: PaymentStatus;
  instrument?: Maybe<PaymentInstrument>;
};

export type MerchantPaymentDeposit = Payment & Adjustable & Node & MerchantAware & MerchantPayment & {
  __typename?: 'MerchantPaymentDeposit';
  invoiceNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  paymentTransactionID?: Maybe<Scalars['String']>;
  paymentTransactionResponse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  adjustmentsTotal?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  merchant?: Maybe<Merchant>;
  accountType?: Maybe<MerchantPaymentAccountType>;
  createdAt: Scalars['DateTime'];
  sandbox: Scalars['Boolean'];
  status: PaymentStatus;
  instrument?: Maybe<PaymentInstrument>;
};

export type MerchantPaymentWithdrawal = Payment & Adjustable & Node & MerchantAware & MerchantPayment & {
  __typename?: 'MerchantPaymentWithdrawal';
  invoiceNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  paymentTransactionID?: Maybe<Scalars['String']>;
  paymentTransactionResponse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  adjustmentsTotal?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  merchant?: Maybe<Merchant>;
  accountType?: Maybe<MerchantPaymentAccountType>;
  createdAt: Scalars['DateTime'];
  sandbox: Scalars['Boolean'];
  status: PaymentStatus;
  instrument?: Maybe<PaymentInstrument>;
};

export type TopUp = Node & MerchantAware & Adjustable & Transaction & {
  __typename?: 'TopUp';
  id: Scalars['ID'];
  adjustmentsTotal?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  userSession?: Maybe<UserSession>;
  date: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sandbox: Scalars['Boolean'];
  agent?: Maybe<Merchant>;
  user?: Maybe<User>;
  merchant?: Maybe<Merchant>;
  status?: Maybe<TransactionStatus>;
  service?: Maybe<ServiceProcess>;
  product?: Maybe<Product>;
  logs?: Maybe<Array<Maybe<TransactionLog>>>;
  adjustments?: Maybe<Array<Maybe<TransactionAdjustment>>>;
  commissionBreakdown?: Maybe<CommissionBreakdown>;
  customer?: Maybe<Customer>;
  customerPayment?: Maybe<CustomerPayment>;
  number?: Maybe<Scalars['String']>;
  clientIp?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  /** Transaction date using merchant timezone instead of the server one */
  localDate?: Maybe<Scalars['DateTime']>;
  /** Transaction merchant cost */
  merchantCost?: Maybe<Scalars['Float']>;
  correlationId?: Maybe<Scalars['String']>;
  receiptEmail?: Maybe<Scalars['String']>;
  inputs?: Maybe<TransactionInputsMeta>;
  response?: Maybe<TransactionResponse>;
};

export type PinSale = Node & MerchantAware & Adjustable & Transaction & {
  __typename?: 'PinSale';
  id: Scalars['ID'];
  adjustmentsTotal?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  userSession?: Maybe<UserSession>;
  date: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sandbox: Scalars['Boolean'];
  agent?: Maybe<Merchant>;
  user?: Maybe<User>;
  merchant?: Maybe<Merchant>;
  status?: Maybe<TransactionStatus>;
  service?: Maybe<ServiceProcess>;
  product?: Maybe<Product>;
  logs?: Maybe<Array<Maybe<TransactionLog>>>;
  adjustments?: Maybe<Array<Maybe<TransactionAdjustment>>>;
  commissionBreakdown?: Maybe<CommissionBreakdown>;
  customer?: Maybe<Customer>;
  customerPayment?: Maybe<CustomerPayment>;
  number?: Maybe<Scalars['String']>;
  clientIp?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  /** Transaction date using merchant timezone instead of the server one */
  localDate?: Maybe<Scalars['DateTime']>;
  /** Transaction merchant cost */
  merchantCost?: Maybe<Scalars['Float']>;
  correlationId?: Maybe<Scalars['String']>;
  receiptEmail?: Maybe<Scalars['String']>;
  inputs?: Maybe<TransactionInputsMeta>;
  response?: Maybe<TransactionResponse>;
};

export type TrustRuleWhereExpression = Node & TrustRuleWhere & {
  __typename?: 'TrustRuleWhereExpression';
  id: Scalars['ID'];
  field?: Maybe<Scalars['String']>;
  comparison?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TrustRuleWhereOr = Node & TrustRuleWhere & {
  __typename?: 'TrustRuleWhereOr';
  id: Scalars['ID'];
  subConditions?: Maybe<Array<Maybe<TrustRuleWhere>>>;
};

export type TrustRuleWhereAnd = Node & TrustRuleWhere & {
  __typename?: 'TrustRuleWhereAnd';
  id: Scalars['ID'];
  subConditions?: Maybe<Array<Maybe<TrustRuleWhere>>>;
};

export type Administrator = Node & MerchantAware & User & {
  __typename?: 'Administrator';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  email: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  number: Scalars['Int'];
  login: Scalars['String'];
  name: Scalars['String'];
  /** If current credentials are using sandbox mode or not. */
  sandbox?: Maybe<Scalars['Boolean']>;
};

export type Manager = Node & MerchantAware & User & {
  __typename?: 'Manager';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  email: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  number: Scalars['Int'];
  login: Scalars['String'];
  name: Scalars['String'];
  /** If current credentials are using sandbox mode or not. */
  sandbox?: Maybe<Scalars['Boolean']>;
};

export type Clerk = Node & MerchantAware & User & {
  __typename?: 'Clerk';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  email: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  number: Scalars['Int'];
  login: Scalars['String'];
  name: Scalars['String'];
  /** If current credentials are using sandbox mode or not. */
  sandbox?: Maybe<Scalars['Boolean']>;
};

export type Representative = Node & MerchantAware & User & {
  __typename?: 'Representative';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  email: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  number: Scalars['Int'];
  login: Scalars['String'];
  name: Scalars['String'];
  /** If current credentials are using sandbox mode or not. */
  sandbox?: Maybe<Scalars['Boolean']>;
};

export type Staff = Node & MerchantAware & User & {
  __typename?: 'Staff';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  email: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  number: Scalars['Int'];
  login: Scalars['String'];
  name: Scalars['String'];
  /** If current credentials are using sandbox mode or not. */
  sandbox?: Maybe<Scalars['Boolean']>;
};

export type ApiId = Node & MerchantAware & User & {
  __typename?: 'ApiID';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  email: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  apiKey?: Maybe<ApiKey>;
  number: Scalars['Int'];
  login: Scalars['String'];
  name: Scalars['String'];
  /** If current credentials are using sandbox mode or not. */
  sandbox?: Maybe<Scalars['Boolean']>;
};

export type VerifonePos = Node & MerchantAware & User & {
  __typename?: 'VerifonePOS';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  email: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  number: Scalars['Int'];
  login: Scalars['String'];
  name: Scalars['String'];
  /** If current credentials are using sandbox mode or not. */
  sandbox?: Maybe<Scalars['Boolean']>;
  terminal?: Maybe<CurrentVerifoneTerminal>;
};

export type CurrentVerifoneTerminal = {
  __typename?: 'CurrentVerifoneTerminal';
  number: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  creditBalance?: Maybe<Scalars['Float']>;
};

/** A generic category can contains child categories or products of any type */
export type GenericCategory = Node & ProductCategory & {
  __typename?: 'GenericCategory';
  id: Scalars['ID'];
  parent?: Maybe<ProductCategory>;
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  sku?: Maybe<Scalars['String']>;
  canonicalName: Scalars['String'];
  bgColor?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<GenericCategory>>>;
  private?: Maybe<Scalars['Boolean']>;
};

/** This category refer to wireless products. Only contains subcategories of type `WirelessCarrier` */
export type WirelessCategory = Node & ProductCategory & {
  __typename?: 'WirelessCategory';
  id: Scalars['ID'];
  parent?: Maybe<ProductCategory>;
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  sku?: Maybe<Scalars['String']>;
  canonicalName: Scalars['String'];
  bgColor?: Maybe<Scalars['String']>;
  /**
   * Indicate if the UI should separate foreign and local carriers.
   *     When is true must filter carriers by country to display only local carriers in this category and
   *     create another category using `foreignCategoryName` to display foreign carriers.
   *     This is very useful to separate local carriers from foreigners according to the country of the client or the store.
   */
  separateForeignCarriers: Scalars['Boolean'];
  /** Name of the category to display carriers when the country does not match with customer or merchant country */
  foreignCategoryName?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
};

/** Is used as subcategory to store different type of products */
export type GenericCarrier = Node & ProductCarrier & ProductCategory & {
  __typename?: 'GenericCarrier';
  id: Scalars['ID'];
  country: CountryCode;
  parent?: Maybe<ProductCategory>;
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  sku: Scalars['String'];
  canonicalName: Scalars['String'];
  bgColor?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
};

/** Represent a wireless carrier like AT&T, T-Mobile, Sprint etc. Only contains products of type `PrepaidPlan` */
export type WirelessCarrier = Node & ProductCarrier & ProductCategory & {
  __typename?: 'WirelessCarrier';
  id: Scalars['ID'];
  country: CountryCode;
  parent?: Maybe<ProductCategory>;
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  sku: Scalars['String'];
  canonicalName: Scalars['String'];
  bgColor?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
};

/** Company issuing a gift card, like eBay, Amazon, etc. */
export type GiftCardIssuer = Node & ProductCarrier & ProductCategory & {
  __typename?: 'GiftCardIssuer';
  id: Scalars['ID'];
  country: CountryCode;
  parent?: Maybe<ProductCategory>;
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  sku: Scalars['String'];
  canonicalName: Scalars['String'];
  bgColor?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
};

export type RetailFee = Node & MerchantAware & MerchantFee & {
  __typename?: 'RetailFee';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  /** Priority to apply the fee, fees with highest priority must be applied firstly */
  priority?: Maybe<Scalars['Int']>;
  /** Name or concept of the fee */
  name: Scalars['String'];
  /** Amount to apply or rate */
  amount: Scalars['Float'];
  /** How the fee must be calculated */
  mode?: Maybe<FeeMode>;
  /** Conditions to apply the fee only for certain scenarios or payments types, fees without conditions must be applied always. */
  apply?: Maybe<Array<Maybe<MerchantFeeApplicableCondition>>>;
};

export type MerchantFee = {
  /** Priority to apply the fee, fees with highest priority must be applied firstly */
  priority?: Maybe<Scalars['Int']>;
  /** Name or concept of the fee */
  name: Scalars['String'];
  /** Amount to apply or rate */
  amount: Scalars['Float'];
  /** How the fee must be calculated */
  mode?: Maybe<FeeMode>;
  /** Conditions to apply the fee only for certain scenarios or payments types, fees without conditions must be applied always. */
  apply?: Maybe<Array<Maybe<MerchantFeeApplicableCondition>>>;
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
};

export enum MerchantFeeApplicableCondition {
  /** This fee must be applied for credit card payments with card present. */
  CcPaymentCardPresent = 'CC_PAYMENT_CARD_PRESENT',
  /** This fee will be applied for credit card payments with card **NOT** present. */
  CcPaymentCardNotPresent = 'CC_PAYMENT_CARD_NOT_PRESENT',
  /** Apply this fee when need collect a fee for concept of service processing, helpful to apply Transactions fees for merchants. */
  ServiceProcessing = 'SERVICE_PROCESSING',
  /** Apply this fee for Virtual Checking Account payments. */
  VirtualCheckingAccountProcessing = 'VIRTUAL_CHECKING_ACCOUNT_PROCESSING'
}

export type WholesaleFee = Node & MerchantAware & MerchantFee & {
  __typename?: 'WholesaleFee';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  /** Priority to apply the fee, fees with highest priority must be applied firstly */
  priority?: Maybe<Scalars['Int']>;
  /** Name or concept of the fee */
  name: Scalars['String'];
  /** Amount to apply or rate */
  amount: Scalars['Float'];
  /** How the fee must be calculated */
  mode?: Maybe<FeeMode>;
  /** Conditions to apply the fee only for certain scenarios or payments types, fees without conditions must be applied always. */
  apply?: Maybe<Array<Maybe<MerchantFeeApplicableCondition>>>;
};

export type PrepaidPlan = Node & ProductWithCarrier & ProductWithServiceProvider & Product & {
  __typename?: 'PrepaidPlan';
  id: Scalars['ID'];
  serviceProvider?: Maybe<ServiceProvider>;
  serviceSku?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  category?: Maybe<ProductCategory>;
  price: Scalars['Float'];
  /** @deprecated [Since 02/2019] Use fees instead to get the list of product fees */
  fee: Scalars['Float'];
  /** @deprecated [Since 02/2019]  Use fees instead to get the list of product fees */
  feeMode?: Maybe<FeeMode>;
  variablePrice: Scalars['Boolean'];
  minPrice: Scalars['Float'];
  maxPrice: Scalars['Float'];
  details?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  canonicalName: Scalars['String'];
  carrier?: Maybe<ProductCarrier>;
  prepaidProduct: Scalars['Boolean'];
  /** @deprecated Use `prepaidType` instead */
  planType?: Maybe<PrepaidPlanType>;
  prepaidType?: Maybe<PrepaidProductType>;
  /**
   * All required inputs to purchase a product.
   * Commonly these fields are used to build the html form after the user select desired product.
   */
  inputs?: Maybe<Array<Maybe<InputField>>>;
  discountMode: AmountMode;
  enabled?: Maybe<Scalars['Boolean']>;
  fees?: Maybe<Array<Maybe<ProductFee>>>;
  discount: Scalars['Float'];
};

export enum PrepaidPlanType {
  /** PIN based product */
  Pin = 'PIN',
  /** Real-Time-Replenishment */
  Rtr = 'RTR'
}

/** This type of products are used to dispense cash to customers using solutions like Spare(gotspare.com) */
export type AtmProduct = Node & ProductWithCarrier & ProductWithServiceProvider & Product & {
  __typename?: 'ATMProduct';
  id: Scalars['ID'];
  serviceProvider?: Maybe<ServiceProvider>;
  serviceSku?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  category?: Maybe<ProductCategory>;
  price: Scalars['Float'];
  /** @deprecated [Since 02/2019] Use fees instead to get the list of product fees */
  fee: Scalars['Float'];
  /** @deprecated [Since 02/2019]  Use fees instead to get the list of product fees */
  feeMode?: Maybe<FeeMode>;
  variablePrice: Scalars['Boolean'];
  minPrice: Scalars['Float'];
  maxPrice: Scalars['Float'];
  details?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  canonicalName: Scalars['String'];
  carrier?: Maybe<ProductCarrier>;
  prepaidProduct: Scalars['Boolean'];
  prepaidType?: Maybe<PrepaidProductType>;
  /**
   * All required inputs to purchase a product.
   * Commonly these fields are used to build the html form after the user select desired product.
   */
  inputs?: Maybe<Array<Maybe<InputField>>>;
  discountMode: AmountMode;
  enabled?: Maybe<Scalars['Boolean']>;
  fees?: Maybe<Array<Maybe<ProductFee>>>;
  discount: Scalars['Float'];
};

export type GiftCard = Node & ProductWithCarrier & ProductWithServiceProvider & Product & {
  __typename?: 'GiftCard';
  id: Scalars['ID'];
  serviceProvider?: Maybe<ServiceProvider>;
  serviceSku?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  category?: Maybe<ProductCategory>;
  price: Scalars['Float'];
  /** @deprecated [Since 02/2019] Use fees instead to get the list of product fees */
  fee: Scalars['Float'];
  /** @deprecated [Since 02/2019]  Use fees instead to get the list of product fees */
  feeMode?: Maybe<FeeMode>;
  variablePrice: Scalars['Boolean'];
  minPrice: Scalars['Float'];
  maxPrice: Scalars['Float'];
  details?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  canonicalName: Scalars['String'];
  carrier?: Maybe<ProductCarrier>;
  prepaidProduct: Scalars['Boolean'];
  prepaidType?: Maybe<PrepaidProductType>;
  /**
   * All required inputs to purchase a product.
   * Commonly these fields are used to build the html form after the user select desired product.
   */
  inputs?: Maybe<Array<Maybe<InputField>>>;
  discountMode: AmountMode;
  enabled?: Maybe<Scalars['Boolean']>;
  fees?: Maybe<Array<Maybe<ProductFee>>>;
  discount: Scalars['Float'];
};

export type WholeSalePriceListItem = Node & PriceListItem & {
  __typename?: 'WholeSalePriceListItem';
  id: Scalars['ID'];
  list?: Maybe<PriceList>;
  enabled?: Maybe<Scalars['Boolean']>;
  product?: Maybe<Product>;
  price: Scalars['Float'];
  discount: Scalars['Float'];
  discountMode: Scalars['String'];
  fee: Scalars['Float'];
  feeMode: Scalars['String'];
  /** This item supports automatically sync with provider */
  supportAutoSync: Scalars['Boolean'];
  /** This item is automatically synced with provider */
  autoSync: Scalars['Boolean'];
  commission?: Maybe<Scalars['Float']>;
  agentCommission?: Maybe<Scalars['Float']>;
  saleCommission?: Maybe<Scalars['Float']>;
  invalidSalePrice?: Maybe<Scalars['Boolean']>;
  invalidSaleDiscount?: Maybe<Scalars['Boolean']>;
  invalidSaleFee?: Maybe<Scalars['Boolean']>;
  shouldBeComputed?: Maybe<Scalars['Boolean']>;
};

export type RetailerPriceListItem = Node & PriceListItem & {
  __typename?: 'RetailerPriceListItem';
  id: Scalars['ID'];
  list?: Maybe<PriceList>;
  enabled?: Maybe<Scalars['Boolean']>;
  product?: Maybe<Product>;
  baseItem?: Maybe<PriceListItem>;
  price: Scalars['Float'];
  discount: Scalars['Float'];
  discountMode: Scalars['String'];
  fee: Scalars['Float'];
  feeMode: Scalars['String'];
  customSalePrice: Scalars['Boolean'];
  salePrice: Scalars['Float'];
  customSaleDiscount: Scalars['Boolean'];
  saleDiscount: Scalars['Float'];
  saleDiscountMode: Scalars['String'];
  customSaleFee: Scalars['Boolean'];
  saleFee: Scalars['Float'];
  saleFeeMode: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  agentCommission?: Maybe<Scalars['Float']>;
  saleCommission?: Maybe<Scalars['Float']>;
  invalidSalePrice?: Maybe<Scalars['Boolean']>;
  invalidSaleDiscount?: Maybe<Scalars['Boolean']>;
  invalidSaleFee?: Maybe<Scalars['Boolean']>;
  shouldBeComputed?: Maybe<Scalars['Boolean']>;
};

export type Invoice = MerchantAware & Node & {
  __typename?: 'Invoice';
  merchant?: Maybe<Merchant>;
  id: Scalars['ID'];
};


export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "AchPayment",
      "ServiceProviderConfig",
      "ServiceProvider",
      "ServiceProviderCredential",
      "ProductFee",
      "PaymentGateway",
      "ConsumerAuthenticationProvider",
      "ApiKey",
      "WholeSalePriceList",
      "AchPaymentLine",
      "CronJob",
      "CustomerCredit",
      "CustomerCreditSettlementBatch",
      "Customer",
      "E911UserFee",
      "ExternalServiceRequest",
      "Pin",
      "ServiceProcess",
      "MerchantPaymentPurchase",
      "InvoiceItem",
      "TransactionLog",
      "TransactionAdjustment",
      "SalesRepresentative",
      "CustomerPayment",
      "UserSession",
      "UserSessionDump",
      "Plugin",
      "VirtualProduct",
      "TrustRuleProcessor",
      "TrustRule",
      "TrustRuleIf",
      "UserActivity",
      "VerifoneTerminal",
      "VerifoneTerminalDevice",
      "VerifoneTerminalAni",
      "VerifoneTerminalProductServer",
      "Master",
      "Agent",
      "Dealer",
      "RetailerPriceList",
      "MerchantPaymentRefund",
      "MerchantPaymentDeposit",
      "MerchantPaymentWithdrawal",
      "TopUp",
      "PinSale",
      "TrustRuleWhereExpression",
      "TrustRuleWhereOr",
      "TrustRuleWhereAnd",
      "Administrator",
      "Manager",
      "Clerk",
      "Representative",
      "Staff",
      "ApiID",
      "VerifonePOS",
      "GenericCategory",
      "WirelessCategory",
      "GenericCarrier",
      "WirelessCarrier",
      "GiftCardIssuer",
      "RetailFee",
      "WholesaleFee",
      "PrepaidPlan",
      "ATMProduct",
      "GiftCard",
      "WholeSalePriceListItem",
      "RetailerPriceListItem",
      "Invoice"
    ],
    "MerchantAware": [
      "AchPayment",
      "PaymentGatewayConfig",
      "ApiKey",
      "WholeSalePriceList",
      "AchPaymentLine",
      "CustomerCredit",
      "CustomerCreditSettlementBatch",
      "Customer",
      "Pin",
      "MerchantPaymentPurchase",
      "SalesRepresentative",
      "CustomerPayment",
      "TrustRule",
      "VerifoneTerminal",
      "VerifoneTerminalProductServer",
      "Master",
      "Agent",
      "Dealer",
      "RetailerPriceList",
      "MerchantPaymentRefund",
      "MerchantPaymentDeposit",
      "MerchantPaymentWithdrawal",
      "TopUp",
      "PinSale",
      "Administrator",
      "Manager",
      "Clerk",
      "Representative",
      "Staff",
      "ApiID",
      "VerifonePOS",
      "RetailFee",
      "WholesaleFee",
      "Invoice"
    ],
    "Merchant": [
      "Master",
      "Agent",
      "Dealer"
    ],
    "PriceList": [
      "WholeSalePriceList",
      "RetailerPriceList"
    ],
    "PriceListItem": [
      "WholeSalePriceListItem",
      "RetailerPriceListItem"
    ],
    "Product": [
      "VirtualProduct",
      "PrepaidPlan",
      "ATMProduct",
      "GiftCard"
    ],
    "ProductCategory": [
      "GenericCategory",
      "WirelessCategory",
      "GenericCarrier",
      "WirelessCarrier",
      "GiftCardIssuer"
    ],
    "ProductCarrier": [
      "GenericCarrier",
      "WirelessCarrier",
      "GiftCardIssuer"
    ],
    "Timestampable": [
      "WholeSalePriceList",
      "RetailerPriceList"
    ],
    "WithNumericIdentifier": [
      "WholeSalePriceList",
      "RetailerPriceList"
    ],
    "User": [
      "Customer",
      "Administrator",
      "Manager",
      "Clerk",
      "Representative",
      "Staff",
      "ApiID",
      "VerifonePOS"
    ],
    "MerchantPayment": [
      "MerchantPaymentPurchase",
      "MerchantPaymentRefund",
      "MerchantPaymentDeposit",
      "MerchantPaymentWithdrawal"
    ],
    "PaymentInstrument": [
      "CreditCardInstrument",
      "VirtualCheckingAccountInstrument"
    ],
    "Transaction": [
      "TopUp",
      "PinSale"
    ],
    "Payment": [
      "MerchantPaymentPurchase",
      "CustomerPayment",
      "MerchantPaymentRefund",
      "MerchantPaymentDeposit",
      "MerchantPaymentWithdrawal"
    ],
    "Adjustable": [
      "MerchantPaymentPurchase",
      "CustomerPayment",
      "MerchantPaymentRefund",
      "MerchantPaymentDeposit",
      "MerchantPaymentWithdrawal",
      "TopUp",
      "PinSale"
    ],
    "Adjustment": [
      "TransactionAdjustment",
      "CustomerPaymentAdjustment"
    ],
    "TransactionResponse": [
      "PinSaleResponse",
      "TopUpResponse"
    ],
    "ProductWithCarrier": [
      "VirtualProduct",
      "PrepaidPlan",
      "ATMProduct",
      "GiftCard"
    ],
    "ProductWithServiceProvider": [
      "VirtualProduct",
      "PrepaidPlan",
      "ATMProduct",
      "GiftCard"
    ],
    "TrustRuleWhere": [
      "TrustRuleWhereExpression",
      "TrustRuleWhereOr",
      "TrustRuleWhereAnd"
    ],
    "OnFinishTransactionSubscription": [
      "OnFinishTransactionEvent",
      "SubscriptionLink"
    ],
    "Connection": [
      "NodeConnection"
    ],
    "Edge": [
      "NodeConnectionEdge"
    ],
    "MerchantFee": [
      "RetailFee",
      "WholesaleFee"
    ]
  }
};
export default result;
