// import { logger } from 'common/lib/logger';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'setFingerPrint';

interface Data extends Action {
    fingerPrint: string
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        fingerPrint: data.fingerPrint,
    };
}

export const setFingerPrint = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
