import { md5 } from '../../../../lib/string.util';
import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { Notification, State } from '../state';

const NAME = 'enqueueNotification';

interface Data extends Action, Notification {

}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    const key = md5(data);

    return {
        ...state,
        notifications: {
            ...state.notifications,
            [key]: data,
        },
    };
}

export const enqueueNotification = (context: ContextType, data: Omit<Data, 'type'>) => {
    context.dispatch({ type: NAME, ...data });

    return md5(data);
};
