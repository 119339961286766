import React, { useState } from "react";
import { View, Image, StyleSheet, TouchableOpacity } from "react-native";
import { useMediaQuery } from "react-responsive";
import {
  Avatar,
  Card,
  IconButton,
  Text,
  Title,
  useTheme,
} from "react-native-paper";
import Autocomplete from "../AutoComplete/AutoComplete";
import LoadingComponent from "../Loading/Loading";
import { getPlatform, IS_DESKTOP_OR_LAPTOP, IS_SMARTPHONE } from "../../lib/PlatformDevice";

interface Props {
  setSku: (sku: string) => void;
  setSearchingProduct: (s: boolean) => void;
  setProducts: (arr: []) => void;
  setQuickSaleError: (e: string) => void;
  searchProducts: (c: string) => void;
  quickSale: (sku: string) => void;
  setSwiping: (s: boolean) => void;
  products: any[];
  searchingProduct: boolean;
  quickSaleError: string;
  searchingSku: boolean;
  loadingPaymentLink: boolean;
  sku: string;
}

const QuickSalesMerchant = ({
  setSku,
  setSearchingProduct,
  setProducts,
  setQuickSaleError,
  searchProducts,
  quickSale,
  setSwiping,
  products,
  quickSaleError,
  searchingProduct,
  loadingPaymentLink,
  searchingSku,
  sku,
}: Props) => {
  const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
  const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
  const [clearSelected, setClearSelected] = useState(false);
  const { colors } = useTheme();
  const text = !isSmartPhone
    ? `Swipe / Scan Gift Card or enter product name`
    : `Enter product name`;

  const onChangeText = (text: string) => {
    if (text) {
      setSku(text);
      setQuickSaleError("");
      searchProducts(text);
    } else {
      setClearSelected((prevData) => !prevData);
      setSearchingProduct(false);
      setSku("");
      setProducts([]);
    }
  };

  const inputRightProps = (
    <>
      {searchingSku || loadingPaymentLink ? (
        <LoadingComponent size="small" />
      ) : null}
      {!searchingSku && !loadingPaymentLink && sku.length > 0 ? (
        <IconButton
          icon="close"
          color={colors.onSurface}
          onPress={() => {
            setSku("");
            setClearSelected((prevData) => !prevData);
            setSearchingProduct(false);
            setProducts([]);
          }}
        />
      ) : null}
    </>
  );

  return (
    <Card style={{ backgroundColor: colors.primary }} elevation={4}>
      <Card.Content>
        <View style={styles.container}>
          <Image
            style={{
              resizeMode: "stretch",
              width: !isSmartPhone ? 140 : 80,
              height: !isSmartPhone ? 140 : 80,
            }}
            source={require("../../../../assets/img/cart.png")}
          />
          <View
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              paddingLeft: !isSmartPhone ? 20 : 10,
              flex: 1,
            }}
          >
            <Title
              numberOfLines={3}
              style={{
                color: colors.surface,
                fontSize: 25,
                fontFamily: "Montserrat_Semi_Bold",
              }}
            >
              QUICK SALE
            </Title>
            <Text
              style={{
                color: colors.surface,
                fontFamily: "Montserrat_Regular",
              }}
              numberOfLines={3}
            >
              {text}
            </Text>
            {/* Web */}
            {!isSmartPhone && (
              <View style={styles.containerAutoCompleteWeb}>
                <View style={styles.autoCompleteWeb}>
                  <Autocomplete
                    array={products}
                    field="name"
                    value={(val) => quickSale(val.sku)}
                    clearSelected={clearSelected}
                    clearArray={() => setProducts([])}
                    loading={searchingProduct}
                    error={!!quickSaleError}
                    errorMessage={quickSaleError}
                    onChangeText={onChangeText}
                    inputRightProps={inputRightProps}
                  />
                </View>
                <TouchableOpacity onPress={() => setSwiping(true)}>
                  <Avatar.Image
                    style={{ backgroundColor: "transparent" }}
                    source={require("../../../../assets/img/icon_qr.png")}
                    size={100}
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>

        {/* Smartphone */}
        {isSmartPhone && (
          <View
            style={[
              styles.autoCompleteMovil,
              { paddingLeft: isDesktopOrLaptop ? 120 : undefined },
            ]}
          >
            <Autocomplete
              array={products}
              field="name"
              value={(val) => quickSale(val.sku)}
              clearSelected={clearSelected}
              clearArray={() => setProducts([])}
              loading={searchingProduct}
              error={!!quickSaleError}
              errorMessage={quickSaleError}
              onChangeText={onChangeText}
              inputRightProps={inputRightProps}
            />
          </View>
        )}
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  containerAutoCompleteWeb: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  autoCompleteWeb: {
    flex: 1,
    paddingTop: 10,
    marginBottom: 10,
    position: "relative",
    zIndex: 99999999,
  },
  autoCompleteMovil: {
    flex: 1,
    paddingTop: 20,
    marginBottom: 10,
    position: "relative",
    zIndex: 99999999,
    elevation: getPlatform() === "android" ? 99999999 : 0,
  },
});

export default QuickSalesMerchant;
