export enum CreditCardType {
    UNKNOWN = 'UNKNOWN',
    MAESTRO = 'MAESTRO',
    VISA = 'VISA',
    MASTERCARD = 'MASTERCARD',
    AMEX = 'AMEX',
    DINERSCLUB = 'DINERSCLUB',
    DISCOVER = 'DISCOVER',
    UNIONPAY = 'UNIONPAY',
    JCB = 'JCB',
}
