import _ from 'lodash';
import _md5 from 'md5';

/**
 * Humanize a string to convert to human readable version
 */
export const humanize = (str: string) => _.startCase(_.capitalize(_.trim(_.snakeCase(str).replace(/_/g, ' '))));

/**
 * Generate a Unique identifier
 *
 * @return {string}
 */
export function uniqueId() {
    return Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);
}

export function md5(data: any) {
    return _md5(data);
}

/**
 * Truncate string in the middle, example abcdefgh -> abc...fgh
 *
 * @param fullStr
 * @param strLen
 * @param separator
 */
export function truncateInTheMiddle(fullStr: string, strLen: number, separator = '...') {
    if (fullStr.length <= strLen) return fullStr;

    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars);
}
