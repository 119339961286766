import React from 'react'
import { StackedAreaChart } from 'react-native-svg-charts'
import * as shape from 'd3-shape'

interface ChartProps {
    data: Array<{ [key: string]: number }>,
    colors: Array<String>,
    keys: Array<String>,
}

/**
 * @function Component StackedArea
 * @param {Array} data Data
 * @param {Array} colors Colors
 * @param {Array} keys Key property
 */
export const StackedArea = ({ data, colors, keys }: ChartProps) => {
    const svgs = [
        { onPress: () => { } },
    ]

    return (
        <StackedAreaChart
            style={{ height: 200, paddingVertical: 16 }}
            data={data}
            keys={keys}
            colors={colors}
            curve={shape.curveNatural}
            showGrid={true}
            svgs={svgs}
        >
        </StackedAreaChart>
    )
}

