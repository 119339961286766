import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { View, StyleSheet, Image, LogBox, Platform } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import {
  Card,
  Text,
  TextInput,
  Button,
  RadioButton,
  useTheme,
  Title,
  Portal,
  TouchableRipple,
} from "react-native-paper";
import { useMediaQuery } from "react-responsive";
import * as yup from "yup";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import parsePhoneNumberFromString, {
  CountryCallingCode,
  CountryCode,
  isPossibleNumber,
} from "libphonenumber-js";
import { InputHelperError } from "../../components/CustomInputs/InputHelperText";
import Hyperlink from "../../components/HiperLink/HyperLink";
import { useSignUp } from "./hook/useSignUp";
import {
  CustomerAccount,
  customerExample,
  ProtocolTypes,
} from "./reducer/actions/actionTypes";
import DismissKeyboard from "../../components/DismissKeyboard/DismisKeyboard";
import { useNavigation } from "@react-navigation/native";
import {
  GooglePlaceData,
  GooglePlaceDetail,
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
} from "react-native-google-places-autocomplete";
import useKeyboardHeight from "react-native-use-keyboard-height";
import { CustomInput } from "../../components/CustomInputs/CustomInputAdornment";
import CountryPicker, { Country } from "react-native-country-picker-modal";
import Flag from "react-native-flags";
import DropDownComponent from "../../../common_modules/components/DropDown/DropDown";
import { idleData } from "../../../screens/Settings/Settings";
import { COUNTRY_LIST2 } from "../../lib/constants";
import { isIosPlatform, IS_DESKTOP_OR_LAPTOP } from "../../lib/PlatformDevice";

const SignUp = () => {
  LogBox.ignoreLogs([
    "VirtualizedLists should never be nested", // TODO: Remove when fixed
  ]);
  const {
    getTwoFactorAuth,
    dispatch,
    registerCustomerAccount,
    done,
    requestingTwoFactorAuth,
    requestingPing,
    inProcess,
    processCompleted,
    processWithError,
  } = useSignUp();

  const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
  const [showPassword, setShowPassword] = useState(true);
  const [protocol, setProtocol] = useState<ProtocolTypes>("SMS");
  const { colors } = useTheme();
  const navigation = useNavigation();
  const [autoCompletHeight, setAutoCompletHeight] = useState(0);
  const [countryCode, setCountryCode] = useState("");
  const inputGoogleRef = useRef<GooglePlacesAutocompleteRef>();
  const keyboardHeight = useKeyboardHeight();

  let schemaFields: { [name: string]: yup.SchemaOf<any> } = {};
  schemaFields.fullName = yup.string().required().ensure().label("Full Name");
  schemaFields.email = yup.string().required().ensure().email().label("Email");
  schemaFields.phone = yup
    .string()
    .test("isValidPhone", "Please enter valid phone number", (value) =>
      isPossibleNumber(value)
    );
  schemaFields.phone = yup
    .string() /* .required() */
    .ensure()
    .label("Phone Number");
  schemaFields.billingAddress1 = yup
    .string()
    .required()
    .label("Billing Address");
  schemaFields.billingAddress2 = yup
    .string()
    .ensure()
    .label("Billing Address 2");
  schemaFields.billingCountryCode = yup.string().required().label("Country");
  schemaFields.billingCity = yup.string().required().label("City");
  schemaFields.billingState = yup.string().required().label("State");
  schemaFields.billingZipCode = yup.string().required().label("Zip Code");
  schemaFields.password = yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    );
  schemaFields.repeatPassword = yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .label("Repeat Password");
  const schema = yup.object(schemaFields);

  const { control, handleSubmit, errors, setError, getValues, setValue } =
    useForm({
      mode: "all",
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    if (done) navigation.navigate("Login");
    dispatch({ type: "RESET_OPERATION" });
  }, [done]);

  const handleSubmitInputs = async (c: CustomerAccount) => {
    const customer = {
      ..._.omit(c, "repeatPassword"),
      billingCountryCode: countryCode,
    };
    const target = protocol === "SMS" ? customer?.phone : customer?.email;
    dispatch({
      type: "REQUEST_TWO_FACTOR_AUTH",
      customer,
      operationType: "REGISTER",
    });
    await getTwoFactorAuth(protocol, target);
  };

  const parserAddress = (
    data: GooglePlaceData,
    details: GooglePlaceDetail | null
  ) => {
    if (data) {
      setValue("billingAddress1", data.structured_formatting.main_text);
    }
    if (details) {
      details.address_components.map((component) => {
        if (_.includes(component.types, "postal_code")) {
          setValue("billingZipCode", component.short_name);
        }
        if (_.includes(component.types, "administrative_area_level_1")) {
          setValue("billingState", component.long_name);
        }
        if (_.includes(component.types, "locality")) {
          setValue("billingCity", component.short_name);
        }
        if (_.includes(component.types, "country")) {
          setCountryCode(component.short_name);
          setValue("billingCountryCode", component.short_name);
        }
      });
    }
  };

  const [phoneCountryCode, setPhoneCountryCode] = useState<{
    iso: CountryCode;
    code: CountryCallingCode;
  }>({ iso: "US", code: "1" });

  const onSelectCountry = (country: Country) => {
    setPhoneCountryCode({
      iso: country.cca2,
      code: country.callingCode,
    });
  };

  const parserPhoneString = (phone) => {
    const phoneParser = parsePhoneNumberFromString(
      phone,
      phoneCountryCode?.iso
    );
    if (phoneParser) {
      return countryCode === "CU"
        ? phoneParser.formatNational().replace(/^0/, "")
        : phoneParser.formatNational();
    } else return phone;
  };

  const suggestionBar =
    Platform.OS === "ios" ? { zIndex: 99, elevation: 99 } : {};

  return (
    <View style={styles.container}>
      <Card style={{ borderRadius: 0 }}>
        <Card.Content>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
              marginBottom: Platform.OS === "ios" ? keyboardHeight : undefined,
            }}
            contentContainerStyle={{ padding: 10 }}
            keyboardShouldPersistTaps="handled"
          >
            <View style={{ alignItems: "center", marginTop: 10 }}>
              <Image
                style={styles.tinyLogo}
                source={require("../../../../assets/img/logo.png")}
              />
            </View>

            {/* *** Personal Information *** */}

            <Title style={{ marginTop: 5 }}>Personal Information:</Title>
            <View nativeID="disable-outline">
              {/* Full Name */}
              <Controller
                control={control}
                name="fullName"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <>
                    <TextInput
                      autoCompleteType="off"
                      label="Full Name"
                      mode="outlined"
                      multiline={false}
                      numberOfLines={1}
                      style={{ marginTop: 15, textAlign: "auto" }}
                      value={value}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      error={!!errors.name}
                      left={<TextInput.Icon name="account-circle" size={28} />}
                    />
                    <InputHelperError
                      isError={!!errors.fullName}
                      message={errors.fullName?.message}
                    />
                  </>
                )}
              />

              {/* Email */}
              <Controller
                control={control}
                name="email"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <>
                    <TextInput
                      autoCompleteType="off"
                      mode="outlined"
                      multiline={false}
                      numberOfLines={1}
                      label="Email"
                      value={value}
                      style={{ marginTop: 15, textAlign: "auto" }}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      error={!!errors.email}
                      left={<TextInput.Icon name="email" size={28} />}
                    />
                    <InputHelperError
                      isError={!!errors.email}
                      message={errors.email?.message}
                    />
                  </>
                )}
              />

              {/* Phone Number */}
              <Controller
                control={control}
                name="phone"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <>
                    <CustomInput
                      autoCompleteType="off"
                      // label="Phone"
                      placeholder="Phone Number"
                      style={{ marginTop: 15, textAlign: "auto" }}
                      mode={"outlined"}
                      error={!!errors.phone}
                      value={parserPhoneString(value)}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      numberOfLines={1}
                      multiline={false}
                      keyboardType="phone-pad"
                      left={
                        <CountryPicker
                          translation="common"
                          withCallingCode
                          withFlagButton
                          withFilter
                          onSelect={onSelectCountry}
                          renderFlagButton={({ onOpen }) => {
                            return (
                              <TouchableRipple
                                onPress={onOpen}
                                centered
                                style={{ height: 32 }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Flag
                                    code={phoneCountryCode?.iso}
                                    size={32}
                                  />
                                  <Text style={{ marginLeft: 8 }}>
                                    +{phoneCountryCode?.code}
                                  </Text>
                                </View>
                              </TouchableRipple>
                            );
                          }}
                        />
                      }
                    />
                    <InputHelperError
                      isError={!!errors.phone}
                      message={errors.phone?.message}
                    />
                  </>
                )}
                rules={{
                  setValueAs: (value) => `+${phoneCountryCode?.code}${value}`,
                }}
              />

              {/* <Text style={{ marginTop: 10 }}>
                Select the preferred method to validate your account.
              </Text>
              <View style={{ flexDirection: "row", marginVertical: 5 }}>
                <View
                  style={{
                    marginLeft: 10,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text>Email</Text>
                  <RadioButton
                    color={colors.primary}
                    uncheckedColor={colors.primary}
                    value="EMAIL"
                    status={protocol === "EMAIL" ? "checked" : "unchecked"}
                    onPress={() => setProtocol("EMAIL")}
                  />
                </View>
                <View
                  style={{
                    marginLeft: 10,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text>Phone</Text>
                  <RadioButton
                    color={colors.primary}
                    disabled
                    uncheckedColor={colors.primary}
                    value="SMS"
                    status={protocol === "SMS" ? "checked" : "unchecked"}
                    onPress={() => setProtocol("SMS")}
                  />
                </View>
              </View> */}

              {/* Password */}
              <Controller
                control={control}
                name="password"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <>
                    <TextInput
                      label="Password"
                      mode="outlined"
                      autoCompleteType="off"
                      multiline={false}
                      numberOfLines={1}
                      value={value}
                      style={{ marginTop: 15, textAlign: "auto" }}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      secureTextEntry={showPassword}
                      left={<TextInput.Icon name="lock" size={28} />}
                      right={
                        <TextInput.Icon
                          name={
                            showPassword ? "remove-red-eye" : "visibility-off"
                          }
                          disabled={!value}
                          size={28}
                          onPress={() =>
                            setShowPassword((prevState) => !prevState)
                          }
                        />
                      }
                      error={!!errors.password}
                    />
                    <InputHelperError
                      isError={!!errors.password}
                      message={errors.password?.message}
                    />
                  </>
                )}
              />

              {/* Repeat Password */}
              <Controller
                control={control}
                name="repeatPassword"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <>
                    <TextInput
                      label="Repeat Password"
                      mode="outlined"
                      multiline={false}
                      numberOfLines={1}
                      value={value}
                      style={{ marginTop: 15, textAlign: "auto" }}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      secureTextEntry={showPassword}
                      left={<TextInput.Icon name="lock" size={28} />}
                      error={!!errors.repeatPassword}
                    />
                    <InputHelperError
                      isError={!!errors.repeatPassword}
                      message={errors.repeatPassword?.message}
                    />
                  </>
                )}
              />

              {/* *** Address *** */}
              <Title style={{ marginTop: 25 }}>Billing Information:</Title>
              {/* Billing Address */}
              <Controller
                control={control}
                name="billingAddress1"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <>
                    {/* <TextInput
                        label="Billing Address"
                        value={value}
                        style={{ marginTop: 15 }}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        left={<TextInput.Icon name="add-location" size={28} />}
                        error={!!errors.billingAddress1}
                      /> */}
                    <GooglePlacesAutocomplete
                      ref={inputGoogleRef}
                      placeholder=""
                      numberOfLines={1}
                      enablePoweredByContainer={false}
                      minLength={2} // minimum length of text to search
                      autoFocus={false}
                      returnKeyType={"search"} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
                      listViewDisplayed="auto" // true/false/undefined
                      fetchDetails={true}
                      renderDescription={(row) => row.description} // custom description render
                      onPress={parserAddress}
                      query={{
                        // available options: https://developers.google.com/places/web-service/autocomplete
                        key: "AIzaSyBFQWH3MFgD8sQg1qL-sDuVD7pqNASpuNc",
                        language: "en", // language of the results
                      }}
                      textInputProps={{
                        InputComp: TextInput,
                        mode: "outlined",
                        style: { flex: 1, textAlign: "auto" },
                        multiline: false,
                        numberOfLines: 1,
                        value: value,
                        left: <TextInput.Icon name="location-history" />,
                        onChangeText: onChange,
                        onBlur: onBlur,
                        label: "Billing Address",
                        error: !!errors.billingAddress1,
                        editable: !requestingTwoFactorAuth,
                        right:
                          !!getValues("billingAddress1") && !isIosPlatform() ? (
                            <TextInput.Icon
                              name="close"
                              onPress={() => {
                                inputGoogleRef.current?.clear();
                                setValue("billingAddress1", "");
                              }}
                            />
                          ) : undefined,
                        onLayout: (event) => {
                          let { x, y, width, height } =
                            event.nativeEvent.layout;
                          setAutoCompletHeight(height);
                        },
                        // onTouchStart: (e) => sendCoordinates(e, addScroll),
                      }}
                      styles={{
                        description: {
                          fontFamily: "Montserrat_Regular",
                        },
                        predefinedPlacesDescription: {
                          color: "#1faadb",
                        },
                        container: {
                          marginTop: 10,
                          flex: 1,
                          ...suggestionBar,
                        },
                        listView: {
                          top: autoCompletHeight + 10,
                          position: "absolute",
                          zIndex: 99,
                          elevation: 99,
                        },
                        row: {
                          backgroundColor: colors.background,
                          ...suggestionBar,
                        },
                        separator: {
                          display: "none",
                        },
                      }}
                      // currentLocation={true} // Will add a 'Current location' button at the top of the predefined places list
                      currentLocationLabel="Current location"
                      nearbyPlacesAPI="GooglePlacesSearch" // Which API to use: GoogleReverseGeocoding or GooglePlacesSearch
                      GoogleReverseGeocodingQuery={
                        {
                          // available options for GoogleReverseGeocoding API : https://developers.google.com/maps/documentation/geocoding/intro
                        }
                      }
                      GooglePlacesSearchQuery={{
                        // available options for GooglePlacesSearch API : https://developers.google.com/places/web-service/search
                        rankby: "distance",
                      }}
                      // filterReverseGeocodingByTypes={[
                      //   'locality',
                      // ]} // filter the reverse geocoding results by types - ['locality', 'administrative_area_level_3'] if you want to display only cities
                      debounce={200}
                    />
                    <InputHelperError
                      isError={!!errors.billingAddress1}
                      message={errors.billingAddress1?.message}
                    />
                  </>
                )}
              />

              {/* Billing Address 2 */}
              <Controller
                control={control}
                name="billingAddress2"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <>
                    <TextInput
                      label="Apt"
                      mode="outlined"
                      multiline={false}
                      numberOfLines={1}
                      value={value}
                      style={{ marginTop: 15, textAlign: "auto" }}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      left={<TextInput.Icon name="meeting-room" size={28} />}
                      error={!!errors.billingAddress2}
                    />
                    <InputHelperError
                      isError={!!errors.billingAddress2}
                      message={errors.billingAddress2?.message}
                    />
                  </>
                )}
              />

              {/* Country */}
              {/* <Controller
                control={control}
                name="billingCountryCode"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <> */}
              {/* <TextInput
                      label="Country"
                      value={value}
                      multiline={false}
                      numberOfLines={1}
                      mode="outlined"
                      style={{ marginTop: 15 }}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      left={<TextInput.Icon name="map" size={28} />}
                      error={!!errors.billingCountryCode}
                    /> */}
              <View style={{ marginTop: 20 }}>
                <DropDownComponent
                  control={control}
                  name="billingCountryCode"
                  data={COUNTRY_LIST2}
                  defaultValue="US"
                  inputPropsLeft={<TextInput.Icon name={"map"} />}
                  inputPropsRight={<TextInput.Icon name={"unfold-more"} />}
                  onSelectionChange={(value) =>
                    setValue("billingCountryCode", value)
                  }
                />
                <InputHelperError
                  isError={!!errors.billingCountryCode}
                  message={errors.billingCountryCode?.message}
                />
              </View>
              {/* </> */}
              {/* )} */}
              {/* /> */}

              {/* City */}
              <Controller
                control={control}
                name="billingCity"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <>
                    <TextInput
                      label="City"
                      mode="outlined"
                      multiline={false}
                      numberOfLines={1}
                      value={value}
                      style={{ marginTop: 15, textAlign: "auto" }}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      left={<TextInput.Icon name="location-city" size={28} />}
                      error={!!errors.billingCity}
                    />
                    <InputHelperError
                      isError={!!errors.billingCity}
                      message={errors.billingCity?.message}
                    />
                  </>
                )}
              />

              {/* State */}
              <Controller
                control={control}
                name="billingState"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <>
                    <TextInput
                      label="State"
                      multiline={false}
                      numberOfLines={1}
                      left={
                        <TextInput.Icon name="emoji-transportation" size={28} />
                      }
                      mode="outlined"
                      value={value}
                      style={{ marginTop: 15, textAlign: "auto" }}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      error={!!errors.billingState}
                    />
                    <InputHelperError
                      isError={!!errors.billingState}
                      message={errors.billingState?.message}
                    />
                  </>
                )}
              />

              {/* ZipCode */}
              <Controller
                control={control}
                name="billingZipCode"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <>
                    <TextInput
                      label="ZipCode"
                      mode="outlined"
                      multiline={false}
                      numberOfLines={1}
                      value={value}
                      style={{ marginTop: 15, textAlign: "auto" }}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      error={!!errors.billingZipCode}
                    />
                    <InputHelperError
                      isError={!!errors.billingZipCode}
                      message={errors.billingZipCode?.message}
                    />
                  </>
                )}
              />
            </View>
            <View style={{ marginTop: 20 }}>
              <Button
                mode="contained"
                onPress={handleSubmit(handleSubmitInputs)}
                loading={requestingTwoFactorAuth}
                disabled={requestingTwoFactorAuth}
              >
                Register
              </Button>
              <View style={{ alignItems: "center", marginTop: 10 }}>
                <Hyperlink
                  text="Do you already have an account?"
                  color="#D32323"
                  url="Login"
                />
              </View>
            </View>
          </ScrollView>
        </Card.Content>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#d32f2e",
    justifyContent: "center",
  },
  tinyLogo: {
    width: 150,
    height: 60,
    resizeMode: "contain",
  },
});

export default SignUp;
