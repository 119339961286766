import { onCancel } from '../../../TwoFactorAuth/reducer/actions/onCancel';
import { onConfirmPIN } from '../../../TwoFactorAuth/reducer/actions/onConfirmPIN';
import { onRequestPassword } from '../../../TwoFactorAuth/reducer/actions/onRequestPassword';
import { resetTwoFactorAuth } from '../../../TwoFactorAuth/reducer/actions/resetTwoFactorAuth';
import { sendTwoFactorAuthPin } from '../../../TwoFactorAuth/reducer/actions/sendTwoFactorAuthPin';
import TwoFactorAuthContext from '../../../TwoFactorAuth/reducer/context';
import { State } from '../../../TwoFactorAuth/reducer/state';
import _ from 'lodash';
import { useContext } from 'react';

export interface TwoFactorAuth extends Omit<State, 'username' | 'password' | 'onConfirmPIN'> {
    requestPassword: (callback: (password: string) => void) => void
    sendPin: (params: { username?: string, password?: string, customerToken?: string, target?: string, protocol: 'SMS' | 'EMAIL' }) => Promise<string>,
    reset: () => any,
    onConfirmPIN: (callback: (token: string, pin: string) => void) => void
    onCancel: (callback: () => void) => void
}

export function useTwoFactorAuth(): TwoFactorAuth {
    const context = useContext(TwoFactorAuthContext);

    if (!context){
        return undefined;
    }

    return {
        ..._.omit(context.state, ['username', 'password']),
        requestPassword: (callback) => onRequestPassword(context, { callback }),
        onConfirmPIN: (callback) => onConfirmPIN(context, { callback }),
        onCancel: (callback) => onCancel(context, { callback }),
        sendPin: (params) => sendTwoFactorAuthPin(context, { ...params }),
        reset: () => resetTwoFactorAuth(context),
    };
}
