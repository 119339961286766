import { PaymentData } from '../../../common_modules/providers/CreditCardPayment/CreditCardPaymentForm';
import { InputField } from '../../../common_modules/lib/form-yup.util';
import { SubmitTransactionComplete } from '../../../common_modules/lib/Transactions/transactions';
import { Adjustment } from '../../../common_modules/model/Adjustment';
import { APIError } from '../../../common_modules/model/APIError';
import { TransactionPaymentMethod } from '../../../common_modules/model/TransactionPaymentMethod';

//  generic - use as common category
//  local - filter carriers using merchant country
//  foreign - filter carriers using NON merchant country
export enum CategoryType {
    'generic' = 'generic',
    'local' = 'local',
    'foreign' = 'foreign',
}

export interface Category {
    id: string,
    name: string,
    order: number,
    description: string,
    icon: string,
    color: string,
    type: CategoryType,
}

export interface Carrier {
    id: string,
    sku: string,
    name: string,
    color: string,
    logoUrl: string,
    standalonePaymentLink?: string
    country?: string
}

export interface Product {
    id: string,
    name: string,
    details: string,
    price: number,
    variablePrice: boolean,
    minPrice: number,
    maxPrice: number,
    discount: number,
    discountMode: 'FIXED' | 'RATE',
    sku: string,
    inputs?: InputField[],
    country?: string,
    type: 'PrepaidPlan' | 'GiftCard' | 'VirtualProduct' | 'ATMProduct',
    /** @deprecated use isPIN instead **/
    planType?: 'RTR' | 'PIN',
    isPIN?: boolean,
    carrier?: Carrier,
}

export interface CategoryState {
    loading: boolean,
    categories: Category[],
    selected?: Category,
}

export interface CarrierState {
    loading: boolean,
    carriers: Carrier[],
    selected?: Carrier,
}

export interface ProductState {
    loading: boolean,
    products: Product[],
    selected?: Product,
    inputsValues?: { [name: string]: any }
}

export enum MarketWizardStep {
    SETTINGS = 'SETTINGS',
    TRANSACTIONS = 'TRANSACTIONS',
    DASHBOARD = 'DASHBOARD',
    PRODUCT_LIST = 'PRODUCT_LIST',
    WELCOME = 'WELCOME',
    CARRIERS = 'CARRIERS',
    PRODUCTS = 'PRODUCTS',
    PRODUCT_INPUTS = 'PRODUCT_INPUTS',
    FINISH = 'FINISH',
    REDEEM = 'REDEEM',
}

// map step names with orders in `MarketWizard`
export const MARKET_WIZARD_STEP_MAP = [
    MarketWizardStep.SETTINGS,
    MarketWizardStep.TRANSACTIONS,
    MarketWizardStep.PRODUCT_LIST,
    MarketWizardStep.DASHBOARD,
    MarketWizardStep.WELCOME,
    MarketWizardStep.CARRIERS,
    MarketWizardStep.PRODUCTS,
    MarketWizardStep.PRODUCT_INPUTS,
    MarketWizardStep.FINISH,
];

export interface WizardState {
    currentStep: MarketWizardStep,
    subHeaderColor?: string,
}

export interface StoreInfo {
    number: string,
    name: string,
    consumerAuthentication: 'NONE' | 'CARDINAL_COMMERCE';
    agent: {
        name
    }
    address: {
        country: string
    },
    theme: {
        primaryColor: string,
        secondaryColor: string,
    }
}

export interface Clerk {
    name: string,
    sandbox: boolean,
    settings: {
        showProductDiscounts: boolean,
        use3DSecureWhenIsAvailable: boolean
    }
}

export interface UISettings {
    preferredPaymentMethod: TransactionPaymentMethod,
    sessionIdleTimeout: number, // time in min to close the session on idle,
    favoriteCarriers: string[]
}

export interface SubmitTransactionData {
    product: Product,
    productInputs: { [index: string]: any },
    receiptEmail?: string,
    paymentMethod: TransactionPaymentMethod,
    creditCard?: PaymentData,
    privateLabelGiftCard?: { privateLabelNumber: string, privateLabelCardMagData: string }
}

export interface SubmitTransactionPayload extends SubmitTransactionComplete {

}

export interface SidebarState {
    opened: boolean,
}

/// GLOBAL Market State
export interface State {
    loadingMarketInfo?: boolean,
    storeInfo?: StoreInfo,
    clerk?: Clerk,
    uiSettings: UISettings,
    wizard: WizardState,
    category: CategoryState,
    carrier: CarrierState,
    product: ProductState,
    transaction?: SubmitTransactionData,
    transactionIsProcessing: boolean,
    transactionPayload?: SubmitTransactionPayload,
    transactionFailed?: APIError,
    sidebar: SidebarState,
}

export const initialState: State = {
    wizard: {
        currentStep: MarketWizardStep.WELCOME,
    },
    uiSettings: {
        preferredPaymentMethod: TransactionPaymentMethod.CASH,
        sessionIdleTimeout: 15,
        favoriteCarriers: [],
    },
    category: {
        loading: false,
        categories: [],
    },
    carrier: {
        loading: false,
        carriers: [],
    },
    product: {
        loading: false,
        products: [],
    },
    transactionIsProcessing: false,
    sidebar: {
        opened: false,
    }
};
