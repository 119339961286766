import React from "react";
import { appIdentifier } from "../../../identifier";
import { AutoRefreshTokenR } from "./AutoRefreshTokenR";
import { AutoRefreshTokenRN } from "./AutoRefreshTokenRN";

export const AutoRefreshToken = () => {
  if (appIdentifier === "MERCHANT") {
    return <></>;
  }

  return appIdentifier === "CLIENT" ? (
    <AutoRefreshTokenRN />
  ) : (
    <AutoRefreshTokenR />
  );
};
