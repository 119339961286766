import ApolloClient from 'apollo-client';
import {removeAPIBearerToken} from '../reducer/actions/removeAPIBearerToken';
import {setAPIBearerToken} from '../reducer/actions/setAPIBearerToken';
import ApolloGraphQLContext from '../reducer/context';
import {Dispatch, useContext} from 'react';
import {Action} from '../reducer/reducer';

export interface ApolloGraphQL {
    client: ApolloClient<any>,
    dispatch: Dispatch<Action>,
    clientWithToken: boolean,
    logout: boolean,
    authMethod: "BIOMETRIC" | "SECRET",
    setBearerToken: (token: string) => any
    removeBearerToken: () => any
}

export function useApolloGraphQL(): ApolloGraphQL {
    const context = useContext(ApolloGraphQLContext);
    if (!context) {
        throw new Error('ApolloGraphqlProvider is required, wrap your components inside a <ApolloGraphqlProvider>');
    }

    return {
        client: context.state.client,
        logout: context.state.logout,
        authMethod: context.state.authMethod,
        dispatch: context.dispatch,
        clientWithToken: context.state.clientWithToken,
        setBearerToken: (bearerToken) => setAPIBearerToken(context, {bearerToken}),
        removeBearerToken: () => removeAPIBearerToken(context),
    };
}
