import React from "react";
import { appIdentifier } from "../../../identifier";
import { ClientFingerPrintR } from "./ClientFingerPrintR";
import { ClientFingerPrintRN } from "./ClientFingerPrintRN";

interface Props {}

export const ClientFingerPrintController = (props: Props) => {
  if (appIdentifier === "MERCHANT") {
    return <></>;
  }

  return appIdentifier === "CLIENT" ? (
    <ClientFingerPrintRN />
  ) : (
    <ClientFingerPrintR />
  );
};
