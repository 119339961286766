import { InputField, inputFieldToYupSchema } from '../lib/form-yup.util';
import { isPossibleNumber } from 'libphonenumber-js';
import _ from 'lodash';
import * as yup from 'yup';

interface Product {
    inputs?: InputField[],
    country?: string,
    type: 'PrepaidPlan' | 'GiftCard' | 'VirtualProduct' | 'ATMProduct',
    isPIN?: boolean,
}

export const productInputsToSchema = (product: Product): { [name: string]: yup.Schema<any> } => {
    const schema = {};

    _.forEach<InputField>(product ? product.inputs : [], (input) => {
        let name = input.name;
        let fieldSchema = inputFieldToYupSchema(input);

        if(name === 'multiRedeemPINRequest') return;

        if (input.type === 'phone') {
            fieldSchema = fieldSchema.test('isValidPhone', 'Please enter valid phone number', (value) => {
                if (input.required || value.length) {
                    return isPossibleNumber(value);
                }

                return true;
            });
        }

        schema[input.name] = fieldSchema;

        if (input.name === 'accountNumber' && input.html.type !== 'hidden' && product.type !== 'ATMProduct') {
            schema[`verify${name}`] = inputFieldToYupSchema(input)
                .oneOf([yup.ref(name), null], 'Fields must match');
        }

        if (input.type === 'magdata' && product.type === 'GiftCard') {
            schema['cardMagData'] = yup.string().ensure();
            schema['accountNumber'] = yup.string().ensure().required('Swipe or Scan a Gift Card to proceed.');
        }
    });

    return schema;
};
