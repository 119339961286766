import { SubmitTransactionCreditCard } from '../../../../lib/Transactions/transactions';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'initializeCreditCardVerification';

interface Data extends Action {
    onCancel: () => void,
    onAuthorize: () => Promise<any>
    creditCard: SubmitTransactionCreditCard
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        creditCard: data.creditCard,
        onCancel: data.onCancel,
        onAuthorize: data.onAuthorize,
    };
}

export const initializeCreditCardVerification = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
