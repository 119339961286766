import * as Sentry from 'sentry-expo';
import { LOGIN } from '../actions/authenticate.graphql';
import { authenticationFailed } from '../../reducer/actions/authenticationFailed';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State } from './../state';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { APIError } from '../../../../common_modules/model/APIError';
import { captureException } from '../../../../common_modules/lib/sentry';
import { PaynUpAPIError } from '../../../../common_modules/providers/ApolloGraphQL/ApolloGraphQL';
const NAME = 'authenticate';
interface Data extends Action {
    username: string,
    password: string,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        authenticating: true,
    };
}

export const authenticate = async (context: ContextType, _data: Omit<Data, 'type'>) => {
    try {
        const { data } = await context.apolloGraphQL.client.query({
            query: LOGIN,
            fetchPolicy: 'no-cache',
            variables: {
                ..._data,
            }
        });
        await AsyncStorage.removeItem('apollo-cache-persist');
        context.apolloGraphQL.setBearerToken(data.token);
        context.dispatch({ type: NAME, ...data });
    } catch (error) {
        const err = error as PaynUpAPIError;
        const authFailedError = new APIError(500, 'Unknown Error');
        if (err.graphQLErrors && err.graphQLErrors.length) {
            authFailedError.code = err.graphQLErrors[0].code;
            authFailedError.message = err.graphQLErrors[0].message;
        }
        
        authenticationFailed(context, { error: authFailedError });
        captureException(error)
    }

};
