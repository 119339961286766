import React from "react";
import { StyleSheet, View } from "react-native";
import { HelperText, Avatar, Text } from "react-native-paper";

interface Props {
  isError: boolean;
  message: string;
  visible?: boolean;
  type?: string;
}

/**
 * @function Component InputHelperError
 * @param {Boolean} isError Status Error
 * @param {string} message Message
 */
export const InputHelperError = (props: Props) => {
  return props.isError ? (
    <View style={styles.container}>
      <View
        style={{ flexDirection: "row", alignItems: "center", width: "90%" }}
      >
        <Avatar.Icon size={24} icon={"error"} />
        <Text
          numberOfLines={2}
          style={{
            color: "#ffffff",
            fontSize: 12,
            fontFamily: "Montserrat_Semi_Bold",
          }}
        >
          {props.message}
        </Text>
      </View>
    </View>
  ) : (
    <></>
  );
};

/**
 * @function Component InputHelperInfo
 * @param {Boolean} isError Status Error
 * @param {string} message Message
 * @param {boolean} visible Status mounted component
 */
export const InputHelperInfo = (props: Props) => {
  return (
    <HelperText
      type={!props.isError ? "info" : "error"}
      visible={props.visible}
    >
      <Text style={{ fontFamily: "Montserrat_Light", color: "#757575" }}>
        {props.message}
      </Text>
    </HelperText>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    backgroundColor: "#d23232",
    borderRadius: 5,
    marginTop: 2,
  },
});
