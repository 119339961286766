import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'onRequestPassword';

interface Data extends Action {
    callback: (password: string) => void | undefined,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        onRequestPassword: data.callback,
    };
}

export const onRequestPassword = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
