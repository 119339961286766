import { APIError } from '../../../../common_modules/model/APIError';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'submitTransactionFailed';

interface Data extends Action {
    error: APIError
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        transactionIsProcessing: false,
        transactionFailed: data.error,
    };
}

export const submitTransactionFailed = (context: ContextType, data: Omit<Data, 'type'>) => {

    context.notify.error(data.error.message);

    context.dispatch({ type: NAME, ...data });
};
