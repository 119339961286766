import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State, UserActivityRecord } from './../state';
import _ from 'lodash';

const NAME = 'registerActivity';

interface Data extends Action {
    record: UserActivityRecord,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        queue: [
            ...state.queue,
            data.record,
        ]
    };
}

export const registerActivity = (context: ContextType, data: Omit<Data, 'type'>) => {

    const lastRecord = _.last(context.state.queue);
    if (!lastRecord || lastRecord.details !== data.record.details){
        context.dispatch({ type: NAME, ...data });
    }
};
