import gql from 'graphql-tag';
import { PRODUCT_FRAGMENT } from '../../reducer/actions/loadProducts.graphql';

export const SEARCH_PRODUCT_BY_SKU = gql`
    query ProductBySku($sku: String!) {
        products {
            findBySku(sku: $sku) {
                ...POS_Product
            }
        }
    }
    ${PRODUCT_FRAGMENT}
`;

export const SEARCH_PRODUCTS = gql`
    query Products($search: String!) {
        products{
            all(first: 40, search: $search){
                edges{
                    node{
                        ...POS_Product
                    }
                }
            }
        }
    }
    ${PRODUCT_FRAGMENT}
`;
