import { Action } from "./actions/actionTypes";
import { CardinalState, initialState } from "./state";

export const reducer = (state: CardinalState, action: Action): CardinalState => {

    switch (action.type) {
        case 'SECURE_CARDINAL':
            return {
                ...state,
                visible: true,
                options: action.options
            };
        case 'CLOSE_SECURE_CARDINAL':
            return {
                ...state,
                visible: false,
                options: undefined,
            };
        default:
            return state
    }
}