import { appIdentifier } from "../../identifier";
import { asyncStorageGetItem, asyncStorageRemoveItem, asyncStorageSetItem } from "./asyncStorage";
import { localStorageGetItem, localStorageRemoveItem, localStorageSetItem } from "./localStorage";
import { secureStorageIsAvailable, secureStorageGetItem, secureStorageSetItem, secureStorageRemoveItem } from "./secureStorage";

export const getItem = async (key: string, secure = false) => {
    if (appIdentifier !== "MESH") {
        if (!secure || secure && !await secureStorageIsAvailable()) {
            return await asyncStorageGetItem(key);
        }
        return await secureStorageGetItem(key);
    }
    return localStorageGetItem(key);
}

export const setItem = async (key: string, data: unknown, secure = false, rememberMe = false) => {
    if (appIdentifier !== "MESH") {
        if (!secure || secure && !await secureStorageIsAvailable()) {
            return await asyncStorageSetItem(key, data);
        }
        return await secureStorageSetItem(key, data);
    }
    return localStorageSetItem(key, data, rememberMe);
}

export const removeItem = async (key: string, secure = false) => {
    if (appIdentifier !== "MESH") {
        if (!secure || secure && !await secureStorageIsAvailable()) {
            return await asyncStorageRemoveItem(key);
        }
        return await secureStorageRemoveItem(key);
    }
    return localStorageRemoveItem(key);
}
