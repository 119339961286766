import React, { createContext, Dispatch } from 'react';
import { IActions } from './actions/actionTypes';
import { IState } from './state';

interface IWalletFundContext {
    state: IState,
    dispatch: Dispatch<IActions>
}

export const WalletFundsContext = createContext<IWalletFundContext | null>(null);
export default WalletFundsContext;