import React, { useCallback, useEffect, useState } from 'react';
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { Card, Title, Text, Divider, useTheme, IconButton, Avatar } from 'react-native-paper';
import LoadingComponent from '../../common_modules/components/Loading/Loading';
import { getDisplayWidth, getPlatform, getVariant, isWebPlatform, IS_DESKTOP_OR_LAPTOP, IS_SMARTPHONE } from '../../common_modules/lib/PlatformDevice';
import { Carrier, Category, Product, UISettings } from '../Market/reducer/state';
import { useMediaQuery } from "react-responsive";
import _ from 'lodash';
import CardCategory from '../../common_modules/components/Card/CardCategory';
import CardCarrier from '../../common_modules/components/Card/CardCarrier';
import CardCategoryMobile from '../../common_modules/components/Card/CardCategoryMobile';
import { useNavigation } from '@react-navigation/native';
import SwipeCardScanner from '../../common_modules/components/SwipeCardScanner/SwipeCardScanner';
import { SEARCH_PRODUCTS, SEARCH_PRODUCT_BY_SKU } from '../Market/MarketWizard/StepWelcome/QuickSale.graphql';
import { productFragment2Product } from '../Market/reducer/actions/loadProducts';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';
import { FavoriteCardsHtml } from '../../common_modules/lib/PrintHtml';
import { ScrollView } from 'react-native-gesture-handler';
import CardQuickSales from '../../common_modules/components/Card/CardQuickSales';
import { SCAN_QR } from '../../common_modules/providers/CreditCardPayment/CardSwiper.graphql';
import { useApolloGraphQL } from '../../common_modules/providers/ApolloGraphQL/hook/useGraphqlApolloClient';
interface HomeContentProps {
    categories: Category[],
    carriers: Carrier[],
    selectCategory: (category: Category) => void,
    selectCarrier: (carrier: Carrier) => void,
    selectProduct: (carrier: Product) => void,
    uiSettings: UISettings,
    onSaveSettings: (settings: UISettings) => void,
    onQuickSale: (carrier: Carrier, product: Product, accountNumber?: string, magData?: string) => void,
    tab?: string,
    dataScanned?: string
}

let searchingProductTimer;

export const HomeContent = (props: HomeContentProps) => {
    const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP)
    const customSize = useMediaQuery({ maxDeviceWidth: 1220 });
    const customSize2 = useMediaQuery({ maxDeviceWidth: 1400 });
    const resizeCategory = useMediaQuery({ maxDeviceWidth: 1100 })

    const { client } = useApolloGraphQL();
    const [sku, setSku] = useState('');
    const [quickSaleError, setQuickSaleError] = useState('');
    const [clearSelected, setClearSelected] = useState(false);
    const [searchingSku, setSearchingSku] = useState(false);
    const [isSwiping, setSwiping] = useState(false);
    const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);
    const [products, setProducts] = React.useState([]);
    const [searchingProduct, setSearchingProduct] = useState(false);
    const { colors } = useTheme();
    const navigation = useNavigation();

    useEffect(() => {
        if (props.dataScanned) {
            onScanBarCode(props.dataScanned)
        }
    }, [props.dataScanned]);

    const toggleFavoriteCarrier = (id: string) => {
        let favoriteCarriers = Array.from(props.uiSettings.favoriteCarriers);
        if (favoriteCarriers.includes(id)) {
            const index = favoriteCarriers.findIndex(fav => fav === id);
            favoriteCarriers.splice(index, 1);
        } else {
            favoriteCarriers.push(id);
        }
        props.onSaveSettings({ ...props.uiSettings, favoriteCarriers });
    };

    const quickSale = useCallback((productSku, accountNumber?: string, magData?: string) => {
        if (productSku) {
            setQuickSaleError('');
            setSearchingSku(true);
            setSku(productSku);
            client.query({
                query: SEARCH_PRODUCT_BY_SKU,
                variables: { sku: productSku }
            }).then(({ data }) => {
                setSku('');
                setSearchingSku(false);
                if (data.products.findBySku) {
                    props.onQuickSale(data.products.findBySku.category, productFragment2Product(data.products.findBySku), accountNumber, magData);
                    navigation.navigate("Product Form", { accountNumber });
                    setClearSelected((prevData) => !prevData);
                } else {
                    setQuickSaleError('No product was found');
                }
            }).catch((error) => {
                console.log(error);
                setSearchingSku(false);
            });
        }
    }, [client, props]);

    const searchProducts = (search) => {
        if (search) {
            setSearchingProduct(true);
            clearTimeout(searchingProductTimer);
            searchingProductTimer = setTimeout(() => {
                client.query({
                    query: SEARCH_PRODUCTS,
                    variables: { search },
                }).then(({ data }) => {
                    setSearchingProduct(false);
                    const p = [];
                    const order = [];
                    if (data) {
                        _.forEach(data.products.all.edges, (edge) => {
                            p.push(edge.node);
                        });
                        _.chain(p).groupBy("carrier.id").map((d, i) => order.push({ ...d })).value();
                        setProducts(order);
                    } else {
                        setProducts([]);
                    }
                });
            }, 400);
        } else {
            setProducts([]);
        }
    };

    const onScanBarCode = useCallback((barcode) => {
        //799366367611 => SKU: 799366367611
        //799366710470006277003083356094 => SKU: 79936671047 accountNumber: 0006277003083356094
        const productSku = String(barcode).length <= 12 ? String(barcode) : String(barcode).substr(0, 11);
        const accountNumber = barcode.length > 12 ? String(barcode).substr(-19) : '';
        quickSale(productSku, accountNumber);
    }, [quickSale]);

    const handlePaymentLink = async (dataScan) => {
        setLoadingPaymentLink(true);
        try {
            const { data } = await client.mutate({
                mutation: SCAN_QR,
                variables: { url: dataScan }
            });
            if (data.payments && data.payments.parseLink.product) {
                const product = productFragment2Product(data.payments.parseLink.product)
                props.selectProduct(product);
                navigation.navigate('Product Form');
                setLoadingPaymentLink(false);
            } else if (data.payments && data.payments.parseLink.category) {
                props.selectCarrier(data.payments.parseLink.category);
                navigation.navigate('Products', data.payments.parseLink.category);
                setLoadingPaymentLink(false);
            }
        } catch (error) {
            setLoadingPaymentLink(false);
            console.log(error)
        }
    }

    const scanOperation = (data) => {
        onScanBarCode(data);
    };

    const handlePrint = async () => {
        const { uri } = await Print.printToFileAsync({ html: FavoriteCardsHtml });
        Sharing.shareAsync(uri);
    }

    return (
        <>
            {/* Principal Card */}
            <View style={{ marginBottom: 20, position: 'relative', zIndex: 99999999, elevation: (getPlatform() === 'android') ? 99999999 : 0 }}>
                <CardQuickSales
                    loadingPaymentLink={loadingPaymentLink}
                    products={products}
                    quickSale={quickSale}
                    quickSaleError={quickSaleError}
                    searchProducts={searchProducts}
                    searchingProduct={searchingProduct}
                    searchingSku={searchingSku}
                    setProducts={setProducts}
                    setQuickSaleError={setQuickSaleError}
                    setSearchingProduct={setSearchingProduct}
                    setSwiping={setSwiping}
                    setSku={setSku}
                    sku={sku}
                />


                {/* {getVariant === 'merchant' ?
                <Card style={{ backgroundColor: colors.primary }} elevation={4}>
                    <Card.Content>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                            }}>
                            <Image
                                style={{
                                    resizeMode: 'stretch',
                                    width: !isSmartPhone ? 140 : 80,
                                    height: !isSmartPhone ? 140 : 80,
                                }}
                                source={require('../../../assets/img/cart.png')}
                            />
                            <View
                                style={{
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    paddingLeft: !isSmartPhone ? 20 : 10,
                                    flex: 1
                                }}>
                                <Title
                                    numberOfLines={3}
                                    style={{
                                        color: colors.surface,
                                        fontSize: 25,
                                        fontFamily: 'Montserrat_Semi_Bold',
                                    }}>
                                    QUICK SALE
                                </Title>
                                <Text
                                    style={{
                                        color: colors.surface,
                                        fontFamily: 'Montserrat_Regular',
                                    }}
                                    numberOfLines={3}>
                                    {!isSmartPhone ? `Swipe / Scan Gift Card or enter product name` : `Enter product name`}
                                </Text>
                                {!isSmartPhone &&
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <View style={{
                                            flex: 1,
                                            paddingTop: 10,
                                            marginBottom: 10,
                                            position: 'relative',
                                            zIndex: 99999999,
                                        }}>
                                            <Autocomplete
                                                label=""
                                                mode="flat"
                                                array={products}
                                                field="name"
                                                value={(val) => {
                                                    quickSale(val.sku);
                                                }}
                                                clearSelected={clearSelected}
                                                valueInitial=""
                                                loading={searchingProduct}
                                                error={!!quickSaleError}
                                                errorMessage={quickSaleError}
                                                onChangeText={(text) => {
                                                    if (text) {
                                                        setSku(text);
                                                        ={(e: string)}('');
                                                        searchProducts(text);
                                                    } else {
                                                        setClearSelected((prevData) => !prevData)
                                                        setSearchingProduct(false);
                                                        setSku('');
                                                        setProducts([])
                                                    }
                                                }}
                                                inputRightProps={
                                                    <>
                                                        {searchingSku || loadingPaymentLink ?
                                                            <LoadingComponent size='small' /> : null}
                                                        {!searchingSku && !loadingPaymentLink && sku.length ?
                                                            <IconButton
                                                                icon="close"
                                                                color={colors.onSurface}
                                                                onPress={() => {
                                                                    setSku('');
                                                                    setClearSelected((prevData) => !prevData)
                                                                    setSearchingProduct(false);
                                                                    setProducts([]);
                                                                }} /> : null
                                                        }
                                                    </>
                                                }
                                            >
                                            </Autocomplete>
                                        </View>
                                        <TouchableOpacity onPress={() => setSwiping(true)}>
                                            <Avatar.Image
                                                style={{ backgroundColor: 'transparent' }}
                                                source={require('../../../assets/img/icon_qr.png')}
                                                size={100} />
                                        </TouchableOpacity>
                                    </View>
                                }
                            </View>
                        </View>
                        {isSmartPhone &&
                            <>
                                <View style={{
                                    paddingLeft: isDesktopOrLaptop ? 120 : undefined,
                                    flex: 1,
                                    paddingTop: 20,
                                    marginBottom: 10,
                                    position: 'relative',
                                    zIndex: 99999999,
                                    elevation: (getPlatform() === 'android') ? 99999999 : 0
                                }}>
                                    <Autocomplete
                                        label=""
                                        mode="flat"
                                        array={products}
                                        field="name"
                                        value={(val) => {
                                            quickSale(val.sku);
                                        }}
                                        clearSelected={clearSelected}
                                        valueInitial=""
                                        loading={searchingProduct}
                                        error={!!quickSaleError}
                                        errorMessage={quickSaleError}
                                        onChangeText={(text) => {
                                            if (text) {
                                                setSku(text);
                                                ={(e: string)}('');
                                                searchProducts(text);
                                            } else {
                                                setClearSelected((prevData) => !prevData)
                                                setSearchingProduct(false);
                                                setSku('');
                                                setProducts([])
                                            }
                                        }}
                                        inputRightProps={
                                            <>
                                                {searchingSku || loadingPaymentLink ?
                                                    <LoadingComponent size='small' /> : null}
                                                {!searchingSku && !loadingPaymentLink && sku.length > 0 ?
                                                    <IconButton
                                                        icon="close"
                                                        color={colors.onSurface}
                                                        onPress={() => {
                                                            setSku('');
                                                            setClearSelected((prevData) => !prevData)
                                                            setSearchingProduct(false);
                                                            setProducts([]);
                                                        }} /> : null
                                                }
                                            </>
                                        }>
                                    </Autocomplete>
                                </View>
                            </>}
                    </Card.Content>
                </Card>: null} */}
            </View>

            {/* Category */}
            {isWebPlatform() || isSmartPhone && props.tab === 'home' ?
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        width: '100%',
                        position: 'relative',
                        zIndex: 1,
                        elevation: (getPlatform() === 'android') ? 1 : 0,
                        marginBottom: 30
                    }}>
                    {props.categories.length ? props.categories.map((cat, index) => (
                        <View
                            key={index}
                            style={{
                                marginBottom: 15,
                                width: isWebPlatform() && resizeCategory ? '100%' : '48%',
                                maxHeight: '50%',
                                marginHorizontal: isDesktopOrLaptop ? 3 : undefined
                            }}>
                            {isWebPlatform() ?
                                <CardCategory
                                    key={cat.id}
                                    category={cat}
                                    selectCategory={props.selectCategory}
                                    iconColor={colors.primary}
                                /> :
                                <CardCategoryMobile
                                    key={cat.id}
                                    category={cat}
                                    selectCategory={props.selectCategory}
                                    iconColor={colors.primary}
                                />}
                        </View>
                    )) : <LoadingComponent />}
                </View> : null}
            {isWebPlatform() && isSmartPhone ? <Divider style={{ marginBottom: 20 }} /> : null}

            {/* Carrier  */}
            {isWebPlatform() && isSmartPhone || isSmartPhone && props.tab === 'favorites' ?
                <View style={{
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    marginBottom: 30,
                    justifyContent: isSmartPhone ? 'space-between' : customSize ? 'space-between' : 'flex-start',
                }}>
                    {props.carriers.length ? props.carriers.map((carrier, index) => (
                        <View key={index} style={{
                            marginBottom: !isDesktopOrLaptop ? 15 : undefined,
                            margin: isDesktopOrLaptop ? customSize2 ? 5 : 8 : undefined,
                            width: isDesktopOrLaptop ? getDisplayWidth() * 0.14 : '48%',
                        }}>
                            <CardCarrier
                                key={carrier.id}
                                carrier={carrier}
                                selectCarrier={props.selectCarrier}
                                isFavorite={true}
                                toggleFavoriteCarrier={toggleFavoriteCarrier}
                            />
                        </View>
                    )) : <Text style={{width: '100%', textAlign: 'center', fontSize: 16}}>Not favorite carrier found!</Text>}
                </View> : null
            }

            <SwipeCardScanner
                isOpen={isSwiping}
                onClose={() => setSwiping(false)}
                onScanBarCode={(data) => onScanBarCode(data)}
                onScanQr={(data) => handlePaymentLink(data)}
                onSwipe={(data) => {
                    if (data.sku) {
                        quickSale(data.sku, data.cardNumber, data.magData);
                    }
                    else {
                        setQuickSaleError('Invalid card or swipe data');
                    }
                }}
            />

        </>
    );
}

const styles = StyleSheet.create({
    tinyLogo: {
        resizeMode: 'stretch',
        width: 100,
        height: 100,
    }
})
