import { useContext, Dispatch } from "react"
import { getTwoFactorAuth, registerCustomerAccount, resetPassword, resetPasswordTwoFactorAuth, verifyTarget } from "../reducer/actions/actions";
import { Action, ProtocolTypes } from "../reducer/actions/actionTypes";
import RegistrationContext from "../reducer/context"
import { SignUpState } from "../reducer/state";

export interface SignUp extends Omit<SignUpState, 'twoFactorAuthToken'> {
    getTwoFactorAuth: (protocol: ProtocolTypes, target?: string) => Promise<boolean>,
    registerCustomerAccount: (twoFactorAuthPIN: string) => void,
    resetPasswordTwoFactorAuth: (protocol: ProtocolTypes, target: string) => void,
    resetPassword: (twoFactorAuthPIN: string) => void,
    verifyTarget: (twoFactorAuthPIN: string) => void,
    dispatch: Dispatch<Action>
}

export const useSignUp = (): SignUp => {
    const context = useContext(RegistrationContext);
    if (!context) {
        throw new Error('Only can use `useSignUp` inside a valid SignUpProvider');
    }

    return {
        customer: context.state.customer,
        requestingTwoFactorAuth: context.state.requestingTwoFactorAuth,
        requestingPing: context.state.requestingPing,
        inProcess: context.state.inProcess,
        processCompleted: context.state.processCompleted,
        done: context.state.done,
        processWithError: context.state.processWithError,
        dispatch: context.dispatch,
        getTwoFactorAuth: (protocol, target) => getTwoFactorAuth(context, protocol, target),
        registerCustomerAccount: (twoFactorAuthPIN) => registerCustomerAccount(context, twoFactorAuthPIN),
        resetPasswordTwoFactorAuth: (protocol, target) => resetPasswordTwoFactorAuth(context, protocol, target),
        resetPassword: (twoFactorAuthPIN) => resetPassword(context, twoFactorAuthPIN),
        verifyTarget: (twoFactorAuthPIN) => verifyTarget(context, twoFactorAuthPIN)
    }
}