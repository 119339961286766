import { APIError } from '../../../../model/APIError';
import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'setError';

interface Data extends Action {
    error: APIError
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        submitting: false,
        error: data.error
    };
}

export const setError = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
