import { Dimensions, Platform } from "react-native";
import Constants from 'expo-constants';

export const isWebPlatform = (): boolean => {
    return Platform.OS === 'web';
}

export const isIosPlatform = (): boolean => {
    return Platform.OS === 'ios';
}

export const getPlatform = (): string => {
    return Platform.OS;
}

export const getDisplayWidth = (): number => {
    return Dimensions.get('screen').width;
}

export const getDisplayHeight = (): number => {
    return Dimensions.get('screen').height;
}

export const IS_SMARTPHONE = { maxDeviceWidth: 1024 };
export const IS_DESKTOP_OR_LAPTOP = { minDeviceWidth: 1224 };
export const IS_TABLET_OR_MOBILE_DEVICE = { maxDeviceWidth: 1224 };
export const IS_SMALL_SCREEN = { maxDeviceWidth: 390 };