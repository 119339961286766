import { clearTransactionError } from '../reducer/actions/clearTransactionError';
import { goBackMarketWizard } from '../reducer/actions/goBackMarketWizard';
import { goToStep } from '../reducer/actions/goToStep';
import { loadCategories } from '../reducer/actions/loadCategories';
import { loadMarketInfo } from '../reducer/actions/loadMarketInfo';
import { quickSale } from '../reducer/actions/quickSale';
import { resetMarketWizard } from '../reducer/actions/resetMarketWizard';
import { saveUISettings } from '../reducer/actions/saveUISettings';
import { selectCarrier } from '../reducer/actions/selectCarrier';
import { selectCategory } from '../reducer/actions/selectCategory';
import { selectProduct } from '../reducer/actions/selectProduct';
import { submitTransactionSuccess } from '../reducer/actions/submitTransactionSuccess';
import { toggleSidebar } from '../reducer/actions/toggleSidebar';
import Context from '../reducer/context';
import { Carrier, CarrierState, Category, CategoryState, Clerk, MarketWizardStep, Product, ProductState, SidebarState, StoreInfo, SubmitTransactionData, SubmitTransactionPayload, UISettings, WizardState } from '../reducer/state';
import { useContext } from 'react';
import { submitTransaction } from '../reducer/actions/submitTransaction';
import { APIError } from '../../../common_modules/model/APIError';

export interface Market {
    sidebar: SidebarState,
    uiSettings: UISettings,
    saveSettings: (settings: UISettings) => void,
    toggleSidebar: () => void,
    goToSettings: () => void,
    goToWelcome: () => void,
    goToTransactions: () => void,
    goToProductList: () => void,
    goToCharts: () => void,
    storeInfo: StoreInfo,
    clerk: Clerk,
    loadMarketInfo: () => any,
    loadingMarketInfo: boolean,
    resetWizard: () => any,
    goBack: () => any,
    wizardState: WizardState,
    categoryState: CategoryState,
    loadCategories: () => any,
    quickSale: (carrier: Carrier, product: Product, accountNumber?: string, magData?: string) => void,
    selectCategory: (category: Category) => any,
    selectCarrier: (carrier: Carrier) => any,
    selectProduct: (product: Product) => any,
    carrierState: CarrierState,
    productState: ProductState,
    transactionSubmit: (transaction: SubmitTransactionData) => void,
    transaction: SubmitTransactionData,
    transactionCompleted: (payload: SubmitTransactionPayload)=> void,
    transactionIsProcessing: boolean,
    transactionPayload: SubmitTransactionPayload,
    transactionFailed: APIError,
    clearTransactionError: () => void,
}

export function useMarket(): Market {
    const context = useContext(Context);
    if (!context) {
        throw new Error('Only can use `useMarket` inside a valid MarketProvider');
    }

    return {
        sidebar: context.state.sidebar,
        uiSettings: context.state.uiSettings,
        saveSettings: (settings: UISettings) => saveUISettings(context, { settings }),
        goToSettings: () => goToStep(context, { step: MarketWizardStep.SETTINGS }),
        goToWelcome: () => goToStep(context, { step: MarketWizardStep.WELCOME }),
        goToTransactions: () => goToStep(context, { step: MarketWizardStep.TRANSACTIONS }),
        goToProductList: () => goToStep(context, { step: MarketWizardStep.PRODUCT_LIST }),
        goToCharts: () => goToStep(context, { step: MarketWizardStep.DASHBOARD }),
        toggleSidebar: () => toggleSidebar(context),
        storeInfo: context.state.storeInfo,
        clerk: context.state.clerk,
        loadMarketInfo: () => loadMarketInfo(context),
        loadingMarketInfo: context.state.loadingMarketInfo,
        resetWizard: () => resetMarketWizard(context),
        quickSale: (carrier: Carrier, product: Product, accountNumber?: string, magData?: string) => quickSale(context, { carrier, product, accountNumber, magData }),
        goBack: () => goBackMarketWizard(context),
        wizardState: context.state.wizard,
        categoryState: context.state.category,
        loadCategories: () => loadCategories(context),
        selectCategory: (category: Category) => selectCategory(context, { category }),
        selectCarrier: (carrier: Carrier) => selectCarrier(context, { carrier }),
        carrierState: context.state.carrier,
        productState: context.state.product,
        selectProduct: (product: Product) => selectProduct(context, { product }),
        transactionIsProcessing: context.state.transactionIsProcessing,
        transactionPayload: context.state.transactionPayload,
        transaction: context.state.transaction,
        transactionSubmit: (transaction) => submitTransaction(context, { transaction }),
        transactionCompleted: (payload: SubmitTransactionPayload) =>  submitTransactionSuccess(context, { payload }),
        transactionFailed: context.state.transactionFailed,
        clearTransactionError: () => clearTransactionError(context),
    };
}
