
import React, { Dispatch } from 'react';
import { Action } from './reducer';
import { State } from './state';
import { CardinalSecure } from '../../../components/Cardinal/hook/useCardinal';
import { CreditCardVerification } from '../../CreditCardVerification/hook/useCreditCardVerification';
import { CustomerAuth } from '../../CustomerAuth/hook/useCustomerAuth';
import { TwoFactorAuth } from '../../TwoFactorAuth/reducer/hook/useTwoFactorAuth';
import { TransactionRedirection } from '../../TransactionRedirection/hook/useTransactionRedirection';
import { UserActivity } from '../../UserActivity/hook/useUserActivity';
import { ApplePay } from '../../ApplePay/hook/useApplePay';
import { LogRocket } from '../../LogRocket/hook/useLogRocket';
import { ClientFingerPrint } from '../../ClientFingerPrint/hook/useClientFingerPrint';
import { ApolloGraphQL } from '../../ApolloGraphQL/hook/useGraphqlApolloClient';
import { StoreInformation } from '../../StoreInfo/hook/useStoreInformation';
import { ApiViewer, StoreInfo } from '../../StoreInfo/reducer/state';

export interface ContextType {
    readonly _type?: 'SubmitTransaction',
    dispatch: Dispatch<Action>,
    state: State,
    apolloGraphQL: ApolloGraphQL,
    customerAuth: CustomerAuth,
    twoFactorAuth?: TwoFactorAuth
    transactionRedirection?: TransactionRedirection
    creditCardVerification: CreditCardVerification
    storeInfo: StoreInformation<StoreInfo, ApiViewer>
    userActivity?: UserActivity
    applePay?: ApplePay
    logRocket?: LogRocket
    clientFingerPrint?: ClientFingerPrint
    cardinalSecure?: CardinalSecure 
}

export const SubmitTransactionContext = React.createContext<ContextType>(null);
export default SubmitTransactionContext;
