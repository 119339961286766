import React from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import CardReceipt from '../../common_modules/components/Card/CardReceipt';
import { useMediaQuery } from "react-responsive";
import { useNavigation } from '@react-navigation/native';
import { getDisplayHeight, IS_DESKTOP_OR_LAPTOP } from '../../common_modules/lib/PlatformDevice';
import Footer from '../../common_modules/components/Footer/Footer';
import { useMarket } from '../Market/hook/useMarket';
import { ScrollView } from 'react-native-gesture-handler';
import HeaderTitle from '../../navigation/Header/HeaderTitle';

const Finish = ({ route }) => {
    const {
        resetWizard
    } = useMarket();
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
    const navigation = useNavigation();
    const { payload } = route.params;

    return (
        <>
            <HeaderTitle title={payload && payload.transaction ? payload.transaction.product.carrier.name : ''} />
            <ScrollView>
                <View style={{
                    paddingHorizontal: isDesktopOrLaptop ? '40%' : 20,
                    paddingVertical: getDisplayHeight() * 0.05
                }}>
                    <CardReceipt {...route.params} />
                    <Button style={{ marginTop: 20 }}
                        mode="outlined"
                        onPress={() => {
                            resetWizard();
                            navigation.navigate('Home');
                        }}>
                        Start Over
                    </Button>
                </View>
            </ScrollView>

            {/* <Footer /> */}
        </>
    );
};

export default Finish;