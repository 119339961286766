import React, { useContext, useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import DrawerContent from '../common_modules/components/Drawer/DrawerContent';
import { Linking, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import LoadingComponent from '../common_modules/components/Loading/Loading';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Home from '../screens/Home/Home';
import * as SplashScreen from "expo-splash-screen";
import StackNavigatorSetup from './Header/Header';
const Tab = createBottomTabNavigator();

const Drawer = createDrawerNavigator();
const PERSISTENCE_NAVIGATION_KEY = 'PERSISTENT_NAVIGATION_POS'
export default function StackNavigator(props) {
    const [isReady, setIsReady] = React.useState(false);
    const [initialState, setInitialState] = React.useState();

    useEffect(() => {
        const restoreState = async () => {
            try {
                const initialUrl = await Linking.getInitialURL();

                if (Platform.OS === 'web' && initialUrl == null) {
                    // Only restore state if there's no deep link and we're not on web
                    const savedStateString = await AsyncStorage.getItem(PERSISTENCE_NAVIGATION_KEY);
                    const state = savedStateString ? JSON.parse(savedStateString) : undefined;

                    if (state !== undefined) {
                        setInitialState(state);
                    }
                }
            } finally {
                setIsReady(true);
            }
        };

        if (!isReady) {
            restoreState();
        }
    }, [isReady]);

    if (!isReady) {
        // SplashScreen.preventAutoHideAsync();
        return null

    } else {
        SplashScreen.hideAsync();
    }
    return (
        <NavigationContainer {...props}
            initialState={initialState}
            onStateChange={(state) =>
                AsyncStorage.setItem(PERSISTENCE_NAVIGATION_KEY, JSON.stringify(state))
            }>
            <Drawer.Navigator drawerContent={(props) => <DrawerContent {...props} />}>
                <Drawer.Screen name="Root" component={StackNavigatorSetup} />
            </Drawer.Navigator>
        </NavigationContainer>
    );
}
