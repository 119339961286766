import _ from 'lodash';
import { SubmitTransactionPlugin } from './Plugin';


export const VerifyCreditCardPlugin: SubmitTransactionPlugin = {
    onSubmit: (event) => new Promise((finishDone, finishError) => {
        if (event.data && event.data.creditCard) {
            // remember require a verified credit card or if store require verify credit card and 3D not present
            if (event.data.creditCard.remember || (!event.data.creditCard.token && event.context.storeInfo.store.requireVerifiedCreditCardPayments && !event.data.creditCard.consumerAuthToken)) {
                const creditCardToVerify = _.omit(event.data.creditCard, ['remember', 'token', 'consumerAuthToken', 'magData']);
                event.context.creditCardVerification.isAlreadyVerified(creditCardToVerify, event.context.customerAuth.token)
                    .then((verified) => {
                        // if already verified then continue
                        if (verified) {
                            finishDone({ ...event.data });
                        } else {

                            if (event.context.userActivity) {
                                event.context.userActivity.info('Starting Credit Card verification');
                            }

                            // if not verified start verification process
                            event.context.creditCardVerification.verify(creditCardToVerify, event.context.customerAuth.token)
                                .then((verified) => {
                                    if (verified) {
                                        if (event.context.userActivity) {
                                            event.context.userActivity.info('Card verification completed successfully');
                                        }

                                        finishDone({ ...event.data });
                                    } else {
                                        if (event.context.userActivity) {
                                            event.context.userActivity.warning('Card verification failed');
                                        }

                                        finishError('Please verify this credit card');
                                    }
                                }).catch(finishError);
                        }
                    }).catch((finishError));
            } else {
                finishDone({ ...event.data });
            }
        } else {
            finishDone({ ...event.data });
        }
    }),
    onError: (event) => new Promise((finishDone, finishError) => {
        if (event.data.error.code === 2503 || event.data.error.code == 2550) {

            // resubmit
            const submitTransactionWithVerifiedCard = () => {
                event.data.retry({
                    ...event.data.transaction,
                });
            };

            const creditCardToVerify = _.omit(event.data.transaction.creditCard, ['remember', 'token', 'consumerAuthToken', 'magData']);
            event.context.creditCardVerification.isAlreadyVerified(creditCardToVerify, event.context.customerAuth.token)
                .then((verified) => {
                    // if already verified then resubmit
                    if (verified) {
                        submitTransactionWithVerifiedCard();
                    } else {

                        if (event.context.userActivity) {
                            event.context.userActivity.info('Starting Credit Card verification');
                        }

                        // if not verified start verification process
                        event.context.creditCardVerification.verify(creditCardToVerify, event.context.customerAuth.token)
                            .then((verified) => {
                                if (verified) {
                                    if (event.context.userActivity) {
                                        event.context.userActivity.info('Card verification completed successfully');
                                    }

                                    submitTransactionWithVerifiedCard();
                                } else {
                                    if (event.context.userActivity) {
                                        event.context.userActivity.warning('Card verification failed');
                                    }

                                    finishError(event.data.error);
                                }
                            }).catch(finishError);
                    }
                }).catch((finishError));
        } else {
            finishDone(event.data);
        }
    })
};
