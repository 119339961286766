import AsyncStorage from '@react-native-async-storage/async-storage';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'logout';

interface Data extends Action {

}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        authenticating: false,
        authenticationError: undefined,
    };
}

export const logout = async (context: ContextType) => {
    //remove apollo cache
    await context.apolloGraphQL.client.clearStore();
    context.apolloGraphQL.removeBearerToken();
    await removeApolloCache();
    context.dispatch({ type: NAME });
};

const removeApolloCache = async () => {
    await AsyncStorage.removeItem('apollo3-cache-persist');
}
