// import { logger } from 'common/lib/logger';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { Carrier, MarketWizardStep, Product, State } from './../state';

const NAME = 'quickSale';

interface Data extends Action {
    product: Product,
    carrier: Carrier,
    accountNumber?: string,
    magData?: string,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    // logger.debug('Quick sale', data);
    return {
        ...state,
        wizard: {
            ...state.wizard,
            currentStep: MarketWizardStep.PRODUCT_INPUTS,
        },
        carrier: {
            carriers: [],
            loading: false,
            selected: data.carrier,
        },
        product: {
            products: [],
            loading: false,
            selected: data.product,
            inputsValues:{
                cardMagData: data.magData,
                accountNumber: data.accountNumber,
            }
        }
    };
}

export const quickSale = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
