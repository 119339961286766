import { APIError } from '../../../../common_modules/model/APIError';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'authenticationFailed';

interface Data extends Action {
    error: APIError,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        authenticating: false,
        authenticationError: data.error,
    };
}

export const authenticationFailed = (context: ContextType, data: Omit<Data, 'type'>) => {
    context.notification.error(data.error.message);
    context.dispatch({ type: NAME, ...data });
};
