import React, { useReducer } from "react";
import Context from "./reducer/context";
import { reducer } from "./reducer/reducer";
import { initialState } from "./reducer/state";
import { useCreditCardVerification } from "../CreditCardVerification/hook/useCreditCardVerification";
import { useCardinalSecure } from "../../components/Cardinal/hook/useCardinal";
import { useCustomerAuth } from "../CustomerAuth/hook/useCustomerAuth";
import { useTwoFactorAuth } from "../TwoFactorAuth/reducer/hook/useTwoFactorAuth";
import { useClientFingerPrint } from "../ClientFingerPrint/hook/useClientFingerPrint";
import { useTransactionRedirection } from "../TransactionRedirection/hook/useTransactionRedirection";
import { useUserActivity } from "../UserActivity/hook/useUserActivity";
import { useApplePay } from "../ApplePay/hook/useApplePay";
import { useStoreInformation } from "../StoreInfo/hook/useStoreInformation";
import { useApolloGraphQL } from "../ApolloGraphQL/hook/useGraphqlApolloClient";

interface Props {
  children?: any;
}

export function SubmitTransactionProvider(props: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const apolloGraphQL = useApolloGraphQL();
  const customerAuth = useCustomerAuth();
  const twoFactorAuth = useTwoFactorAuth();
  const storeInfo = useStoreInformation();
  const creditCardVerification = useCreditCardVerification();
  const clientFingerPrint = useClientFingerPrint();
  const transactionRedirection = useTransactionRedirection();
  const userActivity = useUserActivity();
  const logRocket = /* useLogRocket(); */ null;
  const applePay = useApplePay();
  const cardinalSecure = useCardinalSecure();

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        apolloGraphQL,
        customerAuth,
        creditCardVerification,
        storeInfo,
        logRocket,
        applePay,
        clientFingerPrint,
        transactionRedirection,
        userActivity,
        twoFactorAuth,
        cardinalSecure,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}
