import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Linking } from "react-native";
import { Text } from "react-native-paper";
interface Props {
  text: string;
  url: string;
  color?: string;
  navigation?: boolean;
}

/**
 * @function Component Hyperlink
 * @param {string} text Description link
 * @param {string} url Redirect url
 * @param {string} color Text color
 * @param {string} navigation Optional, by default the navigation is to external links, change to true navigation to navigate within the app
 */
const Hyperlink = (props: Props) => {
  const navigation = useNavigation();

  const handleRedirect = (url: string) => {
    if (props.navigation) Linking.openURL(url);
    else navigation.navigate(url);
  };

  return (
    <Text
      style={{ color: props?.color }}
      onPress={() => handleRedirect(props.url)}
    >
      {props.text}
    </Text>
  );
};

export default Hyperlink;
