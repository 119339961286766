import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Keyboard, Platform, StyleSheet, View } from "react-native";
import { TextInput, useTheme, Checkbox, Button } from "react-native-paper";
import { InputHelperError } from "../CustomInputs/InputHelperText";
import InputDate from "../CustomInputs/InputDate";
import { useMediaQuery } from "react-responsive";
import { CustomInput } from "../CustomInputs/CustomInputAdornment";
import SwipeCardScanner from "../SwipeCardScanner/SwipeCardScanner";
import { useMarket } from "../../../screens/Market/hook/useMarket";
import { StoreInfo } from "../../../screens/Market/reducer/state";
import { IS_SMARTPHONE } from "../../lib/PlatformDevice";
export interface PaymentData {
  token?: string;
  address: string;
  cardNumber: string;
  cvv: string;
  magData: string;
  name: string;
  zipCode: string;
  cardExp: Date;
  email?: string;
  remember?: boolean;
  acceptTerms?: boolean;
}

interface Props {
  termsAndConditions?: JSX.Element;
  control: any;
  err: any;
  storeInfo?: StoreInfo;
  setValue: (value: string) => void;
  blocked?: boolean;
  smallContainer?: boolean;
  getCoordinates?: (coordinates: {
    x: number;
    y: number;
    add?: number;
  }) => void;
}

/**
 * @function Component CreditCardPayment
 * @param {JSX.Element} termsAndConditions TermsAndConditions
 * @param {SubmitTransactionPayload} control Form control property
 * @param {Function} setValue Callback handle swipeCard set value
 * @param {Object} err Error object
 * @param {Object} blocked Disable edit inputs
 * @param {boolean} smallContainer If the parent container is not very large
 */
const CreditCardPayment = ({
  err,
  control,
  termsAndConditions,
  setValue,
  blocked,
  smallContainer,
  getCoordinates,
  storeInfo,
}: Props) => {
  const { colors } = useTheme();
  const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
  const [isSwiping, setSwiping] = useState(false);

  const maskCreditCard = (cardNumber: string) => {
    var results = cardNumber.match(/\d{1,4}/g);
    return cardNumber ? results?.join(" ") : "";
  };

  const maskDate = (date: string) => {
    var results = date.match(/\d{1,2}/g);
    return date ? results?.join("/") : "";
  };

  const sendCoordinates = (e) => {
    if (getCoordinates) {
      getCoordinates({
        x: e.nativeEvent.locationX,
        y: e.nativeEvent.locationY,
        add: e.add ? e.add + (err ? 5 : 0) : undefined,
      });
    }
  };

  
  useEffect(() => {
    if (Platform.OS === "ios") {
      Keyboard.dismiss();
    }
  }, [storeInfo]);

  return (
    <>
      <View style={{ marginTop: 12 }}>
        <Controller
          render={({ onChange, onBlur, value }) => (
            <View style={{ padding: 5 }}>
              {!isSmartPhone ? (
                <CustomInput
                  label="Card Number"
                  mode="outlined"
                  underlineColor={colors.primary}
                  maxLength={19}
                  style={{ backgroundColor: colors.surface, textAlign: "auto" }}
                  editable={blocked ? false : true}
                  onChangeText={(text) => {
                    onChange(text.replace(/\s+/g, ""));
                  }}
                  value={maskCreditCard(value)}
                  onBlur={onBlur}
                  right={
                    <Button
                      mode="outlined"
                      icon="credit-card"
                      style={{ borderColor: colors.onSurface }}
                      uppercase
                      color={colors.primary}
                      onPress={() => setSwiping(true)}
                    >
                      Swipe
                    </Button>
                  }
                  error={!!err.cardNumber}
                  keyboardType="number-pad"
                />
              ) : (
                <TextInput
                  onTouchStart={(e) => sendCoordinates({ ...e, add: 20 })}
                  label="Card Number"
                  mode="outlined"
                  underlineColor={colors.primary}
                  maxLength={19}
                  style={{ backgroundColor: colors.surface, textAlign: "auto" }}
                  editable={blocked ? false : true}
                  onChangeText={(text) => {
                    onChange(text.replace(/\s+/g, ""));
                  }}
                  value={maskCreditCard(value)}
                  onBlur={onBlur}
                  left={<TextInput.Icon name="credit-card" size={28} />}
                  error={!!err.cardNumber}
                  keyboardType="number-pad"
                />
              )}
              <InputHelperError
                isError={!!err.cardNumber}
                message={err.cardNumber?.message}
              />
            </View>
          )}
          control={control}
          name="cardNumber"
          defaultValue=""
        />
      </View>
      <View style={[styles.item_row, { padding: 5 }]}>
        <Controller
          render={({ onChange, onBlur, value }) => (
            <View
              style={{ flexDirection: "column", width: "48%" }}
              onTouchStart={(e) => sendCoordinates({ ...e, add: 20 })}
            >
              <InputDate
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={err}
              />
            </View>
          )}
          control={control}
          name="cardExp"
          defaultValue=""
        />
        <Controller
          render={({ onChange, onBlur, value }) => (
            <View style={{ flexDirection: "column", width: "48%" }}>
              <TextInput
                onTouchStart={(e) => sendCoordinates({ ...e, add: 20 })}
                label="CVV"
                onChangeText={(text) => {
                  onChange(text);
                }}
                value={value}
                editable={blocked ? false : true}
                maxLength={4}
                onBlur={onBlur}
                mode="outlined"
                style={{ backgroundColor: colors.surface, textAlign: "auto" }}
                left={<TextInput.Icon name="lock" size={28} />}
                keyboardType="number-pad"
              />
              <InputHelperError
                isError={!!err.cvv}
                message={err.cvv?.message}
              />
            </View>
          )}
          control={control}
          name="cvv"
          defaultValue=""
        />
      </View>
      <View style={{ padding: 5 }}>
        <Controller
          render={({ onChange, onBlur, value }) => (
            <>
              <TextInput
                onTouchStart={(e) => sendCoordinates({ ...e, add: 20 })}
                label="Card Holder Name"
                onChangeText={(text) => {
                  onChange(text);
                }}
                value={value}
                editable={blocked ? false : true}
                onBlur={onBlur}
                mode="outlined"
                style={{ backgroundColor: colors.surface, textAlign: "auto" }}
                left={<TextInput.Icon name="account-circle" size={28} />}
              />
              <InputHelperError
                isError={!!err.name}
                message={err.name?.message}
              />
            </>
          )}
          control={control}
          name="name"
          defaultValue={storeInfo?.name}
        />
      </View>
      <View style={[styles.item_row, { flexWrap: "wrap" }]}>
        <Controller
          render={({ onChange, onBlur, value }) => (
            <View
              style={{
                flexDirection: "column",
                width: !smallContainer ? "48%" : "100%",
                padding: 5,
              }}
            >
              <TextInput
                onTouchStart={sendCoordinates}
                label="Billing Address"
                onChangeText={(text) => {
                  onChange(text);
                }}
                value={value}
                editable={blocked ? false : true}
                onBlur={onBlur}
                mode="outlined"
                style={{ backgroundColor: colors.surface, textAlign: "auto" }}
                left={<TextInput.Icon name="location-history" size={28} />}
              />
              <InputHelperError
                isError={!!err.address}
                message={err.address?.message}
              />
            </View>
          )}
          control={control}
          name="address"
          defaultValue={storeInfo ? storeInfo.address.billingAddress1 : ""}
        />
        <Controller
          render={({ onChange, onBlur, value }) => (
            <View
              style={{
                flexDirection: "column",
                width: !smallContainer ? "48%" : "100%",
                padding: 5,
              }}
            >
              <TextInput
                onTouchStart={sendCoordinates}
                label="Zip Code"
                onChangeText={onChange}
                value={value}
                onBlur={onBlur}
                editable={blocked ? false : true}
                mode="outlined"
                style={{ backgroundColor: colors.surface, textAlign: "auto" }}
                // left={<TextInput.Icon name="location-on" size={28} />}
                keyboardType="number-pad"
              />
              <InputHelperError
                isError={!!err.zipCode}
                message={err.zipCode?.message}
              />
            </View>
          )}
          control={control}
          name="zipCode"
          defaultValue={storeInfo ? storeInfo.address.billingZipCode : ""}
        />
      </View>
      <View style={styles.terms}>
        <Controller
          render={({ onChange, onBlur, value }) => (
            <Checkbox.Android
              status={value ? "checked" : "unchecked"}
              color={colors.primary}
              onPress={() => onChange(!value)}
            />
          )}
          name="acceptTerms"
          control={control}
          defaultValue={false}
        />
        {termsAndConditions}
      </View>
      <InputHelperError
        isError={!!err.acceptTerms}
        message={err.acceptTerms?.message}
      />

      <SwipeCardScanner
        isOpen={isSwiping}
        isSwipe
        onClose={() => setSwiping(false)}
        onScanBarCode={(data) => {
          setValue(data);
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  item_row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  terms: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 10,
  },
});

export default CreditCardPayment;
