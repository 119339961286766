import { ContextType } from './../context';
import { registerReducer } from './../reducer';
import { MarketWizardStep, State } from './../state';

const NAME = 'resetMarketWizard';

registerReducer(NAME, reducer);

function reducer(state: State): State {

    return {
        ...state,
        transactionPayload: undefined,
        wizard:{
            currentStep: MarketWizardStep.WELCOME,
            subHeaderColor: state.storeInfo.theme.primaryColor,
        },
        category: {
            ...state.category,
            loading: false,
            selected: undefined,
        },
        carrier: {
            carriers: [],
            loading: false,
            selected: undefined,
        },
        product: {
            products: [],
            loading: false,
            selected: undefined,
        },
        transactionFailed: undefined,
        transactionIsProcessing: false,
        transaction: undefined,
    };
}

export const resetMarketWizard = (context: ContextType) =>
    context.dispatch({ type: NAME });
