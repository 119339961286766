import gql from 'graphql-tag';
import { PRODUCT_FRAGMENT } from '../../../screens/Market/reducer/actions/loadProducts.graphql';

export const SWIPE_CARD = gql`
    mutation SWIPE_CARD($magData: String!) {
        tools {
            swipeCard(magData: $magData) {
                __typename
                cardNumber
                ... on GiftCardMagData{
                    sku
                }
                ... on CreditCardMagData{
                    cardHolder
                    expYear
                    expMonth
                }
            }
        }
    }
`;

export const SCAN_QR = gql`
    mutation QR ($url: String!){
        payments {
            parseLink(url: $url) {
                token
                product {
                    ...POS_Product
                }
                category {
                    ... on ProductCategory{
                        id
                        name
                        sku
                        bgColor
                        logoUrl
                    }
                }
            }
        }
    }
    ${PRODUCT_FRAGMENT}
`;
