import React, {useState} from "react";
import {SafeAreaView, StyleSheet, TouchableOpacity, View} from "react-native";
import {Avatar, Text, TextInput, useTheme} from "react-native-paper";
import DropDown from "react-native-paper-dropdown";
import {CustomInput} from "../../common_modules/components/CustomInputs/CustomInputAdornment";
import {InputHelperError} from "../../common_modules/components/CustomInputs/InputHelperText";
import {InputField, InputFieldType} from "../../common_modules/lib/form-yup.util";
import {DataPhone} from "./ProductForm";
import {getPlatform, IS_DESKTOP_OR_LAPTOP, IS_SMARTPHONE} from "../../common_modules/lib/PlatformDevice";
import {useMediaQuery} from "react-responsive";
import _ from "lodash";
import {useApolloGraphQL} from "../../common_modules/providers/ApolloGraphQL/hook/useGraphqlApolloClient";
import {FIELD_AUTOCOMPLETE} from "./ProductForm.graphql";
import InputAutocomplete from "../../common_modules/components/CustomInputs/InputAutocomplete";

interface Props {
    input: InputField;
    value: any;
    onChangeValue: (value: any) => void;
    getContactFromContactBook: () => void;
    onBlur: () => void;
    dataPhoneNumber?: DataPhone;
    label?: string;
    icon?: string;
    error: any;
    mode: "outlined" | "flat";
    customLabel?: string;
    allowDropdown?: boolean;
    autoFocus?: boolean;
    onFocus?: () => void;
    preferredCountries?: [];
}

/**
 * @function Component ProductInputs
 * @description Component that returns a custom input according to the type and props property
 * @param {InputField} input Input render
 * @param {boolean} autoFocus Input props focus
 * @param {boolean} onBlur Callback handle event blur input
 * @param {boolean} onChangeValue Callback handle event change input
 * @param {boolean} value  Input props value
 * @param {DataPhone} dataPhoneNumber In case the field is phone type, the iso and an example of the country's telephone number are provided
 * @param {string} label Input Label
 * @param {string} icon Name icon left side input
 * @param {string} customLabel Custom label only field "Verify"
 * @param {Object} error Form errors
 * @param {Object} mode Input Mode
 */
export default function ProductInputs(props: Props) {
    let label =
        (!props.customLabel ? props.input.label : props.customLabel) +
        (props.input.required ? " (Required)" : " (Optional)");
    let component;
    let inputProps = {...props, label};

    switch (props.input.type) {
        case InputFieldType.select:
            component = SelectField;
            break;
        case InputFieldType.phone:
            component = inputPhone;
            if (props.country) {
                inputProps = {
                    ...inputProps,
                    onlyCountries: [props.country],
                    allowDropdown: false,
                    preferredCountries: [props.country],
                    getContactFromContactBook: props.getContactFromContactBook,
                };
            }
            break;
        case InputFieldType.autocomplete:
            inputProps = {
                ...inputProps,
                product: props.product
            };
            component = AutocompleteField;
            break;
        default:
            component = inputField;
            break;
    }

    if (component) {
        let icon = "chevron-right";

        if (props.input.type === "phone") {
            icon = undefined;
        }

        if (props.input.type === "email") {
            icon = "mail-outline";
        }

        if (props.input.name === "customerPIN") {
            icon = "fiber-pin";
        }

        if (props.input.type === "float" && props.input.name === "amount") {
            icon = "attach-money";
            // inputProps.onFocus = (event) => event.target.select();
            inputProps.autoFocus = true;
        }
        return component({...inputProps, icon});
    }
}

const inputField = ({
                        error,
                        mode,
                        onBlur,
                        onChangeValue,
                        getContactFromContactBook,
                        icon,
                        input,
                        label,
                        value,
                        onFocus,
                    }: Props) => {
    const {colors, fonts} = useTheme();
    const isNumericField =
        input.type === InputFieldType.float ||
        (input.type === InputFieldType.integer &&
            input.name.includes("accountNumber"));
    const fieldError =
        error[label?.includes("Verify") ? `verify${input.name}` : input.name];

    return (
        <>
            <Text
                style={{
                    paddingTop: 10,
                    paddingBottom: 8,
                    fontFamily: "Montserrat_Regular",
                }}
            >
                {label}
            </Text>
            <TextInput
                value={value}
                onChangeText={(value) => onChangeValue(value)}
                style={{backgroundColor: colors.surface}}
                theme={{fonts: fonts}}
                left={<TextInput.Icon name={icon}/>}
                onBlur={onBlur}
                error={!!fieldError}
                mode={mode}
                onFocus={onFocus}
                keyboardType={isNumericField ? "number-pad" : "email-address"}
                // right={
                //   input.name.includes("accountNumber") && !isWebPlatform() ? (
                //     <TextInput.Icon
                //       name="person-add-alt"
                //       onPress={getContactFromContactBook}
                //     />
                //   ) : null
                // }
            />
            <InputHelperError isError={!!fieldError} message={fieldError?.message}/>
        </>
    );
};

const inputPhone = ({
                        error,
                        mode,
                        onBlur,
                        onChangeValue,
                        getContactFromContactBook,
                        input,
                        label,
                        value,
                        dataPhoneNumber,
                    }: Props) => {
    const {colors} = useTheme();
    const fieldError =
        error[label?.includes("Verify") ? `verify${input.name}` : input.name];

    return (
        <>
            <Text
                style={{
                    paddingTop: 10,
                    paddingBottom: 8,
                    fontFamily: "Montserrat_Regular",
                }}
            >
                {label}
            </Text>
            <CustomInput
                value={value}
                onChangeText={(value) => onChangeValue(value)}
                style={{
                    backgroundColor: colors.surface,
                    marginBottom: !!error[input.name] ? 1 : 0,
                }}
                left={
                    <Text style={{fontSize: 18}}>{`+${dataPhoneNumber?.code}`}</Text>
                }
                onBlur={onBlur}
                placeholder={" " + dataPhoneNumber?.exampleNumber}
                error={!!fieldError}
                mode={mode}
                keyboardType="phone-pad"
                // right={
                //   !isWebPlatform() && (
                //     <IconButton
                //       icon="person-add-alt"
                //       onPress={getContactFromContactBook}
                //     />
                //   )
                // }
            />
            <InputHelperError isError={!!fieldError} message={fieldError?.message}/>
        </>
    );
};

const AutocompleteField = ({
                               error,
                               mode,
                               onChangeValue,
                               input,
                               label,
                               value,
                               product
                           }: Props) => {

    const {client} = useApolloGraphQL();
    const {fonts} = useTheme();

    const [options, setOptions] = useState([]);
    const [searchingOptions, setSearchingOptions] = useState(false);

    const [errorMessage, setErrorMessage] = useState("Start typing");

    let searchingProductTimer;

    const searchOptions = (search, input, product) => {
        if (search) {
            setSearchingOptions(true);
            clearTimeout(searchingProductTimer);
            searchingProductTimer = setTimeout(() => {
                client.query({
                    query: FIELD_AUTOCOMPLETE,
                    variables: {
                        "product": product.id,
                        "input": input,
                        "query": search
                    },
                }).then(({data}) => {
                    setSearchingOptions(false);
                    const options_values = [];
                    if (data) {
                        _.forEach(data.products.fieldAutoComplete, (option) => {
                            options_values.push(option);
                        });
                        setOptions(options_values);
                        if (options_values.length === 0) {
                            setErrorMessage("No options available");
                        }
                    } else {
                        setOptions([]);
                    }
                });
            }, 400);
        } else {
            setOptions([]);
            setErrorMessage("Start typing");
        }
    };

    return (
        <View style={styles.container}>
            <View
                style={{
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    flex: 1,
                }}
            >

                <View style={styles.containerAutoCompleteWeb}>
                    <View style={styles.autoCompleteWeb}>
                        <InputAutocomplete
                            setErrorMessage={setErrorMessage}
                            mode={mode}
                            label={label}
                            array={options}
                            theme={{fonts: fonts}}
                            field="name"
                            value={value}
                            clearArray={() => setOptions([])}
                            loading={searchingOptions}
                            error={options.length === 0 && errorMessage !== ""}
                            errorMessage={errorMessage}
                            onChangeValue={(text) => {
                                onChangeValue(text);
                            }}
                            onChangeText={(text) => {
                                onChangeValue(text);
                                if (text) {
                                    setErrorMessage("");
                                    searchOptions(text, input.name, product);
                                } else {
                                    setSearchingOptions(false);
                                    setOptions([]);
                                }
                            }}
                            // inputRightProps={inputRightProps}
                        />
                    </View>
                </View>
            </View>
        </View>
    );
};

const SelectField = ({
                         error,
                         mode,
                         onChangeValue,
                         input,
                         label,
                         value,
                     }: Props) => {
    const [showDropDown, setShowDropDown] = useState(false);
    return (
        <SafeAreaView style={styles.containerStyle}>
            <DropDown
                label={label}
                mode={mode}
                value={value}
                setValue={(value) => onChangeValue(value)}
                list={input.choices}
                visible={showDropDown}
                showDropDown={() => setShowDropDown(true)}
                onDismiss={() => setShowDropDown(false)}
                inputProps={{
                    right: <TextInput.Icon name="arrow-down"/>,
                }}
            />
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    containerStyle: {
        flex: 1,
        marginHorizontal: 20,
        justifyContent: "center",
    },
    container: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    containerAutoCompleteWeb: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    autoCompleteWeb: {
        flex: 1,
        paddingTop: 10,
        position: "relative",
        zIndex: 99999999,
    },
    autoCompleteMovil: {
        flex: 1,
        paddingTop: 20,
        position: "relative",
        zIndex: 99999999,
        elevation: getPlatform() === "android" ? 99999999 : 0,
    },
});
