import gql from 'graphql-tag';

export const CHECK_CREDIT_CARD = gql`
    mutation checkCreditCard($authCardInput: CheckCreditCardInput!) {
        tools {
            creditCardVerification {
                check(input: $authCardInput)
            }
        }
    }
`;

export const AUTHORIZE_CREDIT_CARD = gql`
    mutation AUTHORIZE_CREDIT_CARD($authCardInput: AuthorizeCreditCardAmountInput!) {
        tools {
            creditCardVerification {
                authorize(input: $authCardInput) {
                    token
                    billingDescriptor
                    cardType
                    attemptsLeft
                }
            }
        }
    }
`;




export const CONFIRM_CREDIT_CARD_AUTH = gql`
    mutation CONFIRM_CREDIT_CARD_AUTH($token: String!, $amount: Int!, $customerToken: String){
        tools{
            creditCardVerification{
                confirmAuthorization(token: $token, amount: $amount, customerToken: $customerToken){
                    success
                    attemptsLeft
                }
            }
        }
    }
`;

