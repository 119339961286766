import { ContextType } from 'react';
import Context from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'customerLoginFailed';

interface Data extends Action {
    error: string | JSX.Element,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        error: data.error,
        authenticating: false,
    };
}

export const customerLoginFailed = (context: ContextType<typeof Context>, data: Omit<Data, 'type'>) => {
    if (context.notify) {
        context.notify.error(data.error);
    }

    if (context.userActivity) {
        context.userActivity.warning('Customer login failed');
    }

    return context.dispatch({ type: NAME, ...data });
};
