import { ContextType } from '../context';
import { registerReducer } from '../reducer';
import { State } from '../state';
import { removeCustomerAuthData } from '../../../../lib/auth';

const NAME = 'removeAPIBearerToken';

registerReducer(NAME, reducer);

function reducer(state: State): State {
    removeBearerToken();
    if (state.persistent) {
        state.bearerToken.updateToken('');
    }

    return {
        ...state,
    };
}

const removeBearerToken = async () => {
    await removeCustomerAuthData();
};

export const removeAPIBearerToken = (context: ContextType) =>
    context.dispatch({ type: NAME });

