import React, { useReducer } from 'react';
import { useApolloGraphQL } from '../ApolloGraphQL/hook/useGraphqlApolloClient';
import Context from './reducer/context';
import { reducer } from './reducer/reducer';
import { initialState } from './reducer/state';

interface Props {
    children?: any
}

export function CreditCardVerificationProvider(props: Props) {

    const [state, dispatch] = useReducer(reducer, initialState);
    const apolloGraphQL = useApolloGraphQL();

    if (!apolloGraphQL) {
        throw new Error('CreditCardVerificationProvider must me placed inside a ApolloGraphQLProvider');
    }

    return (
        <Context.Provider value={{ state, dispatch, apolloGraphQL }}>
            {props.children}
        </Context.Provider>
    );
}
