import gql from 'graphql-tag';
export const REFRESH_TOKEN_RN = gql`
    mutation loginCustomer($username: String!, $password: String!, $rememberDevice: String) {
        customers {
        signIn(username: $username, password: $password, rememberDevice: $rememberDevice) {
            customerToken
            expireAt
            refreshToken
        }
        }
    }
`;

export const REFRESH_TOKEN_R = gql`
    query refreshToken{
        refreshToken
    }
`;
