import React, { useState } from "react";
import { StyleSheet, View, Image } from "react-native";
import { Card, IconButton, Title, Text, useTheme } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { Carrier } from "../../../screens/Market/reducer/state";
import { useMediaQuery } from "react-responsive";
import { isIosPlatform, isWebPlatform, IS_SMARTPHONE } from "../../lib/PlatformDevice";
import { appIdentifier } from "../../../identifier";
interface Props {
  carrier: Carrier;
  isFavorite: boolean;
  selectCarrier: (carrier: Carrier) => void;
  toggleFavoriteCarrier: (id: string) => void;
}

/**
 * @function Component Carrier Card
 * @param {Carrier} carrier Carrier Object
 * @param {Boolean} isFavorite Status favorite carrier
 * @param {Function} selectCarrier Callback handle selection a carrier
 * @param {Function} toggleFavoriteCarrier Callback handle toggle favorite carrier
 */
const CardCarrier = (props: Props) => {
  const navigation = useNavigation();
  const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
  const { colors } = useTheme();
  const isClient = appIdentifier === "CLIENT";
  let discount;
  if (isClient) {
    const discountMode = props.carrier.saleDiscountUpToMode;
    const discountQty = props.carrier.saleDiscountUpTo
      ? props.carrier.saleDiscountUpTo
      : 0;

    discount = discountQty
      ? discountMode === "FIXED"
        ? `$${discountQty}`
        : `${discountQty}%`
      : "";
  }

  return (
    <Card
      elevation={4}
      style={styles.card}
      onPress={() => {
        props.selectCarrier(props.carrier);
        navigation.navigate("Products", props.carrier);
      }}
    >
      <Card.Content
        style={{
          justifyContent: "space-between",
          height: isWebPlatform() ? 150 : isIosPlatform() ? undefined : 156,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Image
            style={[styles.tinyLogo]}
            source={{ uri: props.carrier.logoUrl }}
          />
          <IconButton
            style={{ marginTop: -6, marginRight: -6 }}
            icon={props.isFavorite ? "favorite" : "favorite-outline"}
            onPress={() => props.toggleFavoriteCarrier(props.carrier.id)}
          />
        </View>
        <Text style={{ color: colors.primary, alignSelf: "flex-start" }}>
          {isClient && discount ? `Up to ${discount} off` : " "}
        </Text>
        <View
            style={
              isIosPlatform() ? styles.iosCardContenStyle : styles.cardContenStyle
            }
        >
          <Title
            numberOfLines={2}
            style={{
              fontFamily: "Montserrat_Bold",
              fontSize: 16,
              textAlign: "left",
              lineHeight: isSmartPhone ? 20 : undefined,
            }}
          >
            {props.carrier.name}
          </Title>
        </View>
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    flexGrow: 3,
    height: 156,
    elevation: 1,
  },
  tinyLogo: {
    resizeMode: "contain",
    width: 50,
    height: 50,
  },
  iosCardContenStyle: {
    position: "absolute",
    marginTop: 20,
    marginLeft: 15,
    bottom: -50,
    width: isWebPlatform() ? "90%" : undefined,
  },
  cardContenStyle: {
    flexDirection: "column",
    alignItems: !isWebPlatform() ? "flex-end" : undefined,
    justifyContent: "flex-end",
    height: 40,
    marginTop: 20,
    flexWrap: "wrap",
    width: isWebPlatform() ? "90%" : undefined,
  },
});

export default CardCarrier;
