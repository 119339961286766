import { loadStoreInfo } from '../reducer/actions/loadStoreInfo';
import { ApiViewer, StoreInfo } from '../reducer/state';
import { DocumentNode } from 'graphql';
import { useContext } from 'react';
import Context from '../reducer/context';
import { cleanStoreInfo } from '../reducer/actions/cleanStoreInfo';

export interface StoreInformation<S, V> {
    store: StoreInfo & S,
    viewer: ApiViewer & V,
    loadStoreInfo: (query?: DocumentNode) => Promise<StoreInfo | any>
    cleanStoreInfo: () => any
}

export function useStoreInformation<S, V>(): StoreInformation<S, V> {
    const context = useContext(Context);
    if (!context) {
        throw new Error('Only can use `useStoreInformation` inside a valid StoreInformationProvider');
    }

    return {
        store: context.state.store,
        viewer: context.state.viewer,
        loadStoreInfo: async (query?: DocumentNode) => await loadStoreInfo(context, query),
        cleanStoreInfo: () => cleanStoreInfo(context)
    };
}
