import React from 'react'
import { TextInput } from 'react-native-paper'
import { TextInputProps } from 'react-native-paper/lib/typescript/components/TextInput/TextInput'
import styled from 'styled-components/native'

const InputContainer = styled.View`
  flex-direction: row;
`
const Input = styled.TextInput`
  flex: 1;
`
const AdornmentContainer = styled.View`
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`

interface Props extends TextInputProps {
}

/**
* @function Component CustomInput Adornment
* @description Customized input for add on left or right side any Jsx.Element 
* @param {JSX.Element} left Content left side of input
* @param {JSX.Element} right Content right side of input
*/
export const CustomInput = ({ left, right, ...props }: Props) => (
    <TextInput
        {...props}
        render={(inputProps) => (
            <InputContainer>
                {left && (
                    <AdornmentContainer>
                        {left}
                    </AdornmentContainer>
                )}
                <Input {...inputProps} />
                {right && (
                    <AdornmentContainer>
                        {right}
                    </AdornmentContainer>
                )}
            </InputContainer>
        )}
    />
)