import { ContextType } from '../context';
import { registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'resetTwoFactorAuth';

registerReducer(NAME, reducer);

function reducer(state: State): State {

    return {
        ...state,
        pin: undefined,
        token: undefined,
        waitingPin: false,
        sendingPin: false,
        onConfirmPIN: undefined,
        onCancel: undefined,
    };
}

export const resetTwoFactorAuth = (context: ContextType) =>
    context.dispatch({ type: NAME });
