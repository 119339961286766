import moment from 'moment';

// some helpful functions and types to works with "Dashboard" date pickers

export enum DashboardPeriod { CUSTOM = 'CUSTOM', PT24H = 'PT24H', P7D = 'P7D', P2W = 'P2W', P1M = 'P1M', P3M = 'P3M', P1Y = 'P1Y' }

export const getDatePeriodLabel = (minDate: Date, maxDate: Date, datePeriod?: DashboardPeriod) => {
    const prefix = 'the last ';
    switch (datePeriod) {
        case DashboardPeriod.PT24H:
            return `${prefix}24 hours`;
        case DashboardPeriod.P7D:
            return `${prefix}7 days`;
        case DashboardPeriod.P2W:
            return `${prefix}2 weeks`;
        case DashboardPeriod.P1M:
            return `${prefix}30 days`;
        case DashboardPeriod.P3M:
            return `${prefix}3 months`;
        case DashboardPeriod.P1Y:
            return `${prefix}12 months`;
        default:
            if (isToday(minDate, maxDate)) {
                return `${moment(maxDate).format('ll')} (Today)`;
            }

            if (isCurrentMonth(minDate, maxDate)) {
                return moment(maxDate).format('MMMM YYYY');
            }

            if (isCurrentYear(minDate, maxDate)) {
                return `January - ${moment().format('MMMM')} ${moment(maxDate).format('YYYY')}`;
            }

            if (isOneDay(minDate, maxDate)) {
                return moment(minDate).format('ll');
            }

            return `${moment(minDate).format('ll')} - ${moment(maxDate).format('ll')}`;
    }
};

export const isToday = (minDate: Date, maxDate: Date) => {
    return moment(minDate).format('ll') === moment(maxDate).format('ll') && moment(maxDate).format('ll') === moment().format('ll');
};

export const isCurrentMonth = (minDate: Date, maxDate: Date) => {
    return !isToday(minDate, maxDate) && moment(minDate).format('ll') === moment().startOf('month').format('ll') && moment(maxDate).format('ll') === moment().format('ll');
};

export const isCurrentYear = (minDate: Date, maxDate: Date) => {
    return !isToday(minDate, maxDate) && !isCurrentMonth(minDate, maxDate) && moment(minDate).format('ll') === moment().startOf('year').format('ll') && moment(maxDate).format('ll') === moment().format('ll');
};

export const isOneDay = (minDate: Date, maxDate: Date) => {
    return moment(minDate).format('ll') === moment(maxDate).format('ll');
};

export const createMinDate = (inp?: moment.MomentInput) => moment(inp).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
})


export const createMaxDate = (inp?: moment.MomentInput) => moment(inp).set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
})
