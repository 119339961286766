import React, {useEffect, useState} from 'react';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from "react-native-gesture-handler";
import { Button, Card, Divider, TextInput } from 'react-native-paper';
import DropDownComponent from '../../common_modules/components/DropDown/DropDown';
import RadioButtonPayment from '../../common_modules/components/RadioButton/RadioButtonPayment';
import { CustomText } from '../../common_modules/components/Text/Text';
import { updatePaymentMethodData } from '../../common_modules/lib/Transactions/transactions';
import { useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMarket } from '../Market/hook/useMarket';
import { UISettings } from '../Market/reducer/state';
import { useMediaQuery } from "react-responsive";
import Footer from '../../common_modules/components/Footer/Footer';
import HeaderTitle from '../../navigation/Header/HeaderTitle';
import { useStoreInformation } from '../../common_modules/providers/StoreInfo/hook/useStoreInformation';
import { IS_SMARTPHONE } from '../../common_modules/lib/PlatformDevice';

const idleData = [
    { label: '15M', value: 15 },
    { label: '30M', value: 30 },
    { label: '1H', value: 60 },
    { label: '1H : 30M', value: 90 },
    { label: '2H', value: 120 },
];

const Settings = () => {
    const { saveSettings, uiSettings, storeInfo } = useMarket();
    const { store } = useStoreInformation();
    const [paymentChecked, setPaymentChecked] = useState(uiSettings.preferredPaymentMethod || "CASH");
    const [saving, setSaving] = useState(false);
    const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
    const [supportedPaymentMethods, setSupportedPaymentMethods] = useState([]);

    useEffect(() => {
        setSupportedPaymentMethods(updatePaymentMethodData(store.supportedPaymentMethods))
    }, [])

    const schema = yup.object({
        'preferredPaymentMethod': yup.string().ensure().required().label('Preferred Payment Method'),
        'sessionIdleTimeout': yup.number()
            .transform((v) => Number.isNaN(v) ? 15 : Number(v))
            .strict(true)
            .default(15)
            .integer()
            .min(1)
            .max(120)
            .required()
            .label('Session Idle Timeout')
    }).noUnknown();

    const { control, handleSubmit, errors, setError, getValues, setValue } = useForm({
        mode: 'all',
        resolver: yupResolver(schema)
    });

    const Submit = (settings: UISettings) => {
        // emulated saving
        setSaving(true);
        setTimeout(() => setSaving(false), 1000);
        saveSettings({ ...settings, favoriteCarriers: uiSettings.favoriteCarriers });
    };

    return (
        <>
            <HeaderTitle title="Settings" />
            <ScrollView style={styles.container} contentContainerStyle={{paddingBottom: 60}}>
                <View style={{
                    flexDirection: isSmartPhone ? 'column' : 'row',
                    justifyContent: isSmartPhone ? 'flex-start' : 'space-between',
                    alignItems: !isSmartPhone ? 'flex-start' : undefined
                }}>
                    <View nativeID="dropdown" style={{ marginBottom: isSmartPhone ? 20 : undefined, width: isSmartPhone ? '100%' : '45%' }}>
                        <View style={{ marginBottom: 20 }}>
                            <CustomText type="bold" size={20}>SESSION IDLE TIMEOUT</CustomText>
                        </View>
                        <DropDownComponent
                            control={control}
                            name="sessionIdleTimeout"
                            data={idleData}
                            defaultValue={uiSettings.sessionIdleTimeout || 15}
                            inputPropsLeft={<TextInput.Icon name={'query-builder'} />}
                            inputPropsRight={<TextInput.Icon name={'unfold-more'} />}
                            onSelectionChange={(value) => { }}
                            infoMessage="The session idle timeout setting represents the amount of time a user
                                         can be inactive before the user's session times out and closes (default: 15min)"
                        />
                    </View>
                    <View style={{ width: isSmartPhone ? '100%' : '45%' }}>
                        <View style={{ marginBottom: 20 }}>
                            <CustomText type="bold" size={20}>PREFERRED PAYMENT METHOD</CustomText>
                        </View>
                        <Card>
                            <Card.Content>
                                <View style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                    {supportedPaymentMethods.map((pm, index) => (
                                        <Controller
                                            key={index}
                                            control={control}
                                            render={({ onChange, onBlur, value }) => (
                                                <RadioButtonPayment
                                                    key={index}
                                                    value={pm.value}
                                                    label={pm.label}
                                                    onChecked={(value) => {
                                                        onChange(value);
                                                        setPaymentChecked(value);
                                                    }}
                                                    checked={paymentChecked}
                                                    hideInfo={true}
                                                />
                                            )}
                                            name="preferredPaymentMethod"
                                            rules={{
                                                required: false,
                                            }}
                                            defaultValue={uiSettings.preferredPaymentMethod || "CASH"}
                                        />
                                    ))}
                                </View>
                            </Card.Content>
                        </Card>
                    </View>
                </View>
                <Divider style={{ height: 2, marginVertical: 20 }} />
                <Button style={{ marginHorizontal: isSmartPhone ? '20%' : '40%' }}
                    disabled={saving}
                    loading={saving}
                    mode="contained"
                    onPress={handleSubmit(Submit)}>
                    Save
                </Button>
            </ScrollView>
            {/*<Footer />*/}
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 20,
        paddingTop: 20,
    }
});


export default Settings;