import React, { Dispatch } from 'react';
import { StoreInformation } from '../../StoreInfo/hook/useStoreInformation';
import { ApolloGraphQL } from '../../ApolloGraphQL/hook/useGraphqlApolloClient';
import { ClientFingerPrint } from '../../ClientFingerPrint/hook/useClientFingerPrint';
import { TwoFactorAuth } from '../../TwoFactorAuth/reducer/hook/useTwoFactorAuth';
import { UserActivity } from '../../UserActivity/hook/useUserActivity';
import { Action } from './reducer';
import { State } from './state';

export interface ContextType {
    readonly _type?: 'CustomerAuth',
    dispatch: Dispatch<Action>,
    state: State,
    apolloGraphQL: ApolloGraphQL,
    twoFactorAuth?: TwoFactorAuth,
    notify?: Notification,
    userActivity?: UserActivity,
    clientFingerPrint: ClientFingerPrint
    storeInfo: StoreInformation<'S', 'V'>
}

export const CustomerAuthContext = React.createContext<ContextType>({} as any);
export default CustomerAuthContext;
