import React, {useEffect, useRef, useState} from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Image,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Keyboard,
    Platform
} from 'react-native';
import {ScrollView} from "react-native-gesture-handler";
import {Button, Card, TextInput, HelperText, Text, useTheme} from 'react-native-paper';
import Hyperlink from '../../common_modules/components/HiperLink/HyperLink';
import {
    getDisplayHeight,
    getDisplayWidth,
    isWebPlatform,
    IS_DESKTOP_OR_LAPTOP,
    IS_SMALL_SCREEN
} from '../../common_modules/lib/PlatformDevice';
import {usePOSLogin} from './hook/usePOSLogin';
import '@expo/match-media';
import {useMediaQuery} from "react-responsive";
import DismissKeyboard from '../../common_modules/components/DismissKeyboard/DismisKeyboard';
import useKeyboardHeight from "react-native-use-keyboard-height";
import {GridContainer} from "../../common_modules/components/Material/Layout/GridContainer";
import Constants from 'expo-constants';

interface StoreInfo {
    number: string,
    name: string,
    agent: {
        name: string,
        number: string,
        phone: string,
        logo: string,
    }
}

export default function Login({navigation}) {
    const {authenticate, authenticationError} = usePOSLogin();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [dimensions, setDimensions] = useState({width: getDisplayWidth(), height: getDisplayHeight()});
    const [showErrors, setShowErrors] = useState(false);
    const [showPassword, setShowPassword] = useState(true);
    const [loading, setLoading] = useState(false);
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP)
    const isSmallScreen = useMediaQuery(IS_SMALL_SCREEN)
    const secondInput = useRef();
    const keyboardHeight = useKeyboardHeight();

    const theme = useTheme();

    const onChange = ({window, screen}) => {
        setDimensions({width: screen.width, height: screen.height});
    };

    useEffect(() => {
        if (authenticationError) {
            setLoading(false);
        }
    }, [authenticationError]);

    useEffect(() => {
        Dimensions.addEventListener('change', onChange);
        return () => {
            Dimensions.removeEventListener('change', onChange);
        };
    });

    let logo_app = require('../../../assets/img/logo.png');
    let logo_web = require('../../../assets/img/logo.png');
    let name = 'Pay\'nUp';
    if (theme.agent && theme.agent.logo) {
        name = theme.agent.name;
        logo_app = {uri: theme.agent.logo};
        logo_web = theme.agent.logo;
    }

    let storeInfo: Partial<StoreInfo> = {};
    if (theme.dealer) {
        storeInfo = {
            name: theme.dealer.name,
            number: theme.dealer.number,
        };
    }

    const handleAuthenticate = async () => {
        if (!username || !password) {
            setShowErrors(true)
        } else {
            Keyboard.dismiss();
            setLoading(true);
            authenticate(username, password);
        }
    };

    const title = theme.agent ? (theme.dealer ? theme.dealer.name : theme.agent.name) : '';

    const version = Constants.manifest.version;

    return (
        <ScrollView
            contentContainerStyle={styles.container}
            style={{
                marginBottom: Platform.OS === "ios" ? keyboardHeight : undefined,
            }}
            keyboardShouldPersistTaps="handled"
        >
            <View style={[styles.container, {backgroundColor: theme?.colors.primary}]}>
                <KeyboardAvoidingView behavior="height">
                    <Card style={{marginBottom: 25, marginHorizontal: isDesktopOrLaptop ? '30%' : '10%'}}>
                        <Card.Content style={{margin: isWebPlatform() ? 0 : 10}}>
                            <View style={{alignItems: 'center'}}>
                                {Platform.OS !== "web" &&
                                <Image
                                    source={logo_app}
                                    style={styles.logoApp}/>
                                }
                                {Platform.OS === "web" &&
                                <img
                                    src={logo_web}
                                    alt={name}
                                    style={{maxWidth: '200px', maxHeight: '150px'}}
                                />
                                }
                            </View>
                            <View style={{marginTop: 40}}>
                                <View nativeID="disable-outline">
                                    <TextInput
                                        error={username.length == 0 && showErrors}
                                        onSubmitEditing={() => secondInput.current.focus()}
                                        label="Username"
                                        value={username}
                                        onChangeText={text => setUsername(text)}
                                        left={<TextInput.Icon name="account-circle" size={28}/>}
                                    />
                                    <HelperText type="error" visible={username.length == 0 && showErrors}>
                                        Username is required!
                                    </HelperText>
                                </View>
                                <View nativeID="disable-outline">
                                    <TextInput
                                        error={password.length == 0 && showErrors}
                                        label="Password"
                                        value={password}
                                        ref={secondInput}
                                        onChangeText={text => setPassword(text)}
                                        onSubmitEditing={() => handleAuthenticate()}
                                        secureTextEntry={showPassword}
                                        left={
                                            <TextInput.Icon name="lock" size={28}/>}
                                        right={
                                            <TextInput.Icon
                                                name={showPassword ? "remove-red-eye" : "visibility-off"}
                                                disabled={!password}
                                                size={28}
                                                onPress={() => setShowPassword((prevState) => !prevState)}/>}
                                    />
                                    <HelperText type="error" visible={password.length == 0 && showErrors}>
                                        Password is required!
                                    </HelperText>
                                </View>
                            </View>
                            <View style={[styles.buttonsContainer, {flexDirection: isSmallScreen ? 'column' : 'row'}]}>
                                <Button
                                    style={{width: isSmallScreen ? '100%' : '100%'}}
                                    mode="contained"
                                    onPress={() => {
                                        handleAuthenticate()
                                    }}
                                    loading={loading}
                                    disabled={loading}
                                >
                                    Login
                                </Button>
                                {/* <Button
                                    style={{ width: isSmallScreen ? '100%' : '45%', marginTop: isSmallScreen ? 10 : null }}
                                    mode="outlined"
                                    onPress={() => navigation.navigate('SignUp')}>
                                    Register
                                </Button> */}
                            </View>
                            <View style={{alignItems: "flex-end"}}>
                                <Text style={{fontSize: 10}}>
                                    v{version}
                                </Text>
                            </View>
                        </Card.Content>
                    </Card>

                    {theme.agent &&
                    <View style={[{marginHorizontal: isDesktopOrLaptop ? '40%' : '20%'}]}>
                        <Text style={{textAlign: 'center', color: 'white', fontSize: 16}}>
                            This website is operated by {theme.agent.name}.
                        </Text>
                        {theme.agent.phone &&
                        <div>
                            <Text style={{color: 'white'}}>
                                Call {theme.agent.phone} for inquiries.
                            </Text>
                        </div>
                        }
                    </View>
                    }

                    <Text style={[styles.textFooter, {marginHorizontal: isDesktopOrLaptop ? '30%' : '10%'}]}>
                        By using the Site, you accept this <Hyperlink text='Legal Notice.'
                                                                      url='https://www.paynup.com/policies/legal_notice'
                                                                      color='#ffffff'/>
                        The terms of this <Hyperlink text='Legal Notice'
                                                     url='https://www.paynup.com/policies/legal_notice'
                                                     color='#ffffff'/> may be changed at
                        any time and such changes will take immediate effect. By your continued use of the Site
                        thereafter, you agree to be bound by such changes.
                        You should visit this page from time to time to review the then-current terms of this <Hyperlink
                        text=' Legal Notice.'
                        url='https://www.paynup.com/policies/legal_notice' color='#ffffff'/>

                    </Text>

                    {Platform.OS === "web" &&
                    <View style={{alignItems: 'center', marginTop: 10}}>
                        <a target="_blank" href='https://play.google.com/store/apps/details?id=com.paynup.pos&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img
                            src={'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'}
                            style={{maxWidth: '150px', maxHeight: '100px'}}
                        />
                        </a>
                    </View>
                    }
                </KeyboardAvoidingView>
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    logoWeb: {
        width: '80%',
        height: 60,
        resizeMode: 'contain'
    },
    logoApp: {
        width: '80%',
        height: 100,
        resizeMode: 'contain'
    },
    buttonsContainer: {
        justifyContent: 'space-between',
        marginBottom: 5,
        marginTop: 5
    },
    textFooter: {
        textAlign: 'center',
        color: '#efefef',
        justifyContent: 'center',
        fontSize: isWebPlatform() ? 12 : 10,
        marginTop: 5,
    }
});
