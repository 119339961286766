import { setRedirectUrl } from '../reducer/actions/setRedirectUrl';
import { useContext } from 'react';
import Context from '../reducer/context';

export interface TransactionRedirection {
    open: (url: string, onClose?: () => void) => void
    close: () => void
}

export function useTransactionRedirection(): TransactionRedirection {
    const context = useContext(Context);
    if (!context) {
        return null;
    }

    return {
        open: (url: string, onClose?: () => void) => setRedirectUrl(context, { redirectUrl: url, onClose }),
        close: () => {
            setRedirectUrl(context, { redirectUrl: undefined });
            if (context.state.onClose) {
                context.state.onClose();
            }
        },
    };
}
