import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'setToken';

interface Data extends Action {
    token: string,
    target: string,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        token: data.token,
        pin: undefined,
        target: data.target,
        sendingPin: false,
    };
}

export const setToken = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
