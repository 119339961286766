export interface Notification {
    message: string | JSX.Element,
    variant: 'info' | 'error' | 'warning' | 'success'
}

export interface State {
    notifications: { [key: string]: Notification }
}

export const initialState: State = {
    notifications: {},
};
