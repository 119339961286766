import { persistCache, AsyncStorageWrapper } from "apollo3-cache-persist";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { ContextType } from "../context";
import { Action, registerReducer } from "../reducer";
import { State } from "../state";
import AsyncStorage from "@react-native-async-storage/async-storage";
import result from "../../generated/graphql";
import {
  offsetLimitPagination,
  relayStylePagination,
} from "@apollo/client/utilities";
import {API_URL} from "@env";
import { KJUR } from "jsrsasign";
import { FieldPolicy } from "@apollo/client/cache";
import { interceptor } from "./interceptor";
import { getSignJWT } from "../../../../lib/jwt";
import { appIdentifier } from "../../../../../identifier";

const fixedRelayStylePagination = (...args): FieldPolicy => ({
  ...relayStylePagination(...args),
  read: (...readArgs) => {
    const existing = readArgs[0];
    const originalRead = relayStylePagination(...args).read;
    if (!existing || !originalRead) {
      return;
    }
    return originalRead(...readArgs);
  },
});

const NAME = "initializeClient";

interface Data extends Action {
  client: ApolloClient<any>;
  clientWithToken: boolean;
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
  return {
    ...state,
    client: data.client,
    clientWithToken: data.clientWithToken,
  };
}

export const initializeClient = async (context: ContextType) => {
  /* TODO Generate sign token and verify time to expire || Temporary*/
  let token: string | null;
  if (appIdentifier === "MERCHANT") {
    token = context.state.bearerToken.getToken()
      ? context.state.bearerToken.getToken()
      : null;
  } else {
    const now = KJUR.jws.IntDate.get("now");
    const payload: null | string = await AsyncStorage.getItem("ApiToken");
    const apiToken =
      payload && payload.includes("expire") ? JSON.parse(payload) : null;
    if (
      apiToken &&
      typeof apiToken === "object" &&
      "expire" in apiToken &&
      apiToken.expire >= now
    ) {
      token = apiToken.token;
    } else {
      token = await getSignJWT();
    }
  }

  const cache = new InMemoryCache({
    possibleTypes: result.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          products: {
            merge: false,
          },
          productCategories: {
            merge: false,
          },
          transactions: {
            merge: false,
          },
          TransactionMutation: {
            merge: false,
          },
          storeInfo: {
            merge: false,
          },
          customers: {
            merge: false,
          },
        },
      },
      Mutations: {
        fields: {
          customers: {
            merge: false,
          },
          transactions: {
            merge: false,
          },
          CustomersMutations: {
            merge: false,
          },
          ConsumerAuthenticationMutation: {
            merge: false,
          },
          consumerAuthentication: {
            merge: false,
          },
          TransactionMutation: {
            merge: false,
          },
        },
      },
    },
  });

  await persistCache({
    cache,
    storage: new AsyncStorageWrapper(AsyncStorage),
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: headers.Authorization || token ? `Bearer ${headers.Authorization || token}` : null,
      },
    }));
    return forward(operation);
  });

  const errorLink = interceptor(context);

  const httpLink = new HttpLink({
    uri: API_URL,
  });

  const client = new ApolloClient({
    link: from([authMiddleware, errorLink, httpLink]),
    cache,
  });
  const args = {
    client,
    clientWithToken: context.state.bearerToken.getToken(),
  };

  return context.dispatch({ type: NAME, ...args });
};
