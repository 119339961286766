// import { logger } from 'common/lib/logger';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { Clerk, State, StoreInfo, UISettings } from './../state';

const NAME = 'loadMarketInfoSuccess';

interface Data extends Action {
    storeInfo: StoreInfo,
    clerk: Clerk,
    uiSettings: UISettings,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    // logger.debug('Market info loaded', data);
    return {
        ...state,
        loadingMarketInfo: false,
        uiSettings: data.uiSettings,
        storeInfo: data.storeInfo,
        clerk: data.clerk,
        wizard: {
            ...state.wizard,
            subHeaderColor: data.storeInfo.theme.primaryColor,
        }
    };
}

export const loadMarketInfoSuccess = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
