import React, { useEffect, useRef } from "react";
import { Dialog, Portal, Button } from "react-native-paper";
import { ScrollView, View } from "react-native";
import useKeyboardHeight from "react-native-use-keyboard-height";
import { useMediaQuery } from "react-responsive";
import { getDisplayWidth, isIosPlatform, isWebPlatform, IS_SMARTPHONE } from "../../lib/PlatformDevice";
interface DialogProps {
  title?: JSX.Element | string;
  content?: JSX.Element;
  actions?: JSX.Element;
  visible: boolean;
  onDismiss: () => void;
  dismissable?: boolean;
  coordinates?: { x: number; y: number; add?: number };
  getDimensions?: (width: number, height: number) => void;
}

/**
 * @function Component DialogComponent
 * @param {string} visible Ref name of empty list
 * @param {string} dismissable Allow to close the dialog with touch out
 * @param {string} onDismiss Handle close dialog
 * @param {string | JSX.Element} title Actions content
 * @param {JSX.Element} content Actions content
 * @param {JSX.Element} actions Actions content
 */
const DialogComponent = ({
  visible,
  dismissable,
  onDismiss,
  getDimensions,
  title,
  content,
  actions,
  coordinates,
}: DialogProps) => {
  type ScrollViewRef = ScrollView & {
    scrollTo(c: {
      y?: number | { x?: number; y?: number; animated?: boolean };
      x?: number;
      animated?: boolean;
    }): void;
    scrollToEnd(options?: { animated: boolean }): void;
  };

  const scrollViewRef = React.useRef<ScrollViewRef | null>(null);

  useEffect(() => {
    if (coordinates) {
      if (coordinates.add) {
        scrollViewRef.current?.scrollTo({
          y: coordinates.y + coordinates.add,
          animated: true,
        });
      } else {
        scrollViewRef.current?.scrollToEnd({
          animated: true,
        });
      }
    }
  }, [coordinates]);

  const keyboardHeight = useKeyboardHeight();
  const isSmartPhone = useMediaQuery(IS_SMARTPHONE);

  return (
    <Portal>
      <Dialog
        style={{
          marginBottom: isIosPlatform() ? keyboardHeight : undefined,
          marginHorizontal:
            !isSmartPhone && isWebPlatform() ? getDisplayWidth() * 0.3 : 20,
        }}
        visible={visible}
        dismissable={dismissable}
        onDismiss={onDismiss}
      >
        <View
          onLayout={(event) => {
            let { x, y, width, height } = event.nativeEvent.layout;
            if (getDimensions) getDimensions(width, height);
          }}
        >
          <Dialog.ScrollArea style={{ paddingHorizontal: 0 }}>
            <ScrollView keyboardShouldPersistTaps="handled" ref={scrollViewRef}>
              <View pointerEvents="box-none">
                {!!title && (
                  <Dialog.Title style={{ textAlign: "center" }}>
                    {title}
                  </Dialog.Title>
                )}
                <Dialog.Content>{content}</Dialog.Content>
                <Dialog.Actions>{actions}</Dialog.Actions>
              </View>
            </ScrollView>
          </Dialog.ScrollArea>
        </View>
      </Dialog>
    </Portal>
  );
};

export default DialogComponent;
