import { START_APPLE_SESSION } from './ApplePay.graphql';
import { SubmitTransactionPlugin } from './Plugin';
import { validateTransaction } from '../../../../../lib/Transactions/transactions';
import { APIError } from '../../../../../model/APIError';
import { TransactionPaymentMethod } from '../../../../../model/TransactionPaymentMethod';

export const ApplePayPlugin: SubmitTransactionPlugin = {
    onSubmit: (event) => new Promise((finishDone, finishError) => {
        if (event.context.applePay && event.data.paymentMethod === TransactionPaymentMethod.APPLE_PAY) {
            validateTransaction(event.context.apolloGraphQL.client, {
                product: event.data.product,
                paymentMethod: event.data.paymentMethod,
                inputs: event.data.inputs,
            }).then((result) => {
                if (!event.context.applePay || !event.context.applePay.session) {
                    throw new Error('There are not valid Apple Pay session initialized');
                }

                event.context.applePay.session.onvalidatemerchant = (e) => {

                    // logger.debug('Validate Apple Pay merchant');

                    event.context.apolloGraphQL.client.mutate({
                        mutation: START_APPLE_SESSION,
                        variables: {
                            validationURL: e.validationURL,
                            domainName: window.location.host,
                            displayName: event.context.storeInfo.store.name,
                        }
                    }).then(({ data }) => {
                        event.context.applePay.session.completeMerchantValidation(data.payments.startApplePaySession);
                    });
                };

                event.context.applePay.session.oncancel = (e) => {
                    finishError(new Error('Transaction aborted'));
                };

                event.context.applePay.session.onpaymentauthorized = (e) => {
                    // @ts-ignore
                    event.context.applePay.session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                    finishDone({
                        ...event.data,
                        applePay: {
                            billingContact: e.payment.billingContact ? {
                                familyName: e.payment.billingContact.familyName,
                                givenName: e.payment.billingContact.givenName,
                                phoneNumber: e.payment.billingContact.phoneNumber,
                                emailAddress: e.payment.billingContact.emailAddress,
                            } : undefined,
                            shippingContact: e.payment.shippingContact ? {
                                familyName: e.payment.shippingContact.familyName,
                                givenName: e.payment.shippingContact.givenName,
                                phoneNumber: e.payment.shippingContact.phoneNumber,
                                emailAddress: e.payment.shippingContact.emailAddress,
                            } : undefined,
                            token: e.payment.token
                        },
                    });
                };

                event.context.applePay.session.onpaymentmethodselected = (e) => {
                    event.context.applePay.session.completePaymentMethodSelection({
                        newTotal: {
                            label: `${result.product.carrier.name} - ${result.product.name}`,
                            amount: Number(result.total).toFixed(2),
                            type: 'final'
                        }
                    });
                };

                event.context.applePay.session.begin();

            }).catch((error) => {
                finishError(new APIError(error.code, error.message));
            });
        } else {
            finishDone(event.data);
        }
    }),
    onSuccess: (event) => new Promise((success) => {
        // clear session
        if (event.context.applePay && event.context.applePay.session){
            event.context.applePay.setSession(undefined);
        }

        success(event.data);
    }),
    onError: (event) => new Promise((success) => {
        // clear session
        if (event.context.applePay && event.context.applePay.session){
            event.context.applePay.setSession(undefined);
        }

        success(event.data);
    })
};
