import React, { useReducer } from 'react';
import Context from './reducer/context';
import { reducer } from './reducer/reducer';
import { initialState } from './reducer/state';
import { useNotification } from '../../common_modules/providers/Notification/hook/useNotification';
import { useSubmitTransaction } from '../../common_modules/providers/SubmitTransaction/hook/useSubmitTransaction';
import { useApolloGraphQL } from '../../common_modules/providers/ApolloGraphQL/hook/useGraphqlApolloClient';

interface Props {
    children?: any
}

export function MarketProvider(props: Props) {

    const [state, dispatch] = useReducer(reducer, initialState);
    const apolloGraphQL = useApolloGraphQL();
    const notify = useNotification();
    const submitTransaction = useSubmitTransaction();

    return (
        <Context.Provider value={{ state, dispatch, apolloGraphQL, notify, submitTransaction }}>
            {props.children}
        </Context.Provider>
    );
}
