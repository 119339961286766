import React, { Dispatch } from 'react';
import { Action } from './reducer';
import { State } from './state';

export interface ContextType {
    readonly _type?: 'ClientFingerPrint',
    dispatch: Dispatch<Action>,
    state: State,
}

export const ClientFingerPrintContext = React.createContext<ContextType>(null);
export default ClientFingerPrintContext;
