import { ApplePayPlugin } from './plugins/ApplePay';
import { CardinalCommercePlugin } from './plugins/CardinalCommerce';
import { LogRocketPlugin } from './plugins/LogRocket';
import { SubmitTransactionEvent, SubmitTransactionPlugin } from './plugins/Plugin';
import { PrepareTransactionPlugin } from './plugins/PrepareTransaction';
import { RedirectionPlugin } from './plugins/RedirectionPlugin';
import { TwoFactorAuthentication } from './plugins/TwoFactorAuthentication';
import { VerifyCreditCardPlugin } from './plugins/VerifyCreditCard';
import { setError } from './setError';
import { setResponse } from './setResponse';
import { updateTotal } from './updateTotal';
import { convertAPIError, submitTransaction, SubmitTransactionArgs, SubmitTransactionComplete } from '../../../../lib/Transactions/transactions';
import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';
import { captureException } from '../../../../lib/sentry';

const NAME = 'submit';

const plugins: SubmitTransactionPlugin[] = [
    PrepareTransactionPlugin,
    CardinalCommercePlugin,
    VerifyCreditCardPlugin,
    ApplePayPlugin,
    LogRocketPlugin,
    TwoFactorAuthentication,
    RedirectionPlugin,
];

interface Data extends Action {
    transaction: SubmitTransactionArgs
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    return {
        ...state,
        submitting: true,
        error: undefined,
        response: undefined,
        transaction: data.transaction,
    };
}

/**
 * Submit
 *
 * @param context
 * @param data
 */
export const submit = (context: ContextType, data: Omit<Data, 'type'>): Promise<SubmitTransactionComplete> =>
    new Promise<SubmitTransactionComplete>((submitSuccess, submitError) => {
        context.dispatch({ type: NAME, ...data });

        const transactionSuccess = (response) => {
            setResponse(context, { response });
            submitSuccess(response);
        };

        const transactionError = (error) => {
            setError(context, { error });
            submitError(error);
        };

        // update total is optional, only log errors
        updateTotal(context, data.transaction).catch((e) => {
            // logger.debug(e);
        });

        const onTransactionError = (error, transaction) =>
            callPluginsEvent({
                name: 'onError',
                context,
                data: {
                    error,
                    transaction,
                    retry: (newTransaction) => submit(context, { transaction: newTransaction })
                        .then(transactionSuccess)
                        .catch(transactionError)
                }
            }).then((result) => transactionError(result.error)).catch(transactionError);

        // submit
        callPluginsEvent({ name: 'onSubmit', context, data: data.transaction })
            .then((transaction: SubmitTransactionArgs) => {

                // logger.debug('Submitting transaction', transaction);

                submitTransaction(context.apolloGraphQL.client, transaction)
                    .then((result) => {
                        // logger.debug('Transaction completed', result);

                        // call plugins to use the response
                        callPluginsEvent({ name: 'onSuccess', context, data: result })
                            .then((result) => {
                                transactionSuccess(result);
                            })
                            .catch((e) => {
                                captureException(e);
                                return onTransactionError(convertAPIError(e), data.transaction)
                            });

                    }).catch((e) => {
                        captureException(e);
                        return onTransactionError(convertAPIError(e), transaction)
                    });

            })
            .catch((e) => {
                captureException(e);
                return onTransactionError(convertAPIError(e), data.transaction)
            });
    });


// call given event in all registered plugins
// plugins must return a promise with the new event data, works similar like a reducer
function callPluginsEvent(event: SubmitTransactionEvent, index = 0): Promise<any> {
    return new Promise((success, error) => {
        let actionToDo = plugins[index][event.name];

        // the plugin does not listen for given event
        if (!actionToDo) {
            actionToDo = (e: SubmitTransactionEvent) => new Promise((success) => success({ ...e.data }));
        }

        actionToDo(event)
            .then((result) => {
                if (plugins[index + 1]) {
                    callPluginsEvent({ ...event, data: result }, index + 1)
                        .then(success)
                        .catch((e) => {
                            captureException(e);
                            return error(convertAPIError(e))
                        });
                } else {
                    success(result);
                }
            }).catch(error);
    });
}

