import { clear } from '../reducer/actions/clear';
import { submit } from '../reducer/actions/submit';
import { update } from '../reducer/actions/update';
import { SubmitTransactionArgs, SubmitTransactionComplete, ValidationTransactionSuccess } from '../../../lib/Transactions/transactions';
import { TotalAndAdjustments } from '../../../model/Adjustment';
import { APIError } from '../../../model/APIError';
import { useContext } from 'react';
import Context from '../reducer/context';

export interface SubmitTransaction {
    update: (transaction: Partial<SubmitTransactionArgs>) => Promise<ValidationTransactionSuccess>,
    submit: (transaction: SubmitTransactionArgs) => Promise<SubmitTransactionComplete>
    clear: () => void,
    transaction: SubmitTransactionArgs | undefined
    total: TotalAndAdjustments | undefined
    updatingTotal: boolean,
    submitting: boolean
    response?: SubmitTransactionComplete | undefined
    error: APIError | undefined
}

export function useSubmitTransaction(): SubmitTransaction {
    const context = useContext(Context);
    if (!context) {
        throw new Error('Only can use `useSubmitTransaction` inside a valid SubmitTransactionProvider');
    }

    return {
        update: (transaction: Partial<SubmitTransactionArgs>) => update(context, { transaction }),
        submit: (transaction: SubmitTransactionArgs) => submit(context, { transaction }),
        clear: () => clear(context),
        transaction: context.state.transaction,
        total: context.state.total,
        updatingTotal: context.state.updatingTotal,
        submitting: context.state.submitting,
        response: context.state.response,
        error: context.state.error,
    };
}
