import { registerActivity } from '../reducer/actions/registerActivity';
import { useContext } from 'react';
import Context from '../reducer/context';

export interface UserActivity {
    info: (details: string, finish?: boolean, start?: boolean) => void,
    warning: (message: string, finish?: boolean, start?: boolean) => void,
    error: (message: string, finish?: boolean, start?: boolean) => void,
}

export function useUserActivity(): UserActivity {
    const context = useContext(Context);

    if (!context) {
        return;
    }

    return {
        info: (details, finish = false, start = false) => registerActivity(context, {
            record: {
                details,
                finish,
                start,
                type: 'INFO',
                date: new Date(),
            }
        }),
        warning: (details, finish = false, start = false) => registerActivity(context, {
            record: {
                details,
                finish,
                start,
                type: 'WARNING',
                date: new Date(),
            }
        }),
        error: (details, finish = false, start = false) => registerActivity(context, {
            record: {
                details,
                finish,
                start,
                type: 'ERROR',
                date: new Date(),
            }
        }),
    };
}
