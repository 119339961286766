import React from 'react';
import { APIError } from '../../model/APIError';
import { TransactionPaymentMethod } from '../../model/TransactionPaymentMethod';
import { TransactionCheckoutError } from './TransactionCheckoutError';

interface Props {
    error: APIError,
    paymentMethod?: TransactionPaymentMethod,
}

const TransactionError = ({ error, paymentMethod }: Props) => {
    return (
        <TransactionCheckoutError
            error={error}
            paymentMethod={paymentMethod}
        />
    );
};

export default TransactionError;