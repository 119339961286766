import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'updateAttemptsLeft';

interface Data extends Action {
   attemptsLeft:number,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        authorization:{
            ...state.authorization,
            attemptsLeft: data.attemptsLeft,
        }
    };
}

export const updateAttemptsLeft = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
