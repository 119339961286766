import { customerLogin } from '../reducer/actions/customerLogin';
import { customerLogout } from '../reducer/actions/customerLogout';
import CustomerAuthContext from '../reducer/context';
import { useContext } from 'react';
import { waitingAuthBiometric } from '../reducer/actions/waitingAuthBiometric';
import { isValidJsonWebToken } from '../../../lib/jwt';

export interface CustomerAuth {
    token: string,
    // the the token manually for login emulation, helpful after registration
    loginVirtualCheckingAccount: (username: string, password: string, token?: string, rememberMe?: boolean, expDate?: Date) => Promise<any>,
    loginECommerce: (username: string, password: string, token?: string, rememberMe?: boolean, expDate?: Date) => Promise<any>,
    logout: () => any,
    authBiometric: (loading: boolean) => void,
    authenticating: boolean,
    isAuthenticated: boolean,
}

export function useCustomerAuth(): CustomerAuth {
    const context = useContext(CustomerAuthContext);
    if (!context) {
        throw new Error('CustomerAuthProvider is required to do this operation')
    }

    const isAuthenticated = context.state && !!context.state.token && isValidJsonWebToken(context.state.token);

    return {
        token: context.state ? context.state.token : undefined,
        loginVirtualCheckingAccount: (username, password, token, rememberMe, expDate) => customerLogin(context, {
            username,
            password,
            token,
            customerContext: 'VIRTUAL_CHECKING_ACCOUNT',
            expDate,
            rememberMe
        }),
        loginECommerce: (username, password, token, rememberMe, expDate) => customerLogin(context, {
            username,
            password,
            token,
            customerContext: 'ECOMMERCE',
            expDate,
            rememberMe
        }),
        logout: () => customerLogout(context),
        authBiometric: (loading) => waitingAuthBiometric(context, loading),
        authenticating: context.state ? context.state.authenticating : undefined,
        isAuthenticated,
    };
}
