import React, { useState } from "react";
import { StoreInfo } from "../../../screens/Market/reducer/state";
import QuickSalesMerchant from "./QuickSalesMerchant";
import QuickSalesClient from "./QuickSalesClient";
import { appIdentifier } from "../../../identifier";
interface Props {
  setSku: (sku: string) => void;
  setSearchingProduct: (s: boolean) => void;
  setProducts: (arr: []) => void;
  setQuickSaleError: (e: string) => void;
  searchProducts: (c: string) => void;
  quickSale: (sku: string) => void;
  setSwiping: (s: boolean) => void;
  products: any[];
  searchingProduct: boolean;
  quickSaleError: string;
  searchingSku: boolean;
  loadingPaymentLink: boolean;
  sku: string;
}

const CardQuickSales = ({
  setSku,
  setSearchingProduct,
  setProducts,
  setQuickSaleError,
  searchProducts,
  quickSale,
  setSwiping,
  products,
  quickSaleError,
  searchingProduct,
  loadingPaymentLink,
  searchingSku,
  sku,
}: Props) => {
  const isMerchant = appIdentifier === "MERCHANT";

  return isMerchant ? (
    <QuickSalesMerchant
      searchProducts={searchProducts}
      searchingProduct={searchingProduct}
      searchingSku={searchingSku}
      setQuickSaleError={setQuickSaleError}
      quickSale={quickSale}
      quickSaleError={quickSaleError}
      products={products}
      setSwiping={setSwiping}
      loadingPaymentLink={loadingPaymentLink}
      setProducts={setProducts}
      setSearchingProduct={setSearchingProduct}
      setSku={setSku}
      sku={sku}
    />
  ) : (
    <QuickSalesClient />
  );
};

export default CardQuickSales;
