import gql from 'graphql-tag';

export const DEFAULT_STORE_INFO_FRAGMENT = gql`
    fragment DEFAULT_STORE_INFO_FRAGMENT on Store{
        number
        phone
        name
        billingDescriptor
        favoriteProductsCountry
        address {
            line1
            line2
            city
            state
            zipCode
            country
        }
        agent{
            name
        }
        guestCheckout
        billingDescriptor
        applePay {
            merchantIdentifier
            allowedDomains
        }
        consumerAuthentication
        requireVerifiedCreditCardPayments
        restrictedCreditCards
        supportedPaymentMethods
        privateLabelGiftCardSettings {
            productLoad {
                id
                sku
            }
            productRedeem {
                id
                sku
            }
        }
    }
`;

export const DEFAULT_VIEWER_FRAGMENT = gql`
    fragment DEFAULT_VIEWER_FRAGMENT on User{
        id
        login
        name
        sandbox
        roles
    }
`;

export const LOAD_STORE_INFO = gql`
    query LOAD_STORE_INFO {
        storeInfo {
            ...DEFAULT_STORE_INFO_FRAGMENT
        }
        viewer {
            ...DEFAULT_VIEWER_FRAGMENT
        }
    }
    ${DEFAULT_VIEWER_FRAGMENT}
    ${DEFAULT_STORE_INFO_FRAGMENT}
`;
