import { authenticate } from '../reducer/actions/authenticate';
import { logout } from '../reducer/actions/logout';
import { useContext } from 'react';
import Context from '../reducer/context';
import { APIError } from '../../../common_modules/model/APIError';

export interface POSLogin {
    isAuthenticating: boolean,
    authenticationError: APIError,
    authenticate: (username: string, password: string) => void,
    logout: () => void,
    isAuthenticated: boolean,
}

export function usePOSLogin(): POSLogin {
    const context = useContext(Context);
    if (!context) {
        throw new Error('Only can use `usePOSLogin` inside a valid LoginProvider');
    }
    return {
        isAuthenticating: context.state.authenticating,
        authenticationError: context.state.authenticationError,
        authenticate: (username, password) => authenticate(context, { username, password }),
        isAuthenticated: context.apolloGraphQL.clientWithToken,
        logout: () => logout(context),
    };
}