import { ContextType } from './../context';
import { registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'toggleSidebar';


registerReducer(NAME, reducer);

function reducer(state: State): State {

    return {
        ...state,
        sidebar: {
            ...state.sidebar,
            opened: !state.sidebar.opened,
        }
    };
}

export const toggleSidebar = (context: ContextType) =>
    context.dispatch({ type: NAME });
