import { Dispatch, createContext } from "react";
import { Notification } from '../../../providers/Notification/hook/useNotification';
import { Market } from "../../../../screens/Market/hook/useMarket";
import { Action } from "./actions/actionTypes";
import { SignUpState } from "./state";
import { ApolloGraphQL } from "../../../providers/ApolloGraphQL/hook/useGraphqlApolloClient";

export interface ContextType {
    dispatch: Dispatch<Action>,
    state: SignUpState
    apolloGraphQL: ApolloGraphQL,
    notification: Notification,
    market: Market
}

export const RegistrationContext = createContext<ContextType | null>(null);
export default RegistrationContext;