import gql from "graphql-tag";

export const FETCH_CARRIERS = gql`
    query FETCH_CARRIERS($search: String, $endCursor: String, $where: ProductCategoryCondition!) {
        productCategories {
            all(first: 30, after: $endCursor, search: $search, where: $where, order: {field: name}) {
                pageInfo{
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    page
                }
                edges {
                    node {
                        id
                        name
                        sku
                        bgColor
                        logoUrl
                    }
                }
            }
        }
    }    
`;
