import { Dispatch, createContext } from "react";
import { ApolloGraphQL } from "../../../providers/ApolloGraphQL/hook/useGraphqlApolloClient";
import { Notification } from '../../../providers/Notification/hook/useNotification';
import { Action } from '../reducer/actions/actionTypes';
import { CardinalState } from "./state";

export interface ContextType {
    dispatch: Dispatch<Action>,
    state: CardinalState
    apolloGraphQL: ApolloGraphQL,
    notification: Notification,
}

export const CardinalContext = createContext<ContextType | null>(null);
export default CardinalContext;