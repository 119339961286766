import { SubmitTransactionCreditCard } from "../../../lib/Transactions/transactions";

export interface AuthorizedCreditCardAmount {
    token: string
    billingDescriptor: string
    cardType: string
    attemptsLeft: number,
}

export interface State {
    creditCard?: SubmitTransactionCreditCard,
    onCancel?: ()=> void,
    onAuthorize?: () => Promise<any>,

    authorization?: AuthorizedCreditCardAmount,
    waitingAuthorizedAmount: boolean,
    error?: string | JSX.Element | undefined,

    // confirm process
    onConfirm?: (amount: number) => Promise<any>,
}

export const initialState: State = {
    waitingAuthorizedAmount: false,
};
