import { State } from './state';

export interface Action {
    type: string
}

const reducers = {};

export const registerReducer = (name: string, r: (state: State, data: Action) => State) => {
    reducers[name] = r;
};

export const reducer = (state: State, action: Action): State =>
    reducers[action.type](state, action);
