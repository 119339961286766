export enum TransactionPaymentMethod {
    CASH = 'CASH',
    CASH_BACK = 'CASH_BACK',
    EXTERNAL_CARD_TERMINAL = 'EXTERNAL_CARD_TERMINAL',
    CREDIT_CARD = 'CREDIT_CARD',
    EGIFT = 'EGIFT',
    EGIFT_MESH = 'EGIFT_MESH',
    APPLE_PAY = 'APPLE_PAY',
    WALLET = 'WALLET',
    VIRTUAL_CHECKING_ACCOUNT = 'VIRTUAL_CHECKING_ACCOUNT',
    PRIVATE_LABEL_GIFT_CARD = 'PRIVATE_LABEL_GIFT_CARD',
}
