import gql from 'graphql-tag';

export const FIELD_AUTOCOMPLETE = gql`
    query ProductList($product: ID!, $input: String!, $query: String!) {
        products {
            fieldAutoComplete(product: $product, input: $input, query: $query) {
                value
                label
                mask
                query
            }
        }
    }
`;

