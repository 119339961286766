import React, {useState} from "react";
import {Card, Divider, Title, useTheme, Text, TextInput, Button} from "react-native-paper";
import {Product} from "../../../screens/Market/reducer/state";
import {StyleSheet, View} from "react-native";
import {useMediaQuery} from "react-responsive";
import {useNavigation} from "@react-navigation/native";
import {IS_DESKTOP_OR_LAPTOP, IS_SMALL_SCREEN, IS_SMARTPHONE} from "../../lib/PlatformDevice";
import {appIdentifier} from "../../../identifier";
import SwipeCardScanner from "../SwipeCardScanner/SwipeCardScanner";
import {VERIFY_NUMBER_OR_MAG_DATA} from "../../../screens/ScanCode/CustomerPayment.graphql";
import {useApolloGraphQL} from "../../providers/ApolloGraphQL/hook/useGraphqlApolloClient";
import {getPrice} from "../../lib/Transactions/transactions";
import InputPrivateLabelGiftCard from "../CustomInputs/InputPrivateLabelGiftCard";
import {Controller, useForm} from "react-hook-form";
import CardPayment from "./CardPayment";
import {TransactionPaymentMethod} from "../../model/TransactionPaymentMethod";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import LoadingComponent from "../Loading/Loading";
import _ from "lodash";

interface Props {
    url?: string;
    hidePrice?: boolean
}

/**
 * @function Component Card Verify
 */
const CardVerify = (props: Props) => {
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
    const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
    const {colors} = useTheme();
    const isClient = appIdentifier === "CLIENT";
    const {client} = useApolloGraphQL();
    const [isSwiping, setSwiping] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isScanning, setScanning] = useState(false);
    const [number, setNumber] = useState("");
    const [magData, setMagData] = useState("");
    const [giftCard, setGiftCard] = useState({});
    const [showGiftCard, setShowGiftCard] = useState(false);

    let schemaFields: { [name: string]: yup.SchemaOf<any> } = {};

    schemaFields['magData'] = yup.string().ensure();
    schemaFields['number'] = yup.string().ensure().required('Swipe or Scan a Gift Card to proceed.');

    const schema = yup.object(schemaFields);
    const {
        control,
        handleSubmit,
        errors,
        setError,
        getValues,
        setValue,
        trigger,
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
    });

    const getDate = (date: string) => {
        return (new Date(date)).toLocaleDateString() + " - " + (new Date(date)).toLocaleTimeString();
    }

    const verifyPrivateLabelGiftCard = async (numberOrMagData: string) => {
        setLoading(true);
        try {
            const {data} = await client.mutate({
                mutation: VERIFY_NUMBER_OR_MAG_DATA,
                fetchPolicy: "no-cache",
                variables: {
                    numberOrMagData: numberOrMagData,
                },
            });
            setShowGiftCard(true);
            setGiftCard(data.privateLabelGiftCards.verify);
            setLoading(false);
        } catch (e) {
            setShowGiftCard(false);
            setGiftCard({error: e.message})
            setLoading(false);
        }
    }

    const handleSubmitInputs = async () => {
        setShowGiftCard(false);
        setGiftCard({});
        await verifyPrivateLabelGiftCard(number ? number : (magData ? magData : ""));
    };

    return (
        <Card
            style={styles.card}
        >
            <Card.Content style={{flexGrow: 3}}>
                <View style={styles.cardContent}>
                    <View style={{justifyContent: "flex-start"}}>
                        <Title
                            numberOfLines={3}
                            style={{
                                fontFamily: "Montserrat_Bold",
                                fontSize: 18,
                                lineHeight: isSmartPhone ? 18 : undefined,
                                marginVertical: 0,
                                bottom: 1,
                            }}
                        >
                            Check Balance
                        </Title>
                    </View>
                </View>
            </Card.Content>
            <View style={{padding: 10}}>

                <View
                    nativeID="disable-outline"
                    style={{marginTop: isSmartPhone ? 20 : 10}}
                >
                    <>
                        <InputPrivateLabelGiftCard
                            isScan={() => setScanning(true)}
                            isSwipe={() => setSwiping(true)}
                            control={control}
                            errors={errors}
                            setScanningCode={(value) => {
                                setMagData("");
                                setValue("magData", "");

                                setNumber(String(value).substr(-19));
                                setValue("privateLabelNumber", String(value).substr(-19));

                                setShowGiftCard(false);
                                setGiftCard({});
                            }}
                        />

                        <Controller
                            control={control}
                            render={({onChange, onBlur, value}) => (
                                <>
                                    <TextInput
                                        value={value}
                                        onChangeText={(value) => {
                                            onChange(value)
                                        }}
                                        onBlur={onBlur}
                                        style={{display: "none"}}
                                    />
                                </>
                            )}
                            name="magData"
                            defaultValue=""
                        />
                    </>

                    <View style={{paddingTop: 10}}>
                        {isLoading && <LoadingComponent/>}
                    </View>

                    {showGiftCard &&
                        <>
                            <View style={[styles.container, {paddingTop: 20}]}>
                                <Text style={styles.textTotal}>Total</Text>
                                <Text style={styles.textTotalPrice}>{getPrice(giftCard.balance)}</Text>
                            </View>
                            <View style={[styles.container, {paddingTop: 10}]}>
                                <Text style={styles.textTotal}>Activated</Text>
                                <Text style={styles.textTotalPrice}>{giftCard.activatedAt ? getDate(giftCard.activatedAt) : "-"}</Text>
                            </View>
                        </>
                    }
                    {giftCard.error &&
                        <View style={[styles.container, {paddingTop: 10}]}>
                            <Text style={[styles.textTotal, {color: "red"}]}>{giftCard.error}</Text>
                        </View>
                    }

                    <View style={{marginTop: 10, marginBottom: 10}}>
                        <Button
                            disabled={isLoading || (number ? false : !magData)}
                            style={{marginTop: 10}}
                            mode="contained"
                            onPress={handleSubmitInputs}
                        >
                            CHECK
                        </Button>
                    </View>
                </View>
            </View>
            <View style={{paddingTop: !isDesktopOrLaptop ? "2%" : "2%"}}>
                <Divider style={{backgroundColor: colors.primary}}/>
            </View>
            <SwipeCardScanner
                isOpen={isSwiping}
                onClose={() => {
                    setSwiping(false);
                }}
                onScanBarCode={(barcode) => {
                    setShowGiftCard(false);
                    setGiftCard({});
                    verifyPrivateLabelGiftCard(barcode.length > 12 ? String(barcode).substr(-19) : "");
                }}
                onSwipe={(data) => {
                    setShowGiftCard(false);
                    setGiftCard({});
                    verifyPrivateLabelGiftCard(data.cardNumber ? data.cardNumber : (data.magData ? data.magData : ""));
                }}
            />
        </Card>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    textTotal: {
        fontFamily: "Montserrat_Semi_Bold",
        fontSize: 18,
        width: "50%",
    },
    textTotalPrice: {
        fontFamily: "Montserrat_Extra_Bold",
        fontSize: 16,
    },
    card: {
        flexGrow: 1,
        elevation: 1,
    },
    cardContent: {
        flexDirection: "column",
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
});

export default CardVerify;
