import gql from 'graphql-tag';

export const VERIFY_PAYMENT = gql`
mutation verify($paymentAgreementToken: String!) {
    paymentAgreement {
      verify(token: $paymentAgreementToken) {
        token
        amount
        expireAt
        createdAt
      }
    }
  }
`;

export const REQUEST_APPROVAL = gql`
mutation requestApproval($paymentAgreementToken: String!, $amount: Float!) {
    paymentAgreement {
      requestApproval(token: $paymentAgreementToken, amount: $amount) {
        token
        amount
        expireAt
        createdAt
      }
    }
  }
`;

export const REQUEST_APPROVAL_SUBSCRIPTION = gql`
  subscription requestApproval($paymentAgreementToken: String!, $amount: Float!) {
    paymentAgreement {
      requestApproval(token: $paymentAgreementToken, amount: $amount) {
        ... on RequestApprovalPaymentAgreementEvent {
          data {
            token
            amount
            expireAt
            createdAt
            customer {
              fullName
              email
              phone
            }
          }
        }
        ... on SubscriptionLink {
          url
        }
      }
    }
  }
`;

export const DENY_PAYMENT_AGREEMENT = gql`
mutation deny($paymentAgreementToken: String!) {
  paymentAgreement {
    deny(token: $paymentAgreementToken) {
      token
      amount
      expireAt
      createdAt
      customer {
        fullName
        email
        phone
      }
    }
  }
}
`;

export const VERIFY_NUMBER_OR_MAG_DATA = gql`
query verify($numberOrMagData: String!) {
    privateLabelGiftCards {
      verify(numberOrMagData: $numberOrMagData) {
        number
        balance
        activatedAt
      }
    }
  }
`;
