import { StoreInfoController } from './StoreInfoController';
import { DocumentNode } from 'graphql';
import React, { useReducer } from 'react';
import Context from './reducer/context';
import { reducer } from './reducer/reducer';
import { initialState } from './reducer/state';
import { useApolloGraphQL } from '../ApolloGraphQL/hook/useGraphqlApolloClient';

interface Props {
    children?: any
    query?: DocumentNode
}

export function StoreInfoProvider(props: Props) {

    const [state, dispatch] = useReducer(reducer, initialState);
    const apolloGraphQL = useApolloGraphQL();

    return (
        <Context.Provider value={{ state, dispatch, apolloGraphQL }}>
            <StoreInfoController query={props.query} />
            {props.children}
        </Context.Provider>
    );
}
