import React, { useEffect, useState } from "react";
import {
  DataTable,
  Avatar,
  Text,
  useTheme,
  IconButton,
  ProgressBar,
} from "react-native-paper";
import { useSummary } from "../../common_modules/providers/Summary/hook/useSummary";
import { useMediaQuery } from "react-responsive";
import {
  TransactionSummaryOptions,
  TransactionSummaryType,
} from "../../common_modules/providers/Summary/lib/transactions";
import {
  getDisplayHeight,
  getPlatform,
  isWebPlatform,
  IS_SMARTPHONE,
} from "../../common_modules/lib/PlatformDevice";
import { StyleSheet, View } from "react-native";
import { getPrice } from "../../common_modules/lib/Transactions/transactions";
import _ from "lodash";
import { TopProductChart } from "../../common_modules/components/Charts/TopProductsChart";
import { ScrollView } from "react-native-gesture-handler";
import LoadingComponent from "../../common_modules/components/Loading/Loading";
import { useStoreInformation } from "../../common_modules/providers/StoreInfo/hook/useStoreInformation";

interface Props {
  type: TransactionSummaryType;
  summaryOptions: Partial<TransactionSummaryOptions>;
  detailsSummaryOptions: (rowData: any) => Partial<TransactionSummaryOptions>;
  grossCommission?: boolean;
  netCommission?: boolean;
}

const TopProducts = (props: Props) => {
  const { colors } = useTheme();
  const summary = useSummary();
  const { viewer } = useStoreInformation();
  const [summaryData, setSummaryData] = useState({
    data: [],
    expandedRows: [],
  });
  const [loading, setLoading] = useState(false);
  const isSmartPhone = useMediaQuery(IS_SMARTPHONE);

  useEffect(() => {
    setLoading(true);
    const subscription = summary
      .fetchTransactionSummary({
        ...(props.summaryOptions as any),
        type: props.type,
        view: null,
        commissions: props.netCommission || props.grossCommission,
        sandbox: viewer.sandbox,
      })
      .subscribe((data) => {
        setLoading(false);
        setSummaryData((prevData) => ({ ...prevData, data }));
      });

    return () => subscription.unsubscribe();
  }, [props.summaryOptions, props.type]);

  useEffect(() => {
    setSummaryData({ data: [], expandedRows: [] });
  }, [props.type]);

  const handleRowClick = (rowId) => {
    const currentExpandedRows = summaryData.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((expand) => expand !== rowId)
      : currentExpandedRows.concat(rowId);

    setSummaryData((prevData) => ({
      ...prevData,
      expandedRows: newExpandedRows,
    }));
  };

  const detailsSummaryOptions = (rowData) =>
    _.merge(
      { ...props.summaryOptions },
      { where: { product: { nodes: [rowData] } } }
    );

  const renderRow = (data: any) => {
    const itemRows = [
      <DataTable.Row
        style={styles.borderDash}
        key={"row-data" + data._extraData.id}
      >
        <DataTable.Cell style={{ flex: 2 }}>
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Avatar.Image
              style={{ backgroundColor: "transparent", marginRight: 10 }}
              source={{
                uri: data._extraData.logo,
              }}
              size={24}
            />
            <Text numberOfLines={1}>{data._extraData.name}</Text>
          </View>
        </DataTable.Cell>
        <DataTable.Cell style={{ flex: 1, paddingLeft: 25 }}>
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Text>{data.count}</Text>
          </View>
        </DataTable.Cell>
        <DataTable.Cell style={{ flex: 1 }}>
          <Text>{getPrice(data.count ? data.sales / data.count : 0)}</Text>
        </DataTable.Cell>
        <DataTable.Cell style={{ justifyContent: "space-between" }}>
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ width: 75 }}>{getPrice(data.sales)}</Text>
            <IconButton
              icon={
                !summaryData.expandedRows.includes(data._extraData.id)
                  ? "keyboard-arrow-down"
                  : "keyboard-arrow-up"
              }
              size={18}
              onPress={() => handleRowClick(data._extraData.id)}
            />
          </View>
        </DataTable.Cell>
      </DataTable.Row>,
    ];

    if (summaryData.expandedRows.includes(data._extraData.id)) {
      itemRows.push(
        <DataTable.Row
          style={styles.borderDash}
          key={"row-expanded-" + data._extraData.id}
        >
          <View style={styles.wrapperChart}>
            <Text style={styles.chartTitle1}>Transactions over time</Text>
            <TopProductChart
              summaryData={summaryData.data}
              type={TransactionSummaryType.TOP_PRODUCTS}
              summaryOptions={detailsSummaryOptions(data._extraData.id)}
            />
          </View>
        </DataTable.Row>
      );
    }

    return <View key={data._extraData.id}>{itemRows}</View>;
  };

  return (
    <ScrollView horizontal={isSmartPhone ? true : false}>
      <DataTable
        style={{
          backgroundColor: "#ffffff",
          width: isSmartPhone ? getDisplayHeight() * 0.9 : undefined,
        }}
      >
        <DataTable.Header>
          <DataTable.Title style={{ flex: 2 }}>
            <Text style={styles.chartTitle2}> Product</Text>
          </DataTable.Title>
          <DataTable.Title>
            <Text style={styles.chartTitle2}>TXN</Text>
          </DataTable.Title>
          <DataTable.Title>
            <Text style={styles.chartTitle2}> Avg Sales</Text>
          </DataTable.Title>
          <DataTable.Title>
            <Text style={styles.chartTitle2}> Sales</Text>
          </DataTable.Title>
        </DataTable.Header>
        {loading && <ProgressBar indeterminate color={colors.primary} />}
        {summaryData.data && summaryData.data.map((data) => renderRow(data))}
      </DataTable>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  wrapperChart: {
    backgroundColor: "#ffffff",
    borderRadius: 5,
    width: "100%",
  },
  chartTitle1: {
    fontFamily: "Montserrat_Bold",
    fontSize: 24,
    padding: 5,
  },
  chartTitle2: {
    fontFamily: "Montserrat_Bold",
    fontSize: isWebPlatform() ? 18 : 14,
    padding: 5,
  },
  chartText: {
    fontFamily: "Montserrat_Light",
    fontSize: isWebPlatform() ? 16 : 12,
    padding: 5,
  },
  chartHeader: {
    backgroundColor: "#d23232",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    height: 40,
    borderRadius: 5,
    marginVertical: 10,
  },
  borderDash: {
    borderStyle: getPlatform() === "ios" ? "solid" : "dashed",
    borderColor: "#D1D2DE",
    backgroundColor: "#FFFFFF",
    borderBottomWidth: 2,
    borderRadius: 5,
  },
});

export default TopProducts;
