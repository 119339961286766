import React, { useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Card, IconButton, Title } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { Carrier } from '../../../screens/Market/reducer/state';
import SvgQRCode from 'react-native-qrcode-svg';
import { useMarket } from '../../../screens/Market/hook/useMarket';
import _ from 'lodash';
import { getDisplayWidth } from '../../lib/PlatformDevice';
interface Props {
    carrier: Carrier,
    number: number
}

/**
* @function Component Carrier Card QR Favorites
* @param {Carrier} carrier Carrier Object
* @param {number} number Store Number
*/
const CardCarrierQR = ({ carrier, number }: Props) => {
    const navigation = useNavigation();
    const [qrSize, setQrSize] = useState<number>(180);
    const [iconSize, setIconSize] = useState<number>(25);

    const { saveSettings, uiSettings, selectCarrier } = useMarket()

    const isFavorite = (carrierId: string) => uiSettings.favoriteCarriers.includes(carrierId);

    const toggleFavoriteCarrier = (id: string) => {
        let favoriteCarriers = Array.from(uiSettings.favoriteCarriers);
        if (_.includes(favoriteCarriers, id)) {
            _.remove(favoriteCarriers, (fav) => {
                return fav === id;
            });
        } else {
            favoriteCarriers.push(id);
        }
        saveSettings({ ...uiSettings, favoriteCarriers });
    };

    const widthCard = getDisplayWidth() >= 1450 && getDisplayWidth() <= 1750 || getDisplayWidth() <= 900 || getDisplayWidth() >= 1200 && getDisplayWidth() <= 1280 ? 130 : 160;

    return (
        <Card style={[styles.card]} onPress={() => {
            selectCarrier(carrier);
            navigation.navigate('Products', carrier);
        }}>
            <Card.Content onLayout={(event) => {
                let { x, y, width, height } = event.nativeEvent.layout;
                setQrSize(width * 0.8);
                setIconSize(width * 0.18);
            }} style={{ height: 280, justifyContent: 'space-between' }}>
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 10,
                    width: widthCard
                }}>
                    <Image style={[styles.tinyLogo, { width: iconSize, height: iconSize }]} source={{ uri: carrier.logoUrl }} />
                    <IconButton
                        style={{ paddingRight: 0, marginRight: 0 }}
                        icon={isFavorite(carrier.id) ? 'favorite' : 'favorite-outline'}
                        onPress={() => toggleFavoriteCarrier(carrier.id)}
                    />
                </View>
                {!(number === 21232 || number === 116748) &&
                    <View style={{justifyContent: "center", alignContent: "center", alignSelf: "center"}}>
                        <Image style={[styles.tinyLogo, { width: '110px', height: '110px' }]} source={{ uri: carrier.logoUrl }} />
                    </View>
                }
                {(number === 21232 || number === 116748) &&
                    <SvgQRCode size={qrSize} value={carrier.standalonePaymentLink}
                    />
                }
                <View style={{ justifyContent: 'flex-end', height: 50 }}>
                    <Title
                        numberOfLines={2}
                        style={{
                            fontFamily: 'Montserrat_Bold',
                            fontSize: 16,
                            textAlign: 'left',
                            lineHeight: 20,
                            marginVertical: 0,
                            marginTop: 8,
                            width: qrSize,
                        }}>{carrier.name}</Title>
                </View>
            </Card.Content>
        </Card>
    );
};

const styles = StyleSheet.create({
    card: {
        flexGrow: 3,
        height: 280
    },
    tinyLogo: {
        width: getDisplayWidth() * 0.02,
        height: getDisplayWidth() * 0.02,
        resizeMode: 'stretch',
    }
})

export default CardCarrierQR;