// import { logger } from 'common/lib/logger';
import { loadProducts } from '../../reducer/actions/loadProducts';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { Carrier, MarketWizardStep, State } from './../state';

const NAME = 'selectCarrier';

interface Data extends Action {
    carrier: Carrier,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    // logger.debug(`Carrier "${data.carrier.name}" selected`);
    return {
        ...state,
        wizard: {
            ...state.wizard,
            currentStep: MarketWizardStep.PRODUCTS,
            subHeaderColor: data.carrier.color,
        },
        carrier: {
            ...state.carrier,
            selected: data.carrier,
        },
        product: {
            loading: true,
            products: [],
            selected: undefined
        }
    };
}

export const selectCarrier = (context: ContextType, data: Omit<Data, 'type'>) => {
    loadProducts(context, { carrier: data.carrier });
    context.dispatch({ type: NAME, ...data });
};
