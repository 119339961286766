import { onError } from "@apollo/client/link/error";
import { ContextType } from "../context";
import { Action, registerReducer } from "../reducer";
import { State } from "../state";

const NAME = 'registerErrorCode';

interface Data extends Action {
    lastRequestCode: number
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        lastRequestCode: data.lastRequestCode
    };
}

/**
 * @function interceptor
 * @param  context ApolloGraphQlContext
 * @description Failed request interceptor
 */
export const interceptor = (context: ContextType) => onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
        graphQLErrors.forEach((response) => {
            context.dispatch({ type: NAME, lastRequestCode: response.code })
            if (response.code === 401 && context.state.clientWithToken) {
                return forward(operation);
            }
        });

    if (networkError) context.notification.error(`Network error ocurred: ${networkError.message}`);
});