import gql from 'graphql-tag';

export const FETCH_CATEGORIES = gql`
    query FETCH_CATEGORIES {
        productCategories {
            all(first: 100, where: {parent: {nodes: []}}) {
                edges {
                    node {
                        id
                        name
                        description
                        icon
                        sort
                        sku
                        bgColor
                        ... on WirelessCategory {
                            separateForeignCarriers
                            foreignCategoryName
                            foreignCategoryDescription
                            foreignCategoryIcon
                            foreignCategoryBgColor
                            localCategoryName
                            localCategoryDescription
                        }
                    }
                }
            }, 
        }
    }
`;
