import { useContext, Dispatch } from "react"
import { secureWithCardinal, closeSecureCardinal } from "../reducer/actions/actions";
import { Action } from "../reducer/actions/actionTypes";
import CardinalContext from "../reducer/context"
import { CardinalOptions, CardinalResponse, CardinalState } from "../reducer/state";

export interface CardinalSecure extends CardinalState {
    secureWithCardinal: (options: CardinalOptions) => Promise<CardinalResponse>,
    closeSecureCardinal: (cache?: any) => void
}

export const useCardinalSecure = (): CardinalSecure => {
    const context = useContext(CardinalContext);
    if (!context) {
        throw new Error('Only can use `useCardinal` inside a valid CardinalProvider');
    }

    return {
        options: context.state.options,
        response: context.state.response,
        secureWithCardinal: (options) => secureWithCardinal(context, options),
        visible: context.state.visible,
        closeSecureCardinal: (cache) => closeSecureCardinal(context, cache),
    }
}