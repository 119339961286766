import {
  persistCustomerAuthToken,
  removeCustomerAuthPersistedToken,
} from "../../persistenceAuth";
import React, { useContext } from "react";
import { ContextType } from "../context";
import { Action, registerReducer } from "../reducer";
import { State } from "../state";
import { LOGIN_CUSTOMER } from "./customerLogin.graphql";
import { customerLoginFailed } from "./customerLoginFailed";
import { customerLoginSuccess } from "./customerLoginSuccess";
import { waitingTwoFactorAuth } from "./waitingTwoFactorAuth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CustomerAuthData, setAuthenticationMethod } from "../../../../lib/auth";
import { convertAPIError } from "../../../../lib/Transactions/transactions";
import { captureException } from "../../../../lib/sentry";
import { PaynUpAPIError } from "../../../ApolloGraphQL/ApolloGraphQL";

const NAME = "customerLogin";

interface Data extends Action {
  username: string;
  password: string;
  token?: string; // set the token manually, does not call the api
  customerContext: "VIRTUAL_CHECKING_ACCOUNT" | "ECOMMERCE";
  rememberMe?: boolean;
  expDate?: Date;
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
  const { username, password, customerContext, rememberMe, expDate } = data;

  return {
    ...state,
    username,
    password,
    rememberMe,
    expDate,
    context: customerContext,
    authenticating: true,
    waitingTwoFactorAuth: false,
    error: undefined,
  };
}

export const customerLogin = (
  context: ContextType,
  _data: Omit<Data, "type">
) =>
  new Promise(async (loginSuccess, loginFailed) => {
    const { username, password, expDate, customerContext } = _data;
    context.dispatch({ type: NAME, ..._data });

    if (_data.token) {
      // customerLoginSuccess(context, { token: _data.token });
      // loginSuccess(_data.token);
      // await persistCustomerAuthToken(_data.token, _data.rememberMe);
    } else {
      // use TFA if provider is available
      let twoFactorAuthToken = null;
      let twoFactorAuthPIN = null;
      if (
        context.twoFactorAuth &&
        context.twoFactorAuth.pin &&
        context.twoFactorAuth.token
      ) {
        twoFactorAuthToken = context.twoFactorAuth.token;
        twoFactorAuthPIN = context.twoFactorAuth.pin;

        // reset for next execution
        context.twoFactorAuth.reset();
      }

      try {
        const {
          data: {
            customers: { signIn },
          },
        } = await context.apolloGraphQL.client.mutate({
          mutation: LOGIN_CUSTOMER,
          fetchPolicy: "no-cache",
          variables: {
            username,
            password,
            expDate,
            context: customerContext,
            twoFactorAuthToken,
            twoFactorAuthPIN,
            rememberDevice: context.clientFingerPrint.fingerPrint,
          },
        });

        const dataAuth: CustomerAuthData = {
          username,
          token: signIn.customerToken,
          refreshToken: signIn.refreshToken,
          expireAt: signIn.expireAt,
        };

        await AsyncStorage.removeItem("apollo-cache-persist");
        context.apolloGraphQL.setBearerToken(signIn.customerToken);
        customerLoginSuccess(context, { token: signIn.customerToken });
        loginSuccess(signIn.customerToken);
        await persistCustomerAuthToken(dataAuth, _data.rememberMe);
        setAuthenticationMethod( context.apolloGraphQL.dispatch, "SECRET");
      } catch (e) {
        const error = e as PaynUpAPIError;
        let errorMessage = null;
        let errorCode = 0;
        if (error.graphQLErrors.length) {
          errorCode = error.graphQLErrors[0].code;
        } else if (error.networkError) {
          errorCode = error.networkError.statusCode;
        }

        switch (errorCode) {
          case 401:
            errorMessage = "Unauthorized, invalid credentials";
            break;
          case 1105:
            errorMessage =
              "Unauthorized, Please make sure you enter the last authorization code you received.";
            break;
          case 1104:
          case 1106:
            if (!context.twoFactorAuth) {
              throw new Error(
                "TwoFactor Authentication is required but the context is not available. " +
                  "Must place the CustomerAuthProvider inside a valid TwoFactorAuthProvider"
              );
            }

            context.twoFactorAuth
              .sendPin({ username, password, protocol: "SMS" })
              .catch((error) => {
                loginFailed(error);
              });
            waitingTwoFactorAuth(context, { waiting: true });
            break;
          default:
            errorMessage = "Unknown Error";
        }

        if (errorMessage) {
          customerLoginFailed(context, { error: errorMessage });
          loginFailed(convertAPIError(error));
        }
        captureException(error);
      }
    }
  });
