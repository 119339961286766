import gql from 'graphql-tag';

export const SEND_PIN = gql`
    mutation sendPIN($protocol: TwoFactorAuthenticationProtocol!, $username: String, $password: String, $target: String, $customerToken: String){
        customers {
            twoFactorAuth(protocol: $protocol, username: $username, password: $password, target: $target, customerToken: $customerToken, context: VIRTUAL_CHECKING_ACCOUNT) {
                token
                protocol
                target
            }
        }
    }
`;