import { usePOSLogin } from '../Login/hook/usePOSLogin';
import { useMarket } from '../Market/hook/useMarket';
import React, { useState } from 'react';
import ModalComponent from '../../common_modules/components/Modal/Modal';
import UserInactivity from 'react-native-user-inactivity';
import { View } from 'react-native';
import { LogBox } from 'react-native';
import { isWebPlatform } from '../../common_modules/lib/PlatformDevice';
import { AutoRefreshToken } from '../../common_modules/providers/ApolloGraphQL/AutoRefreshToken';

interface Props {
    children: JSX.Element
}

if (!isWebPlatform()) {
    LogBox.ignoreLogs(['Setting a timer']);
}

let idleTimer;

export const MarketSessionTimeout = (props: Props) => {

    const { logout, isAuthenticated } = usePOSLogin();
    const { uiSettings } = useMarket();
    const [active, setActive] = useState(true);
    const [info, setInfo] = useState('Your session is about to expire...');

    const onIdle = () => {
        idleTimer = setTimeout(() => {
            logout();
            setInfo('Your session has expired due to inactivity.')
        }, 30 * 1000);
    };

    const onAction = () => {
        setActive(true)
    };

    return (
        <View style={{ flex: 1 }}>
            <UserInactivity
                isActive={active}
                timeForInactivity={1000 * 60 * (uiSettings.sessionIdleTimeout ? uiSettings.sessionIdleTimeout : 15)}
                onAction={isActive => {
                    if (isAuthenticated) {
                        setActive(isActive);
                        onIdle()
                    }
                }}
            >
                <AutoRefreshToken />
                {props.children}
                <ModalComponent visible={!active} blocked title="Attention" message={info} onDismiss={onAction} />
            </UserInactivity>
        </View >
    );
};
