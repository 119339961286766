import { CreditCardType } from '../../../model/CreditCardType';
import { TransactionPaymentMethod } from '../../../model/TransactionPaymentMethod';

export interface StoreInfo {
    number?: string,
    name?: string,
    phone?: string,
    billingDescriptor?: string,
    favoriteProductsCountry?: string,
    address?: {
        line1?: string,
        line2?: string,
        city?: string,
        state?: string,
        zipCode?: string,
        country?: string,
    }
    agent?: {
        name?: string
    },
    wallet?: {
        id: string
    }
    guestCheckout?: boolean
    applePay?: {
        merchantIdentifier?: string
        allowedDomains?: Array<string>
    }
    requireVerifiedCreditCardPayments?: boolean,
    restrictedCreditCards?: Array<CreditCardType>,
    consumerAuthentication?: 'NONE' | 'CARDINAL_COMMERCE',
    supportedPaymentMethods?: Array<TransactionPaymentMethod>
    privateLabelGiftCardSettings?: {
        productLoad?: {
            id?: string,
            sku?: string
        },
        productRedeem?: {
            id?: string,
            sku?: string
        },
    }}

export interface ApiViewer {
    id: string,
    login: string,
    name: string,
    sandbox: boolean,
    roles: Array<string>
}

export interface State<S, V> {
    store?: StoreInfo & S,
    viewer?: ApiViewer & V
}

export const initialState: State<StoreInfo, ApiViewer> = {};
