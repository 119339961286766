import * as SecureStore from "expo-secure-store";

export const secureStorageIsAvailable = () =>
    SecureStore.isAvailableAsync();

export const secureStorageSetItem = (key: string, data: unknown) =>
    SecureStore.setItemAsync(key, JSON.stringify(data));

export const secureStorageGetItem = (key: string) => 
    SecureStore.getItemAsync(key);

export const secureStorageRemoveItem = (key: string) =>
    SecureStore.deleteItemAsync(key);