export const isTestEnvironment = () => {
    // TODO: find a better way to this, using NODE_ENV=test
    if (window && window.location) {
        return process.env.NODE_ENV === 'production' && (window.location.host.indexOf('localhost') === 0 || /:\d+/.test(window.location.host));
    } else {
        return process.env.NODE_ENV === 'production';
    }
};
export const isDevEnvironment = () => __DEV__;
export const isProdEnvironment = () => !__DEV__;
