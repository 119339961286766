import React from 'react';
import { View } from 'react-native';
import { RadioButton, Text, useTheme } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';

interface Props {
    checked: string,
    onChecked: (value: string) => void,
    value: string,
    label: string,
    hideInfo?: boolean
}

const RadioButtonPayment = (props: Props) => {
    const { colors } = useTheme();
    return (
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <RadioButton.Android
                value={props.value}
                status={props.checked === props.value ? 'checked' : 'unchecked'}
                onPress={() => props.onChecked(props.value)}
                color={colors.primary}
                
            />
            <Text style={{ fontFamily: 'Montserrat_Regular' }}>
                {props.label}
            </Text>
            {!props.hideInfo &&
                <View
                    style={{
                        width: 20, height: 20, backgroundColor: colors.primary,
                        borderRadius: 10, marginLeft: 3, alignItems: 'center'
                    }}>
                    <MaterialIcons
                        name={'unknown'}
                        size={16} color={colors.surface} />
                </View>
            }
        </View>
    );
};

export default RadioButtonPayment;