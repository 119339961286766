import gql from 'graphql-tag';

export const PRODUCT_FRAGMENT = gql`
    fragment POS_Product on Product{
        id
        sku
        name
        details
        variablePrice
        minPrice
        maxPrice
        price
        discount
        discountMode
        carrier {
            id
            sku
            name
            logoUrl
            country
        }
        category {
            id
            sku
            name
            bgColor
            ... on WirelessCarrier{
                country
            }
        }
        planType: prepaidType
        prepaidType: prepaidType
        isPIN
        inputs {
            name
            label
            required
            type
            help
            allowMultiple
            choices
            html {
                value
                type
                pattern
                patternMessage
                max
                maxlength
                min
                minlength
            }
        }
    }
`;

export const FETCH_PRODUCTS = gql`
    query Products($carrierID: ID!) {
        products {
            all(first: 100, where: {category: {nodes: [$carrierID]} }, order: {field: price, direction: ASC}) {
                pageInfo{
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    page
                }
                edges {
                    node {
                        ...POS_Product
                    }
                }
            }
        }
    }
    ${PRODUCT_FRAGMENT}
`;
