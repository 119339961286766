import React, { useState, useEffect, memo } from "react";
import { Keyboard, View } from "react-native";
import { isWebPlatform } from "../../lib/PlatformDevice";

type keyStatus = "active" | "inactive";
interface Props {
  children?: JSX.Element;
  behavior: keyStatus;
}

/**
 * @function Component HideByKeyboardStatus
 * @param {keyStatus} behavior Handle behavior wrapper
 * @description Subscribe event keyboard to hidden or show children component
 */
const HideByKeyboardStatus = ({ children, behavior }: Props) => {
  const [keyboardStatus, setKeyboardStatus] = useState<keyStatus>("inactive");
  const _keyboardDidShow = () => setKeyboardStatus("active");
  const _keyboardDidHide = () => setKeyboardStatus("inactive");
  const status = behavior !== keyboardStatus && !isWebPlatform();

  useEffect(() => {
    Keyboard.addListener("keyboardDidShow", _keyboardDidShow);
    Keyboard.addListener("keyboardDidHide", _keyboardDidHide);

    // cleanup function
    return () => {
      Keyboard.removeListener("keyboardDidShow", _keyboardDidShow);
      Keyboard.removeListener("keyboardDidHide", _keyboardDidHide);
    };
  }, []);

  return <View>{status ? children : null}</View>;
};

export default memo(HideByKeyboardStatus);
