import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'onConfirmPIN';

interface Data extends Action {
callback: (token: string, pin: string)=> void,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        onConfirmPIN: data.callback,
    };
}

export const onConfirmPIN = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
