import React, { useContext, useEffect, useState } from "react";
import { Portal, Snackbar, Text, useTheme } from "react-native-paper";
import { clearNotifications } from "../../providers/Notification/reducer/actions/clearNotifications";
import NotificationContext from "../../providers/Notification/reducer/context";
import _ from "lodash";
import { State, Notification } from "../../providers/Notification/reducer/state";
import { Audio } from "expo-av";
import { useMediaQuery } from "react-responsive";
import { Sound } from "expo-av/build/Audio";
import { AVPlaybackSource } from "expo-av/build/AV";
import { getDisplayHeight, getDisplayWidth, isWebPlatform, IS_SMARTPHONE } from "../../lib/PlatformDevice";
interface PropsNotify extends State {}

interface Notify extends Notification {}

const SnackBarAlert = () => {
  const notify = useContext(NotificationContext);
  const [sound, setSound] = useState<Sound>();
  const [notifications, setNotifications] = useState<Notify>();
  const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
  const { colors } = useTheme();

  if (!notify) {
    throw new Error(
      "SanckBarAlert must me placed inside a NotificationProvider"
    );
  }

  const [alertStyle, setAlertStyle] = useState({
    backgroundColor: colors.success,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: isSmartPhone ? getDisplayHeight() * 0.85 : getDisplayHeight() * 0.8,
    marginHorizontal:
      !isSmartPhone && isWebPlatform() ? getDisplayWidth() * 0.3 : undefined,
    zIndex: 999999,
    elevation: 999999,
  });
  const [fontColor, setFontColor] = useState("");
  const notification = _.map(notify.state.notifications, (n) => n)[0];

  const playSound = async () => {
    const notificationSound: { [key: string]: AVPlaybackSource } = {
      info: require("./sounds/info.mp3"),
      error: require("./sounds/error.mp3"),
      success: require("./sounds/success.mp3"),
    };
    const { sound } = await Audio.Sound.createAsync(
      notificationSound[notification.variant]
    );
    setSound(sound);
    await sound.playAsync();
  };

  useEffect(() => {
    return sound
      ? () => {
          sound.unloadAsync();
        }
      : undefined;
  }, [sound]);

  useEffect(() => {
    (async () => {
      setNotifications(notification);
      setFontColor(colors.onSurface);
      if (notification) {
        switch ((!!notification && notification.variant) || "default") {
          case "info":
            setAlertStyle((prevData) => ({
              ...prevData,
              backgroundColor: colors.info,
            }));
            break;
          case "error":
            setAlertStyle((prevData) => ({
              ...prevData,
              backgroundColor: colors.notifyError,
            }));
            break;
          case "success":
            setAlertStyle((prevData) => ({
              ...prevData,
              backgroundColor: colors.success,
            }));
            setFontColor(colors.surface);
            break;
          default:
            setAlertStyle((prevData) => ({
              ...prevData,
              backgroundColor: colors.info,
            }));
        }
        await playSound();
      }
    })();
  }, [notify]);

  const closeMe = () => {
    clearNotifications(notify);
  };

  return (
    <Portal>
      <Snackbar
        style={alertStyle}
        visible={notifications !== undefined}
        onDismiss={closeMe}
        duration={5000}
      >
        <Text style={{ color: fontColor }}>
          {!!notifications && notifications.message}
        </Text>
      </Snackbar>
    </Portal>
  );
};

export default SnackBarAlert;
