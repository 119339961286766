import { SubmitTransactionPlugin } from './Plugin';
import { EGIFT_SUBSCRIBE_TRANSACTION_COMPLETE } from './RedirectionPlugin.graphql';
import { TransactionPaymentMethod } from '../../../../../model/TransactionPaymentMethod';

import {API_URL} from "@env";

export const RedirectionPlugin: SubmitTransactionPlugin = {
    onSubmit: (event) => new Promise((finishDone, finishError) => {
        if (event.data.paymentMethod === TransactionPaymentMethod.EGIFT_MESH) {
            finishDone({
                ...event.data,
                returnUrl: 'https://api-paynup-carloshm91.cloud.okteto.net/' + '/payment_success',
            });
        } else {
            finishDone(event.data);
        }
    }),
    onSuccess: (event) => new Promise((success, error) => {
        // clear session
        if (event.data.redirectUrl && event.data.transaction.status === 'ON_HOLD') {
            if (event.context.transactionRedirection) {
                event.context.transactionRedirection.open(event.data.redirectUrl, ()=>{
                    error('Payment Cancelled');
                });

                // subscribe to link visit
                event.context.apolloGraphQL.client.subscribe({
                    query: EGIFT_SUBSCRIBE_TRANSACTION_COMPLETE,
                    variables: { correlationId: event.data.transaction.correlationId }
                }).subscribe(({ data }) => {

                    const eventSource = new EventSource(data.transactions.onFinish.url);
                    eventSource.onmessage = (e) => {
                        setTimeout(() => {
                            const { data } = JSON.parse(e.data);

                            if (event.context.transactionRedirection) {
                                event.context.transactionRedirection.close();
                            }

                            // logger.debug('Transaction Event Received', data);

                            if (data.transactions.onFinish) {
                                if (data.transactions.onFinish.transaction.status === 'COMPLETED') {
                                    success(data.transactions.onFinish);
                                } else {
                                    const errorMessage = data.transactions.onFinish.transaction.response.message;
                                    error(errorMessage ? errorMessage : 'Unknown error');
                                }
                            }
                        }, 1000);
                    };
                });
            } else {
                success(event.data);
            }
        } else {
            success(event.data);
        }
    }),
};
