import * as React from "react";
import { View } from "react-native";
import { IconButton, Searchbar, useTheme } from "react-native-paper";
interface Props {
  search: string;
  onSearch: (search: string) => void;
}

/**
 * @function Component SearchBarComponent
 * @param {string} search Value Searchbar
 * @param {Function} onSearch Callback event onchange
 */
const SearchBarComponent = ({ search, onSearch }: Props) => {
  const [criteria, setCriteria] = React.useState("");
  const { colors } = useTheme();

  return (
    <View nativeID="disable-outline">
      <Searchbar
        placeholder="Search"
        onChangeText={setCriteria}
        onEndEditing={() => onSearch(criteria)}
        onSubmitEditing={() => onSearch(criteria)}
        value={criteria}
        style={{ borderRadius: 0 }}
        icon={(props) => (
          <IconButton
            icon={criteria.length > 0 ? "delete" : "search"}
            color={criteria.length > 0 ? colors.primary : undefined}
            onPress={() => {
              setCriteria("");
              onSearch("");
            }}
          />
        )}
        clearIcon={(props) =>
          criteria.length > 3 && (
            <IconButton
              icon={"arrow-forward"}
              color={colors.primary}
              onPress={() => onSearch(criteria)}
            />
          )
        }
      />
    </View>
  );
};

export default SearchBarComponent;
