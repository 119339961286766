import React, { Dispatch } from 'react';
import { Notification } from '../../Notification/hook/useNotification';
import { Action } from './reducer';
import { State } from './state';

export interface ContextType {
    readonly _type?: 'ApolloGraphQL',
    dispatch: Dispatch<Action>,
    state: State,
    notification: Notification
}

export const ApolloGraphQLContext = React.createContext<ContextType | null>(null);
export default ApolloGraphQLContext;
