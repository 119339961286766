import { setTotal } from './setTotal';
import { validateTransaction, ValidateTransactionArgs, ValidationTransactionSuccess } from '../../../../lib/Transactions/transactions';
import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';
import { captureException } from '../../../../lib/sentry';

const NAME = 'updateTotal';

interface Data extends Action {

}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    return {
        ...state,
        updatingTotal: true
    };
}

export const updateTotal = (context: ContextType, transaction: ValidateTransactionArgs): Promise<ValidationTransactionSuccess> =>
    new Promise<ValidationTransactionSuccess>((success, error) => {

        context.dispatch({ type: NAME });

        validateTransaction(context.apolloGraphQL.client, {
            referrer: transaction.referrer,
            standalonePaymentUrl: transaction.standalonePaymentUrl,
            inputs: transaction.inputs ? transaction.inputs : {},
            paymentMethod: transaction.paymentMethod,
            product: transaction.product
        }).then((total) => {
            setTotal(context, { total });
            success(total);
        }).catch((e) => {
            setTotal(context, { total: context.state.total });
            error(e);
            captureException(e);
        });
    });
