import moment from 'moment';
import {Adjustment} from '../model/Adjustment';
import {Clerk, SubmitTransactionPayload} from '../../screens/Market/reducer/state';
import {StoreInfo} from '../providers/StoreInfo/reducer/state';
import {getPrice} from './Transactions/transactions';
import {appIdentifier} from '../../identifier';

export const FavoriteCardsHtml =
    `<html>
        <body>
            <div 
                style=" display: flex;
                flex-direction: row;
                justify-content: center ">

                <p style="text-align: center">
                    Scan the QR code and it will take you directly to our E-Commerce Site so you can safely and conveniently topup 
                    your cellphone and friends and family using your cellphone, tablet, computer, or any internet capable device.
                </p> 
            </div>
        </body>
    <html>`;

export const ReceiptCardsHtml = (storeInfo: StoreInfo, clerk: Clerk, payload: SubmitTransactionPayload, qrBase64: string) => {
    const isMerchant = appIdentifier === "MERCHANT";
    const divider = `<hr style="border-top: 3px dashed #bbb; margin-top: 10px; margin-bottom: 10px"></hr>`;

    const html = `
    <html>
        <body style="margin-left: 10%;margin-right: 10%">
            <div >
                <div style=" display: flex;
                    flex-direction: row;
                    justify-content: center">
                        <h1>${isMerchant ? storeInfo.name : payload.transaction?.product.carrier.name}</h1>
                </div>
                <div style=" display: flex;
                        flex-direction: column;
                        justify-content: center">

                    <label>${moment(payload.transaction?.date).format('LLLL')}</label>
                    <label>Order No. <strong>${payload.transaction.number}</strong></label>
                    <label>Cashier: <strong>${isMerchant ? clerk.name : storeInfo.name}</strong></label>
                </div>
                ${divider}
                <div>
                    <div style="display: flex;
                            flex-direction: row;
                            justify-content: space-between">

                            <label>${payload.transaction?.product.category.name} /
                                <strong>${payload.transaction.product.name}</strong>
                            </label>
                            <label>${getPrice(payload.transaction?.grossTotal)}</label>
                    </div>

                    ${payload.transaction?.adjustments.map((adjustment: Adjustment, index) => (
        `<div style="display: flex;
                            flex-direction: row;
                            justify-content: space-between">

                            <label>${adjustment.name} ${adjustment.rateMode ? `(${adjustment.amount}%)` : ''}</label>
                            <label>${getPrice(adjustment.total, false)}</label>
                        </div>`
    ))}
                </div>
                ${divider}
                <div>
                    <div style="display: flex;
                        flex-direction: row;
                        justify-content: space-between">
                        
                        <h2>Total</h2> <h2>${getPrice(payload.transaction.total)}</h2>
                    </div>
                    ${payload.transaction.product.isPIN ? `<h3>PIN: ${payload.transaction.response.pin}</h3>` : ''}
                    <div>
                        <p style="text-align: justify">
                            Scan the QR code and it will take you directly to our E-Commerce Site so you can safely and conveniently topup 
                            your cellphone and friends and family using your cellphone, tablet, computer, or any internet capable device.
                        </p> 
                    </div>
                    <div style="display: flex;
                    flex-direction: row;
                    justify-content: flex-start";
                    align-items: center>
                       <img style="width: 150px; height: 150px" src="data:image/jpeg;base64,${qrBase64}" />
                        <h3 style="color: #d23232; margin-left: 20px">Thanks you for using our services</h3>
                    </div>
                </div>
            </div>
        </body>
    <html>`;
    return html;
}

export const SimpleReceiptCardsHtml = (storeInfo: StoreInfo, clerk: Clerk, payload: SubmitTransactionPayload, qrBase64: string) => {
    const isMerchant = appIdentifier === "MERCHANT";
    const divider = `--------------------------------`;

    const html = `
    <html style="margin: 0; padding: 0;">
        <body style="margin: 0; padding: 0;">
            <div style="margin: 0; padding: 0;">
                <div>
                    <h1>${isMerchant ? storeInfo.name : payload.transaction?.product.carrier.name}</h1>
                </div>
                <div>
                    <div>Date: <strong>${moment(payload.transaction?.date).format('L, h:mm a')}</strong></div>
                    <div>Order No.: <strong>${payload.transaction.number}</strong></div>
                    <div>Cashier: <strong>${isMerchant ? clerk.name : storeInfo.name}</strong></div>
                </div>
                ${divider}
                <div>
                    <div>${payload.transaction?.product.category.name}</div>
                    <div>${payload.transaction.product.name}. <strong>${getPrice(payload.transaction?.grossTotal)}</strong></div>

                    ${payload.transaction?.adjustments.map((adjustment: Adjustment, index) => (
                    `<div>
                        <div>${adjustment.name} ${adjustment.rateMode ? `(${adjustment.amount}%)` : ''} <strong>${getPrice(adjustment.total, false)}</strong></div>
                    </div>`
                    )).join('')}
                </div>
                ${divider}
                <div>Total <strong>${getPrice(payload.transaction.total)}</strong></div>
                ${divider}
                <h2>${payload.transaction.product.isPIN ? `</h2>

                <h2>PIN: ${payload.transaction.response.pin}</h2>

                <div style="display: flex;
                flex-direction: row;
                justify-content: flex-start";
                align-items: center>
                   <img style="width: 150px; height: 150px" src="https://api.qrserver.com/v1/create-qr-code/?data=${payload.transaction.response.pin}&size=200x200" />
                </div>` : ''}
                <br>
                <div>
                    ${payload.transaction?.response?.message}
                </div>
                <br>
                <div>
                    Thanks you for using our services
                </div>
            </div>
        </body>
    <html>`;
    return html;
}
