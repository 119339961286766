import { ConstraintViolation } from './ConstraintViolation';
// Represent a ERROR in Pay'nUp API

export class APIError extends Error {

    // known error number
    public code: number;

    // in case of error 422 - Validation Error
    public constraintViolations: ConstraintViolation[];

    constructor(code: number, message: string, constraintViolations: ConstraintViolation[] = []) {
        super(message);

        this.code = code;
        this.constraintViolations = constraintViolations;
    }

}
