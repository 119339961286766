import React, { useReducer } from 'react';
import Context from './reducer/context';
import { reducer } from './reducer/reducer';
import { initialState } from './reducer/state';
interface Props {
    children?: any
}

export function NotificationProvider(props: Props) {

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {props.children}
        </Context.Provider>
    );
}
