import React, { Dispatch } from 'react';
import { Action } from './reducer';
import { State } from './state';

export interface ContextType {
    readonly _type?: 'TransactionRedirection',
    dispatch: Dispatch<Action>,
    state: State,
}

export const TransactionRedirectionContext = React.createContext<ContextType>(null);
export default TransactionRedirectionContext;
