import React, { useReducer } from "react";
import Context from "./reducer/context";
import { reducer } from "./reducer/reducer";
import { initialState } from "./reducer/state";
import { useNotification } from "../../providers/Notification/hook/useNotification";
import Cardinal from "./Cardinal";
import { useApolloGraphQL } from "../../providers/ApolloGraphQL/hook/useGraphqlApolloClient";

interface Props {
  children?: any;
}

export function CardinalProvider(props: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const apolloGraphQL = useApolloGraphQL();
  const notification = useNotification();

  return (
    <Context.Provider value={{ state, dispatch, apolloGraphQL, notification }}>
      {props.children}
    </Context.Provider>
  );
}
