import React from 'react';
import { APIError } from '../../model/APIError';
import { TransactionPaymentMethod } from '../../model/TransactionPaymentMethod';
import { View } from 'react-native';
import { useTheme, Text } from 'react-native-paper';
import { useMediaQuery } from "react-responsive";
import { IS_SMARTPHONE } from '../../lib/PlatformDevice';

/**
 * Common transaction to display to user when a transaction fails
 */

interface Props {
    error: APIError,
    paymentMethod?: TransactionPaymentMethod,
}

export function TransactionCheckoutError({ error, paymentMethod }: Props) {
    const { colors } = useTheme();
    const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
    const props = { color: colors.surface };

    if (!error) {
        return <></>;
    }

    let explain: string | JSX.Element = '';
    let message = error.message;

    const verifyCardOrTryAnother = (
        <>
            <Text style={props}>Make sure the <Text style={{ fontFamily: 'Montserrat_Bold', ...props }} >credit card number</Text> you entered is correct.</Text>
            <Text style={props}>Please try another card.</Text>
        </>
    );

    const tryAnotherCard = 'Try another credit/debit card.';

    switch (error.code) {
        // Validation Errors
        case 422:
            message = 'This transaction can\'t be processed.';
            explain = <Text style={props}>Please check all fields and try again</Text>;
            break;

        // Gateway Errors
        case 2501:
        case 2512:

            message = 'This transaction has been declined.';
            explain = (
                <View>
                    {verifyCardOrTryAnother}
                </View>
            );
            if (paymentMethod === TransactionPaymentMethod.VIRTUAL_CHECKING_ACCOUNT) {
                explain = <Text style={props}>{error.message}</Text>;
            }

            break;
        case 2504:
            explain = (
                <View>
                    <Text style={props}>{tryAnotherCard}</Text>
                </View>
            );

            break;
        case 2516:
            message = 'The Credit Card Number in Invalid.';
            explain = (
                <View>
                    <Text style={props}>Make sure the <Text style={{ fontFamily: 'Montserrat_Bold', ...props }}> credit card numTexter </Text> you entered is correct.</Text>
                    <Text style={props}>{tryAnotherCard}</Text>
                </View>
            );
            break;
        case 2510:
            explain = (
                <View>
                    <Text style={props}>
                        Make sure the <Text style={{ fontFamily: 'Montserrat_Bold', ...props }} >credit card number, expiration date or card code (CVV)</Text> you entered is
                        correct.
                    </Text>
                    <Text style={props}>Call the number on the back of your card to determinate the reason for the decline.</Text>
                    <Text style={props}>{tryAnotherCard}</Text>
                </View>
            );
            break;
        case 2511:
            explain = (
                <View>
                    <Text style={props} >
                        Call the <Text style={{ fontFamily: 'Montserrat_Bold', ...props }} >phone number on the back</Text> of your card to proceed with this transaction.
                    </Text>
                    <Text style={props}>
                        Make sure the <Text style={{ fontFamily: 'Montserrat_Bold', ...props }} >credit card number, expiration date or card code (CVV)</Text> you entered is
                        correct.
                    </Text>
                    <Text style={props} >{tryAnotherCard}</Text>
                </View>
            );
            break;
        case 2513:
            explain = (
                <View>
                    <Text style={props} >Make sure the <Text style={{ fontFamily: 'Montserrat_Bold', ...props }} >Billing address and Zip Code</Text> information you entered is correct.</Text>
                    <Text style={props}>{tryAnotherCard}</Text>
                </View>
            );
            break;
        case 2514:
            explain = (
                <View>
                    <Text style={props}>Make sure the <Text style={{ fontFamily: 'Montserrat_Bold', ...props }} >Card Code (CVV)</Text> you entered is correct.</Text>
                    <Text style={props}>{tryAnotherCard}</Text>
                </View>
            );
            break;
        case 2515:
            explain = (
                <View>
                    <Text style={props} >Make sure the <Text style={{ fontFamily: 'Montserrat_Bold', ...props }} >Exp. Date</Text> you entered is correct.</Text>
                    <Text style={props}>{tryAnotherCard}</Text>
                </View>
            );
            break;
        case 2502:
        case 2550:
        case 2551:
        case 2554:
            message = 'Sorry, for your security only 3DS transaction are supported.';
            explain = (
                <View>
                    {verifyCardOrTryAnother}
                </View>
            );
            break;
        case 2553:
            message = 'Sorry, for your security only authenticated transaction are supported.';
            explain = (
                <View style={{ alignItems: 'center' }}>
                    <Text style={props}>Make sure the <Text style={{ fontFamily: 'Montserrat_Bold', ...props }} >Password/Code</Text> you entered is correct.</Text>
                    <Text style={props}>Make sure the <Text style={{ fontFamily: 'Montserrat_Bold', ...props }} >credit card number</Text> you entered is correct.</Text>
                    {verifyCardOrTryAnother}
                </View>
            );
            break;
        case 2552:
            message = 'Sorry, this transaction cannot be performed at this time.';
            explain = (
                <View style={{ alignItems: 'center' }}>
                    <Text style={props} >Try again in a few minutes.</Text>
                </View>
            );
            break;
        default:

    }

    return (
        <View style={{ alignItems: 'center', justifyContent: 'center', width: isSmartPhone ? '80%' : undefined }}>
            <Text style={{ color: colors.surface, marginBottom: 10 }}>{message}</Text>
            {explain ? explain : null}
        </View>
    );
}
