import { useContext } from 'react';
import { setFingerPrint } from '../reducer/actions/setFingerPrint';
import Context from '../reducer/context';

export interface ClientFingerPrint {
    fingerPrint?: string,
    setFingerPrint: (fingerPrint: string) => void
}

export function useClientFingerPrint(): ClientFingerPrint {
    const context = useContext(Context);
    if (!context) {
        throw new Error('Only can use `useClientFingerPrint` inside a valid ClientFingerPrintProvider');
    }

    return {
        fingerPrint: context ? context.state.fingerPrint : undefined,
        setFingerPrint: (fingerPrint) => setFingerPrint(context, { fingerPrint })
    };
}
