// import { logger } from 'common/lib/logger';
import { loadCarriers } from '../../reducer/actions/loadCarriers';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { Category, MarketWizardStep, State } from './../state';

const NAME = 'selectCategory';

interface Data extends Action {
    category: Category,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    // logger.debug(`Category "${data.category.name}" selected`);
    return {
        ...state,
        wizard: {
            ...state.wizard,
            currentStep: MarketWizardStep.CARRIERS,
            subHeaderColor: data.category.color,
        },
        category: {
            ...state.category,
            selected: data.category,
        },
        carrier: {
            loading: true,
            carriers: [],
            selected: undefined,
        }
    };
}

export const selectCategory = (context: ContextType, data: Omit<Data, 'type'>) => {

    // loadCarriers(context, { category: data.category });

    context.dispatch({ type: NAME, ...data });
};
