import React from 'react';
import { View, StyleSheet } from 'react-native';
import { ActivityIndicator, Modal, Portal, useTheme, Text } from "react-native-paper";
import { useMediaQuery } from "react-responsive";
import { IS_DESKTOP_OR_LAPTOP } from '../../lib/PlatformDevice';

interface BlockProps {
    visible: boolean,
    message?: string
}
interface LoadingProps {
    message?: string,
    color?: string,
    size?: "large" | "small"
}

/**
* @function Component LoadingComponent
* @param {string} color Color indicator
* @param {string} size Size indicator
* @param {string} message Helper text
*/
const LoadingComponent = ({ message, color, size }: LoadingProps) => {
    const { colors } = useTheme();
    return (
        <View style={styles.container}>
            <ActivityIndicator animating={true} color={!color ? colors.primary : color} size={!size ? "large" : size} />
            {message ?
                <View style={{ marginTop: "10%" }}>
                    <Text
                        style={{ fontFamily: 'Montserrat_Semi_Bold', color: colors.surface }}>
                        {message}
                    </Text>
                </View> : null}
        </View>
    );
}

/**
* @function Component LoadingBlockComponent
* @param {boolean} visible Status visible component
* @param {string} message Helper text
*/
export const LoadingBlockComponent = ({ visible, message }: BlockProps) => {
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
    const containerStyle = {
        backgroundColor: 'transparent',
        marginHorizontal: !isDesktopOrLaptop ? '10%' : '40%',
        borderWidth: 0, borderTopColor: 'transparent', shadowOpacity: 0,
    };
    return (
        <Portal>
            <Modal
                visible={visible}
                onDismiss={() => null}
                contentContainerStyle={containerStyle}
                dismissable={false}>
                <LoadingComponent message={message || ""} />
            </Modal>
        </Portal>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
})
export default LoadingComponent;