import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Card, Text, TextInput, useTheme } from "react-native-paper";
import { Product } from "../../../screens/Market/reducer/state";
import { TransactionPaymentMethod } from "../../model/TransactionPaymentMethod";
import memoizee from "memoizee";
import debounce from "debounce-promise";
import _ from "lodash";
import {
  getPrice,
  validateTransaction,
  ValidationTransactionSuccess,
} from "../../lib/Transactions/transactions";
import { Adjustment } from "../../model/Adjustment";
import LoadingComponent from "../Loading/Loading";
import { useMediaQuery } from "react-responsive";
import { IS_SMARTPHONE } from "../../lib/PlatformDevice";
import { captureException } from "../../lib/sentry";
import { useApolloGraphQL } from "../../providers/ApolloGraphQL/hook/useGraphqlApolloClient";
import { appIdentifier } from "../../../identifier";
import {VERIFY_NUMBER_OR_MAG_DATA, VERIFY_PAYMENT} from "../../../screens/ScanCode/CustomerPayment.graphql";
interface Props {
  product: Product;
  paymentMethod: TransactionPaymentMethod;
  grossTotal: number;
  onCashTenderedAmountChange?: (total: number, tenderedAmount: number) => void;
  value?: string;
  onBlur?: () => void;
  setValue?: (name: string, value: string) => void;
  calculating?: (calculating: boolean) => void;
  onChange?: any;
  showAmount?: boolean;
  dealerToken?: string;
  numberOrMagData?: any;
}

const moizedCalculateTotal = memoizee(
  (
    client,
    product: Product,
    paymentMethod,
    amount,
    dealerToken
  ): Promise<ValidationTransactionSuccess> => {
    let inputs: any = {};
    if (product.variablePrice && _.find(product.inputs, { name: "amount" })) {
      inputs.amount = amount;
    }

    return validateTransaction(client, {
      product: product.id,
      paymentMethod: paymentMethod,
      inputs,
      dealerToken,
    });
  },
  { promise: true }
);

const debounceCalculateTotal = debounce(moizedCalculateTotal, 400);

/**
 * @function Component Card Payment
 * @param {Product} product Product
 * @param {Number} grossTotal Gross Total
 * @param {string} value Value input
 * @param {TransactionPaymentMethod} paymentMethod Payment method
 * @param {Function} onCashTenderedAmountChange Callback handle change tendered amount
 * @param {Function} onBlur Callback input event blur
 * @param {Function} onChange Callback input event change
 */
const CardPayment = React.memo((props: Props) => {
  const { colors } = useTheme();
  const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
  const [tenderedAmount, setTenderedAmount] = useState<any>(0);
  const [total, setTotal] = useState(0);
  const [number, setNumber] = useState("");
  const [magdata, setMagData] = useState("");
  const [grossTotal, setGrossTotal] = useState(0);
  const [widtAmountText, setWidtAmountText] = useState(0);
  const [calculating, setCalculating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adjustments, setAdjustments] = useState<Adjustment[]>([]);
  const [privateLabelGiftCardsBalance, setPrivateLabelGiftCardsBalance] = useState(-1);
  const { client } = useApolloGraphQL();
  const isClient = appIdentifier === "CLIENT";

  const updateTenderedAmount = (amount: number | any) => {
    setTenderedAmount(amount);
    if (props.onCashTenderedAmountChange) {
      props.onCashTenderedAmountChange(total, amount);
    }
  };

  useEffect(() => {
    if (grossTotal !== props.grossTotal) {
      setCalculating(true);
      if (props.calculating) {
        props.calculating(true);
      }
      setGrossTotal(props.grossTotal);

      const updateTotal = (total: number) => {
        setTotal(total);
        if (props.onCashTenderedAmountChange) {
          props.onCashTenderedAmountChange(total, tenderedAmount);
        }
      };

      (async () => {
        try {
          const result: ValidationTransactionSuccess =
            await debounceCalculateTotal(
              client,
              props.product,
              props.paymentMethod,
              props.grossTotal,
              props.dealerToken
            );

          updateTotal(result.total);
          setAdjustments(
            _(result.adjustments)
              .groupBy("name")
              .map((objs, key) => ({
                name: key,
                total: _.sumBy(objs, "total"),
                amount: _.sumBy(objs, "total"),
                rateMode: false,
              }))
              .value()
          );
          setCalculating(false);
          if (props.calculating) {
            props.calculating(false);
          }
        } catch (error) {
          captureException(error);
        }
      })();
    }
  }, [
    client,
    props.product,
    props.paymentMethod,
    props.grossTotal,
    props,
    tenderedAmount,
    grossTotal,
  ]);

  useEffect(() => {
    if (props.numberOrMagData && props.numberOrMagData.length === 2 && (number !== props.numberOrMagData[0] || magdata !== props.numberOrMagData[1])) {
      setLoading(true);

      setNumber(props.numberOrMagData[0]);
      setMagData(props.numberOrMagData[1]);

      (async () => {
        try {
          if (props.paymentMethod === TransactionPaymentMethod.PRIVATE_LABEL_GIFT_CARD) {
            let numberOrMagData = "";
            if (props.numberOrMagData[1] !== '' && props.numberOrMagData[1] !== undefined && props.numberOrMagData[1] !== null) {
              numberOrMagData = props.numberOrMagData[1];
            }
            if (props.numberOrMagData[0] !== '' && props.numberOrMagData[0] !== undefined && props.numberOrMagData[0] !== null) {
              numberOrMagData = props.numberOrMagData[0];
            }
            try {
              const {data} = await client.mutate({
                mutation: VERIFY_NUMBER_OR_MAG_DATA,
                fetchPolicy: "no-cache",
                variables: {
                  numberOrMagData: numberOrMagData,
                },
              });
              setPrivateLabelGiftCardsBalance(data.privateLabelGiftCards.verify.balance)
            } catch (e) {
              setPrivateLabelGiftCardsBalance(-1.00)
            }
          }
          setLoading(false);
        } catch (error) {
          captureException(error);
        }
      })();
    }
  }, [
    client,
    props.numberOrMagData,
  ]);

  if (calculating) {
    return (
      <View style={{ flex: 1 }}>
        <LoadingComponent />
      </View>
    );
  }

  const amountVisible = props.showAmount === undefined || props.showAmount;

  const content = () => {
    return (
      <View>
        {!!adjustments.length && (
          <View style={{ paddingBottom: 10 }}>
            {amountVisible ? (
              <View style={styles.container}>
                <Text style={styles.textAmount}>Amount</Text>
                <View>
                  <Text
                    onLayout={(event) => {
                      let { x, y, width, height } = event.nativeEvent.layout;
                      setWidtAmountText(width);
                    }}
                    style={styles.textAmount}
                  >
                    {getPrice(props.grossTotal)}
                  </Text>
                  {isClient && props.product.discount ? (
                    <View
                      style={[
                        styles.lineDiscount,
                        {
                          width: widtAmountText,
                          borderBottomColor: colors.primary,
                        },
                      ]}
                    />
                  ) : null}
                </View>
              </View>
            ) : null}
            {adjustments.map((adjustment: Adjustment, index) => (
              <View
                key={index}
                style={[
                  styles.container,
                  {
                    paddingTop: amountVisible ? 10 : 5,
                  },
                ]}
              >
                <Text style={{ fontFamily: "Montserrat_Light" }}>
                  {adjustment.name}
                </Text>
                <Text style={styles.textRateMode}>
                  {adjustment.rateMode
                    ? `${adjustment.amount}%`
                    : getPrice(adjustment.total, false)}
                </Text>
              </View>
            ))}
          </View>
        )}
        <View style={[styles.container, { paddingTop: 0 }]}>
          <Text style={styles.textTotal}>Total</Text>
          <Text style={styles.textTotalPrice}>{getPrice(total)}</Text>
        </View>

        {props.numberOrMagData && props.numberOrMagData.length ===2 && ((props.numberOrMagData[0] !== undefined && props.numberOrMagData[0] !== null && props.numberOrMagData[0] !== "") || (props.numberOrMagData[1] !== undefined && props.numberOrMagData[1] !== null && props.numberOrMagData[1] !== "")) && !loading &&
            (
                <>
                  {props.paymentMethod === TransactionPaymentMethod.PRIVATE_LABEL_GIFT_CARD && privateLabelGiftCardsBalance <= 0 && (
                      <Text style={styles.textSubTitle}>Private Label Gift Card Not Valid</Text>
                  )}
                  {props.paymentMethod === TransactionPaymentMethod.PRIVATE_LABEL_GIFT_CARD && privateLabelGiftCardsBalance > 0 && (
                      <View style={[styles.container, { paddingTop: 10 }]}>
                        <Text style={{}}>Gift Card Current Balance</Text>
                        <Text style={{}}>{getPrice(privateLabelGiftCardsBalance)}</Text>
                      </View>
                  )}
                </>
            )
        }
        {props.numberOrMagData && props.numberOrMagData.length ===2 && ((props.numberOrMagData[0] !== undefined && props.numberOrMagData[0] !== null && props.numberOrMagData[0] !== "") || (props.numberOrMagData[1] !== undefined && props.numberOrMagData[1] !== null && props.numberOrMagData[1] !== "")) && loading &&
            <LoadingComponent />
        }
        {props.paymentMethod === TransactionPaymentMethod.CASH && (
          <>
            <View style={[styles.container, { paddingTop: 2 }]}>
              <Text style={{ fontFamily: "Montserrat_Light" }}>
                Tendered Amount
              </Text>
              <View nativeID="disable-outline">
                <TextInput
                  style={{
                    backgroundColor: colors.surface,
                    height: 40,
                    width: !isSmartPhone ? 100 : 80,
                  }}
                  onFocus={()=> {
                    if (props.value === '0' && props.setValue) {
                      props.setValue('tenderedAmount', '');
                    }
                  }}
                  onChangeText={(text) => {
                      updateTenderedAmount(text);
                      props.onChange(text);
                  }}
                  value={props.value}
                  mode="outlined"
                  onBlur={props.onBlur}
                  keyboardType="number-pad"
                />
              </View>
            </View>
            {tenderedAmount - total >= 0 && (
              <View style={[styles.container, { paddingTop: 10 }]}>
                <Text
                  style={{
                    fontFamily: "Montserrat_Light",
                    color: colors.onSurface,
                  }}
                >
                  Change
                </Text>
                <Text
                  style={{
                    alignItems: "flex-start",
                    color: colors.onSurface,
                  }}
                >
                  {getPrice(tenderedAmount - total)}
                </Text>
              </View>
            )}
            {tenderedAmount - total < 0 && (
              <View style={[styles.container, { paddingTop: 10 }]}>
                <Text
                  style={{
                    fontFamily: "Montserrat_Light",
                    color: colors.primary,
                  }}
                >
                  Due
                </Text>
                <Text
                  style={{
                    fontFamily: "Montserrat_Semi_Bold",
                    alignItems: "flex-start",
                    color: colors.primary,
                  }}
                >
                  {getPrice(total - tenderedAmount)}
                </Text>
              </View>
            )}
          </>
        )}
      </View>
    );
  };

  return (
    <>
      {amountVisible ? (
        <Card>
          <Card.Content>{content()}</Card.Content>
        </Card>
      ) : (
        content()
      )}
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  lineDiscount: {
    position: "absolute",
    transform: [{ rotate: "-10deg" }],
    top: 10,
    height: 1,
    borderBottomWidth: 3,
  },
  textAmount: {
    fontFamily: "Montserrat_Bold",
    fontSize: 18,
  },
  textTotal: {
    fontFamily: "Montserrat_Semi_Bold",
    fontSize: 25,
    width: "50%",
  },
  textSubTitle: {
    fontFamily: "Montserrat_Semi_Bold",
    fontSize: 18,
  },
  textTotalPrice: {
    fontFamily: "Montserrat_Extra_Bold",
    fontSize: 25,
  },
  textRateMode: {
    fontFamily: "Montserrat_Semi_Bold",
    alignSelf: "flex-start",
    alignItems: "flex-start",
  },
});

export default CardPayment;
