import { ApolloClient, WatchQueryOptions } from 'apollo-client';
import { ObservableSummary, SummaryOptions } from './summary';
import { TRANSACTION_SUMMARY } from './transactions.graphql';

export enum TransactionSummaryType {
    COUNT_BY_DATE = 'COUNT_BY_DATE',
    TOP_PRODUCTS = 'TOP_PRODUCTS',
    TOP_DEALERS = 'TOP_DEALERS',
    TOP_AGENTS = 'TOP_AGENTS',
    TOP_SOURCES = 'TOP_SOURCES',
    REPRESENTATIVES = 'REPRESENTATIVES',
    REPRESENTATIVES_ISO = 'REPRESENTATIVES_ISO',
    REPRESENTATIVES_SUB_ISO = 'REPRESENTATIVES_SUB_ISO',
    REPRESENTATIVES_SALES_AGENT = 'REPRESENTATIVES_SALES_AGENT',
    REPRESENTATIVES_SUB_SALES_AGENT = 'REPRESENTATIVES_SUB_SALES_AGENT',
    REPRESENTATIVES_CHAIN = 'REPRESENTATIVES_CHAIN',
    SOURCES = 'SOURCES',
    LOCATIONS = 'LOCATIONS',
}

export interface TransactionSummaryOptions extends SummaryOptions {
    type: TransactionSummaryType,
    commissions?: boolean,
    where?: {
        sandbox?: boolean,
        status?: any,
        product?: any,
        agent?: any,
        merchant?: any,
        paymentMethod?: any,
        [index: string]: any
    },
}

class ObservableTransactionSummary<E> extends ObservableSummary<TransactionSummaryOptions, E> {

    constructor(client: ApolloClient<any>, options: TransactionSummaryOptions, queryOptions?: Partial<WatchQueryOptions>, updateOnEvents?: Array<E>) {
        super(client, options, { query: TRANSACTION_SUMMARY, ...queryOptions }, 'transactions.summary', updateOnEvents);
    }

    protected parseVariables(): any {
        const where = this.options.where ? this.options.where : {};

        if (typeof where.paymentMethod === 'string') {
            where.paymentMethod = { values: [where.paymentMethod] };
        }

        if (typeof where.status === 'string') {
            where.status = { values: [where.status] };
        }

        if (typeof where.product === 'string') {
            where.product = { nodes: [where.product] };
        }

        return {
            ...super.parseVariables(),
            commissions: this.options.commissions ? true : false,
            where: {
                sandbox: where.sandbox ? where.sandbox : false,
                ...where
            }
        };
    }
}

export const transactionSummary = <E>(client: ApolloClient<any>, options: TransactionSummaryOptions, queryOptions?: Partial<WatchQueryOptions>, updateOnEvents?: Array<E>):
    ObservableTransactionSummary<E> => new ObservableTransactionSummary(client, options, queryOptions, updateOnEvents);
