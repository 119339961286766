import * as React from "react";
import {
    ApolloClient,
    InMemoryCache,
} from "@apollo/client";
import gql from 'graphql-tag';
import {API_URL} from "@env";

export const FETCH_MERCHANT_INFO = gql`
    query FetchMerchantInfo($identifier: String!) {
        merchantInfo(identifier: $identifier) {
            name
            number
            logo
            __typename
            ... on DealerMerchantInfo {
                agent{
                    name
                    number
                    phone
                    logo
                }
            },
            siteSettings {
                primaryColor
                secondaryColor
            }
        }
    }
    `;

export interface DealerMerchantInfo extends MerchantInfo {
    agent: AgentMerchantInfo
}

export interface AgentMerchantInfo extends MerchantInfo {

}

export interface MerchantInfo {
    name: string,
    number: string
    logo: string
    email: string
    phone: string
}

export interface SiteSettingsInfo {
    primaryColor: string,
    secondaryColor: string
}

export interface WhiteLabelAgent {
    isLoadingWhiteLabelAgentComplete: boolean,
    agentObj?: AgentMerchantInfo
    dealerObj?: DealerMerchantInfo,
    siteSettings?: SiteSettingsInfo
}

export function useWhiteLabelAgent(): WhiteLabelAgent {
    const [isLoadingWhiteLabelAgentComplete, setLoadingWhiteLabelAgentComplete] = React.useState(false);
    const [dealerObj, setDealerObj] = React.useState(null);
    const [agentObj, setAgentObj] = React.useState(null);
    const [siteSettings, setSiteSettings] = React.useState(null);

    const client = new ApolloClient({
        uri: API_URL,
        cache: new InMemoryCache()
    });

    let dealer: DealerMerchantInfo = undefined;
    let agent: AgentMerchantInfo = undefined;

    let identifier = ''
    if (window && window.location) {
        identifier = window.location.host;
    }

    // Load any resources or data that we need prior to rendering the app
    React.useEffect(() => {
        async function loadWhiteLabelAgent() {
            try {
                if (identifier === null || identifier === undefined || identifier === '') {
                    return;
                }

                const data = await client.query({
                    fetchPolicy: "no-cache",
                    query: FETCH_MERCHANT_INFO,
                    variables: {identifier}
                });

                const merchantInfo = data.data.merchantInfo;

                if (merchantInfo.__typename === 'DealerMerchantInfo') {
                    agent = {
                        name: merchantInfo.agent.name,
                        number: merchantInfo.agent.number,
                        email: merchantInfo.agent.email,
                        phone: merchantInfo.agent.phone,
                        logo: merchantInfo.agent.logo,
                    };
                    dealer = {
                        name: merchantInfo.name,
                        number: merchantInfo.number,
                        email: merchantInfo.email,
                        phone: merchantInfo.phone,
                        logo: merchantInfo.logo,
                        agent,
                    };
                } else {
                    agent = {
                        name: merchantInfo.name,
                        number: merchantInfo.number,
                        email: merchantInfo.email,
                        phone: merchantInfo.phone,
                        logo: merchantInfo.logo,
                    };
                }

                setAgentObj(agent);
                setDealerObj(dealer);
                setSiteSettings(merchantInfo.siteSettings);
            } catch (e) {
                // We might want to provide this error information to an error reporting service
                console.warn(e);
            } finally {
                setLoadingWhiteLabelAgentComplete(true);
            }
        }

        loadWhiteLabelAgent();
    }, []);

    return {isLoadingWhiteLabelAgentComplete, agentObj, dealerObj, siteSettings};
}
