import gql from 'graphql-tag';
import { PRODUCT_FRAGMENT } from '../reducer/actions/loadProducts.graphql';

export const PRODUCT_LIST = gql`
    query ProductsList ($search: String, $endCursor: String){
        products {
            all(first: 20, after: $endCursor, search: $search, order: {field: name}) {
                pageInfo{
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    page
                }
                totalCount
                edges{
                    node{
                        ...POS_Product
                    }
                }
            }
        }
    }
    ${PRODUCT_FRAGMENT}
`;
