import React from "react";
import { StyleSheet, View } from "react-native";
import { Card, useTheme } from "react-native-paper";
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { Category } from "../../../screens/Market/reducer/state";
interface Props {
  category: Category;
  selectCategory: (category: Category) => void;
  iconColor: string;
}

/**
 * @function Component Category Card
 * @param {Category} category Category Object
 * @param {Function} selectCategory Callback handle selection a category
 */
const CardCategoryMobile = (props: Props) => {
  const navigation = useNavigation();
  const { colors } = useTheme();
  const getIcon = (iconName: string) => {
    if (!iconName) return "";
    return iconName.replace(/_/g, "-");
  };

  return (
    <Card
      style={styles.card}
      elevation={4}
      onPress={() => {
        props.selectCategory(props.category);
        navigation.navigate("Carriers", props.category);
      }}
    >
      <Card.Title
        style={{
          paddingTop: 15,
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        title={props.category.name.toUpperCase()}
        titleNumberOfLines={2}
        titleStyle={[
          styles.titleStyle,
          { lineHeight: 15, width: "90%", marginVertical: 0 },
        ]}
      />
      <Card.Actions style={styles.cardActions}>
        <View style={styles.actionWrapper}>
          <View
            style={{
              width: 70,
              height: 70,
              backgroundColor: props.iconColor,
              borderRadius: 35,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MaterialIcons
              name={getIcon(props.category.icon)}
              size={42}
              color={colors.surface}
            />
          </View>
        </View>
      </Card.Actions>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    flexGrow: 3,
    padding: 5,
    elevation: 1,
  },
  tinyLogo: {
    resizeMode: "stretch",
    width: 80,
    height: 80,
  },
  containerIcon: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  titleStyle: {
    fontFamily: "Montserrat_Bold",
    lineHeight: 20,
    fontSize: 16,
    textAlign: "left",
    // marginTop: 13,
    // alignItems: 'center',
  },
  cardActions: {
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  actionWrapper: {
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "center",
  },
});

export default CardCategoryMobile;
