import AsyncStorage from '@react-native-async-storage/async-storage';
import { SubmitTransactionComplete } from '../../../../lib/Transactions/transactions';
import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'setResponse';

interface Data extends Action {
    response: SubmitTransactionComplete
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        submitting: false,
        response: data.response
    };
}

export const setResponse = async (context: ContextType, data: Omit<Data, 'type'>) => {
    context.dispatch({ type: NAME, ...data });
    await AsyncStorage.removeItem('cacheKey');
}