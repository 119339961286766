import React, { useCallback, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import CodeScanner from "../../common_modules/components/BarCodeScanner/CodeScanner";
import { useMarket } from "../Market/hook/useMarket";
import { productFragment2Product } from "../Market/reducer/actions/loadProducts";
import { CommonActions, useNavigation } from "@react-navigation/native";
import Footer from "../../common_modules/components/Footer/Footer";
import { BarCodeScanner } from "expo-barcode-scanner";
import { SEARCH_PRODUCT_BY_SKU } from "../Market/MarketWizard/StepWelcome/QuickSale.graphql";
import HeaderTitle from "../../navigation/Header/HeaderTitle";
import { VERIFY_PAYMENT } from "./CustomerPayment.graphql";
import { useNotification } from "../../common_modules/providers/Notification/hook/useNotification";
import { SCAN_QR } from "../../common_modules/providers/CreditCardPayment/CardSwiper.graphql";
import { useApolloGraphQL } from "../../common_modules/providers/ApolloGraphQL/hook/useGraphqlApolloClient";

const ScanCode = ({ route }) => {
  const navigation = useNavigation();
  const [go, setGo] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [textInfo, setTextInfo] = useState("Please scan code");
  const [errors, setError] = useState({
    error: false,
    message: "",
  });
  const { client } = useApolloGraphQL();
  const { success, error } = useNotification();
  const { selectCarrier, selectProduct, quickSale } = useMarket();

  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () => {
      navigation.dispatch((state) => {
        setLoading(false);
        // Remove the Scan route from the stack
        const routes = state.routes.filter((r) => r.name !== "Scan");

        return CommonActions.reset({
          ...state,
          routes,
          index: routes.length - 1,
        });
      });
    });
    return unsubscribe;
  }, [navigation]);

  const scanComplete = async (dataScan, type) => {
    try {
      setLoading(true);
      if (type === BarCodeScanner.Constants.BarCodeType.qr) {
        if (route.params && route.params.customerScan) {
          await customerScan(dataScan);
        } else {
          await scanQr(dataScan);
          setGo(true);
        }
      } else {
        await scanBarCode(dataScan);
        setGo(true);
      }
    } catch (e) {
      setLoading(false);
      error(e.message);
      setError({
        error: true,
        message: e.message,
      });
    }
  };

  const customerScan = async (dataScan: string) => {
    if (!dataScan) {
      throw new Error("Invalid customer lpayment identifier");
    }
    const { data } = await client.mutate({
      mutation: VERIFY_PAYMENT,
      fetchPolicy: "no-cache",
      variables: {
        paymentAgreementToken: dataScan,
      },
    });
    navigation.navigate("Product Form", {
      customerScan: {
        verified: true,
        dataScan
      },
    });
    success("Payment Verified");
    setGo(true);
  };

  const scanQr = useCallback(async (dataScan) => {
    const { data } = await client.mutate({
      mutation: SCAN_QR,
      variables: { url: dataScan },
    });
    if (data.payments && data.payments.parseLink.product) {
      setTextInfo("Code scanned successfully!");
      const product = productFragment2Product(data.payments.parseLink.product);
      selectProduct(product);
      navigation.navigate("Product Form");
    } else if (data.payments && data.payments.parseLink.category) {
      setTextInfo("Code scanned successfully!");
      selectCarrier(data.payments.parseLink.category);
      navigation.navigate("Products", data.payments.parseLink.category);
    }
  }, []);

  const scanBarCode = useCallback(async (dataScan) => {
    const productSku =
      String(dataScan).length <= 12
        ? String(dataScan)
        : String(dataScan).substr(0, 11);
    const accountNumber =
      dataScan.length > 12 ? String(dataScan).substr(-19) : "";
    const { data } = await client.query({
      query: SEARCH_PRODUCT_BY_SKU,
      variables: { sku: productSku },
    });
    if (data.products.findBySku) {
      setTextInfo("Code scanned successfully!");
      quickSale(
        data.products.findBySku.category,
        productFragment2Product(data.products.findBySku),
        accountNumber,
        ""
      );
      navigation.navigate("Product Form", { accountNumber });
    }
  }, []);

  return (
    <>
      <HeaderTitle title={!errors.error ? textInfo : errors.message} />
      <View style={styles.container}>
        <CodeScanner
          scanComplete={scanComplete}
          error={errors}
          setError={setError}
          setGo={setGo}
          go={go}
          setTextInfo={(value) => setTextInfo(value)}
          loading={loading}
        />
      </View>
      {/* <Footer /> */}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default ScanCode;
