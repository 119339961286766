import React, { useEffect, useRef, useState } from "react";
import {
  View,
  StyleSheet,
  Image,
  KeyboardAvoidingView,
  Keyboard,
} from "react-native";
import {
  Button,
  Card,
  TextInput,
  HelperText,
  Title,
  useTheme,
  Text,
  RadioButton,
} from "react-native-paper";
import "@expo/match-media";
import { useMediaQuery } from "react-responsive";
import DismissKeyboard from "../../components/DismissKeyboard/DismisKeyboard";
import { useSignUp } from "../SignUp/hook/useSignUp";
import { ProtocolTypes } from "../SignUp/reducer/actions/actionTypes";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isPossibleNumber } from "libphonenumber-js";
import { Controller, useForm } from "react-hook-form";
import { isWebPlatform, IS_DESKTOP_OR_LAPTOP, IS_SMALL_SCREEN } from "../../lib/PlatformDevice";

export default function ResetPassword({ navigation }) {
  const [protocol, setProtocol] = useState<ProtocolTypes>("EMAIL");
  const [showPassword, setShowPassword] = useState(true);
  const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
  const isSmallScreen = useMediaQuery(IS_SMALL_SCREEN);
  const { colors } = useTheme();
  const {
    dispatch,
    resetPasswordTwoFactorAuth,
    requestingTwoFactorAuth,
    done,
    processCompleted,
  } = useSignUp();

  let schemaFields: { [name: string]: yup.SchemaOf<any> } = {};
  if (protocol === "EMAIL") {
    schemaFields.email = yup.string().ensure().required().email();
  } else {
    schemaFields.phone = yup
      .string()
      .required()
      .test("isValidPhone", "Please enter valid phone number", (value) =>
        isPossibleNumber(value)
      );
  }
  schemaFields.password = yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    );
  schemaFields.repeatPassword = yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .label("Repeat Password");

  const schema = yup.object(schemaFields);
  const { control, handleSubmit, errors, setError, getValues, setValue } =
    useForm({
      mode: "all",
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    if (done && processCompleted) navigation.navigate("Login");
  }, [done]);

  const protocolText = protocol === "SMS" ? "phone" : protocol.toLowerCase();

  const resetPassword = (data) => {
    dispatch({
      type: "REQUEST_TWO_FACTOR_AUTH",
      operationType: "RESET_PASSWORD",
      target: data[protocolText],
      password: data.password,
    });
    resetPasswordTwoFactorAuth(protocol, data[protocolText]);
  };

  const toggleShowPassword = () => setShowPassword((prevState) => !prevState);

  return (
    <DismissKeyboard>
      <View style={styles.container}>
        <KeyboardAvoidingView behavior="height">
          <Card
            style={{
              marginBottom: 25,
              marginHorizontal: isDesktopOrLaptop ? "30%" : "10%",
            }}
          >
            <Card.Content style={{ margin: isWebPlatform() ? 30 : 10 }}>
              <Title style={{ textAlign: "center" }}>Reset Password</Title>
              <View nativeID="disable-outline" style={{ marginTop: 30 }}>
                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <>
                      <TextInput
                        style={{ textAlign: "auto" }}
                        error={!!errors[protocolText]}
                        label={`Enter your ${protocolText}`}
                        value={value}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        left={
                          <TextInput.Icon name="account-circle" size={28} />
                        }
                      />
                      <HelperText type="error" visible={!!errors[protocolText]}>
                        {errors[protocolText]?.message}
                      </HelperText>
                    </>
                  )}
                  name={protocolText}
                  defaultValue=""
                />

                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <>
                      <TextInput
                        style={{ textAlign: "auto" }}
                        error={!!errors.password}
                        label="New password"
                        value={value}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        left={<TextInput.Icon name="lock" size={28} />}
                        secureTextEntry={showPassword}
                        right={
                          <TextInput.Icon
                            name={
                              showPassword ? "remove-red-eye" : "visibility-off"
                            }
                            disabled={!value}
                            size={28}
                            onPress={toggleShowPassword}
                          />
                        }
                      />
                      <HelperText type="error" visible={!!errors.password}>
                        {errors.password?.message}
                      </HelperText>
                    </>
                  )}
                  name={"password"}
                  defaultValue=""
                />

                <Controller
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <>
                      <TextInput
                        style={{ textAlign: "auto" }}
                        error={!!errors.password}
                        label="Repeat password"
                        value={value}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        secureTextEntry={showPassword}
                        left={<TextInput.Icon name="lock" size={28} />}
                      />
                      <HelperText
                        type="error"
                        visible={!!errors.repeatPassword}
                      >
                        {errors.repeatPassword?.message}
                      </HelperText>
                    </>
                  )}
                  name={"repeatPassword"}
                  defaultValue=""
                />

                <View style={{ alignItems: "center", marginTop: 10 }}>
                  <Text style={{ textAlign: "center" }}>
                    Select the preferred method to validate your account.
                  </Text>

                  <View style={{ flexDirection: "row", marginVertical: 5 }}>
                    <View
                      style={{
                        marginLeft: 10,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text>Email</Text>
                      <RadioButton
                        color={colors.primary}
                        uncheckedColor={colors.primary}
                        value="EMAIL"
                        status={protocol === "EMAIL" ? "checked" : "unchecked"}
                        onPress={() => setProtocol("EMAIL")}
                      />
                    </View>
                    <View
                      style={{
                        marginLeft: 10,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text>Phone</Text>
                      <RadioButton
                        color={colors.primary}
                        uncheckedColor={colors.primary}
                        value="SMS"
                        status={protocol === "SMS" ? "checked" : "unchecked"}
                        onPress={() => setProtocol("SMS")}
                      />
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <Button
                  style={{
                    flex: 1,
                    marginRight: 5,
                  }}
                  mode="outlined"
                  onPress={() => navigation.goBack()}
                >
                  CANCEL
                </Button>
                <Button
                  style={{
                    flex: 1,
                    marginLeft: 5,
                  }}
                  mode="contained"
                  onPress={handleSubmit(resetPassword)}
                  loading={requestingTwoFactorAuth}
                  disabled={requestingTwoFactorAuth}
                >
                  RESET
                </Button>
              </View>
            </Card.Content>
          </Card>
        </KeyboardAvoidingView>
      </View>
    </DismissKeyboard>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#d32f2e",
    justifyContent: "center",
  },
  tinyLogo: {
    width: "80%",
    height: 60,
    resizeMode: "contain",
  },
  buttonsContainer: {
    justifyContent: "space-between",
    marginBottom: 25,
    marginTop: 25,
  },
  textFooter: {
    textAlign: "center",
    color: "#efefef",
    justifyContent: "center",
    fontSize: isWebPlatform() ? 12 : 10,
    marginTop: 5,
  },
});
