import { UserActivity } from '../../UserActivity/hook/useUserActivity';
import React, { Dispatch } from 'react';
import { Action } from './reducer';
import { State } from './state';
import { ApolloGraphQL } from '../../../../ApolloGraphQL/hook/useGraphqlApolloClient';
import { Notification } from '../../Notification/hook/useNotification';

export interface ContextType {
    readonly _type?: 'TwoFactorAuth',
    dispatch?: Dispatch<Action>,
    state?: State,
    apolloGraphQL: ApolloGraphQL,
    notify?: Notification,
    userActivity?: UserActivity,
}

export const TwoFactorAuthContext = React.createContext<ContextType>(null);
export default TwoFactorAuthContext;
