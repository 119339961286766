import { ContextType } from '../context';
import { registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'clearNotifications';

registerReducer(NAME, reducer);

function reducer(state: State): State {

    return {
        ...state,
        notifications: {},
    };
}

export const clearNotifications = (context: ContextType) => {
    context.dispatch({ type: NAME });
}
