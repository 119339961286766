import { QueryOptions } from 'apollo-client';
import { ObservableSummary } from '../../Summary/lib/summary';
import { transactionSummary, TransactionSummaryOptions } from '../../Summary/lib/transactions';
import { useContext } from 'react';
import Context from '../reducer/context';

export interface Summary {
    fetchTransactionSummary: <E>(summaryOptions: TransactionSummaryOptions, queryOptions?: QueryOptions, listenEvents?: Array<E>) => ObservableSummary<TransactionSummaryOptions, E>
}

export function useSummary(): Summary {
    const context = useContext(Context);
    if (!context) {
        throw new Error('Only can use `useSummary` inside a valid SummaryProvider');
    }

    return {
        fetchTransactionSummary: <E>(summaryOptions, queryOptions?, listenEvents?) =>
            transactionSummary<E>(context.apolloGraphQL.client, summaryOptions, queryOptions, listenEvents),
    };
}
