import gql from 'graphql-tag';
import { DEFAULT_STORE_INFO_FRAGMENT, DEFAULT_VIEWER_FRAGMENT } from '../../../../common_modules/providers/StoreInfo/reducer/actions/loadStoreInfo.graphql';

export const QUERY_STORE_INFO = gql`
    query LOAD_STORE_INFO {
        storeInfo {
            ...DEFAULT_STORE_INFO_FRAGMENT
            eGiftCertificateSettings{
                product{
                    id
                    name
                }
            }
        }
        viewer {
            ...DEFAULT_VIEWER_FRAGMENT
            ... on Clerk {
                email
                clerkSettings {
                    showProductDiscounts
                    use3DSecureWhenIsAvailable
                }
            }
        }
        uiSettings
    }
    ${DEFAULT_VIEWER_FRAGMENT}
    ${DEFAULT_STORE_INFO_FRAGMENT}
`;

