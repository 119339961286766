import React, { Dispatch } from 'react';
import { ApolloGraphQL } from '../../ApolloGraphQL/hook/useGraphqlApolloClient';
import { ClientFingerPrint } from '../../ClientFingerPrint/hook/useClientFingerPrint';
import { Action } from './reducer';
import { State } from './state';

export interface ContextType {
    readonly _type?: 'UserActivity',
    dispatch: Dispatch<Action>,
    state: State,
    apolloGraphQL: ApolloGraphQL,
    clientFingerPrint: ClientFingerPrint,
}

export const UserActivityContext = React.createContext<ContextType>(null);
export default UserActivityContext;
