import ApolloClient from 'apollo-client';
import { roundNumber } from '../number.util';
import { captureException } from '../sentry';
import { SUBMIT, VALIDATE } from '../transactions.graphql';
import { Adjustment } from '../../model/Adjustment';
import { APIError } from '../../model/APIError';
import { TransactionPaymentMethod } from '../../model/TransactionPaymentMethod';
export interface ValidateTransactionArgs {
    referrer?: string,
    standalonePaymentUrl?: string,
    customerToken?: string,
    dealerToken?: string,
    product: string,
    paymentMethod: TransactionPaymentMethod,
    inputs: any,
}

export interface ValidateTransactionAdjustment {
    name: string,
    mode: string,
    amount: number,
    total: number,
    isDiscount: boolean,
    rateMode: boolean,
}

export interface ValidationTransactionSuccess {
    message?: string,
    paymentBillingDescriptor?: string,
    paymentTermsAndConditionsUrl?: string,
    grossTotal: number,
    adjustments: ValidateTransactionAdjustment[],
    product: {
        name: string
        carrier: {
            name: string
        }
        category: {
            name: string
        }
    }
    total: number,
}

export const convertAPIError = (error) => {
    if (error.graphQLErrors && error.graphQLErrors.length) {
        const graphqlError = error.graphQLErrors[0];
        if (graphqlError) {
            if (graphqlError.code == 422) {
                return new APIError(graphqlError.code, 'Validation Error', graphqlError.constraintViolations);
            } else {
                return new APIError(graphqlError.code, graphqlError.message);
            }
        }
    } else if (error.networkError) {
        return new APIError(error.networkError.statusCode, error.networkError.message);
    }

    if (error instanceof APIError) {
        return error;
    }

    if (typeof error === 'string') {
        return new APIError(500, error);
    }

    return new APIError(500, error.message ? error.message : 'Unknown Error');
};

export const PaymentMethodData = [
    {
        label: 'Cash',
        value: TransactionPaymentMethod.CASH
    },
    {
        label: 'External Card Terminal',
        value: TransactionPaymentMethod.EXTERNAL_CARD_TERMINAL
    },
    {
        label: 'Credit Card',
        value: TransactionPaymentMethod.CREDIT_CARD
    },
    {
        label: 'Mesh Money',
        value: TransactionPaymentMethod.EGIFT_MESH
    },
    {
        label: 'Wallet',
        value: TransactionPaymentMethod.WALLET
    },
    {
        label: 'Private Label Gift Card',
        value: TransactionPaymentMethod.PRIVATE_LABEL_GIFT_CARD
    },
]

export const updatePaymentMethodData = (payments: string[]) => {
    if (payments === undefined) return [];

    let ret : [{label: string, value: TransactionPaymentMethod}] | [] = []

    payments.forEach((payment) => {
        let obj = PaymentMethodData.find(o => o.value === payment);
        if (obj !== null && obj !== undefined) {
            ret.push(obj)
        }
    })

    return ret;
}

export const validateTransaction = (client: ApolloClient<any>, args: ValidateTransactionArgs): Promise<ValidationTransactionSuccess> =>
    new Promise<ValidationTransactionSuccess>((success, error) => {
        client.mutate({
            mutation: VALIDATE,
            fetchPolicy: 'no-cache',
            context: args.dealerToken ? {
                headers: {
                    Authorization: args.dealerToken,
                }
            } : undefined,
            variables: {
                input: {
                    referrer: args.referrer,
                    standalonePaymentUrl: args.standalonePaymentUrl,
                    customerToken: args.customerToken,
                    product: args.product,
                    paymentMethod: args.paymentMethod ? args.paymentMethod : undefined,
                    inputs: args.inputs,
                },
            },
        }).then(({ data }) => {
            success({ ...data.transactions.validate });
        }).catch((e) => {
            captureException(e);
            error(convertAPIError(e));
        });
    });

export const getPrice = (amountNumber: number, styled: boolean = true) => {
    if (amountNumber === undefined || amountNumber === null)
        return null;
    const amount = Number(roundNumber(amountNumber)).toFixed(2)

    if (styled) {
        const amountParts = String(amount).split('.');
        return `$${amountParts[0]}.${amountParts[1]}`;
    }

    if (Number(amount) < 0) {
        return `$${Math.abs(Number(amount)).toFixed(2)}`
    };

    return `$${amount}`;
}

export interface CreditCardInstrumentInput {
    number?: string,
    expMonth?: number,
    expYear?: number,
    cvv?: string,
    magData?: string,
    holder?: {
        name?: string,
        address1?: string,
        zipCode?: string,
    },
}

export interface SubmitTransactionCreditCard extends CreditCardInstrumentInput {
    remember?: boolean,
    token?: string,
    consumerAuthToken?: string,
}

export interface SubmitTransactionArgs {
    customerToken?: string,
    paymentAgreementToken?: string,
    /* @deprecated obtained automatically */
    securePayments?: 'NONE' | 'CARDINAL_COMMERCE';
    /* @deprecated obtained automatically */
    sandbox?: boolean,
    product?: string,
    referrer?: string
    standalonePaymentUrl?: string
    logRocketSessionUrl?: string
    correlationId?: string,
    clientFingerprint?: string,
    returnUrl?: string,
    receiptEmail?: string,
    inputs?: any,
    paymentMethod?: TransactionPaymentMethod,
    isWalletBalance?: boolean,
    /* @deprecated obtained automatically */
    orderTotal?: number,
    creditCard?: SubmitTransactionCreditCard,
    applePay?: {
        token: any // apple pay token
        billingContact?: {
            familyName?: string,
            givenName?: string,
            phoneNumber?: string,
            emailAddress?: string,
        },
        shippingContact?: {
            familyName?: string,
            givenName?: string,
            phoneNumber?: string,
            emailAddress?: string,
        }
    },
    eGift?: {
        pin: string,
    },
    twoFactorAuthPIN?: string,
    twoFactorAuthToken?: string,
    dealerToken?: string,
    privateLabelGiftCard?: string,
}

export interface SubmitTransactionComplete {
    redirectUrl?: string
    transaction: {
        id: string,
        date: Date,
        number: string,
        correlationId?: string,
        customerReceiptPdf: string,
        status: string,
        receiptEmail: string,
        grossTotal: number,
        adjustments: Adjustment[],
        total: number
        product: {
            id: string,
            name: string,
            isPIN?: boolean,
            carrier: {
                name: string
            }
            category: {
                name: string
            }
        }
        response: {
            redemptionUrl: string,
            errorCode: number,
            message: string,
            code: string
            pin: string
            pinApplied: boolean
        }
    }
}

let redirectWindow: Window = null;

export const submitTransaction =
    (client: ApolloClient<any>, args: SubmitTransactionArgs): Promise<SubmitTransactionComplete> => new Promise((finish, finishError) => {
        client.mutate({
            mutation: SUBMIT,
            fetchPolicy: 'no-cache',
            context: args.dealerToken ? {
                headers: {
                    Authorization: args.dealerToken,
                }
            } : undefined,
            variables: {
                input: {
                    customerToken: args.customerToken,
                    returnUrl: args.returnUrl,
                    correlationId: args.correlationId,
                    clientFingerprint: args.clientFingerprint,
                    referrer: args.referrer,
                    standalonePaymentUrl: args.standalonePaymentUrl,
                    logRocketSessionUrl: args.logRocketSessionUrl,
                    product: args.product,
                    receiptEmail: args.receiptEmail,
                    inputs: args.inputs,
                    paymentMethod: args.paymentMethod,
                    creditCard: args.creditCard,
                    privateLabelGiftCard: args.privateLabelGiftCard,
                    eGift: args.eGift,
                    applePay: args.applePay,
                    twoFactorAuthPIN: args.twoFactorAuthPIN,
                    twoFactorAuthToken: args.twoFactorAuthToken,
                    paymentAgreementToken: args.paymentAgreementToken
                },
            }
        })
            .then((response) => {
                finish({
                    redirectUrl: response.data.transactions.submit.redirectUrl,
                    transaction: {
                        ...response.data.transactions.submit.transaction,
                    },
                });
            })
            .catch((e) => {
                console.log(e)
                captureException(e);
                return finishError(convertAPIError(e));
            });


        return;

        //TODO:: remove the following code, but firstly move the response redirect feature to a plugin
        const randomCorrelationNumber = String(Date.now());
        const submitPayment = ({ consumerAuthToken }) => {

            let creditCard: any = {
                ...args.creditCard,
                consumerAuthToken,
            };

            if (args.customerToken && args.creditCard && args.creditCard.token) {
                creditCard = { token: args.creditCard.token, cvv: args.creditCard.cvv };
            }

            client.mutate({
                mutation: SUBMIT,
                variables: {
                    input: {
                        customerToken: args.customerToken,
                        returnUrl: `${window.location.origin + window.location.pathname}?dialogSuccess=true`,
                        correlationId: args.correlationId ? args.correlationId : randomCorrelationNumber,
                        product: args.product,
                        receiptEmail: args.receiptEmail,
                        inputs: args.inputs ? args.inputs : {},
                        paymentMethod: args.paymentMethod,
                        creditCard: creditCard,
                        twoFactorAuthPIN: args.twoFactorAuthPIN,
                        twoFactorAuthToken: args.twoFactorAuthToken,
                    },
                },
            }).then((response) => {
                const result: SubmitTransactionComplete = {
                    transaction: {
                        ...response.data.transactions.submit.transaction,
                    },
                };

                if (response.data.transactions.submit.transaction.status === 'ON_HOLD'
                    && response.data.transactions.submit.redirectUrl) {
                    const transactionID = response.data.transactions.submit.transaction.id;
                    redirectWindow = openWindowPopup(
                        response.data.transactions.submit.redirectUrl,
                        'Payment',
                        'width=550,height=550,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes',
                        () => {
                            client
                                .query({
                                    query: FETCH_TRANSACTION,
                                    fetchPolicy: 'no-cache',
                                    variables: { id: transactionID },
                                })
                                .then((fetchResponse) => {
                                    if (fetchResponse.data.node.status === 'COMPLETED') {
                                        result.transaction.response.pin = fetchResponse.data.node.response.pin;
                                        finish(result);
                                    } else if (fetchResponse.data.node.response.errorCode) {
                                        finishError(new APIError(
                                            fetchResponse.data.node.response.errorCode,
                                            fetchResponse.data.node.response.message
                                        ));
                                    }
                                })
                                .catch((e) => {
                                    finishError(new APIError(e.code, e.message));
                                });
                        }
                    );
                } else {
                    finish(result);
                }
            }).catch((e) => {
                finishError(convertAPIError(e));
            });
        };

        // logger.debug('Submitting transaction', args);

        if (args.paymentMethod === TransactionPaymentMethod.CREDIT_CARD
            && args.securePayments === 'CARDINAL_COMMERCE'
            && args.creditCard
            && !args.creditCard.token
        ) {
            if (args.sandbox) {
                // set sandbox token
                submitPayment({
                    consumerAuthToken: 'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiI1ODJlMG1NDgQ.m9xie49ddoGSAQ3JHIzKBs5UsDX',
                });
            } else {
                validateTransaction(client, {
                    product: args.product,
                    paymentMethod: args.paymentMethod,
                    inputs: args.inputs,
                }).then((result) => {
                    secureWithCardinal(client, {
                        sandbox: args.sandbox,
                        orderAmount: result.total,
                        orderNumber: args.correlationId ? args.correlationId : randomCorrelationNumber,
                        cardNumber: args.creditCard.number,
                        cardExpMonth: args.creditCard.expMonth,
                        cardExpYear: args.creditCard.expYear,
                    }).then((response) => {
                        submitPayment({
                            consumerAuthToken: response.jwt,
                        });
                    }).catch((error) => {
                        captureException(error);
                        finishError(new APIError(2550, error.message));
                    });
                }).catch((error) => {
                    captureException(error);
                    finishError(new APIError(error.code, error.message));
                });
            }
        } else {
            submitPayment({
                consumerAuthToken: null,
            });
        }
    });
