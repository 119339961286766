import React, { memo } from "react";
import { View, StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import { CustomText } from "../Text/Text";
import moment from "moment";
import HideByKeyboardStatus from "../HideByKeyboardStatus/HideByKeyboardStatus";
import { getPlatform } from "../../lib/PlatformDevice";

const Footer = () => {
  const { colors } = useTheme();

  return (
    <HideByKeyboardStatus behavior="active">
      <View style={[styles.footer, { backgroundColor: colors.primary }]}>
        <View style={{ marginVertical: getPlatform() === "ios" ? 3 : 5 }}>
          <CustomText type="light" color={colors.surface}>
            {/* &copy; {moment().year()} */}
          </CustomText>
        </View>
      </View>
    </HideByKeyboardStatus>
  );
};

const styles = StyleSheet.create({
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    alignItems: "center",
    width: "100%",
  },
});
export default memo(Footer);
