import AsyncStorage from "@react-native-async-storage/async-storage";
import { CustomerAuthData, getCustomerAuthData, removeCustomerAuthData, setCustomerAuthData } from "../../lib/auth";
import { ContextType } from "./reducer/context";

export const persistCustomerAuthToken = async (customerAuth: CustomerAuthData, rememberMe: boolean) => {
    if (rememberMe) {
        await setCustomerAuthData(customerAuth);
    } else {
        await setCustomerAuthData(customerAuth);
    }
};


export const getCustomerAuthPersistedToken = async () => {
    return await getCustomerAuthData();
};

export const removeCustomerAuthPersistedToken = async (context: ContextType) => {
    await removeCustomerAuthData();
    await AsyncStorage.multiRemove(['apollo-cache-persist', 'customer-auth', 'ApiToken', 'refreshToken']);
    if (context && context.apolloGraphQL) {
        if (context.apolloGraphQL.client){
            await context.apolloGraphQL.client.clearStore();
        }
        context.storeInfo.cleanStoreInfo();
        context.apolloGraphQL.removeBearerToken();
    }
};
