import { TotalAndAdjustments } from '../../../../model/Adjustment';
import _ from 'lodash';
import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'setTotal';

interface Data extends Action {
    total: TotalAndAdjustments
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        updatingTotal: false,
        total: {
            ...data.total,
            adjustments: data.total ? _(data.total.adjustments)
                .groupBy('name')
                .map((objs, key) => ({
                    name: key,
                    total: _.sumBy(objs, 'total'),
                    amount: _.sumBy(objs, 'total'),
                    rateMode: false,
                }))
                .value() : []
        },
    };
}

export const setTotal = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
