import React, { useEffect, useState } from 'react';
import { View, Dimensions } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import { Rect, Text as TextSVG, Svg } from 'react-native-svg';
import moment from 'moment';
import { Avatar } from 'react-native-paper';
import { ScrollView } from 'react-native-gesture-handler';
import LoadingComponent from '../Loading/Loading';
import { useMediaQuery } from "react-responsive";
import { useSummary } from '../../providers/Summary/hook/useSummary';
import { TransactionSummaryType } from '../../providers/Summary/lib/transactions';
import { isWebPlatform, IS_SMARTPHONE } from '../../lib/PlatformDevice';

export const TopProductChart = ({ summaryData, summaryOptions, type }) => {
    const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
    let [tooltipPos, setTooltipPos] = useState({
        x: 0,
        y: 0,
        visible: false,
        value: 0,
    });

    const summary = useSummary();
    const [dataChart, setDataChart] = useState({
        transactions: [],
        time: []
    });

    useEffect(() => {
        let data = { transactions: [], time: [] };
        const subscription = summary.fetchTransactionSummary({
            type: TransactionSummaryType.COUNT_BY_DATE,
            commissions: true,
            minDate: summaryOptions.minDate,
            maxDate: summaryOptions.maxDate,
            ...summaryOptions,
        }).subscribe((summary) => {
            summary.map((s, index) => {
                if (index % 7 === 0 || s.count > 0) {
                    data.transactions.push(s.count);
                    const date = new Date(s.name);
                    data.time.push(moment(date).format(!isSmartPhone ? 'DD MMM YYYY' : 'DD MMM YY'))
                }
            });
            setDataChart(data);
        });

        return () => subscription.unsubscribe();
    }, [summaryOptions]);

    return (
        < View >
            {dataChart.transactions.length && dataChart.time.length ?
                <ScrollView horizontal={true}>
                    <LineChart
                        data={{
                            labels: dataChart.time,
                            datasets: [
                                {
                                    data: dataChart.transactions
                                },
                            ],
                        }}
                        width={Dimensions.get('window').height}
                        height={250}
                        yAxisInterval={1}
                        chartConfig={{
                            backgroundColor: 'white',
                            backgroundGradientFrom: '#fbfbfb',
                            backgroundGradientTo: '#fbfbfb',
                            decimalPlaces: 0,
                            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                            labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                            style: {
                                borderRadius: 0,
                            },
                            propsForDots: {
                                r: '6',
                                strokeWidth: '0',
                                stroke: 'blue',
                            },
                        }}
                        bezier
                        style={{
                            marginVertical: 8,
                            borderRadius: 6,
                        }}
                        verticalLabelRotation={!isWebPlatform() ? -16: undefined}
                        decorator={() => {
                            return tooltipPos.visible ? (
                                <View>
                                    <Svg>
                                        <Rect
                                            x={tooltipPos.x - 15}
                                            y={tooltipPos.y + 10}
                                            width="120"
                                            height="30"
                                            fill="black"
                                        />
                                        <Avatar.Icon
                                            icon="attach-money"
                                            size={32}
                                            color="rgb(67, 67, 67)"
                                            style={{ backgroundColor: "transparent" }}
                                        />
                                        <TextSVG
                                            x={tooltipPos.x + 5}
                                            y={tooltipPos.y + 30}
                                            fill="white"
                                            fontSize="12"
                                            fontWeight="bold"
                                            textAnchor="end"
                                        >
                                            {tooltipPos.value}
                                        </TextSVG>
                                        <TextSVG
                                            x={tooltipPos.x + 10}
                                            y={tooltipPos.y + 30}
                                            fill="white"
                                            fontSize="12"
                                            fontWeight="bold"
                                            textAnchor="start">
                                            Transactions
                                        </TextSVG>
                                    </Svg>
                                </View>
                            ) : null;
                        }}
                        onDataPointClick={(data) => {
                            let isSamePoint = tooltipPos.x === data.x && tooltipPos.y === data.y;

                            isSamePoint
                                ? setTooltipPos((previousState) => {
                                    return {
                                        ...previousState,
                                        value: data.value,
                                        visible: !previousState.visible,
                                    };
                                })
                                : setTooltipPos({
                                    x: data.x,
                                    value: data.value,
                                    y: data.y,
                                    visible: true,
                                });
                        }}
                    />
                </ScrollView > : <LoadingComponent />}
        </View >
    );
};