import gql from 'graphql-tag';

export const RESET_PASSWORD_TWO_FACTOR_AUTH = gql`
    mutation resetPasswordRequest($username: String!, $protocol: TwoFactorAuthenticationProtocol!) {
        customers {
            resetPasswordRequest(username: $username, protocol: $protocol) {
                token
                protocol    
                target
            }
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPasswordConfirm($username: String!, $newPassword: String!, $twoFactorAuthToken: String!, $twoFactorAuthPIN: String!) {
        customers {
            resetPasswordConfirm(username: $username, newPassword: $newPassword, twoFactorAuthPIN: $twoFactorAuthPIN, twoFactorAuthToken: $twoFactorAuthToken)     
        }
    }     
`;