import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { State } from './../state';

const NAME = 'setRedirectUrl';

interface Data extends Action {
    redirectUrl?: string
    onClose?: () => void,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        redirectUrl: data.redirectUrl,
        onClose: data.onClose
    };
}

export const setRedirectUrl = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
