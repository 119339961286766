import React from 'react';
import {Controller} from 'react-hook-form';
import {View} from 'react-native';
import {Text, TextInput, useTheme} from 'react-native-paper';
import {InputHelperError} from './InputHelperText';
import {useMediaQuery} from "react-responsive";
import {useNavigation} from '@react-navigation/native';
import {isWebPlatform, IS_DESKTOP_OR_LAPTOP} from '../../lib/PlatformDevice';

interface Props {
    control: any,
    errors: any,
    isSwipe: () => void,
    isScan: () => void,
    setScanningCode: (data) => void,
    editable: boolean
}

/**
 * @function Component InputBarCode
 * @description Customized input for barcode scan
 * @param {Object} control Form control
 * @param {Object} errors Form errors
 * @param {Function} isSwipe Callback handle swipe complete
 * @param {Function} isScan Callback handle scan complete
 * @param {Function} setScanningCode Callback clean input
 * @param {boolean} editable Made the input editable or not
 */
const InputBarCode = ({control, errors, isScan, setScanningCode, isSwipe, editable}: Props) => {
    const {colors} = useTheme();
    const isDesktopOrLaptop = useMediaQuery(IS_DESKTOP_OR_LAPTOP);
    const navigation = useNavigation();

    return (
        <Controller
            control={control}
            render={({onChange, onBlur, value}) => (
                <>
                    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{paddingBottom: 10, fontFamily: 'Montserrat_Regular'}}>Card Number</Text>
                    </View>
                    <View>
                        <TextInput
                            onChangeText={(text) => onChange(text)}
                            value={value}
                            style={{backgroundColor: colors.surface, textAlign: "auto"}}
                            mode={isDesktopOrLaptop ? 'outlined' : 'flat'}
                            editable={editable}
                            onBlur={onBlur}
                            error={!!errors.accountNumber}
                            left={<TextInput.Icon
                                color={!!errors.accountNumber ? colors.primary : colors.onSurface}
                                name="card-giftcard"/>}
                            right={value ?
                                <TextInput.Icon name="close" onPress={() => setScanningCode('')}/> :
                                <TextInput.Icon
                                    name="fit-screen" /* crop-free */
                                    onPress={() => !isWebPlatform() ?
                                        navigation.navigate('Scan') : isSwipe()}/>
                            }
                        />
                        <InputHelperError
                            isError={!!errors.accountNumber}
                            message={errors.accountNumber?.message}/>
                    </View>
                </>
            )}
            name="accountNumber"
            defaultValue=""
        />
    );
};

export default InputBarCode;

