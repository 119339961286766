import { useNavigation } from "@react-navigation/native";
import React, { memo, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { useTheme, Text, Avatar } from "react-native-paper";
import { BaseButton } from "react-native-gesture-handler";
import { CustomText } from "../Text/Text";
import moment from "moment";
import Footer from "../Footer/Footer";
import { getPlatform } from "../../lib/PlatformDevice";
import { appIdentifier } from "../../../identifier";

interface Props {
  selectionTab: (value) => void;
  currentTabOption: string;
}

/**
 * @function BottomTabNavigator
 * @param {string} currentTabOption Name current tab selected
 * @param {Function} selectionTab Callback handle change tab
 */
function BottomTabNavigator({ selectionTab, currentTabOption }: Props) {
  const { colors } = useTheme();
  const navigation = useNavigation();
  const [marginTab, setMarginTab] = useState(0);
  const isClient = appIdentifier === "CLIENT";

  const BtnNavigator = ({ icon, title, color }) => {
    const active =
      !title || title.toLowerCase() === currentTabOption
        ? color
        : colors.dividers;
    const dimensions = !title ? 68 : 38;
    return (
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <BaseButton
          style={[
            styles.btnNavigator,
            {
              backgroundColor: active,
              marginHorizontal: title ? 98 : undefined,
              width: dimensions,
              height: dimensions,
              borderRadius: dimensions / 2,
              position: !title ? "absolute" : "relative",
              bottom: !title ? "40%" : undefined,
              zIndex: 99999999,
              elevation: getPlatform() === "android" ? 999999999 : undefined,
            },
          ]}
          onPress={() => {
            if (!title) navigation.navigate("Scan");
            else {
              selectionTab(title.toLowerCase());
            }
          }}
        >
          {!title ? (
            <Avatar.Image
              source={require("../../../../assets/img/icon_qr.png")}
              style={{ backgroundColor: active }}
              size={dimensions}
            />
          ) : (
            <Avatar.Icon
              icon={icon}
              style={{ backgroundColor: active }}
              color={colors.surface}
              size={dimensions}
            />
          )}
        </BaseButton>
        <Text style={{ fontSize: 10, color: active }}>{title}</Text>
      </View>
    );
  };

  return (
    // <>
    <View
      style={[styles.footer, { backgroundColor: colors.surface }]}
      onLayout={(event) => {
        let { x, y, width, height } = event.nativeEvent.layout;
        const spacing = width / 4;
        setMarginTab(spacing);
      }}
    >
      <View
        style={{
          marginVertical: getPlatform() === "ios" ? 8 : 5,
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <BtnNavigator color={colors.primary} icon="home" title={"Home"} />
        <BtnNavigator color={colors.onSurface} icon="fit-screen" title="" />
        <BtnNavigator
          color={colors.primary}
          icon="card-giftcard"
          title={isClient ? "Deals" : "Favorites"}
        />
      </View>
    </View>
    /* <Footer /> */
    /* </> */
  );
}

const styles = StyleSheet.create({
  footer: {
    flex: 1,
    position: "absolute",
    bottom: 0,
    left: 0,
    // alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    borderWidth: 1,
    borderColor: "#ddd",
    borderBottomWidth: 0,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 2,
    shadowOpacity: 0.8,
    elevation: 12,
  },
  footerWrapper: {
    width: "100%",
    alignItems: "center",
    paddingVertical: getPlatform() === "ios" ? 8 : 5,
  },
  btnNavigator: {
    alignItems: "center",
    justifyContent: "center",
  },
});

export default memo(BottomTabNavigator);
