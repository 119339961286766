/**
 * Round number to specific decimals with better precision
 *
 * @see https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
 *
 * @param num
 * @param scale
 */
export function roundNumber(num, scale = 2) {
    if (!(`${num}`).includes('e')) {
        return +(`${Math.round(Number(`${num}e+${scale}`))}e-${scale}`);
    }
    const arr = (`${num}`).split('e');
    let sig = '';
    if (+arr[1] + scale > 0) {
        sig = '+';
    }

    return +(`${Math.round(Number(`${+arr[0]}e${sig}${+arr[1] + scale}`))}e-${scale}`);

}
