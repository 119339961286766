import { useContext } from 'react';
import { CreditCardInstrumentInput } from '../../../lib/Transactions/transactions';
import { authorizationSuccess } from '../reducer/actions/authorizationSuccess';
import { initializeCreditCardVerification } from '../reducer/actions/initializeCreditCardVerification';
import { resetVerification } from '../reducer/actions/resetVerification';
import { updateAttemptsLeft } from '../reducer/actions/updateAttemptsLeft';
import Context from '../reducer/context';
import { AUTHORIZE_CREDIT_CARD, CHECK_CREDIT_CARD, CONFIRM_CREDIT_CARD_AUTH } from './useCreditCardVerification.graphql';

export interface CreditCardVerification {
    isAlreadyVerified: (creditCard: CreditCardInstrumentInput, customerToken: string) => Promise<boolean>
    verify: (creditCard: CreditCardInstrumentInput, customerToken: string) => Promise<boolean>
}

export function useCreditCardVerification(): CreditCardVerification {
    const context = useContext(Context);
    if (!context) {
        throw new Error('Only can use `useCreditCardVerification` inside a valid CreditCardVerificationProvider');
    }

    return {
        isAlreadyVerified: (creditCard: CreditCardInstrumentInput, customerToken: string) => new Promise<boolean>((isVerified, verifyError) => {
            const client = context.apolloGraphQL.client;
            client.mutate({
                mutation: CHECK_CREDIT_CARD,
                variables: {
                    authCardInput: {
                        customerToken: customerToken,
                        creditCard: creditCard,
                    },
                }
            }).then(({ data }) => {
                isVerified(data.tools.creditCardVerification.check);
            }).catch(verifyError);
        }),
        verify: (creditCard: CreditCardInstrumentInput, customerToken: string) => new Promise<boolean>((verifyComplete, verifyError) => {
            initializeCreditCardVerification(context, {
                creditCard,
                onAuthorize: () => new Promise((onAuthorizeSuccess, onAuthorizeError) => {
                    const client = context.apolloGraphQL.client;
                    client.mutate({
                        mutation: AUTHORIZE_CREDIT_CARD,
                        variables: {
                            authCardInput: {
                                customerToken: customerToken,
                                creditCard: creditCard,
                            },
                        }
                    }).then(({ data }) => {
                        onAuthorizeSuccess();
                        authorizationSuccess(context, {
                            authorization: data.tools.creditCardVerification.authorize,
                            onConfirm: (amount) => new Promise((successConfirm, errorConfirm) => {
                                client.mutate({
                                    mutation: CONFIRM_CREDIT_CARD_AUTH,
                                    variables: {
                                        customerToken,
                                        token: data.tools.creditCardVerification.authorize.token,
                                        amount: Number(amount < 1 ? amount * 100 : amount).toFixed(0),
                                    }
                                }).then(({ data }) => {
                                    updateAttemptsLeft(context, { attemptsLeft: data.tools.creditCardVerification.confirmAuthorization.attemptsLeft });
                                    if (!data.tools.creditCardVerification.confirmAuthorization.attemptsLeft) {
                                        resetVerification(context);
                                        verifyError(new Error('Too many attempts; This card has been locked out, please try another card or try again later.'));
                                    }

                                    if (data.tools.creditCardVerification.confirmAuthorization.success) {

                                        successConfirm();
                                        verifyComplete(true);
                                        resetVerification(context);
                                    } else {
                                        errorConfirm('Amount does not match');
                                    }
                                }).catch((e) => {
                                    resetVerification(context);
                                    verifyError(e);
                                });
                            })
                        });
                    }).catch((e) => {
                        onAuthorizeError();
                        resetVerification(context);
                        verifyError(e);
                    });
                }),
                onCancel: () => {
                    resetVerification(context);
                    verifyComplete(false);
                }
            });
        })
    };
}
