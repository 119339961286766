import gql from 'graphql-tag';

// export const LOGIN_CUSTOMER = gql`
//     mutation loginCustomer($username: String!, $password: String!, $context: CustomerContext, $twoFactorAuthToken: String, $twoFactorAuthPIN: String, $expDate: DateTime) {
//         customers {
//             login(username: $username, password: $password, context: $context, twoFactorAuthToken: $twoFactorAuthToken, twoFactorAuthPIN: $twoFactorAuthPIN, expDate: $expDate)
//         }
//     }
// `;

export const LOGIN_CUSTOMER = gql`
    mutation loginCustomer($username: String!, $password: String!, $rememberDevice: String) {
        customers {
            signIn(username: $username, password: $password, rememberDevice: $rememberDevice) {
                customerToken
                expireAt
                refreshToken
            }
        }
    }`
;
