import React, { Dispatch } from 'react';
import { ApolloGraphQL } from '../../ApolloGraphQL/hook/useGraphqlApolloClient';
import { Action } from './reducer';
import { State } from './state';

export interface ContextType {
    readonly _type?: 'CreditCardVerification',
    dispatch: Dispatch<Action>,
    state: State,
    apolloGraphQL: ApolloGraphQL,
}

export const CreditCardVerificationContext = React.createContext<ContextType>(null);
export default CreditCardVerificationContext;
