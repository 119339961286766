import {useNavigation} from "@react-navigation/native";
import React, {useCallback, useEffect, useState} from "react";
import {View, StyleSheet, TouchableOpacity, Image} from "react-native";
import {BaseButton, ScrollView} from "react-native-gesture-handler";
import {Card, ProgressBar, Text, useTheme} from "react-native-paper";
import {getPlatform, isWebPlatform} from "../../lib/PlatformDevice";
import {CustomInput} from "./CustomInputAdornment";
import {InputHelperError} from "./InputHelperText";

interface Props {
    label?: string;
    array: any[];
    field: string;
    mode: string;
    valueInitial?: string;
    loading: boolean;
    error: boolean;
    errorMessage: string;
    onChangeText: (value: string) => void;
    onChangeValue: (value: string) => void;
    inputLeftProps?: JSX.Element;
    inputRightProps?: JSX.Element;
    value: (data: any) => void;
    setErrorMessage: (error: string) => void;
    clearSelected: boolean;
    clearArray: () => void;
}

/**
 * @function Component Autocomplete
 * @param {string} array Array of records to filter
 * @param {string} label Label Input
 * @param {string} field field name show match
 * @param {string} mode mode of input
 * @param {string} valueInitial Value initial Input
 * @param {boolean} error Status error˚
 * @param {boolean} loading Status loading
 * @param {string} errorMessage Error Message
 * @param {boolean} clearSelected: Clean input
 * @param {Function} clearArray: Clean Array Data
 * @param {Function} onChangeText Callback handle change input text and value
 * @param {Function} onChangeValue Callback handle change input value
 * @param {Function} setErrorMessage Put error message
 * @param {Function} value Callback handle set value
 * @param {JSX.Element} inputLeftProps Content left side of input
 * @param {JSX.Element} inputRightProps Content right side of input
 */
const InputAutocomplete = ({
                               label,
                               array,
                               field,
                               loading,
                               error,
                               errorMessage,
                               onChangeText,
                               onChangeValue,
                               clearArray,
                               inputLeftProps,
                               inputRightProps,
                               clearSelected,
                               setErrorMessage,
                               value,
                               mode,
                           }: Props) => {

    const initial = "";
    const [autoData, setAutoData] = useState({
        text: initial,
        selected: initial,
        error: true,
    });

    const [pressed, setPressed] = useState(false);

    const {colors, fonts} = useTheme();

    const [onBlur, setOnBlur] = useState(true);
    const navigation = useNavigation();

    useEffect(() => {
        setAutoData((prevData) => ({
            ...prevData,
            error: false,
            text: "",
            selected: "",
        }));
    }, [clearSelected]);

    useEffect(() => {
        const unsubscribe = navigation.addListener("blur", () => {
            clearArray();
            setAutoData((prevData) => ({
                ...prevData,
                error: false,
                text: "",
                selected: "",
            }));
        });
        return unsubscribe;
    }, [navigation]);

    useEffect(() => {
        if (!pressed) {
            setErrorMessage("Start typing");
            onChangeText("");
            setAutoData((prevData) => ({
                ...prevData,
                error: false,
                text: "",
                selected: "",
            }));
        }
        setPressed(false);
    }, [onBlur]);

    const renderItem = useCallback(
        (item, index) => (
            <BaseButton
                enabled={item.value !== ""}
                style={[
                    {
                        zIndex: 99999999,
                        elevation: 99999999,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    },
                    item.value !== "" ? {} : {
                        flexDirection: "row",
                        justifyContent: "space-between",
                        cursor: 'not-allowed'
                    }
                ]}
                key={index}
                onPress={() => {
                    setPressed(true);
                    setAutoData({
                        selected: index,
                        text: item.query,
                        error: false,
                    });
                    onChangeValue(item.value);
                }}
            >
                <TouchableOpacity
                    accessible={false}
                    accessibilityLabel="Group"
                    disabled={true}
                    style={[
                        {
                            width: '100%',
                            zIndex: 99999999,
                            flexDirection: "row",
                            alignItems: "center",
                            padding: 10,
                            borderColor: "#D1D2DE",
                            borderBottomWidth: 2,
                        },
                        item.value !== "" ? {backgroundColor: 'lightcyan'} : {backgroundColor: "#ffffff"},
                    ]}
                >
                    <View style={{flex: 1, marginLeft: 10}}>
                        <View style={{flexDirection: 'row'}}>
                            <Text>
                                <strong>{item.query}</strong>{item.mask.substr(item.query.length)}
                            </Text>
                        </View>
                        <View style={{marginTop: 3}}>
                            <Text>
                                {item.mask}
                            </Text>
                        </View>
                    </View>
                    <View style={{flex: 1, alignItems: "center", justifyContent: "center"}}>
                        <Text>
                            {item.label}
                        </Text>
                    </View>
                </TouchableOpacity>
            </BaseButton>
        ),
        []
    );

    const _listOptions = () => {
        return (
            <View
                style={{
                    position: "absolute",
                    zIndex: 99999999,
                    elevation: getPlatform() === "android" ? 99999999 : 0,
                    width: "100%",
                    top: 0,
                    maxHeight: 250,
                }}
            >
                <ScrollView
                    showsVerticalScrollIndicator={!isWebPlatform()}
                    keyboardShouldPersistTaps="handled"
                    style={styles.autocomplete}
                >
                    {array.map((data, key) => renderItem(data, key))}
                </ScrollView>
            </View>
        );
    };
    return (
        <View style={{}}>
            <View nativeID="disable-outline">
                <Text
                    style={{
                        fontFamily: "Montserrat_Regular",
                    }}
                >
                    {label}
                </Text>
                <CustomInput
                    style={{textAlign: "auto", backgroundColor: colors.surface}}
                    mode={mode}
                    onBlur={() => {
                        setTimeout(() => {
                            setOnBlur(true);
                        }, 150);
                    }}
                    onKeyPress={() => {
                    }}
                    onFocus={() => {
                        setOnBlur(false)
                        setAutoData((prevData) => ({
                            ...prevData,
                            error: false,
                            text: "",
                            selected: "",
                        }));
                        onChangeText("");
                        setPressed(false);
                    }}
                    value={autoData.text}
                    error={autoData.error}
                    right={inputRightProps ? inputRightProps : undefined}
                    onChangeText={(text) => {
                        setAutoData((prevState) => ({...prevState, text}));
                        onChangeText(text);
                    }}
                    numberOfLines={1}
                />
                <InputHelperError isError={error} message={errorMessage}/>
            </View>
            <View>
                {loading ? <ProgressBar indeterminate color="#efefef"/> : null}
                {!onBlur ? _listOptions() : null}
            </View>
        </View>
    );
};

export default InputAutocomplete;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    autocomplete: {
        backgroundColor: "#efefef",
        zIndex: 999999999,
        elevation: getPlatform() === "android" ? 99999999 : 0,
        borderBottomStartRadius: 5,
        borderBottomEndRadius: 5,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2,
    },
    autocompleteText: {
        zIndex: 999999999,
        padding: 10,
        fontSize: 17,
    },
    tinyLogo: {
        width: 24,
        height: 24,
        resizeMode: "stretch",
    },
});
