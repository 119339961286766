import { REFRESH_TOKEN_RN } from "./AutoRefreshToken.graphql";
import { setAPIBearerToken } from "./reducer/actions/setAPIBearerToken";
import ApolloGraphQLContext, { ContextType } from "./reducer/context";
import React, { useContext, useEffect } from "react";
import { Action, registerReducer } from "./reducer/reducer";
import { State } from "./reducer/state";
import ClientFingerPrintContext from "../ClientFingerPrint/reducer/context";
import { useNotification } from "../Notification/hook/useNotification";
import {
  authenticationBiometric,
  CustomerAuthData,
  getCustomerAuthData,
  setCustomerAuthData,
} from "../../lib/auth";
import { captureException } from "../../lib/sentry";
import { secureStorageIsAvailable } from "../../lib/secureStorage";

interface Data extends Action {
  logout: boolean;
}

const NAME = "biometricLogout";

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
  return {
    ...state,
    logout: data.logout,
  };
}

// this component can be used inside a ApolloGraphQLProvider
// to automatically refresh the token before expire
// only works for endpoints with the `refreshToken` query, ADMIN, POS etc..
export const AutoRefreshTokenRN = () => {
  const context = useContext(ApolloGraphQLContext);
  const clientFingerPrint = useContext(ClientFingerPrintContext);
  const { error, success } = useNotification();

  useEffect(() => {
    (async () => {
      try {
        const customerAuthData = await getCustomerAuthData() as CustomerAuthData;

        // Verify token expire and request error unauthorized if user is authenticated
        if (
          context &&
          context.state &&
          context.state.client &&
          customerAuthData &&
          (new Date(customerAuthData.expireAt).getTime() <
            new Date().getTime() ||
            (!!context.state.lastRequestCode &&
              context.state.lastRequestCode === 401))
        ) {
          if (
            new Date(customerAuthData.expireAt).getTime() <
              new Date().getTime() &&
            (await secureStorageIsAvailable())
          ) {
            if (customerAuthData && customerAuthData.token) {
              const auth = await authenticationBiometric();

              if (auth.success) {
                const { data } = await context.state.client.query({
                  query: REFRESH_TOKEN_RN,
                  fetchPolicy: "no-cache",
                  variables: {
                    username: customerAuthData.username,
                    password: customerAuthData.token,
                    rememberDevice: clientFingerPrint.state.fingerPrint,
                  },
                });

                const newCustomerDataAuth = {
                  ...customerAuthData,
                  token: data.customers.signIn.customerToken,
                  expireAt: data.customers.signIn.expireAt,
                  refreshToken: data.customers.signIn.refreshToken,
                };

                await setCustomerAuthData(
                  newCustomerDataAuth as CustomerAuthData,
                );

                setAPIBearerToken(context, {
                  bearerToken: data.customers.signIn.customerToken,
                });
              } else {
                context.dispatch({ type: NAME, logout: true });
                error(auth.error);
              }
            }
          } else {
            const { data } = await context.state.client.query({
              query: REFRESH_TOKEN_RN,
              fetchPolicy: "no-cache",
              variables: {
                username: customerAuthData.username,
                password: customerAuthData.token,
                rememberDevice: clientFingerPrint.state.fingerPrint,
              },
            });

            const newCustomerDataAuth = {
              ...customerAuthData,
              token: data.customers.signIn.customerToken,
              expireAt: data.customers.signIn.expireAt,
              refreshToken: data.customers.signIn.refreshToken,
            };
            await setCustomerAuthData(newCustomerDataAuth as CustomerAuthData);
            setAPIBearerToken(context, {
              bearerToken: data.customers.signIn.customerToken,
            });
          }
        }
      } catch (error) {
        captureException(error);
      }
    })();
  }, [context?.state.lastRequestCode, context?.state.client]);
  return <></>;
};
