// import { logger } from 'common/lib/logger';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { Product, State } from './../state';

const NAME = 'loadProductSuccess';

interface Data extends Action {
    products: Product[],
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    // logger.debug('Products loaded', data);
    return {
        ...state,
        product:{
            loading: false,
            products: [
                ...data.products
            ],
            selected: state.product ? state.product.selected : undefined,
        }
    };
}

export const loadProductSuccess = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
