import gql from 'graphql-tag';

export const TWO_FACTOR_AUTH = gql`
    mutation twoFactorAuth($protocol: TwoFactorAuthenticationProtocol!, $target: String, $customerToken: String) {
        customers {
            twoFactorAuth(protocol: $protocol, target: $target, customerToken: $customerToken) {
                token
                protocol
                target
            }
        }
    }
`;

export const REGISTER_CUSTOMER = gql`
   mutation registerCustomer($input: RegisterCustomerInput!) {
        customers {
            register(input: $input)
        }
    }
`;

export const VERIFY_CUSTOMER_TARGET = gql`
    mutation verify($customerToken: String!, $twoFactorAuthPIN: String!, $twoFactorAuthToken: String!) {
        customers {
            verify(twoFactorAuthPIN: $twoFactorAuthPIN, twoFactorAuthToken: $twoFactorAuthToken, customerToken: $customerToken) {
                verifiedPhone
                verifiedEmail
            }
        }
    }
`;