import gql from 'graphql-tag';

export const TRANSACTION_LIST = gql`
    query TransactionList ($search: String, $endCursor: String){
        transactions {
            all(first: 20, after: $endCursor, search: $search, order: {field: date, direction: DESC}) {
                pageInfo{
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    page
                }
                totalCount
                edges{
                    node{
                        id
                        number
                        date
                        status
                        grossTotal
                        merchantCost
                        inputs{
                            accountNumber
                        }
                        product{
                            name
                            ... on PrepaidPlan{
                                carrier{
                                    name
                                }
                            }
                            ... on GiftCard{
                                carrier{
                                    name
                                }
                            }
                        }
                        adjustmentsTotal
                        adjustments{
                            amount
                            name
                            isDiscount
                            rateMode
                            rateOverMode
                            fixedMode
                            total
                        }
                        total
                    }
                }
            }
        }
    }
`;
