import { ApolloClient } from 'apollo-client';
import { getTokenTimeToExpire, isValidJsonWebToken } from '../../../lib/jwt';

export class BearerToken {
    public token: string;

    constructor(token: string) {
        this.token = token;
    }

    public updateToken(token: string) {
        this.token = token;
    }

    public getToken(): string {
        return this.token;
    }

    public isValid(): boolean {
        return isValidJsonWebToken(this.token);
    }

    public TokenTimeToExpire(): number {
        return getTokenTimeToExpire(this.token);
    }
}

export interface State {
    client?: ApolloClient<any>
    bearerToken: BearerToken,
    clientWithToken: boolean,
    persistent: boolean,
    lastRequestCode?: number,
    logout: boolean,
    authMethod: "BIOMETRIC" | "SECRET"
}

export const initialState: State = {
    clientWithToken: false,
    bearerToken: new BearerToken(''),
    persistent: false,
    logout: false,
    authMethod: 'BIOMETRIC'
};
