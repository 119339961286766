import React, { useEffect, useState } from "react";
import { Text, Avatar, Divider, useTheme } from "react-native-paper";
import { StyleSheet, View } from "react-native";
import { StackedArea } from "../../common_modules/components/Charts/Charts";
import { getPrice } from "../../common_modules/lib/Transactions/transactions";
import { ScrollView } from "react-native-gesture-handler";
import { useMediaQuery } from "react-responsive";
import { isWebPlatform, IS_SMARTPHONE } from "../../common_modules/lib/PlatformDevice";
import moment from "moment";
import _ from "lodash";
import LoadingComponent from "../../common_modules/components/Loading/Loading";
import TopProducts from "./TopProducts";
import PeriodComparison from "./PeriodComparison";
import DropDownComponent from "../../common_modules/components/DropDown/DropDown";
import HeaderTitle from "../../navigation/Header/HeaderTitle";
import { useStoreInformation } from "../../common_modules/providers/StoreInfo/hook/useStoreInformation";
import { useSummary } from "../../common_modules/providers/Summary/hook/useSummary";
import { TransactionSummaryType } from "../../common_modules/providers/Summary/lib/transactions";

const RangeDate = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "This week", value: "this week" },
  { label: "A week ago", value: "week" },
  { label: "This month", value: "this month" },
  { label: "One month ago", value: "month" },
  { label: "A year ago", value: "year" },
  { label: "This year", value: "this year" },
];

const DashBoard = () => {
  const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
  const [summaryData, setSummaryData] = useState();
  const { viewer } = useStoreInformation();
  const [rangeInitial, setRangeInitial] = useState(
    moment().subtract(1, "month").startOf("month").toDate()
  );
  const summary = useSummary();
  const { colors } = useTheme();

  useEffect(() => {
    const subscription = summary
      .fetchTransactionSummary({
        type: TransactionSummaryType.COUNT_BY_DATE,
        minDate: rangeInitial,
        maxDate: moment().toDate(),
        where: { sandbox: viewer.sandbox },
      })
      .subscribe((data) => {
        setSummaryData(data);
      });

    return () => subscription.unsubscribe();
  }, [rangeInitial]);

  const value = (dataKey: string) => _.sumBy(summaryData, String(dataKey)) || 0;

  const handleDate = (date) => {
    switch (date) {
      case "today":
        setRangeInitial(moment().startOf("D").toDate());
        break;
      case "yesterday":
        setRangeInitial(moment().subtract(1, "day").startOf("day").toDate());
        break;
      case "week":
        setRangeInitial(moment().subtract(1, "week").startOf("week").toDate());
        break;
      case "this week":
        setRangeInitial(moment().startOf("week").toDate());
        break;
      case "month":
        setRangeInitial(moment().subtract(1, "month").startOf("day").toDate());
        break;
      case "this month":
        setRangeInitial(moment().startOf("month").toDate());
        break;
      case "year":
        setRangeInitial(moment().subtract(1, "year").startOf("month").toDate());
        break;
      case "this year":
        setRangeInitial(moment().startOf("year").toDate());
        break;
      default:
        setRangeInitial(
          moment().subtract(1, "month").startOf("month").toDate()
        );
    }
  };

  return (
    <>
      <HeaderTitle title="Charts" />
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={[
            styles.container,
            {
              paddingHorizontal: !isSmartPhone ? "8%" : 10,
              marginBottom: !isSmartPhone ? "3%" : "10%",
            },
          ]}
        >
          <View style={{ width: !isSmartPhone ? "48%" : "100%" }}>
            <DropDownComponent
              data={RangeDate}
              defaultValue="month"
              onSelectionChange={(value) => handleDate(value)}
            />
            {summaryData ? (
              <View style={[styles.wrapperChart, { marginTop: 10 }]}>
                <Text style={styles.chartTitle1}>
                  {value("count")} Transactions
                </Text>
                <StackedArea
                  data={summaryData}
                  colors={[colors.primary]}
                  keys={["count"]}
                />
              </View>
            ) : (
              <LoadingComponent />
            )}

            <View style={[styles.chartHeader, {backgroundColor: colors.primary}]}>
              <Avatar.Icon icon="local-offer" size={32} />
              <Text
                style={{ color: "#ffffff", fontFamily: "Montserrat_Semi_Bold" }}
              >
                Top Products
              </Text>
            </View>

            <TopProducts
              summaryOptions={{
                minDate: rangeInitial,
                maxDate: moment().toDate(),
                where: { sandbox: viewer.sandbox },
              }}
              type={TransactionSummaryType.TOP_PRODUCTS}
            />

            {isSmartPhone && <Divider style={{ marginVertical: 20 }} />}
          </View>
          <View style={{ width: !isSmartPhone ? "48%" : "100%" }}>
            {summaryData ? (
              <View
                style={[
                  styles.wrapperChart,
                  { marginTop: !isSmartPhone ? 75 : undefined },
                ]}
              >
                <Text style={styles.chartTitle1}>
                  {getPrice(value("sales"))} Sales
                </Text>
                <StackedArea
                  data={summaryData}
                  colors={[colors.onSurface]}
                  keys={["sales"]}
                />
              </View>
            ) : (
              <LoadingComponent />
            )}

            <View style={[styles.chartHeader, {backgroundColor: colors.primary}]}>
              <Avatar.Icon icon="show-chart" size={32} />
              <Text
                style={{ color: "#ffffff", fontFamily: "Montserrat_Semi_Bold" }}
              >
                Period Comparison
              </Text>
            </View>

            <PeriodComparison
              summaryOptions={{
                minDate: rangeInitial,
                maxDate: moment().toDate(),
                where: { sandbox: viewer.sandbox },
              }}
            />
          </View>
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  wrapperChart: {
    backgroundColor: "#ffffff",
    borderRadius: 5,
    width: "100%",
  },
  chartTitle1: {
    fontFamily: "Montserrat_Bold",
    fontSize: 24,
    padding: 5,
  },
  chartTitle2: {
    fontFamily: "Montserrat_Bold",
    fontSize: isWebPlatform() ? 18 : 14,
    padding: 5,
  },
  chartText: {
    fontFamily: "Montserrat_Light",
    fontSize: isWebPlatform() ? 16 : 12,
    padding: 5,
  },
  chartHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    height: 40,
    borderRadius: 5,
    marginVertical: 10,
  },
  borderDash: {
    borderStyle: "dashed",
    borderColor: "#D1D2DE",
    backgroundColor: "#FFFFFF",
    borderBottomWidth: 2,
  },
});

export default DashBoard;
