import gql from 'graphql-tag';

export const TRANSACTION_SUMMARY = gql`
    query TRANSACTION_SUMMARY($type: TransactionSummaryType!, $minDate: DateTime!, $maxDate: DateTime!, $where: TransactionCondition, $commissions: Boolean!, $view: SummaryViewMode){
        transactions {
            summary(type: $type, minDate: $minDate, maxDate: $maxDate, commissions: $commissions, where: $where, view: $view) {
                name
                extraData
                values {
                    name
                    value
                }
            }
        }
    }
`;
