import { SubmitTransactionPlugin } from './Plugin';
import { TransactionPaymentMethod } from '../../../../../model/TransactionPaymentMethod';
import _ from 'lodash';

export const TwoFactorAuthentication: SubmitTransactionPlugin = {
    onError: (event) => new Promise((finishDone, finishError) => {
        if (_.includes([1104, 1105, 1106, 1107], event.data.error.code)
            && event.context.twoFactorAuth
        ) {
            // Mesh require two factor authentication on withdrawals
            if (event.data.transaction.paymentMethod === TransactionPaymentMethod.VIRTUAL_CHECKING_ACCOUNT
                && event.context.customerAuth
                && event.context.customerAuth.token) {
                event.context.twoFactorAuth.sendPin({
                    customerToken: event.context.customerAuth.token,
                    protocol: 'SMS',
                }).then(() => {
                    event.context.twoFactorAuth.onCancel(() => {
                        finishDone(event.data);
                    });
                    event.context.twoFactorAuth.onConfirmPIN((token, pin) => {
                        event.data.retry({
                            ...event.data.transaction,
                            twoFactorAuthPIN: pin,
                            twoFactorAuthToken: token,
                        });
                    });
                }).catch((e) => {
                    // logger.error(convertAPIError(e).message);
                    finishDone(event.data);
                });
            } else {
                finishDone(event.data);
            }
        } else {
            finishDone(event.data);
        }
    })
};
