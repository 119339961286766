import React, { useCallback, useEffect, useState } from 'react';
import { View, Image, StyleSheet, Linking } from 'react-native';
import { Button, Divider, Text, Title, useTheme } from 'react-native-paper';
import { Carrier, Clerk, StoreInfo, UISettings } from '../Market/reducer/state';
import CardCarrierQR from '../../common_modules/components/Card/CardCarrierQR';
import LoadingComponent from '../../common_modules/components/Loading/Loading';
import { useMediaQuery } from "react-responsive";
import _ from 'lodash';
import { usePOSLogin } from '../Login/hook/usePOSLogin';
import axios from 'axios';
import { useAlert } from '../../common_modules/components/SnackBar/context';
import { setContext } from '@apollo/client/link/context';
import { useNotification } from '../../common_modules/providers/Notification/hook/useNotification';
import { getDisplayWidth } from '../../common_modules/lib/PlatformDevice';
interface Props {
    storeInfo: StoreInfo,
    carriers: Carrier[],
    loadingCarriers: boolean,
}

const HomeIntroWeb = ({ carriers, clerk, storeInfo, loadingCarriers }: Props) => {
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [widthCard, setWidthCard] = useState(200);
    const [domesticCarriers, setDomesticCarriers] = useState<Carrier[]>([]);
    const [notDomesticCarriers, setNotDomesticCarriers] = useState<Carrier[]>([]);
    const [carriersPrint, setCarriersPrint] = useState<string>("");
    const footer = useMediaQuery({ minDeviceWidth: 1800 });
    const { colors } = useTheme();
    const { isAuthenticated } = usePOSLogin();
    const { error } = useNotification();

    const onLayout = (e) => {
        if (widthCard !== e.nativeEvent.layout.width) {
            setWidthCard(e.nativeEvent.layout.width);
        }
    }

    const userCountry = storeInfo.address.country.toLowerCase();

    const number = parseInt(storeInfo.number);

    const handlePrint = () => {
        setLoadingPrint(true);
        axios(`https://paynup.link/product/qr-code-page?c=${carriersPrint}&t=${isAuthenticated}`, {
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
            .then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
                setLoadingPrint(false);
            })
            .catch(e => {
                console.error(e)
                error(`Sorry, an error occurred while generating the pdf file`);
                setLoadingPrint(false);

            });
    }

    useEffect(() => {
        if (carriers) {
            let cPrint: string[] = [];
            let domestic: Carrier[] = [];
            let notDomestic: Carrier[] = [];
            carriers.map((carrier) => {
                cPrint.push(carrier.sku);
                if (carrier.country?.toLowerCase() === userCountry)
                    domestic.push(carrier)
                else
                    notDomestic.push(carrier)
            });
            setDomesticCarriers(domestic)
            setNotDomesticCarriers(notDomestic)
            setCarriersPrint(cPrint.toString());
        }
    }, [carriers])

    const cardSize = getDisplayWidth() >= 1450 && getDisplayWidth() <= 1750 || getDisplayWidth() <= 900 || getDisplayWidth() >= 1200 && getDisplayWidth() <= 1280 ? 162 : 192;
    const itemsPerDimension = Math.round((getDisplayWidth() * 0.4) / cardSize);
    const itemsPerRows = itemsPerDimension;
    const cardCenter = getDisplayWidth() <= 1199;
    const getRows = (data: number) => Math.floor(data / itemsPerRows) + (data % itemsPerRows === 0 ? 0 : 1);


    const QRContainer = useCallback((carrier, index) => {
        return (
            <View key={index} style={[styles.carrier, { marginHorizontal: cardCenter ? 5 : 0 }]}>
                <CardCarrierQR
                    key={carrier.id}
                    carrier={carrier}
                    number={number}
                />
            </View>)
    }, []);

    return (

        <View >
            <View style={{ alignItems: 'center' }}>
                <Text
                    style={styles.primaryText}>DON'T LOSE SALES!
                </Text>
                <Text
                    style={styles.secondaryText}>Your customer can topup online with you
                </Text>
            </View>

            {!loadingCarriers && carriers ?
                <View>
                    <View >
                        {domesticCarriers ?
                            Array.from(Array(getRows(domesticCarriers.length)), (e, i) => {
                                const qty = domesticCarriers.length;
                                const rows = Math.floor(qty / itemsPerRows) + (qty % itemsPerRows === 0 ? 0 : 1);
                                const control = itemsPerRows * i;
                                const fake = i === rows - 1 ? qty % itemsPerRows : 0;
                                return (
                                    <View key={`rowD` + i} style={[styles.carriersWrapper, {
                                        marginTop: 10,
                                        justifyContent: cardCenter ? 'center' : 'space-between'
                                    }]} >
                                        {domesticCarriers.map((carrier, index) => (
                                            index >= control && index <= control + (itemsPerRows - 1) ?
                                                QRContainer(carrier, index) : null
                                        ))}
                                        {fake > 0 && Array.from(Array(fake), (e, i) =>
                                            <View key={`fakeD` + i} style={{ width: cardSize }}></View>)
                                        }
                                    </View>)
                            }) : null}
                    </View>

                    {domesticCarriers.length && notDomesticCarriers.length ?
                        <Divider style={{
                            borderStyle: 'dashed',
                            borderWidth: 0.5,
                            borderColor: '#757575',
                            backgroundColor: '#D1D2DE',
                        }} /> : null
                    }

                    <View >
                        {notDomesticCarriers ?
                            Array.from(Array(getRows(notDomesticCarriers.length)), (e, i) => {
                                const qty = notDomesticCarriers.length;
                                const rows = Math.floor(qty / itemsPerRows) + (qty % itemsPerRows === 0 ? 0 : 1);
                                const control = itemsPerRows * i;
                                const fake = i === rows - 1 ? qty % itemsPerRows : 0;
                                return (
                                    <View key={`rowND` + i} style={[styles.carriersWrapper, {
                                        marginTop: 10,
                                        justifyContent: cardCenter ? 'center' : 'space-between'
                                    }]} >
                                        {notDomesticCarriers.map((carrier, index) => (
                                            index >= control && index <= control + (itemsPerRows - 1) ?
                                                QRContainer(carrier, index) : null
                                        ))}
                                        {fake > 0 && Array.from(Array(fake), (e, i) =>
                                            <View key={`fakeND` + i} style={{ width: cardSize }}></View>)
                                        }
                                    </View>)
                            }) : null}
                    </View>
                    {loadingCarriers ?
                        <LoadingComponent /> :
                        carriers.length == 0 ?
                            <Title
                                style={{
                                    marginVertical: 250,
                                    textAlign: 'center'
                                }}>
                                No favorite carrier found!</Title> : null}
                </View> : null}

            {/*{footer &&*/}
            {/*    <View style={[styles.favoriteFooter, { backgroundColor: colors.primary }]}>*/}
            {/*        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'space-between' }}>*/}
            {/*            <Image*/}
            {/*                style={{*/}
            {/*                    resizeMode: 'stretch',*/}
            {/*                    width: 90,*/}
            {/*                    height: 90,*/}
            {/*                }}*/}
            {/*                source={require('../../../assets/img/poster_final_01.png')}*/}
            {/*            />*/}
            {/*            <View style={{ marginLeft: 10, width: '60%' }}>*/}
            {/*                <Text style={{ color: colors.surface, fontSize: 20, fontFamily: 'Montserrat_Bold' }}>1 / SCAN</Text>*/}
            {/*                <Text style={{ marginTop: 10 }}>Scan the QR Code or Gift Card you want to purchase.</Text>*/}
            {/*            </View>*/}
            {/*        </View>*/}
            {/*        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>*/}
            {/*            <Image*/}
            {/*                style={{*/}
            {/*                    resizeMode: 'stretch',*/}
            {/*                    width: 90,*/}
            {/*                    height: 90,*/}
            {/*                }}*/}
            {/*                source={require('../../../assets/img/poster_final_02.png')}*/}
            {/*            />*/}
            {/*            <View style={{ marginLeft: 10, width: '60%' }}>*/}
            {/*                <Text style={{ color: colors.surface, fontSize: 20, fontFamily: 'Montserrat_Bold' }}>2 / SELECT</Text>*/}
            {/*                <Text style={{ marginTop: 10 }}>Select the denomination/amount of your product.</Text>*/}
            {/*            </View>*/}
            {/*        </View>*/}
            {/*        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>*/}
            {/*            <Image*/}
            {/*                style={{*/}
            {/*                    resizeMode: 'stretch',*/}
            {/*                    width: 90,*/}
            {/*                    height: 90,*/}
            {/*                }}*/}
            {/*                source={require('../../../assets/img/poster_final_03.png')}*/}
            {/*            />*/}
            {/*            <View style={{ marginLeft: 10, width: '60%' }}>*/}
            {/*                <Text style={{ color: colors.surface, fontSize: 20, fontFamily: 'Montserrat_Bold' }}>3 / PAY</Text>*/}
            {/*                <Text style={{ marginTop: 10 }}>To help our valued dealer network retail sales from customers.</Text>*/}
            {/*            </View>*/}
            {/*        </View>*/}
            {/*    </View>}*/}

            {/*<View style={{ justifyContent: 'center', paddingTop: 20, alignItems: 'center' }}>*/}
            {/*    <View style={{ flexDirection: 'row', justifyContent: 'space-around', paddingTop: 20, width: '100%', flexWrap: 'wrap' }}>*/}
            {/*        <View style={{ flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '80%' }}>*/}
            {/*            <Button style={{ width: '60%' }}*/}
            {/*                mode="contained"*/}
            {/*                onPress={handlePrint}*/}
            {/*                loading={loadingPrint}*/}
            {/*                disabled={loadingPrint}>*/}
            {/*                PRINT ME*/}
            {/*            </Button>*/}
            {/*            <Text style={{*/}
            {/*                color: colors.primary,*/}
            {/*                fontFamily: 'Montserrat_Semi_Bold',*/}
            {/*                marginTop: 5*/}
            {/*            }}>I's that easy!</Text>*/}
            {/*        </View>*/}
            {/*    </View>*/}
            {/*</View>*/}
        </View >
    );
}

const styles = StyleSheet.create({
    primaryText: {
        fontFamily: 'Montserrat_Extra_Bold',
        fontSize: 55,
        fontWeight: 'bold',
    },
    secondaryText: {
        fontFamily: 'Montserrat_Semi_Bold',
        fontSize: 25,
        paddingTop: 10,
    },
    textInfo: {
        fontFamily: 'Montserrat_Light'
    },
    carriersWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    carrier: {
        marginVertical: 12,
        alignItems: 'center',
    },
    favoriteFooter: {
        flex: 1,
        height: 140,
        padding: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: 10
    }
})

export default HomeIntroWeb;
