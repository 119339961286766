import _ from 'lodash';
import { FETCH_CATEGORIES } from '../../reducer/actions/loadCategories.graphql';
import { loadCategoriesSuccess } from '../../reducer/actions/loadCategoriesSuccess';
import { ContextType } from './../context';
import { registerReducer } from './../reducer';
import { Category, CategoryType, State } from './../state';

const NAME = 'loadCategories';

registerReducer(NAME, reducer);

function reducer(state: State): State {

    return {
        ...state,
        category: {
            loading: true,
            categories: state.category ? {
                ...state.category.categories,
            } : undefined,
            selected: state.category ? state.category.selected : undefined,
        }
    };
}

export const loadCategories = (context: ContextType) => {
    context.dispatch({ type: NAME });

    context.apolloGraphQL.client.watchQuery({
        query: FETCH_CATEGORIES,
        fetchPolicy: 'no-cache'
    })
        .subscribe(({ data }) => {
            if (data) {
                const categories: Category[] = [];
                _.forEach(data.productCategories.all.edges, (edge) => {

                    // TODO: get this info from API
                    let name = edge.node.separateForeignCarriers && edge.node.localCategoryName ? edge.node.localCategoryName : edge.node.name;
                    let order = edge.node.sort ? edge.node.sort : 1;
                    let description = edge.node.separateForeignCarriers && edge.node.localCategoryDescription ? edge.node.localCategoryDescription : edge.node.description;
                    let color = edge.node.bgColor;
                    let icon = edge.node.icon;
                    let type = CategoryType.generic;

                    if (edge.node.separateForeignCarriers) {
                        type = CategoryType.local
                    }

                    categories.push({
                        id: edge.node.id,
                        name,
                        order,
                        color,
                        description,
                        icon,
                        type
                    });


                    if (edge.node.separateForeignCarriers) {
                        //TODO: this must be modified in the backend
                        categories.push({
                            id: edge.node.id,
                            name: edge.node.foreignCategoryName,
                            order: order,
                            color: edge.node.foreignCategoryBgColor,
                            description: edge.node.foreignCategoryDescription,
                            icon: edge.node.foreignCategoryIcon,
                            type: CategoryType.foreign
                        });
                    }
                });

                loadCategoriesSuccess(context, { categories: _.orderBy(categories, ['order'], ['asc']) });
            }
        });
};
