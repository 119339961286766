import React, { useEffect, useState } from "react";
import { View, StyleSheet, FlatList, Platform } from "react-native";
import {
  Text,
  TouchableRipple,
  RadioButton,
  useTheme,
  ProgressBar,
  IconButton,
  Avatar,
  Searchbar,
} from "react-native-paper";
import {
  getContactsAsync,
  getContactByIdAsync,
  Contact,
  Fields,
  SortTypes,
} from "expo-contacts";
import Constants from "expo-constants";
// @ts-ignore
import _, { groupBy } from "lodash";
import parsePhoneNumberFromString, { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { useNavigation } from "@react-navigation/native";
import Empty from "../../components/Empty/Empty";
import { useMarket } from "../../../screens/Market/hook/useMarket";
import { StoreInfo } from "../../../screens/Market/reducer/state";
import { useNotification } from "../../providers/Notification/hook/useNotification";
import HeaderTitle from "../../../navigation/Header/HeaderTitle";

// Is this redux?
type ContactsState = {
  loading: boolean;
  error: Error | null;
  data: Array<Contact>;
};

type ContactRowProps = {
  id: string;
  name: string;
  emailOrNumber: string;
  image?: string;
  onPress: () => void;
  selected: boolean;
  personalContact: StoreInfo;
};

interface Contacts {
  id: string;
  name: string;
  phone?: string;
  email?: string;
  image?: string;
}

let timeInterval: ReturnType<typeof setTimeout>;

const ContactRow = React.memo(
  ({ onPress, name, emailOrNumber, selected, image, id }: ContactRowProps) => {
    const { colors } = useTheme();
    const isMyContact = id === "myContact";
    return (
      <TouchableRipple onPress={onPress}>
        <View
          style={{
            padding: 16,
            flexDirection: "row",
            alignItems: "center",
            borderBottomWidth: isMyContact ? 1 : 0.5,
            borderBottomColor: isMyContact ? colors.primary : undefined,
          }}
        >
          <RadioButton
            color={colors.primary}
            uncheckedColor={colors.primary}
            status={selected ? "checked" : "unchecked"}
            onPress={onPress}
          />
          {isMyContact && image ? (
            <Avatar.Image
              size={32}
              source={{
                uri: image,
              }}
            />
          ) : isMyContact && !image && name[0] ? (
            <Avatar.Text size={32} label={name[0]} />
          ) : null}
          {!isMyContact && image ? (
            <Avatar.Image
              size={32}
              source={{
                uri: image,
              }}
            />
          ) : !isMyContact && name[0] ? (
            <Avatar.Text size={32} label={name[0]} />
          ) : null}
          {!isMyContact && !name[0] ? (
            <Avatar.Icon icon="account-circle" />
          ) : null}
          <View style={{ flex: 1, marginLeft: 8 }}>
            <Text>{name || emailOrNumber}</Text>
            <Text style={{ marginTop: 4, color: "#666" }}>{emailOrNumber}</Text>
          </View>
        </View>
      </TouchableRipple>
    );
  }
);

type RowItem = {
  id: string;
  name: string;
  phoneNumber?: string;
  email?: string;
};

const ContactListScreen = ({ route }) => {
  const [loading, setLoading] = useState(true);
  const [nextPage, setHasNextPage] = useState(false);
  const [refreshed, setRefreshed] = useState("");
  const { storeInfo } = useMarket();
  const [listContact, setListContact] = useState<Contacts[]>();
  const [contactsSearch, setContactSearch] = useState<Contacts[]>();
  const [criteria, setCriteria] = useState("");
  const [contactSelected, setContactSelected] = useState<Contacts>();
  const [offset, setOffset] = useState(0);
  const navigation = useNavigation();
  const { error } = useNotification();
  const { colors } = useTheme();

  const myProfile: Contacts = {
    id: "myContact",
    name: storeInfo.name,
    email: route.params.contactFields === "emails" ? storeInfo.email : "",
    phone: route.params.contactFields === "phoneNumbers" ? storeInfo.phone : "",
    image: storeInfo.photoUrl ? storeInfo.photoUrl : undefined,
  };

  const getImage = async (id: string) => {
    return await getContactByIdAsync(id, ["image"]);
  };

  useEffect(() => {
    (async () => {
      try {
        if (
          route &&
          route.params &&
          route.params.contactFields &&
          route.params.countryCode
        ) {
          setLoading(true);
          // Query params contact
          const contactFields = route.params.contactFields;
          const countryCode = route.params.countryCode;

          // Fetch contacts
          const { data, hasNextPage, hasPreviousPage } = await getContactsAsync(
            {
              fields: [contactFields],
              pageSize: 100,
              pageOffset: !criteria ? offset : undefined,
              sort: SortTypes.FirstName,
              name: criteria ? criteria : undefined,
            }
          );
          setHasNextPage(hasNextPage);

          if (data.length) {
            const _listContact: Contacts[] = [];
            for (let contact of data) {
              let imageUri = undefined;
              if (contact && contact.id && contact.imageAvailable) {
                const { image } = await getImage(contact.id);
                imageUri = image ? image.uri : undefined;
              }
              const _contact: Contacts = {
                id: contact.id,
                name: contact.name,
                phone: "",
                email: "",
                image: imageUri,
              };

              // Validate contact email
              if (
                contactFields === Fields.Emails &&
                contact &&
                contact.emails
              ) {
                var mailFormat =
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                contact.emails.forEach((email) => {
                  if (email && email.email && email.email.match(mailFormat)) {
                    _contact.email = email.email;
                  }
                  if (_contact.email) {
                    _listContact.push(_contact);
                  }
                });

                //  Validate contact phone number
              } else if (
                contactFields === Fields.PhoneNumbers &&
                contact &&
                contact.phoneNumbers
              ) {
                contact.phoneNumbers.forEach((ph, index) => {
                  if (ph && ph.number) {
                    const phone = parsePhoneNumberFromString(ph.number, countryCode);
                    if (phone) {
                      const match = _listContact.find(
                        (c) => c.phone === phone?.nationalNumber
                      );
                      if (
                        !match &&
                        phone.isValid() &&
                        phone.isPossible() &&
                        isValidPhoneNumber(phone.nationalNumber.toString(), countryCode)
                      ) {
                        _contact.phone = phone?.nationalNumber.toString();
                        _listContact.push(_contact);
                      }
                    }
                  }
                });
              }
            }
            if (criteria) {
              setListContact(_listContact);
              setContactSearch(_listContact);
            } else {
              setListContact((prevState) => {
                if (contactsSearch) {
                  setContactSearch([]);
                  return _listContact;
                } else {
                  return prevState
                    ? prevState.concat(_listContact)
                    : _listContact;
                }
              });
            }
            setLoading(false);
          }
        }
      } catch (e) {
        error(e.message);
      }
    })();
  }, [offset, refreshed]);

  const loadMore = () => {
    if (nextPage && !criteria) {
      setOffset((prevState) => prevState + 100);
    }
  };

  const onPress = (contact: Contacts) => {
    setContactSelected(contact);
    const contactList = {
      accountNumber: contact.phone || contact.email,
    };
    navigation.navigate("Product Form", { contactList });
  };

  const searchContact = (c: string) => {
    clearTimeout(timeInterval);
    setCriteria(c);
    setLoading(true);
    timeInterval = setTimeout(() => {
      setRefreshed(c);
    }, 2000);
  };

  return (
    <View nativeID="disable-outline">
      <HeaderTitle title="contacts" />
      <Searchbar
        placeholder="Search"
        onChangeText={searchContact}
        value={criteria}
        style={{ borderRadius: 0 }}
      />
      {loading && <ProgressBar indeterminate color={colors.primary} />}
      <ContactRow
        id={myProfile.id}
        name={myProfile.name}
        emailOrNumber={(myProfile.email || myProfile.phone)!}
        image={myProfile.image}
        selected={
          contactSelected?.email
            ? contactSelected?.email === myProfile.email
            : contactSelected?.phone === myProfile.phone
        }
        onPress={() => onPress(myProfile)}
      />
      <FlatList
        contentContainerStyle={{ justifyContent: "center" }}
        data={listContact}
        keyExtractor={(item, index) => index.toString()}
        ListEmptyComponent={<Empty target="contacts" icon="account-circle" />}
        renderItem={({ item, index }) => (
          <ContactRow
            id={item.id}
            name={item.name}
            emailOrNumber={(item.email || item.phone)!}
            personalContact={storeInfo}
            image={item.image}
            selected={
              contactSelected?.email
                ? contactSelected?.email === item.email
                : contactSelected?.phone === item.phone
            }
            onPress={() => onPress(item)}
          />
        )}
        refreshing={loading}
        onEndReached={loadMore}
        onEndReachedThreshold={0.01}
      />
    </View>
  );
};

export default ContactListScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    paddingTop: Constants.statusBarHeight,
    backgroundColor: "#ecf0f1",
    padding: 8,
  },
  paragraph: {
    margin: 24,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
});
