/**
 * Set of functions to work with API summaries results to build charts or anything else
 */

import * as _ from 'lodash';

/**
 * Convert a summary from the API to a flatten object version
 * and easy to work with charts.
 *
 * For example, convert this:
 * {
 *    name: "2019-05-30",
 *    values: [
 *           {
 *             name: "amount",
 *             value: 391.95
 *           },
 *           {
 *             name: "amount_abstained",
 *             value: 209.04
 *           }
 *        ]
 *  }
 *
 * To this:
 *
 * {
 *    name: "2019-05-30",
 *    amount: 391.95,
 *    amount_abstained: 209.04,
 * }
 *
 */
export const flattenSummary = (summary) =>
    _.map(summary, ({ name, extraData, values }) => {
        const row = { name, _extraData: extraData  };
        _.forEach(values, (value) => row[value.name] = value.value);

        return row;
    });
