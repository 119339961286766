import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { roundNumber } from './number.util';
import { md5 } from './string.util';
import load from 'load-script';

interface CardinalOptions {
    // token: string, // OUR api token to fetch a cardinal JWT Token using cardinal JWT mutation
    sandbox?: boolean,
    orderAmount: number, // order amount using float
    orderNumber: string,
    cardNumber: string,
    cardExpMonth: number,
    cardExpYear: number,
    billingAddress?: {
        address1?: string,
        city?: string,
        state?: string,
        zipCode?: string
    },
}

interface CardinalResponse {
    data: {
        ActionCode: 'SUCCESS' | 'NOACTION' | 'FAILURE' | 'ERROR',
        ErrorDescription: string,
        ErrorNumber: number,
        Payment: any,
        Validated: boolean
    },
    jwt: string,
}

const JWT_TOKEN_MUTATION = gql`
    mutation cardinalJWT($orderNumber: String!, $amount: Int!) {
        consumerAuthentication {
            cardinalJWT(orderNumber: $orderNumber, amount: $amount)
        }
    }
`;

/**
 * Secure given order with cardinal 3D secure payments
 *
 * Return a promise with cardinal data and JWT or error if fail
 *
 * @param options
 */
export const secureWithCardinal = (client: ApolloClient<any>, options: CardinalOptions): Promise<CardinalResponse> =>
    new Promise((success, error) => {

        /**
         * Load cardinal script and return cardinal object
         */
        const loadCardinalScript = (): Promise<any> =>
            new Promise((loadScriptSuccess) => {
                let url = 'https://songbird.cardinalcommerce.com/cardinalcruise/v1/songbird.js';
                if (options.sandbox === true) {
                    url = 'https://songbirdstag.cardinalcommerce.com/cardinalcruise/v1/songbird.js';
                }
                load(url, (err, script) => {
                    if (err) {
                        error(err);
                    } else {
                        // @ts-ignore
                        loadScriptSuccess(Cardinal);
                    }
                });
            });

        client.mutate({
            mutation: JWT_TOKEN_MUTATION,
            variables: {
                orderNumber: options.orderNumber,
                amount: roundNumber(Number(options.orderAmount) * 100, 0),
            },
        }).then((response: any) => {
            loadCardinalScript()
                .then((Cardinal) => {
                    Cardinal.configure({
                        logging: {
                            level: 'on',
                            timeout: 300000,
                        },
                    });
                    const cardinalJWT = response.data.consumerAuthentication.cardinalJWT;
                    Cardinal.setup('init', {
                        jwt: cardinalJWT,
                    });
                    Cardinal.trigger('jwt.update', cardinalJWT);
                    Cardinal.on('payments.setupComplete', (data) => {
                        const ccaPayload = {
                            OrderDetails: {
                                OrderNumber: options.orderNumber,
                            },
                            Consumer: {
                                Account: {
                                    AccountNumber: options.cardNumber,
                                    ExpirationMonth: options.cardExpMonth,
                                    ExpirationYear: options.cardExpYear,
                                },
                                BillingAddress: {
                                    Address1: options.billingAddress && options.billingAddress.address1,
                                    City: options.billingAddress && options.billingAddress.city,
                                    State: options.billingAddress && options.billingAddress.state,
                                    PostalCode:  options.billingAddress && options.billingAddress.zipCode
                                },
                            },
                            CCAExtension : {
                                ShippingDetails: {
                                    ShippingMethodIndicator:'05',
                                },
                            }
                        };
                        Cardinal.start('cca', ccaPayload);
                        Cardinal.trigger('order.update', ccaPayload);
                    });
                    Cardinal.on('payments.validated', (data, jwt) => {
                        // logger.debug('Consumer Authentication complete', { data, jwt });

                        switch (data.ActionCode) {
                            case 'SUCCESS':
                            case 'NOACTION':
                                success({ data, jwt });
                                break;

                            case 'FAILURE':
                                error(new Error('Error processing your payment.' +
                                    ' 3D Secure Failed Signature Verification'));
                                break;

                            case 'ERROR':
                                error(new Error(data.ErrorDescription));
                                break;

                            default:

                        }
                    });
                });
        }).catch(error);
    });
