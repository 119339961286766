import { setApplePaySession } from '../../ApplePay/reducer/actions/setApplePaySession';
import { useContext } from 'react';
import Context from '../reducer/context';

export interface ApplePay {
    session: any,
    setSession: (session: any) => void,
}

export function useApplePay(): ApplePay {
    const context = useContext(Context);

    return {
        session: context ? context.state.session : undefined,
        setSession: context ? (session) => setApplePaySession(context, { session }) : undefined
    };
}
