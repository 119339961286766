import { ContextType } from 'react';
import Context from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'authenticatingForBiometricAuth';

interface Data extends Action {
    loading: boolean,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        authenticating: data.loading,
    };
}

export const waitingAuthBiometric = (context: ContextType<typeof Context>, loading: boolean) => {
    return context.dispatch({ type: NAME, loading });
};
