import { enqueueNotification } from '../reducer/actions/enqueueNotification';
import NotificationContext from '../reducer/context';
import { useContext } from 'react';

export interface Notification {
    error: (message: string | JSX.Element) => any
    info: (message: string | JSX.Element) => any
    warning: (message: string | JSX.Element) => any
    success: (message: string | JSX.Element) => any
}

export function useNotification(): Notification {
    const context = useContext(NotificationContext);

    return {
        error: (message) => enqueueNotification(context, { message, variant: 'error' }),
        info: (message) => enqueueNotification(context, { message, variant: 'info' }),
        warning: (message) => enqueueNotification(context, { message, variant: 'warning' }),
        success: (message) => enqueueNotification(context, { message, variant: 'success' }),
    };
}