// import { logger } from 'common/lib/logger';
import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { Category, State } from './../state';

const NAME = 'loadCategoriesSuccess';

interface Data extends Action {
    categories: Category[],
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {
    // logger.debug('Categories loaded', data);

    return {
        ...state,
        category: {
            loading: false,
            categories: [
                ...data.categories
            ],
            selected: state.category ? state.category.selected : undefined,
        }
    };
}

export const loadCategoriesSuccess = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
