import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { IntrospectionFragmentData } from '../../fragment-types';
import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';
import {API_URL} from "@env";
const NAME = 'initializeClient';

interface Data extends Action {
    client: ApolloClient<any>
    clientWithToken: boolean,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        client: data.client,
        clientWithToken: data.clientWithToken,
    };
}

export const initializeClient = (context: ContextType) => {

    // https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher
    const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData: IntrospectionFragmentData,
    });

    const cache = new InMemoryCache({fragmentMatcher});

    persistCache({
        cache,
        storage: window.localStorage,
    });

    const authLink = setContext((_, { headers }) => {
        const xDebugSession = () => {
            const value = '; ' + document.cookie;
            const parts = value.split('; XDEBUG_SESSION=');
            if (parts.length == 2) return parts.pop().split(';').shift();
        };

        return {
            uri: xDebugSession() ? `${process.env.REACT_APP_API_URL}?XDEBUG_SESSION_START=${xDebugSession()}` : process.env.REACT_APP_API_URL,
            headers: {
                ...headers,
                Authorization: context.state.bearerToken.getToken() ? `Bearer ${context.state.bearerToken.getToken()}` : null,
            },
        };
    });

    const httpLink = new HttpLink({
        uri: API_URL,
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache,
    });

    const args = { client, clientWithToken: !!context.state.bearerToken.getToken() };

    return context.dispatch({ type: NAME, ...args });
};
