import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { DataTable, Avatar, Text, useTheme, IconButton, Badge } from 'react-native-paper';
import { useSummary } from '../../common_modules/providers/Summary/hook/useSummary';
import { useMediaQuery } from "react-responsive";
import { getDisplayHeight, isWebPlatform, IS_SMARTPHONE } from '../../common_modules/lib/PlatformDevice';
import { StyleSheet, View } from 'react-native'
import { getPrice } from '../../common_modules/lib/Transactions/transactions';
import { PeriodComparisonChart } from '../../common_modules/components/Charts/PeriodComparisonChart';
import moment from 'moment';
import _ from 'lodash';
import { ScrollView } from 'react-native-gesture-handler';
import { PeriodComparisonValue } from '../../common_modules/components/PeriodComparisonValue/PeriodComparisonValue';

export interface PeriodsToCompare {
    label: string,
    minDate: Date,
    maxDate: Date,
}

const PeriodComparisonWidget = (props) => {
    const { colors } = useTheme();
    const isSmartPhone = useMediaQuery(IS_SMARTPHONE);
    const [loading, setLoading] = useState(false);

    const renderRow = useCallback((data: any, key: number) =>
        <View key={key}>
            <DataTable.Row key={`row-data ${key}`}>
                <DataTable.Cell style={{ flex: 3 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <View style={{ backgroundColor: data.color, marginRight: 5, width: 10, height: 10, borderRadius: 5 }} ></View>
                        <Text>
                            {data.name}
                        </Text>
                    </View>
                </DataTable.Cell>
                <DataTable.Cell style={{ flex: 1, paddingLeft: 20 }}>
                    <PeriodComparisonValue current={data.currentPeriodCount} last={data.count} />
                </DataTable.Cell>
                <DataTable.Cell style={{ flex: 1 }}>
                    <PeriodComparisonValue money current={data.currentPeriodSales} last={data.sales} />
                </DataTable.Cell>
            </DataTable.Row>
            {props.dataPeriods.length - 1 === key ?
                <DataTable.Row style={[styles.borderDash]} key={`chart-row ${key}`}>
                    <View style={styles.wrapperChart}>
                        <Text style={styles.chartTitle1}>Transactions over time</Text>
                        {props.currentPeriodSummary &&
                            <PeriodComparisonChart
                                summaryData={props.currentPeriodSummary}
                                periodsToCompare={props.periodsToCompare}
                            />}
                    </View>
                </DataTable.Row> : null}
        </View>, [props.dataPeriods]);


    return (
        <>
            <ScrollView horizontal={isSmartPhone ? true : false}>
                <DataTable
                    style={{ backgroundColor: '#ffffff', width: isSmartPhone ? getDisplayHeight() * 0.6 : undefined }}>
                    <DataTable.Header>
                        <DataTable.Title style={{ flex: 3 }}>
                            <Text style={styles.chartTitle2}>
                                Compare with
                        </Text>
                        </DataTable.Title>
                        <DataTable.Title>
                            <Text style={styles.chartTitle2}>
                                TXN
                        </Text>
                        </DataTable.Title>
                        <DataTable.Title>
                            <Text style={styles.chartTitle2}>
                                Sales
                        </Text>
                        </DataTable.Title>
                    </DataTable.Header>
                    {!props.dataPeriods && <ProgressBar indeterminate color={colors.primary} />}
                    {props.dataPeriods && props.dataPeriods.map((data, index) => (renderRow(data, index)))}
                </DataTable>
            </ScrollView >
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    wrapperChart: {
        backgroundColor: '#ffffff',
        borderRadius: 5,
        width: '100%'
    },
    chartTitle1: {
        fontFamily: 'Montserrat_Bold',
        fontSize: 24,
        padding: 5,
    },
    chartTitle2: {
        fontFamily: 'Montserrat_Bold',
        fontSize: isWebPlatform() ? 18 : 14,
        padding: 5,
    },
    chartText: {
        fontFamily: 'Montserrat_Light',
        fontSize: isWebPlatform() ? 16 : 12,
        padding: 5,
    },
    chartHeader: {
        backgroundColor: '#d23232',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 40,
        borderRadius: 5,
        marginVertical: 10
    },
    borderDash: {
        borderStyle: 'dashed',
        borderColor: '#D1D2DE',
        backgroundColor: '#FFFFFF',
        borderBottomWidth: 2
    },
});

export default PeriodComparisonWidget;