import { convertAPIError } from '../../../../lib/Transactions/transactions';
import { onRequestPassword } from '../../../TwoFactorAuth/reducer/actions/onRequestPassword';
import { resetTwoFactorAuth } from '../../../TwoFactorAuth/reducer/actions/resetTwoFactorAuth';
import { SEND_PIN } from '../../../TwoFactorAuth/reducer/actions/sendTwoFactorAuthPin.graphql';
import { setToken } from '../../../TwoFactorAuth/reducer/actions/setToken';
import { ContextType } from '../context';
import { Action, registerReducer } from '../reducer';
import { State } from '../state';

const NAME = 'sendTwoFactorAuthPin';

interface Data extends Action {
    username?: string,
    password?: string,
    customerToken?: string,
    protocol: 'SMS' | 'EMAIL',
    target?: string,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        waitingPin: true,
        protocol: data.protocol,
        target: data.target,
        sendingPin: true,
        pin: undefined,
        token: undefined,
        onConfirmPIN: null,
    };
}

export const sendTwoFactorAuthPin = (context: ContextType, data: Omit<Data, 'type'>): Promise<string> => {
    return new Promise((sendPinSuccess, sendPinError) => {
        context.dispatch({ type: NAME, ...data });

        const { username, password, protocol, target, customerToken } = data;

        context.apolloGraphQL.client.mutate({
            mutation: SEND_PIN,
            variables: {
                target,
                username,
                password,
                customerToken,
                protocol,
            },
        }).then(({ data: { customers: { twoFactorAuth: { token, target } } } }) => {
            // logger.debug('Two Factor Authentication PIN sent successfully');
            if (context.userActivity) {
                context.userActivity.info('Two Factor Authorization Code has been sent, waiting for user');
            }
            setToken(context, { token, target });
            sendPinSuccess(token);
        }).catch((error: PaynUpAPIError) => {
            // logger.debug('Two Factor Authentication PIN sent failed', { error });

            let errorMessage = null;
            let errorCode = 0;
            if (error.graphQLErrors.length) {
                errorCode = error.graphQLErrors[0].code;
            } else if (error.networkError) {
                errorCode = error.networkError.statusCode;
            }

            // password required
            if (errorCode === 1107) {
                onRequestPassword(context, {
                    callback: (password) => {
                        if (!password) {
                            sendPinError(error);
                            return;
                        }

                        sendTwoFactorAuthPin(context, {
                            ...data,
                            password
                        }).then(sendPinSuccess).catch(sendPinError);
                    }
                });
                return;
            }

            switch (errorCode) {
                case 401:
                    errorMessage = 'Unauthorized, Check your credentials';
                    break;
                case 500:
                    errorMessage = 'Unknown Error, try again later.';
                    break;
                default:
                    errorMessage = convertAPIError(error).message;
            }

            if (context.userActivity) {
                context.userActivity.warning(`Error sending Two Factor Authentication Code: ${errorMessage ? errorMessage : error.message}`);
            }

            // due to request by Mesh, ignore errors containing a update mesh url
            // are redirected after login failed
            if (String(errorMessage).match( new RegExp(/members\.mymesh\.money\/update\/customer_profile/))){
                sendPinError(error);
                resetTwoFactorAuth(context);
                return;
            }

            sendPinError(error);
            context.notify.error(errorMessage);
            resetTwoFactorAuth(context);
        });
    });
};
