import * as yup from 'yup';

export enum InputFieldType {
    boolean = 'boolean',
    date = 'date',
    datetime = 'datetime',
    email = 'email',
    float = 'float',
    integer = 'integer',
    magdata = 'magdata',
    phone = 'phone',
    select = 'select',
    string = 'string',
    time = 'time',
    autocomplete = 'autocomplete',
}

export interface InputField {
    name: string;
    label: string | null;
    required: boolean | null;
    type: InputFieldType;
    html: InputFieldHtml | null;
    help: string,
    allowMultiple: boolean,
    choices: { [index: string]: string },
}

export interface InputFieldHtml {
    max: number | null;
    maxlength: number | null;
    min: number | null;
    minlength: number | null;
    pattern: string | null;
    patternMessage: string | null;
    type: string;
    value: any
}

/**
 * Generate valid yup schema for given dynamic input
 */
export const inputFieldToYupSchema = (input: InputField): yup.BooleanSchema | yup.NumberSchema | yup.StringSchema | yup.DateSchema => {
    let inputSchema: yup.BooleanSchema | yup.NumberSchema | yup.StringSchema | yup.DateSchema = null;

    switch (input.type) {

        case InputFieldType.boolean:
            inputSchema = yup.boolean();
            break;
        case InputFieldType.float:
        case InputFieldType.integer:
            inputSchema = yup.string()
                .matches(
                    /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/,
                    "Only numeric characters greater than 0 are supported"
                )
                .test(
                    "isValidRange",
                    `Allowed must range between ${input.html.min} and  ${input.html.max}`,
                    (value) => {
                        const money = parseFloat(value ? value : "0");
                        if (input.html.min && money < input.html.min) {
                            return false;
                        } else if (input.html.max && money > input.html.max) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                )
            break;
        case InputFieldType.date:
        case InputFieldType.datetime:
            inputSchema = yup.date();
            break;
        case InputFieldType.email:
            inputSchema = yup.string().email();
            break;
        default:
            inputSchema = yup.string().ensure();
            if (input.html.maxlength) {
                inputSchema = inputSchema.max(input.html.maxlength);
            }
            if (input.html.minlength) {
                inputSchema = inputSchema.min(input.html.minlength);
            }


            break;
    }

    if (input.html && input.html.pattern && 'matches' in inputSchema) {
        const flags = input.html.pattern.replace(/.*\/([gimy]*)$/, '$1');
        const pattern = input.html.pattern.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');
        inputSchema = inputSchema.matches(new RegExp(pattern, flags), input.html.patternMessage)
    }

    if (input.label) {
        inputSchema = inputSchema.label(input.label);
    }

    if (input.required) {
        inputSchema = inputSchema.required();
    }

    if (input.html.value) {
        inputSchema = inputSchema.default(input.html.value);
    }

    return inputSchema;
};
