import React from 'react';
import { TextInput, useTheme } from 'react-native-paper';
import { InputHelperError } from './InputHelperText';
import moment from 'moment';

interface Props {
    onChange: (value) => void
    onBlur: () => void,
    value: any,
    error: any,
}

/**
* @function Component InputDate
* @description Input Mask date MM/YY  
* @param {Object} value Input value
* @param {Object} error Input errors
* @param {Function} onChange Callback handle change input
* @param {Function} onBlur Callback handle blur input
*/
const InputDate = ({ onChange, onBlur, error, value }: Props) => {
    const { colors } = useTheme();

    const mask = [/\d/, /\d/, '/', /\d/, /\d/];
    const format = 'MM/YY';

    const isValid = (v) => moment(v, format, true).isValid();

    const maskDate = (date: string) => {
        var results = date.match(/\d{1,2}/g);
        return date ? results?.join("/") : '';
    };

    return (
        <>
            <TextInput
                label="MM/YY"
                onChangeText={(v) => {
                    if (isValid(v)) {
                        onChange(moment(v, format).toDate());
                    } else {
                        onChange(v);
                    }
                }}
                value={typeof value === 'object' ? moment(value).format(format) : maskDate(value)}
                onBlur={onBlur}
                maxLength={5}
                mode="outlined"
                style={{ backgroundColor: colors.surface, textAlign: "auto" }}
                left={<TextInput.Icon name="calendar-today" size={28} />}
                keyboardType="number-pad"
            />
            <InputHelperError isError={!!error.cardExp} message={error.cardExp?.message} />
        </>
    );
}

export default InputDate;