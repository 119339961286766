import { ContextType } from './../context';
import { Action, registerReducer } from './../reducer';
import { MarketWizardStep, State } from './../state';

const NAME = 'goToStep';

interface Data extends Action {
    step: MarketWizardStep,
}

registerReducer(NAME, reducer);

function reducer(state: State, data: Data): State {

    return {
        ...state,
        wizard:{
            ...state.wizard,
            currentStep: data.step,
        },
        sidebar:{
            ...state.sidebar,
            opened: false,
        }
    };
}

export const goToStep = (context: ContextType, data: Omit<Data, 'type'>) =>
    context.dispatch({ type: NAME, ...data });
