import React, { ReactNode, useState } from 'react';
import { SafeAreaView, View } from 'react-native';
import DropDown from './DropDownComponent';
import { InputHelperInfo } from '../CustomInputs/InputHelperText';
import { Controller } from 'react-hook-form';

interface Props {
    name: string,
    control?: any,
    data: Array<{ label: string; value: string | number; custom?: ReactNode; }>,
    defaultValue?: number | string | undefined,
    onSelectionChange: (value: number | string) => void,
    infoMessage?: string,
    inputPropsLeft?: JSX.Element,
    inputPropsRight?: JSX.Element
}

/**
* @function Component DropDownComponent
* @param {Array} data Array items
* @param {Object} control Form control (optional only if dropdown is controlled)
* @param {Function} name Name control (optional only if dropdown is controlled)
* @param {number|string} defaultValue Default value selected
* @param {string} infoMessage Helper info
* @param {Function} onSelectionChange Callback handle selection item
* @param {JSX.Element} inputPropsLeft Content left side of input
* @param {JSX.Element} inputPropsRight Content right side of input
*/
function DropDownComponent({ name, data, control, defaultValue, inputPropsLeft, inputPropsRight, onSelectionChange, infoMessage }: Props) {
    const [showDropDown, setShowDropDown] = useState(false);
    const [dropDownValue, setDropdownValue] = useState(defaultValue);

    return (
        <>
            {control ?
                <Controller
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                        <SafeAreaView>
                            <DropDown
                                mode='flat'
                                value={value}
                                setValue={(value) => {
                                    onChange(value);
                                    onSelectionChange(value);
                                }}
                                list={data}
                                visible={showDropDown}
                                showDropDown={() => setShowDropDown(true)}
                                onDismiss={() => setShowDropDown(false)}
                                inputProps={{
                                    left: inputPropsLeft ? inputPropsLeft : undefined,
                                    right: inputPropsRight ? inputPropsRight : undefined
                                }}
                            />
                            {infoMessage && <InputHelperInfo message={infoMessage} />}
                        </SafeAreaView>)
                    }
                    defaultValue={!defaultValue ? '' : defaultValue}
                    name={name}
                /> :
                <View >
                    <SafeAreaView>
                        <DropDown
                            mode='flat'
                            value={dropDownValue}
                            setValue={(value) => {
                                setDropdownValue(value);
                                onSelectionChange(value);
                            }}
                            list={data}
                            visible={showDropDown}
                            showDropDown={() => setShowDropDown(true)}
                            onDismiss={() => setShowDropDown(false)}
                            inputProps={{
                                left: inputPropsLeft ? inputPropsLeft : undefined,
                                right: inputPropsRight ? inputPropsRight : undefined
                            }}
                        />
                        {infoMessage && <InputHelperInfo message={infoMessage} />}
                    </SafeAreaView>
                </View>}
        </>
    );
}

export default DropDownComponent;