import React from "react";
import { TouchableWithoutFeedback, Keyboard } from "react-native";

/**
 * @function Component DismissKeyboard
 * @description Wrapper to touch outside input dismiss keyboard
 */
const DismissKeyboard = ({ children }) => (
  <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
    {children}
  </TouchableWithoutFeedback>
);

export default DismissKeyboard;
