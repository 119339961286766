import { ApolloGraphQL } from '../../../ApolloGraphQL/hook/useGraphqlApolloClient';
import React, { Dispatch } from 'react';
import { Action } from './reducer';
import { State } from './state';
import { SubmitTransaction } from '../../../common_modules/providers/SubmitTransaction/hook/useSubmitTransaction';
import { Notification } from '../../../common_modules/providers/Notification/hook/useNotification';

export interface ContextType {
    readonly _type?: 'Market',
    dispatch: Dispatch<Action>,
    state: State,
    apolloGraphQL: ApolloGraphQL,
    notify: Notification
    submitTransaction: SubmitTransaction
}

export const MarketContext = React.createContext<ContextType>(null);
export default MarketContext;
