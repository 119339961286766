import React, { memo } from "react";
import { View, StyleSheet } from "react-native";
import { Text, Avatar, useTheme } from "react-native-paper";

interface Props {
  target?: string;
  icon?: string;
}

/**
 * @function Component Empty
 * @param {string} target Ref name of empty list
 * @param {string} icon Icon name identifier
 * @description Subscribe event keyboard to hidden or show children component
 */
const Empty = ({ target, icon }: Props): JSX.Element => {
  const { colors } = useTheme();

  return (
    <View style={styles.container}>
      <Avatar.Icon
        size={100}
        color={colors.dividers}
        icon={icon ? icon : "inbox"}
        style={styles.icon}
      />
      <Text style={[styles.text, { color: colors.dividers }]}>
        Not {target ? target : "records"} found!{" "}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: "transparent",
  },
  text: {
    marginTop: 0,
    paddingTop: 0,
    fontSize: 12,
    textAlign: "center",
  },
});

export default memo(Empty);
