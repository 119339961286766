import { REGISTER_CUSTOMER, TWO_FACTOR_AUTH, VERIFY_CUSTOMER_TARGET } from '../SignUp.graphql'
import { ContextType } from '../context'
import { ProtocolTypes } from './actionTypes'
import { RESET_PASSWORD, RESET_PASSWORD_TWO_FACTOR_AUTH } from '../ResetPassword.graphql';
import { captureException } from '../../../../lib/sentry';
import { PaynUpAPIError } from '../../../../providers/ApolloGraphQL/ApolloGraphQL';

export const getTwoFactorAuth = (context: ContextType, protocol: ProtocolTypes, target?: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        context.apolloGraphQL.client.mutate({
            mutation: TWO_FACTOR_AUTH,
            fetchPolicy: 'no-cache',
            variables: { protocol, customerToken: context.apolloGraphQL.clientWithToken, target }
        }).then(({ data }) => {
            const token = data.customers.twoFactorAuth.token;
            context.dispatch({ type: 'REQUEST_PIN', twoFactorAuthToken: token, protocol })
            resolve(true);
        }).catch((error) => {
            context.dispatch({ type: 'OPERATION_FAILED', processWithError: error.message })
            context.notification.error(error.message)
            captureException(error);
            resolve(false);
        })
    })
}

export const registerCustomerAccount = async (context: ContextType, twoFactorAuthPIN: string) => {
    try {
        const customerAccount = {
            ...context.state.customer,
            twoFactorAuthToken: context.state.twoFactorAuthToken,
            twoFactorAuthPIN: twoFactorAuthPIN,
        }
        const { data } = await context.apolloGraphQL.client.mutate({
            mutation: REGISTER_CUSTOMER,
            variables: { input: customerAccount }
        });
        context.dispatch({ type: 'OPERATION_COMPLETED' })
        context.notification.success('Congratulations, registration completed!')

    } catch (error) {
        const err = error as PaynUpAPIError;
        if (err.graphQLErrors && err.graphQLErrors?.length > 0 && err.graphQLErrors[0].code == 1105) {
            context.dispatch({ type: 'INVALID_PIN' });
            context.notification.error('Invalid Pin!');
        } else {
            context.notification.error(error.message);
            context.dispatch({ type: 'OPERATION_FAILED' })
        }
        captureException(error);
    }
}

export const resetPasswordTwoFactorAuth = async (context: ContextType, protocol: ProtocolTypes, username: string) => {
    try {
        const { data } = await context.apolloGraphQL.client.mutate({
            mutation: RESET_PASSWORD_TWO_FACTOR_AUTH,
            variables: { protocol, username },
            fetchPolicy: 'no-cache',
        });

        const token = data.customers.resetPasswordRequest.token;
        context.dispatch({ type: 'REQUEST_PIN', twoFactorAuthToken: token, protocol })

    } catch (error) {
        captureException(error);
        context.dispatch({ type: 'OPERATION_FAILED' })
        context.notification.error(error.message)
    }
}

export const resetPassword = async (context: ContextType, twoFactorAuthPIN: string) => {
    try {
        const reset = {
            username: context.state.target,
            newPassword: context.state.password,
            twoFactorAuthToken: context.state.twoFactorAuthToken,
            twoFactorAuthPIN,
        }
        const { data } = await context.apolloGraphQL.client.mutate({
            mutation: RESET_PASSWORD,
            fetchPolicy: 'no-cache',
            variables: { ...reset }
        });
        context.dispatch({ type: 'OPERATION_COMPLETED' })
        context.notification.success('Your password has been changed successfully!')

    } catch (error) {
        captureException(error);
        const err = error as PaynUpAPIError;
        if (err.graphQLErrors?.length) {
            if (err.graphQLErrors[0].code == 1105) {
                context.dispatch({ type: 'INVALID_PIN' });
                context.notification.error('Invalid Pin!');
            }
        } else {
            context.notification.error(error.message);
            context.dispatch({ type: 'OPERATION_FAILED' })
        }
    }
}

export const verifyTarget = async (context: ContextType, twoFactorAuthPIN: string) => {
    try {
        const { data } = await context.apolloGraphQL.client.mutate({
            mutation: VERIFY_CUSTOMER_TARGET,
            fetchPolicy: 'no-cache',
            variables: {
                customerToken: context.apolloGraphQL.clientWithToken,
                twoFactorAuthPIN,
                twoFactorAuthToken: context.state.twoFactorAuthToken
            }
        });
        context.market.loadMarketInfo();
        context.dispatch({ type: 'OPERATION_COMPLETED' })
        context.notification.success(`Your ${context.state.protocol} has been validated successfully!`)

    } catch (error) {
        captureException(error);
        const err = error as PaynUpAPIError;
        if (err.graphQLErrors?.length) {
            if (err.graphQLErrors[0].code == 1105) {
                context.dispatch({ type: 'INVALID_PIN' });
                context.notification.error('Invalid Pin!');
            }
        } else {
            context.notification.error(error.message);
            context.dispatch({ type: 'OPERATION_FAILED' })
        }
    }
}