import React, {useEffect, useState} from "react";
import {Card, useTheme, Text, Button} from "react-native-paper";
import {StyleSheet, View, Image, Platform} from "react-native";
import {CustomText} from "../Text/Text";
import {getPrice} from "../../lib/Transactions/transactions";
import {
    StoreInfo,
    SubmitTransactionPayload,
    Clerk,
} from "../../../screens/Market/reducer/state";
import moment from "moment";
import {Adjustment} from "../../model/Adjustment";
import SvgQRCode from "react-native-qrcode-svg";
import * as Print from "expo-print";
import * as Sharing from "expo-sharing";
import {ReceiptCardsHtml, SimpleReceiptCardsHtml} from "../../lib/PrintHtml";
import axios from "axios";
import {useNotification} from "../../providers/Notification/hook/useNotification";
import {isWebPlatform} from "../../lib/PlatformDevice";
import {appIdentifier} from "../../../identifier";

interface Props {
    storeInfo: StoreInfo;
    payload: SubmitTransactionPayload;
    clerk: Clerk;
}

/**
 * @function Component Card Receipt
 * @param {StoreInfo} storeInfo StoreInfo
 * @param {SubmitTransactionPayload} payload Payload
 * @param {Clerk} clerk Clerk
 */
const CardReceipt = ({storeInfo, payload, clerk}: Props) => {
    const {colors} = useTheme();
    const [qrBase64, setQrBase] = useState("");
    const [loadingPrint, setLoadingPrint] = useState(false);
    const {error} = useNotification();
    const isMerchant = appIdentifier === "MERCHANT";

    const [selectedPrinter, setSelectedPrinter] = React.useState();

    const divider = (
        <Image
            style={{
                resizeMode: "stretch",
                height: 16,
                marginVertical: 20,
                width: "100%",
            }}
            source={require("../../../../assets/img/ticketSeparator.png")}
        />
    );

    let qrRef: any;

    useEffect(() => {
        if (qrRef && !isWebPlatform())
            qrRef.toDataURL((value: string) => setQrBase(value));
    }, [qrRef]);

    const handlePrint = async () => {
        setLoadingPrint(true);
        if (!isWebPlatform()) {
            const html = ReceiptCardsHtml(storeInfo, clerk, payload, qrBase64);
            const data = await Print.printToFileAsync({html});
            setLoadingPrint(false);
            Sharing.shareAsync(data.uri);
        } else {
            axios(payload.transaction.customerReceiptPdf, {
                method: "GET",
                responseType: "blob", //Force to receive data in a Blob Format
            })
                .then((response) => {
                    //Create a Blob from the PDF Stream
                    const file = new Blob([response.data], {type: "application/pdf"});
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                    setLoadingPrint(false);
                })
                .catch((e) => {
                    console.error(e);
                    error(`Sorry, an error occurred while generating the pdf file`);
                    setLoadingPrint(false);
                });
        }
    };

    const handlePrinter = async () => {
        setLoadingPrint(true);
        const html = SimpleReceiptCardsHtml(storeInfo, clerk, payload, qrBase64);
        setLoadingPrint(false);
        await Print.printAsync({html});
    };

    return (
        <Card>
            <Card.Content>
                <CustomText type="eBold" size={25}>
                    {payload.transaction?.product.carrier.name}
                </CustomText>
                <View style={{paddingTop: 10}}>
                    <CustomText type="light" size={12}>
                        {moment(payload.transaction?.date).format("LLLL")}
                    </CustomText>
                    <CustomText type="light" size={12}>
                        Order No.{" "}
                        <CustomText type="bold">{payload.transaction.number}</CustomText>
                    </CustomText>
                    <CustomText type="light" size={12}>
                        Cashier:{" "}
                        <CustomText type="bold">
                            {isMerchant ? clerk.name : storeInfo.name}
                        </CustomText>
                    </CustomText>
                </View>
            </Card.Content>
            {divider}
            <Card.Content>
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Text style={{width: "60%"}}>
                        {payload.transaction?.product.category.name} /{" "}
                        <CustomText type="bold">
                            {payload.transaction.product.name}
                        </CustomText>
                    </Text>
                    <CustomText type="bold">
                        {getPrice(payload.transaction?.grossTotal)}
                    </CustomText>
                </View>
                {payload.transaction?.adjustments.map(
                    (adjustment: Adjustment, index) => (
                        <View key={index} style={styles.container}>
                            <Text>
                                {adjustment.name}{" "}
                                {adjustment.rateMode ? `(${adjustment.amount}%)` : ""}
                            </Text>
                            <Text
                                style={{alignSelf: "flex-start", alignItems: "flex-start"}}
                            >
                                {getPrice(adjustment.total, false)}
                            </Text>
                        </View>
                    )
                )}
            </Card.Content>
            {divider}
            <Card.Content>
                <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                    <CustomText type="sBold" size={24}>
                        Total
                    </CustomText>
                    <CustomText type="bold" size={24}>
                        {getPrice(payload.transaction.total)}
                    </CustomText>
                </View>
                {payload.transaction?.product.isPIN && (
                    <View style={{alignItems: 'center'}}>
                        {payload.transaction.response.pin &&
                            <>
                                <View style={{marginTop: 10}}>
                                    <SvgQRCode size={100} value={payload.transaction.response.pin}/>
                                </View>
                                <View style={{marginTop: 10}}>
                                    <CustomText type="bold" size={18}>
                                        PIN: {payload.transaction.response.pin}
                                    </CustomText>
                                </View>
                            </>
                        }
                        {payload.transaction.response.redemptionUrl &&
                            <>
                                <View style={{marginTop: 10}}>
                                    <SvgQRCode size={100} value={payload.transaction.response.redemptionUrl}/>
                                </View>
                                <View style={{marginTop: 10}}>
                                    <CustomText type="bold" size={18}>
                                        LINK: {payload.transaction.response.redemptionUrl}
                                    </CustomText>
                                </View>
                            </>
                        }
                    </View>
                )}
                {/* <View style={{ justifyContent: "flex-start", marginTop: 10 }}>
          <CustomText type="light" size={11} align="left">
            Scan the QR code and it will take you directly to our E-Commerce
            Site so you can safely and conveniently topup your cellphone and
            friends and family using your cellphone, tablet, computer, or any
            internet capable device.
          </CustomText>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <View style={{ flexDirection: "column" }}>
            <SvgQRCode
              value={`https://paynup.com/site/${storeInfo.number}`}
              getRef={(c) => (qrRef = c)}
            />
          </View> */}
                <View>
                    {/*<Button*/}
                    {/*    mode="contained"*/}
                    {/*    onPress={handlePrint}*/}
                    {/*    loading={loadingPrint}*/}
                    {/*    disabled={loadingPrint}*/}
                    {/*    style={{marginTop: 20}}*/}
                    {/*>*/}
                    {/*    PRINT RECEIPT*/}
                    {/*</Button>*/}
                    {/*{Platform.OS === "android" &&*/}
                    <Button
                        mode="contained"
                        onPress={handlePrint}
                        loading={loadingPrint}
                        disabled={loadingPrint}
                        style={{marginTop: 20}}
                    >
                        PRINT RECEIPT
                    </Button>
                    {/*}*/}
                    <View style={{marginTop: 10}}>
                        <CustomText align="center" type="sBold" color={colors.primary}>
                            Thanks you for using our services
                        </CustomText>
                    </View>
                </View>
                {/* </View> */}
            </Card.Content>
        </Card>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 10,
    },
});

export default CardReceipt;
